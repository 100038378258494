<div
    class="tier-card tier-{{
        product.tier ?? '1'
    }} rounded-lg flex flex-col py-4 px-2.5"
>
    <div class="flex items-center">
        @for (star of stars; track $index) {
            <div class="me-2.5">
                <svg class="w-4 h-4 fill-white">
                    <use href="../../../assets/svg/svGP.svg#star"></use>
                </svg>
            </div>
        }
    </div>
    <div class="mt-4">
        <h3 class="font-bold text-white text-lg">{{ product.name }}</h3>
    </div>
    <div class="mt-4">
        <span class="font-bold text-white text-lg">
            @if (voucher) {
                <span class="text-sm line-through text-light-grey">{{ voucher.originalPrice | currency: '':'':(voucher.originalPrice % 1 == 0) ? '1.0-0': '1.2-2' }} €</span>
                {{ voucher.discountedPrice | currency: '':'':(voucher.discountedPrice % 1 == 0) ? '1.0-0': '1.2-2' }} €
            } @else {
                {{ product.price | currency: '':'':(product.price % 1 == 0) ? '1.0-0': '1.2-2'  }} €
            }
            <span class="font-normal text-white text-base">/
                {{ "components.tier-card." + (product.subscriptionRecurringInterval == null || product.subscriptionRecurringInterval === 1 ?
                    product.subscriptionRecurringPeriod :
                    ('' + product.subscriptionRecurringInterval + product.subscriptionRecurringPeriod)) + "Label" | transloco }}</span>
        </span>
    </div>
    <div class="mt-4" *ngIf="showDescription">
        <p class="m-0 text-white">{{ product.description }}</p>
    </div>
    <div class="mt-4" *ngIf="showActionButton">
        <button
            (click)="onActionButtonClick()"
            [disabled]="disabledActionButton"
            class="py-2 px-4 border-0 bg-white rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-primary text-sm font-bold">{{
                        actionButtonLabel
                    }}</span>
            </div>
        </button>
    </div>
</div>
