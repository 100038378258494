<health-data-page
    [chartLabel]="chartLabel"
    [chartConfiguration]="chartConfiguration"
    [chartValueUnitSymbol]="healthDataUnitSymbolLabel"
    [healthDataLabel]="healthDataLabel"
    [healthDataType]="weightHealthDataType"
    [healthDataUnitSymbolLabel]="healthDataUnitSymbolLabel"
    [hasTwoDecimals]="hasTwoDecimals"
    [payloadTypes]="payloadTypes"
/>
