import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UserService } from '../../../../../../services/user.service';
import { Router, RouterModule } from '@angular/router';
import { confirmPasswordValidator } from '../../../../../../shared/validators/confirm-password.validator';
import { AbstractControlOptions } from '@angular/forms';
import { Validators } from '@angular/forms';
import { first } from 'rxjs';
import { ChangeCurrentPasswordForm } from '../../../../../../models/formgroups/change-current-password-form.model';
import { NavigationService } from '../../../../../../services/navigation.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../../../../components/error-modal/error-modal.component';
import { LogService } from '../../../../../../services/log.service';
import { ModalService } from '../../../../../../services/modal.service';
import { GenericModalComponent } from '../../../../../../components/generic-modal/generic-modal.component';
import { APP_ROUTES } from '../../../../../../app.routes.definition';
import { InfoModalComponent, InfoModalData } from '../../../../../../components/info-modal/info-modal.component';

@Component({
    selector: 'change-password-page',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        GenericModalComponent,
        RouterModule,
        TranslocoModule,
    ],
    templateUrl: './change-password-page.component.html',
})
export default class ChangePasswordPageComponent {
    #router = inject(Router);
    #fb = inject(FormBuilder);
    #userService = inject(UserService);
    #navigationService = inject(NavigationService);
    #logService = inject(LogService);
    #modalService = inject(ModalService);

    changeCurrentPasswordForm!: FormGroup<ChangeCurrentPasswordForm>;
    settingsPath = APP_ROUTES.SETTINGS(false);

    isLoading: boolean = false;

    constructor() {
        this.initializeForm();
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }

    initializeForm() {
        this.changeCurrentPasswordForm = this.#fb.nonNullable.group(
            {
                currentPassword: [
                    '',
                    [Validators.required, Validators.minLength(8)],
                ],
                newPassword: [
                    '',
                    [Validators.required, Validators.minLength(8)],
                ],
                confirmPassword: ['', [Validators.required]],
            },
            {
                validator: confirmPasswordValidator(
                    'newPassword',
                    'confirmPassword'
                ),
            } as AbstractControlOptions
        );
    }

    onSubmitChangePassword() {
        if (this.changeCurrentPasswordForm.invalid || this.isLoading) return;
        const { currentPassword, newPassword } =
            this.changeCurrentPasswordForm.value;

        this.isLoading = true;

        this.#userService
            .changeUserPassword(currentPassword!, newPassword!)
            .pipe(first())
            .subscribe({
                error: (error) => {
                    this.isLoading = false;
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                        },
                    });
                },
                complete: () => {
                    this.isLoading = false;
                    this.#modalService.open<InfoModalData, InfoModalComponent>(
                        InfoModalComponent,
                        {
                            data: {
                                title: 'Cambio password effettuato',
                                message:
                                    'Password correttamente cambiata',
                            },
                        }
                    );
                },
            });
    }

    goBack() {
        this.#navigationService.navigateBack();
    }
}
