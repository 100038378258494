import { Component, OnInit, inject } from '@angular/core';

import { ChartConfiguration } from 'chart.js';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationService } from '../../../../../../services/navigation.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {
    HealthChartPeriod,
    HealthDataAggregateOperation,
    HealthDataPeriod,
    HealthDataType
} from "../../../../../../models/health.models";
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-sleep-in-bed-page',
    standalone: true,
    imports: [HealthDataPageComponent, AsyncPipe, TranslocoModule, NgClass],
    templateUrl: './sleep-in-bed-page.component.html',
})
export default class SleepInBedPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Sonno';
    chartLabel = 'Sonno';
    healthDataUnitSymbolLabel = 'ore';
    sleepInBedHealthDataType = HealthDataType.SLEEP_IN_BED;
    hasTwoDecimals = false;
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];
    payloadTypes: dataType[] = ['sleep'];
    HealthDataAggregateOperation = HealthDataAggregateOperation;

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
