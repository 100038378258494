import {createReducer, on} from '@ngrx/store';
import {CreateOrderState} from './create-order.state';
import {
    cancelOrder, cancelOrderFailure, cancelOrderSuccess,
    clearCreateOrder, completeOrder, completeOrderFailure,
    createOrder,
    createOrderFailure,
    createOrderSuccess,
} from './create-order.actions';

const initialState: CreateOrderState = {
    data: null,
    error: null,
    isLoading: false,
};

export const createOrderReducer = createReducer(
    initialState,
    on(createOrder, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(createOrderSuccess, (state, {data}) => ({
        ...state,
        data,
        error: null,
        isLoading: false,
    })),
    on(createOrderFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(completeOrder, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(completeOrderFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(cancelOrder, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(cancelOrderSuccess, (state) => ({
        ...state,
        data: null,
        error: null,
        isLoading: false,
    })),
    on(cancelOrderFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(clearCreateOrder, () => initialState),
);
