<div class="survey-page bg-body-bg h-full flex flex-col">
    <app-registration-dash-icon>
        <svg class="w-14 h-auto fill-primary">
            <use href="../assets/svg/svGP.svg#paste-clipboard"></use>
        </svg>
    </app-registration-dash-icon>
    <div class="mt-10 h-full flex flex-col justify-between items-center">
        <div class="text-center px-5">
            <h1 class="text-primary font-semibold text-3xl">
                Prima di continuare, completa il nostro questionario.
            </h1>
        </div>
        <div>
            <img
                src="../assets/img/illustrations/illustration-survey.png"
                class="overflow-x-hidden w-18/12"
                alt=""
            />
        </div>
        <div class="m-5 w-full px-5">
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                [routerLink]="surveyPath"
            >
                <div class="flex items-center">
                    <span class="text-white text-sm font-bold uppercase">Inizia il questionario</span>
                </div>
            </button>
        </div>
    </div>
</div>
