import {inject, Injectable} from '@angular/core';
import {LogService} from "./log.service";

declare global {
    interface Window {
        dataLayer?: GoogleAnalyticsEvent[] | undefined;
    }
}

export interface GoogleAnalyticsEvent {
    eventName: string;
    event?: 'analytics_event' | string;

    [key: string]: any;
}

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    #logService = inject(LogService);

    constructor() {
    }

    sendAnalyticsEvent(data: GoogleAnalyticsEvent) {
        if (data.event == null) {
            data.event = 'analytics_event';
        }
        window.dataLayer?.push(data);
    }
}
