@if (!isError) {
<section class="h-full mx-6 pb-28">
    <div class="flex flex-col justify-between h-full">
        <div>
            <div class="flex items-center justify-center">
                <img class="size-14" src="../assets/img/emoji/grin.png" alt />
            </div>
            <div class="mt-5 text-center">
                <h1 class="font-bold text-xl">Grazie</h1>
            </div>
            <div class="mt-4 text-center">
                <p class="text-sm">
                    Complimenti! Hai completato l’acquisto con successo.
                </p>
            </div>
            <div class="mt-14 mx-5">
                <img
                    src="../assets/img/buy-base-plan-carousel/completato-acquisto-successo.png"
                    class="w-full rounded-lg"
                    alt />
            </div>
        </div>
        <div class="mt-5">
            <a
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                [routerLink]="homePath">
                <div>
                    <span class="text-white text-sm font-bold uppercase">Torna
                        alla home</span>
                </div>
            </a>
        </div>
    </div>
</section>
} @else {
<section class="h-full mx-6 pb-28">
    <div class="flex flex-col justify-between h-full">
        <div>
            <div class="flex items-center justify-center">
                <img
                    class="size-14"
                    src="../assets/img/emoji/dizzy_face.png"
                    alt />
            </div>
            <div class="mt-5 text-center">
                <h1 class="font-bold text-xl">Errore</h1>
            </div>
            <div class="mt-4 text-center">
                <p class="text-sm">
                    C'è stato un errore con il suo pagamento!
                </p>
            </div>
            <div class="mt-14 mx-5">
                <img
                    src="../assets/img/buy-base-plan-carousel/abbiamo-riscontrato-errore.png"
                    class="w-full rounded-lg"
                    alt />
            </div>
            <div class="mt-4 text-center">
                <p class="text-sm">Clicca sul link sottostante per accedere al
                    modulo di supporto.</p>
                <a class="text-primary cursor-pointer"
                    [routerLink]="supportPath">
                    <u>Compila il modulo di supporto</u>
                </a>
            </div>
        </div>
        <div class="mt-5">
            <a
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                [routerLink]="homePath">
                <div>
                    <span class="text-white text-sm font-bold uppercase">Torna
                        alla home</span>
                </div>
            </a>
        </div>
    </div>
</section>
}
