import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {APP_ROUTES} from "../../../../app.routes.definition";

@Component({
    selector: 'app-navbar-categories',
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive
    ],
    templateUrl: './navbar-categories.component.html',
    styleUrl: './navbar-categories.component.css'
})
export class NavbarCategoriesComponent implements AfterViewInit {
    @ViewChild('list') list!: ElementRef<HTMLUListElement>;

    heartPath = APP_ROUTES.MONITOR_HEART(false);
    bodyPath = APP_ROUTES.MONITOR_BODY(false);
    activityPath = APP_ROUTES.MONITOR_ACTIVITY(false);
    sleepPath = APP_ROUTES.MONITOR_SLEEP(false);

    constructor() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.list.nativeElement.querySelector('.active')?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        });
    }
}
