<app-loader *ngIf="isLoading$ | async as isLoading; else homeContainer"></app-loader>

<ng-template #homeContainer>
    <div class="px-6 pt-0.5 pb-32 relative">
        <section *ngIf="(userTryTerraIsNativeInitializing$ | async) || (userTryTerraIsNativeTriggeringWebhook$ | async)" class="border-2 border-white bg-white rounded-lg py-4 px-3 mb-8 card-shadow-bottom">
            <div class="flex items-center">
                <div class="w-7 -my-7"><span class="loader"></span></div>
                <span class="ms-7 text-sm">Importazione dati di salute in corso...</span>
            </div>
        </section>
        <section *ngIf="(((userTryTerraNativeInitializationError$ | async) && !(userTryTerraIsNativeInitializing$ | async)) ||
                ((userTryTerraNativeTriggerWebhookError$ | async) && !(userTryTerraIsNativeTriggeringWebhook$ | async))) &&
                ((userTryTerraHasNativeHealthConnectConnection$ | async) || (userTryTerraHasNativeAppleConnection$ | async))" class="border-2 border-white bg-white rounded-lg py-4 px-3 mb-8 card-shadow-bottom">
            <div class="flex items-center">
                <div>
                    <svg class="size-7 fill-danger">
                        <use href="../../../assets/svg/svGP.svg#close-circle"></use>
                    </svg>
                </div>
                <div class="flex flex-col justify-center ms-3.5">
                    <span class="text-sm">Importazione dati di salute fallita.</span>
                    @if (currentPlatform === TargetPlatform.android && !healthConnectAvailable && (userTryTerraHasNativeHealthConnectConnection$ | async)) {
                    <span class="text-sm">Per poter continuare, è necessario installare l'App "Connessione Salute" dal Google Play Store per poter poi importare i dati della tua salute.</span>
                    <a (click)="installHealthConnect()" class="mt-2 text-primary underline text-sm font-bold">Installa</a>
                    }
                    <a class="mt-2 text-sm text-primary underline" (click)="requestImportHealthData()">Prova di
                        nuovo</a>
                </div>
            </div>
        </section>
        @if (lastConversation) {
        <!-- TODO: nuovo messaggio  -->
        <!--         <div class="bg-[#f0fdf4] py-1.5 px-2 flex items-center justify-between rounded-lg mt-4 mb-8">-->
        <!--            <div>-->
        <!--                <span class="text-black text-sm">Hai ricevuto un nuovo messaggio!</span>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--                <button class="bg-[#16A34A] py-1.5 px-2 text-white rounded-lg"><strong>Rispondi</strong></button>-->
        <!--            </div>-->
        <!--         </div>-->
        <!-- fine nuovo messaggio -->
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row items-center gap-1.5">
                <h2 class="font-bold">
                    Ultimi messaggi
                </h2>
                <span class="last-conversation-notification"></span>
            </div>
            <button class="btn-primary bg-primary rounded-lg border-0 p-2 flex items-center justify-center" (click)="chatConversationCard.openConversation(lastConversation.conversation)">
                <span class="font-bold text-sm text-white">Rispondi</span>
            </button>
        </div>
        <chat-conversation-card #chatConversationCard [data]="lastConversation" />
        }
        @if (appointments.length > 0) {
        <div [ngClass]="{ 'mt-8': lastConversation }">
            <section class="border-2 border-white bg-white rounded-lg py-4 px-3 card-shadow-bottom">
                <div class="flex items-center justify-between w-full">
                    <div>
                        <h3 class="font-semibold text-base">
                            Prossima sessione
                        </h3>
                    </div>
                    <div class="flex align-items-center justify-end">
                        <button class="py-2 px-2.5 bg-primary rounded-lg" (click)="joinVideoMeeting(appointments[0].id)" [disabled]="isCallTimeInvalid()">
                            <svg class="w-3.5 h-3.5 fill-white">
                                <use href="../../../assets/svg/svGP.svg#videocall-cam-on"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="flex items-center justify-between w-full mt-7">
                    <div class="flex items-center me-3">
                        <div class="ms-1.5">
                            <img class="w-6 h-6 rounded-full border border-light-grey object-cover" [src]="appointments[0].coach.profileImageUrl ?? defaultCoachImg" alt="" />
                        </div>
                        <div class="ms-1.5">
                            <span class="text-xs">{{ appointments[0].coach.firstName }}</span>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="flex items-center ms-3">
                            <div class="flex align-items-center">
                                <svg class="w-3.5 h-3.5 fill-primary translate-y-0.5">
                                    <use href="../../../assets/svg/svGP.svg#Appointments"></use>
                                </svg>
                            </div>
                            <div class="ms-1.5">
                                <span class="appointment-hour text-xs font-normal text-9f9d9d">{{ appointmentDate | customDate }}</span>
                            </div>
                        </div>
                        <div class="flex items-center ms-3">
                            <div class="flex align-items-center">
                                <svg class="w-3.5 h-3.5 fill-primary translate-y-0.5">
                                    <use href="../../../assets/svg/svGP.svg#clock"></use>
                                </svg>
                            </div>
                            <div class="ms-1.5">
                                <span class="appointment-hour text-xs font-normal text-9f9d9d">{{ appointmentTime }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full" [routerLink]="appointmentsPath">
                    <div class="flex items-center justify-center w-full">
                        <span class="text-white text-sm font-bold uppercase">Visualizza tutte</span>
                    </div>
                </button>
            </div>
        </div>
        }
        <div>
            <div [ngClass]="{ 'mt-8': appointments.length > 0 || lastConversation }">
                @if (!hasValidInitialSurvey) {
                <initial-survey-card />
                } @else {
                <how-do-you-feel-card />
                }
            </div>

            @if (userGoals$ | async; as userGoals) {
            <section *ngIf="userGoals.length > 0" class="border-2 border-white bg-white rounded-lg py-4 px-3 mt-8 card-shadow-bottom">
                <div class="flex flex-col">
                    @if (userGoals[0].goal === 'Altro') {
                    <p class="text-sm my-1" *ngIf="userGoals[0].otherGoal && userGoals[0].otherGoal !== ''">
                        <strong>Obiettivo</strong>: {{ userGoals[0].otherGoal }}
                    </p>
                    } @else {
                    <p class="text-sm my-1" *ngIf="userGoals[0].goal && userGoals[0].goal !== ''">
                        <strong>Obiettivo</strong>: {{ userGoals[0].goal }}
                    </p>
                    }
                    <p class="text-sm my-1" *ngIf="userGoals[0].goalSmart && userGoals[0].goalSmart !== ''">
                        <strong>Obiettivo Smart</strong>: {{ userGoals[0].goalSmart }}
                    </p>
                </div>
            </section>
            }

            <ng-container *ngIf="!(userTryTerraIsNativeInitializing$ | async) && !(userTryTerraIsLoading$ | async)">
                <!-- Monitor -->
                <div class="mt-8">
                    <h2 class="font-bold">
                        {{ "pages.home-page.monitoringLabel" | transloco }}
                    </h2>
                </div>

                @if (!(userTryTerraHasProviders$ | async)) {
                <no-wearable-connected-card></no-wearable-connected-card>
                }
                <health-statistic-heart-rate-card />
                <health-statistic-steps-card />

                <div>
                    <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full" [routerLink]="monitoringPath">
                        <div class="flex items-center justify-center w-full">
                            <span class="text-white text-sm font-bold uppercase">Visualizza tutti i monitoraggi</span>
                        </div>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="products$ | async as products">
                @if (products.addonProducts.length > 0) {
                <div class="mt-8">
                    <h2 class="font-bold">Servizi aggiuntivi</h2>
                </div>
                @for (product of products.addonProducts; track product) {
                <div class="mt-4">
                    <product-card [product]="product" />
                </div>
                }
                <div class="pb-5">
                    <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full" [routerLink]="marketplacePath">
                        <div class="flex items-center justify-center w-full">
                            <span class="text-white text-sm font-bold uppercase">Visualizza tutti i pacchetti</span>
                        </div>
                    </button>
                </div>
                }
            </ng-container>
        </div>
    </div>
</ng-template>