import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {NgClass, NgIf} from "@angular/common";
import {NavigationService} from "../../../../services/navigation.service";
import {APP_ROUTES} from "../../../../app.routes.definition";

@Component({
  selector: 'app-plan-description-page',
  standalone: true,
    imports: [
        RouterLink,
        NgIf,
        NgClass
    ],
  templateUrl: './plan-description-page.component.html',
  styleUrl: './plan-description-page.component.css'
})
export default class PlanDescriptionPageComponent implements OnInit {
    #router = inject(Router);
    #navigationService = inject(NavigationService);

    selectPlanPath = APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN();

    currentStep = 0;
    maxSteps = 3;
    steps: any[] = [];

    constructor() {
        this.steps = new Array(this.maxSteps);
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }

    previousStep() {
        if (this.currentStep - 1 < 0) {
            return;
        }
        this.currentStep--;
    }

    nextStep() {
        if (this.currentStep + 1 >= this.maxSteps) {
            this.#router.navigate([APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN()]);
            return;
        }
        this.currentStep++;
    }
}
