<div class="w-full h-full fixed inset-0 z-20">
    <div class="w-full h-full relative">
        <div
            class="header-video-container absolute top-0 inset-x-0 z-50 w-full flex items-center justify-center pb-4 pt-6"
        >
            <div class="flex flex-col items-center justify-center">
                <h1 class="text-lg font-semibold text-white">
                    {{
                        "components.video-call-player.sessionLabel" | transloco
                    }}
                </h1>
                <h2 class="text-sm font-light text-white">
                    {{ callTimeDisplay }}
                </h2>
            </div>
        </div>
        <div #localPlayerContainer class="local-video absolute w-24 h-32"></div>
        <div
            #remotePlayerContainer
            class="remote-video w-full h-full bg-black"
        ></div>
        <div
            class="footer-video-container bg-white absolute bottom-0 inset-x-0 z-50 w-full flex flex-col items-center justify-center p-10 rounded-t-3xl"
        >
            <div class="w-full flex items-center justify-center">

                @if (cameras.length > 1) {
                    <div class="mx-6">
                        <button
                            class="footer-video-button bg-white border-0 p-4 rounded-full"
                            (click)="onSwitchCamera()"
                        >
                            <svg class="w-6 h-6 fill-white">
                                <use
                                    href="../../../assets/svg/svGP.svg#switch-camera"
                                ></use>
                            </svg>
                        </button>
                    </div>
                }
                <button (click)="onToggleCamera()">
                    <div class="mx-6">
                        <button
                            class="footer-video-button bg-white border-0 p-4 rounded-full"
                        >
                            <svg class="w-6 h-6 fill-white">
                                @if (videoCallParameters.localVideo?.enabled) {
                                    <use href="../../../assets/svg/svGP.svg#camera-on"></use>
                                } @else {
                                    <use href="../../../assets/svg/svGP.svg#camera-off"></use>
                                }
                            </svg>
                        </button>
                    </div>
                </button>
                <button (click)="onToggleBlur()">
                    <div class="mx-6">
                        <button
                            class="footer-video-button bg-white border-0 p-4 rounded-full"
                        >
                            <svg class="w-6 h-6 fill-white">
                                @if (virtualBackgroundProcessor?.enabled) {
                                    <use href="../../../assets/svg/svGP.svg#blur-on"></use>
                                } @else {
                                    <use href="../../../assets/svg/svGP.svg#blur-off"></use>
                                }
                            </svg>
                        </button>
                    </div>
                </button>
                <div class="mx-6">
                    <button
                        class="footer-video-button bg-white border-0 p-4 rounded-full"
                        (click)="onToggleMicrophone()"
                    >
                        <svg class="w-6 h-6 fill-white">
                            @if (videoCallParameters.localAudio?.enabled) {
                                <use href="../../../assets/svg/svGP.svg#videocall-mic-on"></use>
                            } @else {
                                <use href="../../../assets/svg/svGP.svg#videocall-mic-off"></use>
                            }
                        </svg>
                    </button>
                </div>
            </div>
            <div class="mx-6 mt-3 w-full">
                <button
                    class="w-full flex items-center justify-center py-5 px-4 bg-danger rounded-lg"
                    (click)="onLeaveMeeting()"
                >
                    <svg class="size-6 fill-white mt-1">
                        <use
                            href="../../../assets/svg/svGP.svg#phone-down"
                        ></use>
                    </svg>
                    <div class="ms-2">
                        <span class="text-sm uppercase text-white  font-bold">
                            Abbandona
                        </span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
