@if (data) {
<div class="fixed top-0 right-0 left-0 m-0-auto max-w-screen-md">
    <div class="flex items-center justify-between p-7 bg-white-40">
        <div class="flex items-center">
            <button class="p-2 bg-transparent rounded-full border border-primary me-3" (click)="goBack()">
                <svg class="size-6 fill-primary-dark">
                    <use href="../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
            </button>
            <span class="font-bold">{{ data.userName }}</span>
        </div>
        <img
            class="size-10 rounded-full overflow-hidden object-cover"
            [src]="data.userAvatar ? data.userAvatar : defaultAvatar"
        />
    </div>
</div>
}
<app-loader *ngIf="loading"></app-loader>

<message-list
    #messageListComponent
    [disabled]="!chatActive"
    [freeMessages]="chatMessages"
    [myId]="(user$ | async)?.sfAccountId"
/>
@if (chatActive) {
<message-form
    [disabled]="sendingMessage"
    (newMessage)="sendFreeMessage($event, recipientId!)"
/>
} @else {
<div class="fixed w-full bottom-0 inset-x-0">
    <div class="w-full bg-white rounded-t-4xl p-7">
        <div class="flex items-center justify-center">
            <span>Vuoi inviare un messaggio al tuo coach ?</span>
        </div>
        <div class="flex items-center mt-4">
            <button
                [routerLink]="buyBasePlanPath"
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
            >
                <div class="flex items-center">
                    <div class="me-2.5">
                        <svg class="w-6 h-6 fill-white">
                            <use
                                href="../assets/svg/svGP.svg#Marketplace"
                            ></use>
                        </svg>
                    </div>
                    <div>
                        <span class="text-white text-sm font-bold uppercase"
                            >Fai l'upgrade</span
                        >
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>
}
