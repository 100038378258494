<section class="bg-custom-survey-gradient rounded-lg card-shadow-bottom">
    <div class="flex">
        <div class="basis-8/12 p-4 flex flex-col justify-between">
            <div>
                <div>
                    <h3 class="font-bold text-white">È tempo di creare il tuo percorso personale!</h3>
                    <p class="text-white text-sm mt-1.5">
                        Bastano <strong class="font-bold">solo 2 minuti</strong> per completarlo
                    </p>
                </div>
            </div>
            <button class="w-full bg-white mt-5 rounded-lg border-0 py-2 flex items-center justify-center" (click)="goToSurvey()">
                <span class="font-bold text-sm text-primary">Fissa i tuoi obiettivi</span>
            </button>
        </div>
        <div class="basis-4/12 bg-light-green rounded-r-lg flex items-center justify-center">
            <img class="rounded-r-lg max-h-52 w-full object-cover" src="../../../assets/img/homepage-images/come-ti-senti-oggi.png" alt="" />
        </div>
    </div>
</section>