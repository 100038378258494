@if (isRegistrationCompleted) {
    <app-registration-dash-icon>
        <svg class="w-14 h-auto fill-primary">
            <use href="../../../assets/svg/svGP.svg#paste-clipboard"></use>
        </svg>
    </app-registration-dash-icon>
    <div class="mt-10">
        <div class="text-center px-5">
            <h1 class="text-primary font-semibold text-3xl">
                Complimenti! Hai completato la registrazione
            </h1>
        </div>
        <div class="mt-24">
            <img
                src="../assets/img/illustrations/illustration-registration-complete.png"
                class="w-full"
                alt=""
            />
        </div>
        <div class="mx-5">
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-14 w-full"
                (click)="showLoginForm()"
            >
                <div class="flex items-center justify-center">
                    <span class="text-white text-sm font-bold uppercase">Accedi</span>
                </div>
            </button>
        </div>
    </div>
} @else {
    <div class="flex items-center justify-start px-3">
        <button
            class="p-2 border border-white rounded-full mt-3"
            [routerLink]="authPath"
        >
            <svg class="w-4 h-4 fill-primary">
                <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
            </svg>
        </button>
    </div>
    <app-registration-dash-icon>
        <svg class="w-14 h-auto fill-primary">
            <use href="../../../assets/svg/svGP.svg#registration-pencil"></use>
        </svg>
    </app-registration-dash-icon>

    <section class="mt-10 relative">
        <div>
            <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl">
                <h1 class="text-primary font-semibold text-3xl">Registrati</h1>
                <span class="text-danger">{{ errorMessage }}</span>
                <p class="mt-3">
                    Inserisci i tuoi dati per completare la registrazione.
                </p>
                <form
                    [formGroup]="registrationForm"
                    (ngSubmit)="onRegistrationFormSubmit()"
                >
                    <div class="flex flex-col mt-1.5">
                        <div class="relative mt-2.5">
                            <input
                                id="firstName"
                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                type="text"
                                formControlName="firstName"
                                placeholder=" "
                            />
                            <label
                                for="firstName"
                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >{{ "components.registration-form.firstNameLabel" | transloco }}</label>
                        </div>
                        <!-- Gestione errore campo form firstname da stilizzare -->
                        @if (registrationForm.controls['firstName'].invalid && registrationForm.controls['firstName'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci un nome valido.</span>
                        }
                        <!-- Fine gestion errore campo form firstname-->
                        <div class="relative mt-2.5">
                            <input
                                id="lastName"
                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                type="text"
                                formControlName="lastName"
                                placeholder=" "
                            />
                            <label
                                for="lastName"
                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >{{ "components.registration-form.lastNameLabel" | transloco }}</label>
                        </div>
                        <!-- Gestione errore campo form lastname da stilizzare -->
                        @if (registrationForm.controls['lastName'].invalid && registrationForm.controls['lastName'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci un cognome valido.</span>
                        }
                        <!-- Fine gestion errore campo form lastname-->
                        <div class="relative mt-2.5">
                            <input
                                id="emailLabel"
                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                type="email"
                                formControlName="email"
                                placeholder=" "
                            />
                            <label
                                for="emailLabel"
                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >{{ "components.registration-form.emailLabel" | transloco }}</label>
                        </div>
                        <!-- Gestione errore campo form email da stilizzare -->
                        @if (registrationForm.controls['email'].invalid && registrationForm.controls['email'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci una email valida.</span>
                        }
                        <!-- Fine gestion errore campo email -->
                        <label
                            for="birthDate"
                            class="px-4 text-9ea5b0 text-xs mt-5"
                        >{{ "components.registration-form.birthDateLabel"| transloco }}</label>
                        <input
                            id="birthDate"
                            [class]="targetPlatform === TargetPlatform.iOS ? 'ios-device' : 'not-ios-device'"
                            class="py-5 px-4 bg-transparent border border-light-grey rounded-lg mt-1 w-full"
                            type="date"
                            formControlName="birthDate"
                            placeholder=" "
                        />
                        <!-- Gestione errore campo form birthDate da stilizzare -->
                        @if (registrationForm.controls['birthDate'].errors?.['invalidAge'] && registrationForm.controls['birthDate'].touched) {
                            <span class="text-danger text-sm mt-2">La data di nascita non è valida: devi essere maggiorenne per proseguire con l'iscrizione.</span>
                        }
                        <!-- Fine gestion errore campo form birthdate-->
                        <div class="relative mt-2.5">
                            <input
                                id="passwordLabel"
                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                type="password"
                                formControlName="password"
                                placeholder=" "
                            />
                            <label
                                for="passwordLabel"
                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >{{ "components.registration-form.passwordLabel" | transloco }}</label>
                        </div>
                        <!-- Gestione errore campo form password da stilizzare -->
                        @if (registrationForm.controls['password'].invalid && registrationForm.controls['password'].touched) {
                            <span
                                class="text-danger text-sm mt-2">Per favore inserisci una password di almeno 8 caratteri.</span>
                        } @else {
                            <span class="text-sm mt-2">La password deve essere lunga almeno 8 caratteri.</span>
                        }
                        <!-- fine  gestione errore campo form password -->
                        <div class="relative mt-2.5">
                            <input
                                id="confirmPasswordLabel"
                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                type="password"
                                formControlName="confirmPassword"
                                placeholder=" "
                            />
                            <label
                                for="confirmPasswordLabel"
                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >{{ "components.registration-form.confirmPasswordLabel" | transloco }}</label>
                        </div>
                        <!-- Gestione errore campo form confirmPassword da stilizzare -->
                        @if (registrationForm.controls['confirmPassword'].errors && registrationForm.controls['confirmPassword'].touched) {
                            <span
                                class="text-danger text-sm mt-2">{{ registrationForm.controls["confirmPassword"].errors["confirmedValidator"] }}</span>
                        }
                        <!-- Fine gestione errore campo form confirmPassword -->
                        <div class="ngx-tel mt-5 w-100">
                            <label
                                for="mobilePhone"
                                class="px-4 text-9ea5b0 text-xs"
                            >{{ "components.registration-form.mobilePhoneLabel" | transloco }}</label>
                            <ngx-intl-tel-input
                                [cssClass]="'form-control'"
                                [customPlaceholder]="'components.registration-form.mobilePhoneLabel' | transloco"
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [searchCountryPlaceholder]="'components.registration-form.searchCountry' | transloco"
                                [selectFirstCountry]="true"
                                [phoneValidation]="true"
                                [separateDialCode]="true"
                                [required]="true"
                                id="mobilePhone"
                                formControlName="mobilePhone"
                            ></ngx-intl-tel-input>
                        </div>
                        <!-- Gestione errore campo form mobilePhone da stilizzare -->
                        @if (registrationForm.controls['mobilePhone'].invalid && registrationForm.controls['mobilePhone'].touched) {
                            <span class="text-danger text-sm mt-2">Inserire un numero di telefono valido per il prefisso selezionato.</span>
                        }
                        <!-- Fine gestione errore campo form mobilePhone -->
                        <!--  --- --- --- --- ---->

                        <div>
                            <div class="flex items-center justify-start mt-5">
                                <input
                                    type="checkbox"
                                    class="me-2"
                                    formControlName="policies"
                                    id="policies"
                                />
                                <span class="text-sm">
                                    <label
                                        for="policies">* Dichiaro di aver preso visione e di accettare la </label>
                                    <a class="text-primary cursor-pointer"
                                       [href]="iubenda.privacyPolicy" target="_blank">privacy policy</a> e i
                                    <a class="text-primary cursor-pointer"
                                       [href]="iubenda.termsAndConditions" target="_blank">termini e le condizioni</a>.
                                </span>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center justify-start mt-5">
                                <input
                                    type="checkbox"
                                    class="me-2"
                                    formControlName="healthDataConsent"
                                    id="healthDataConsent"
                                />
                                <span class="text-sm">
                                    <label for="healthDataConsent">* Acconsento al trattamento dei miei dati personali relativi alla mia salute che deciderò di comunicare durante l’utilizzo di Be Our Best.</label>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center justify-start mt-5">
                                <input
                                    type="checkbox"
                                    class="me-2"
                                    formControlName="marketingConsent"
                                    id="marketingConsent"
                                />
                                <span class="text-sm">
                                    <label for="marketingConsent">Acconsento al trattamento dei miei dati personali per ricevere e-mail aventi contenuto promozionale in relazione a nuovi prodotti e servizi promossi da Be Our Best.</label>
                                </span>
                            </div>
                        </div>

                        <button
                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                            type="submit"
                            [disabled]="
                            registrationForm.invalid || isLoading
                        "
                        >
                            <div class="flex items-center">
                                @if (isLoading) {
                                    <span class="button-loader"></span>
                                } @else {
                                    <span
                                        class="text-white text-sm font-bold uppercase"
                                    >{{ "components.registration-form.registerLabel" | transloco }}</span>
                                }
                            </div>
                        </button>
                        <div class="my-7 text-center">
                            <span>Oppure</span>
                        </div>
                        <a
                            class="py-5 px-4 border border-light-grey rounded-lg flex items-center justify-center mb-2.5"
                            href="{{ apiUrl }}{{ googleAuth }}"
                        >
                            <div>
                                <svg class="w-4 h-4 translate-y-px me-2">
                                    <use
                                        href="../../../assets/svg/svGP.svg#google-logo"
                                    ></use>
                                </svg>
                            </div>
                            <div>
                                <span
                                    class="font-titillium text-sm font-bold uppercase"
                                >{{ "components.registration-form.registerWithGoogleLabel" | transloco }}</span>
                            </div>
                        </a>
                        <a
                            class="py-5 px-4 border border-light-grey rounded-lg flex items-center justify-center mb-2.5 "
                            href="{{ apiUrl }}{{ appleAuth }}"
                        >
                            <div>
                                <svg class="w-4 h-4 translate-y-px me-2">
                                    <use
                                        href="../../../assets/svg/svGP.svg#apple-logo"
                                    ></use>
                                </svg>
                            </div>
                            <div>
                                <span
                                    class="font-titillium text-sm font-bold uppercase"
                                >{{ "components.registration-form.registerWithAppleLabel" | transloco }}</span>
                            </div>
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </section>
}
