<section class="custom-bg-white py-7 px-6 relative h-full pb-32">
    <div class="flex items-center justify-between">
        <h1 class="font-semibold text-lg">
            {{ "pages.monitor-page.pages.add-internal-health-data-page." + typeName | transloco }}
        </h1>
    </div>
    <br/>
    <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
            <div class="flex flex-col py-3.5">
                <label class="text-595959 font-bold text-sm" for="dateFrom">Data e orario</label>
                <input
                    type="datetime-local"
                    class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-black"
                    id="dateFrom"
                    formControlName="dateFrom"
                    tabindex="0"
                />
                @if (form.controls.dateFrom.invalid && form.controls.dateFrom.touched) {
                    <span class="text-danger text-sm mt-2">Per favore inserisci una data e orario validi.</span>
                }
            </div>
            <div class="flex flex-col py-3.5">
                <label class="text-595959 font-bold text-sm" for="value">Valore (in {{HealthDataUnitToString[HealthDataTypeToUnit[typeName]]}})</label>
                <input
                    type="number"
                    class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-black"
                    id="value"
                    formControlName="value"
                    tabindex="0"
                />
                @if (form.controls.value.invalid && form.controls.value.touched) {
                    <span class="text-danger text-sm mt-2">Per favore inserisci un valore.</span>
                }
            </div>
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                [disabled]="form.invalid || (isLoading$ | async)"
                type="submit"
            >
                <div class="flex items-center justify-center w-full">
                    @if (isLoading$ | async) {
                        <span class="button-loader"></span>
                    } @else {
                        <span
                            class="text-white text-sm font-bold uppercase">
                            Aggiungi
                        </span>
                    }
                </div>
            </button>
        </form>
</section>
