<div class="h-full flex flex-col">
    <div class="flex items-center justify-between px-3">
        @if (canFirstPageGoBack || (currentPage > 0 && currentPage < pages.length - 1)) { <button class="p-2 border border-primary rounded-full my-3" (click)="goToPreviousPage()" [disabled]="isLoading">
            <svg class="w-4 h-4 fill-primary">
                <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
            </svg>
            </button>
            <div>
                <img class="h-4 w-auto" src="../../../assets/img/registration-carousel/v2/logo-wide.png" alt="">
            </div>
            <div class="p-2 border border-primary rounded-full my-3 invisible pointer-events-none select-none">
                <svg class="w-4 h-4 fill-primary">
                    <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
            </div>
            }
    </div>

    <form class="mx-4 flex-grow flex-shrink-0" [formGroup]="form">
        <div class="flex flex-col justify-between h-full">
            <div>
                @if (pages.length > 1) {
                <div class="wizard-container my-6">
                    <div class="flex items-center gap-x-2 rounded-md overflow-hidden">
                        @for (page of pages; track $index) {
                        <div class="wizard-item bg-e9e9e9 h-3" [ngClass]="{
                                    'active': currentPage === $index,
                                    'basis-6/12': isLessThanThree,
                                    'basis-3/12': !isLessThanThree
                                  }"></div>
                        }
                    </div>
                </div>
                }
                <ng-container *ngFor="let p of pages; let pageIndex = index; let first = first; let last = last">
                    @if (pageIndex === currentPage && hasSummaryPage && last && summaryPage) {
                    <div *ngIf="pageIndex === currentPage" [class]="'page-' + pageIndex + ' last-page summary-page'" [ngClass]="{'no-last-page-image': last}">
                        <span class="font-bold">{{ pageIndex + 1 }}/{{ pages.length }}</span>
                        <div>
                            <div>
                                <h3>Rivediamo le tue scelte</h3>
                                <div class="container-summary">
                                    @for (category of summaryPage.categories; track $index) {
                                    @if (category.responses.length > 0) {
                                    <div>
                                        <div class="container-summary-header" style="margin-bottom:16px; margin-top:32px;">
                                            <h3 style="margin:0!important; font-size:16px!important; font-weight:500!important; color:#373536!important;">{{ category.name }}</h3>
                                            <a style="margin:0!important; font-size:14px!important; font-weight:700!important; color:#86AF96!important;" href="#" class="underline" (click)="$event.preventDefault(); goToPage(category.page)">Modifica</a>
                                        </div>
                                        <div class="container-categories flex items-center justify-start flex-wrap">
                                            @for (response of category.responses; track $index) {
                                            <span>{{ response.value }}</span>
                                            }
                                        </div>
                                    </div>
                                    }
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    @if (getSurveyPageAtIndex(pageIndex); as page) {
                    <div *ngIf="pageIndex === currentPage" [class]="'page-' + pageIndex + ' page-type-' + page.type.toLowerCase() + ' ' + (first ? 'first-page' : (last ? 'last-page' : ''))" [ngClass]="{'no-last-page-image': last}">
                        <span class="font-bold">{{ pageIndex + 1 }}/{{ pages.length }}</span>
                        <div *ngIf="page.type === 'Text'">
                            <div [innerHTML]="page.description"></div>
                        </div>
                        <div *ngIf="page.type === 'Questions'">
                            <div *ngFor="let question of page.surveyQuestions; let questionIndex = index">
                                <div *ngIf="!form.get(question.id)?.disabled" class="{{question.layoutOfQuestions}}">
                                    <h3>{{ question.questionName }}<span *ngIf="question.isRequired"> *</span></h3>
                                    <div *ngIf="
                                            question.questionType ===
                                            'MultiPicklist'
                                        " [formGroupName]="question.id">
                                        <label *ngFor="let choice of question.surveyQuestionChoices; let choiceIndex = index">
                                            <input type="checkbox" [formControlName]="choice.name" />
                                            {{ choice.name }}
                                        </label>
                                    </div>
                                    <div *ngIf="question.questionType === 'Picklist'">
                                        <label *ngFor="let choice of question.surveyQuestionChoices; let choiceIndex = index">
                                            <input type="radio" [formControlName]="question.id" [value]="choice.name" />
                                            {{ choice.name }}
                                        </label>
                                    </div>
                                    <div *ngIf="question.questionType === 'FreeText'" class="py-1 px-0.5">
                                        <input type="text" class="border w-full" [formControlName]="question.id" />
                                    </div>
                                    <div *ngIf="question.questionType === 'Date'" class="py-1 px-0.5">
                                        <input type="date" class="border w-full" [formControlName]="question.id" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </ng-container>
            </div>
            <div class="flex items-center w-full pb-6">
                @if (currentPage < pages.length - 1) { <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full mt-7" (click)="goToNextPage()" [disabled]="!currentPageValid || isLoading">
                    <div class="flex items-center">
                        <span class="text-white text-sm font-bold uppercase">
                            {{getSurveyPageAtIndex(currentPage)?.nextTextButton ?? 'Avanti'}}
                        </span>
                        <svg class="w-4 h-4 fill-white ms-3">
                            <use href="../../../assets/svg/svGP.svg#chevron-right"></use>
                        </svg>
                    </div>
                    </button>
                    }
                    @if (currentPage === pages.length - 1) {
                    <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full mt-7" (click)="onSubmit()" [disabled]="form.invalid || isLoading">
                        <div class="flex items-center">
                            <svg class="w-4 h-4 fill-white me-3">
                                <use href="../../../assets/svg/svGP.svg#check-circle"></use>
                            </svg>
                            @if (isLoading) {
                            <span class="button-loader"></span>
                            } @else {
                            <span class="text-white text-sm font-bold uppercase">
                                {{ completeSurveyButtonLabel ?? "Conferma" }}
                            </span>
                            }
                        </div>
                    </button>
                    }
            </div>
        </div>
    </form>
</div>
