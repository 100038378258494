<health-statistic-card
    [healthTypePath]="healthTypePath"
    [title]="title"
    [value]="sumData"
    [dataType]="dataType"
    [unitSymbolLabel]="unitSymbolLabel"
    [period]="period"
    [isSingleValue]="true"
    [isLoading]="isLoading"
    [iconPath]="'../../../assets/img/monitoring/bed.png'">
</health-statistic-card>
