import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../../../../services/navigation.service';
import { HealthStatisticHeartRateCardComponent } from '../../../../components/health-statistic-heart-rate-card/health-statistic-heart-rate-card.component';
import { HealthStatisticRestingHeartRateCardComponent } from '../../../../components/health-statistic-resting-heart-rate-card/health-statistic-resting-heart-rate-card.component';
import {NavbarCategoriesComponent} from "../../components/navbar-categories/navbar-categories.component";

@Component({
    selector: 'app-heart-category-page',
    standalone: true,
    imports: [
        HealthStatisticHeartRateCardComponent,
        HealthStatisticRestingHeartRateCardComponent,
        RouterModule,
        NavbarCategoriesComponent,
    ],
    templateUrl: './heart-category-page.component.html',
    styleUrl: './heart-category-page.component.css',
})
export default class HeartCategoryPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
