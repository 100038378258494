import {
    Component,
    effect,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    signal,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'generic-modal',
    standalone: true,
    imports: [],
    templateUrl: './generic-modal.component.html',
    styleUrl: './generic-modal.component.css',
})
export class GenericModalComponent implements OnInit, OnDestroy {
    @Input() buttonLabel: string = 'Chiudi';
    @Input() isVisible = signal(false);

    @Output() buttonClick: EventEmitter<void> = new EventEmitter();

    constructor() {
        effect(() => {
            if (this.isVisible()) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        });
    }

    ngOnInit() {
        if (this.isVisible()) {
            document.body.style.overflow = 'hidden';
        }
    }

    toggleModal() {
        this.isVisible.update((v) => !v);
        if (this.isVisible()) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        this.buttonClick.emit();
    }

    ngOnDestroy() {
        document.body.style.overflow = 'auto';
    }
}
