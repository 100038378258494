import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { selectUserData } from '../../../../store/user/user.selectors';
import packageJson from '../../../../../../package.json';
import { fetchUser } from '../../../../store/user/user.actions';
import { PackageInfoNativeService } from '../../../../services/native/package-info-native.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import {
    Form,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {
    NativeAPIService,
    TargetPlatform,
} from '../../../../services/native/native-api.service';
import { CommonModule } from '@angular/common';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../../components/error-modal/error-modal.component';
import { LogService } from '../../../../services/log.service';
import { ModalService } from '../../../../services/modal.service';
import { UserService } from '../../../../services/user.service';
import { NoWhitespaceValidator } from '../../../../shared/validators/no-whitespace.validator';
import { APP_ROUTES } from '../../../../app.routes.definition';
import { NavigationService } from '../../../../services/navigation.service';
import { SuccessRequestSentModalContentComponent } from './success-request-sent-modal-content/success-request-sent-modal-content.component';

export interface SupportForm {
    subject: FormControl<string>;
    description: FormControl<string>;
}

export interface SupportRequest {
    subject: string;
    description: string;
    deviceType: 'Android' | 'iOS' | 'Web';
    nativeAppVersion?: string | null;
    webAppVersion: string;
    errorMessage?: string | null;
}

@Component({
    selector: 'app-support-page',
    standalone: true,
    imports: [ReactiveFormsModule, CommonModule],
    templateUrl: './support-page.component.html',
})
export default class SupportPageComponent {
    #store = inject(Store);
    #packageInfoNativeService = inject(PackageInfoNativeService);
    #nativeAPIService = inject(NativeAPIService);
    #navigationService = inject(NavigationService);
    #userService = inject(UserService);
    #logService = inject(LogService);
    #modalService = inject(ModalService);
    #ActivatedRoute = inject(ActivatedRoute);
    #router = inject(Router);
    #fb = inject(FormBuilder);

    user$ = this.#store.select(selectUserData);

    sfAccountId!: string;
    webAppVersion = packageJson.version;
    nativeAPIAvailability = this.#nativeAPIService.isAvailable();
    appNativeVersion?: string | null = null;
    deviceType: 'Android' | 'iOS' | 'Web' = 'Web';
    errorMessage: string | null = null;
    supportForm!: FormGroup<SupportForm>;
    TargetPlatform = TargetPlatform;

    isLoading = false;

    constructor() {
        this.#navigationService.saveCurrentRoute();

        // deviceType
        if (this.nativeAPIAvailability) {
            this.#nativeAPIService.defaultTargetPlatform().then((platform) => {
                this.deviceType =
                    platform === TargetPlatform.android ? 'Android' : 'iOS';
            });
        }

        // nativeAppVersion
        if (this.#packageInfoNativeService.isAvailable()) {
            this.#packageInfoNativeService
                .fromPlatform()
                .then((packageInfo) => {
                    this.appNativeVersion =
                        packageInfo.version + '+' + packageInfo.buildNumber;
                });
        }

        // errorMessage
        const navigation = this.#router.getCurrentNavigation();
        this.errorMessage = navigation?.extras.state?.['errorMessage'] || null;

        this.initializeForm();
    }

    private initializeForm() {
        this.supportForm = this.#fb.nonNullable.group({
            subject: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            description: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
        });
    }

    onSupportFormSubmit() {
        if (this.supportForm.invalid) return;

        this.isLoading = true;

        const { subject, description } = this.supportForm.value;

        const supportRequest: SupportRequest = {
            subject: subject!,
            description: description!,
            deviceType: this.nativeAPIAvailability ? this.deviceType : 'Web',
            nativeAppVersion: this.appNativeVersion ?? null,
            webAppVersion: this.webAppVersion,
            errorMessage: this.errorMessage ?? null,
        };
    
        this.#userService.sendSupportRequest(supportRequest).subscribe({
            next: () => {
                this.isLoading = false;

            },
            error: (error) => {
                this.isLoading = false;
                this.#logService.error(error);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                        },
                    }
                );
            },
            complete: () => {
                this.isLoading = false;
                this.supportForm.reset();
                this.errorMessage = null;
                this.#modalService.open(
                    SuccessRequestSentModalContentComponent
                );
            },
        });
    }
}
