<div
    class="h-1/6 flex items-center relative justify-center custom-bg-illustration-red mt-14"
>
    <svg class="size-20 fill-danger">
        <use href="../assets/svg/svGP.svg#warning-circle"></use>
    </svg>
</div>
<div
    class="custom-bg-white py-7 px-6 relative pb-28 h-4/6 flex flex-col justify-between"
>
    <div class="mt-5">
        <h1 class="font-semibold text-xl">
            Vuoi davvero eliminare l'autenticazione a due fattori?
        </h1>
    </div>
    <div class="mt-5">
        <p class="m-0">
            Quest’azione è irrevocabile. Proseguendo, verranno eliminati tutti i
            dati registrati sul nostro sistema.
        </p>
    </div>
    <form [formGroup]="deleteTwoFactorForm">
        <div class="flex flex-col mt-5">
            <label for="password" class="text-595959 font-bold text-sm"
                >Inserisci la password *</label
            >
            <input
                formControlName="password"
                type="password"
                class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full"
                id="password"
                placeholder="Inserisci password"
                autocomplete="current-password"
            />
        </div>
        <div class="mt-5">
            <button
                [disabled]="deleteTwoFactorForm.invalid"
                (click)="onSubmitDelete2faAuthentication()"
                type="submit"
                class="py-5 px-4 border-0 bg-danger rounded-lg flex items-center justify-center w-full"
            >
                <div>
                    @if (isLoading) {
                    <span class="button-loader"></span>
                    } @else {
                    <span class="text-white text-sm font-bold uppercase"
                        >ELIMINA</span
                    >
                    }
                </div>
            </button>
            <button
                class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
                [routerLink]="twoFactorSetupPath"
            >
                <div>
                    <span class="text-danger text-sm font-bold"
                        >Annulla operazione</span
                    >
                </div>
            </button>
        </div>
    </form>
</div>
