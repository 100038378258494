import {Component, inject, OnInit} from '@angular/core';
import { SurveyService } from '../../services/survey.service';
import { LogService } from '../../services/log.service';
import { Survey } from '../../models/survey.models';
import {combineLatest, first} from 'rxjs';
import { Router } from '@angular/router';
import { RegistrationDashIconComponent } from '../../components/registration-dash-icon/registration-dash-icon.component';
import { SurveyComponent } from '../../components/survey/survey.component';
import { NavigationService } from '../../services/navigation.service';
import { APP_ROUTES } from '../../app.routes.definition';
import { ModalService } from '../../services/modal.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../components/error-modal/error-modal.component';

@Component({
    selector: 'expired-initial-survey-page',
    standalone: true,
    imports: [RegistrationDashIconComponent, SurveyComponent],
    templateUrl: './expired-initial-survey-page.component.html',
    styleUrl: './expired-initial-survey-page.component.css',
})
export default class ExpiredInitialSurveyPageComponent implements OnInit {
    #surveyService = inject(SurveyService);
    #loggerService = inject(LogService);
    #modalService = inject(ModalService);
    #router = inject(Router);
    #navigationService = inject(NavigationService);
    initialSurvey?: Survey;
    startSurvey = false;

    isLoading = false;

    constructor() {
        this.#navigationService.saveCurrentRoute();
    }

    ngOnInit() {
        combineLatest([
            this.#surveyService.checkInitialSurveyValidity(),
            this.#surveyService.getInitialSurvey(),
        ]).pipe(first()).subscribe({
            next: ([isValid, survey]) => {
                if (isValid) {
                    this.#router.navigate([APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN()]);
                    return;
                }
                this.initialSurvey = survey;
            },
            error: (err) => {
                this.#loggerService.error(err);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                            message: 'Non è stato possibile caricare il questionario',
                            onCloseCallback: () => {
                                this.#router.navigate([APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN()]);
                            }
                        },
                    }
                );
            }
        });
    }

    onSurveySubmit(survey: Survey) {
        this.isLoading = true;
        this.#surveyService.postSurvey(survey).subscribe({
            next: () => {
                this.#loggerService.log('Survey submitted');
            },
            error: (error) => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                        },
                    }
                );
            },
            complete: () => {
                this.isLoading = false;
                this.#router.navigate([APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN()]);
            },
        });
    }
}
