import { Component, inject } from '@angular/core';
import { first } from 'rxjs';
import { UserService } from '../../../../../../../../../services/user.service';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from '../../../../../../../../../app.routes.definition';
import { GenerateRecoveryCodesModalContentComponent } from './generate-recovery-codes-modal-content/generate-recovery-codes-modal-content.component';
import { ModalService } from '../../../../../../../../../services/modal.service';
import ErrorModalComponent, { ErrorModalData } from '../../../../../../../../../components/error-modal/error-modal.component';
import { LogService } from '../../../../../../../../../services/log.service';

@Component({
    selector: 'two-factor-added',
    standalone: true,
    imports: [RouterModule],
    templateUrl: './two-factor-added.component.html',
    styleUrl: './two-factor-added.component.css',
})
export class TwoFactorAddedComponent {
    #userService = inject(UserService);
    #modalService = inject(ModalService);
    #logService = inject(LogService);

    recoveryCodes: string[] = [];
    settingsPath = APP_ROUTES.SETTINGS_SECURITY(false);
    deleteTwoFactorPath = APP_ROUTES.SETTINGS_DELETE_TWO_FACTOR(true);

    isLoading = false;

    onSubmitResendRecoveryCodes() {
        if (this.isLoading) return;

        this.isLoading = true;

        this.#userService
            .refreshRecoveryCode()
            .pipe(first())
            .subscribe({
                next: (response: string[]) => {
                    this.recoveryCodes = response;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                            message: 'Errore mentre si tentava di rigenerare i codici di recupero. Riprova più tardi.'
                        },
                    });
                },
                complete: () => {
                    this.isLoading = false;
                    this.#modalService.open(
                            GenerateRecoveryCodesModalContentComponent,
                            {
                                data: {
                                    recoveryCodes: this.recoveryCodes,
                                },
                            }
                        );
                }
            });
    }
}
