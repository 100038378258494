<ng-container *ngIf="order$ | async as order; else pendingOrderNotFound">
    <section class="flex flex-col h-full px-6 pb-28">
        <h1>Hai un acquisto in attesa di essere completato! Completa l'acquisto ora</h1>
        <div
            class="border-2 border-white bg-white rounded-lg mt-7 card-shadow p-0.5"
        >
            <div class="flex">
                <div class="basis-4/12 flex items-center justify-center">
                    <img
                        class="w-full h-full max-h-40 object-cover rounded"
                        *ngIf="order.orderItems[0].product.imageUrl"
                        [src]="order.orderItems[0].product.imageUrl"
                    />
                </div>
                <div class="basis-8/12 py-2.5 px-3 bg-white">
                    <div class="mt-4">
                        <h3 class="font-bold text-sm">
                            {{ order.orderItems[0].product.name }}
                        </h3>
                        <p
                            class="font-light text-text-color text-sm mt-1.5"
                            *ngIf="order.orderItems[0].product.description"
                        >
                            {{ order.orderItems[0].product.description }}
                        </p>
                        <span
                            *ngIf="
                                order.orderItems[0].product.family ===
                                    'Subscription' &&
                                order.orderItems[0].product
                                    .subscriptionRecurringPeriod
                            "
                        >/ {{ "pages.pending-page." + (order.orderItems[0].product.subscriptionRecurringInterval == null || order.orderItems[0].product.subscriptionRecurringInterval === 1 ?
                            order.orderItems[0].product.subscriptionRecurringPeriod :
                            ('' + order.orderItems[0].product.subscriptionRecurringInterval + order.orderItems[0].product.subscriptionRecurringPeriod)) + "Label" | transloco }}
                        </span>
                        <div class="mt-3">
                            <strong>
                                {{ order.orderItems[0].unitPrice | currency: '':'':(order.orderItems[0].unitPrice % 1 == 0) ? '1.0-0': '1.2-2'  }} €
                            </strong>
                            @if (order.orderItems[0].product.family === 'One shot purchase') {
                                <span> / {{ "pages.pending-page.oneShotPurchaseLabel" | transloco }}</span>
                            } @else {
                                <span> / {{ "pages.pending-page.subscriptionLabel" | transloco }}</span>
                            }
                        </div>
                        <div
                            class="flex items-center justify-between w-full mt-6"
                        >
                            <div class="flex align-items-center justify-end">
                                @if (!(isUserBillingInfoValid$ | async)) {
                                    <div class="flex flex-col">
                                        <p class="text-sm">Prima di poter effettuare qualsiasi acquisto, hai bisogno di
                                            aggiornare i tuoi dati di fatturazione.</p>
                                        <button
                                            [routerLink]="billingInfoPath" [queryParams]="{isReadOnly: false, shouldRedirectBackOnSave: true}"
                                            class="py-2 px-2.5 bg-primary rounded-lg my-3"
                                        >
                                            <div>
                                                <span class="text-white font-bold text-sm">Dati di fatturazione</span>
                                            </div>
                                        </button>
                                        <button
                                            class="py-2 px-2.5 bg-danger rounded-lg"
                                            [disabled]="isCreatingOrder$ | async"
                                            (click)="cancelOrder()"
                                        >
                                            <span class="text-white font-bold text-sm">Rimuovi</span>
                                        </button>
                                    </div>
                                } @else {
                                    <button
                                        class="py-2 px-2.5 bg-primary rounded-lg me-3"
                                        [disabled]="isCreatingOrder$ | async"
                                        (click)="completeOrder()"
                                    >
                                        <span class="text-white font-bold text-sm">Acquista</span>
                                    </button>
                                    <button
                                        class="py-2 px-2.5 bg-danger rounded-lg"
                                        [disabled]="isCreatingOrder$ | async"
                                        (click)="cancelOrder()"
                                    >
                                        <span class="text-white font-bold text-sm">Rimuovi</span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<ng-template #pendingOrderNotFound>
    <section
        class="flex flex-col justify-between h-screen bg-body-bg mt-14 mx-6"
    >
        <div class="flex flex-col">
            <div class="flex items-center justify-center">
                <img
                    class="size-14"
                    src="../assets/img/emoji/dizzy_face.png"
                    alt=""
                />
            </div>
            <div class="mt-5 text-center">
                <h1 class="font-bold text-xl">404</h1>
            </div>
            <div class="mt-4 text-center">
                <p class="text-sm">Ordine non trovato!</p>
            </div>
            <div class="mt-5">
                <a
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                    [routerLink]="homePath"
                >
                    <div>
                        <span class="text-white text-sm font-bold uppercase">Torna alla home</span>
                    </div>
                </a>
            </div>
        </div>
    </section>
</ng-template>
