<section
    class="border-2 border-white bg-light-linear rounded-lg card-shadow-bottom"
>
    <div class="flex">
        <div
            class="basis-4/12 bg-light-green rounded-l-lg flex items-center justify-center"
        >
            <img
                class="rounded-l-lg"
                src="../../../assets/img/homepage-images/come-ti-senti-oggi.png"
                alt=""
            />
        </div>
        <div class="basis-8/12 py-2.5 px-3 bg-white flex flex-col justify-between">
            <div>
                <div class="flex justify-between items-center">
                    <div
                        class="emoji-container flex items-center bg-light-green py-1.5 rounded-2xl"
                    >
                    <span class="mx-1.5">
                        <img
                            class="w-5 h-5"
                            src="../../../assets/img/emoji/grin.png"
                            alt=""
                        />
                    </span>
                        <span class="mx-1.5">
                        <img
                            class="w-5 h-5"
                            src="../../../assets/img/emoji/slightly_smiling_face.png"
                            alt=""
                        />
                    </span>
                        <span class="mx-1.5">
                        <img
                            class="w-5 h-5"
                            src="../../../assets/img/emoji/slightly_frowning_face.png"
                            alt=""
                        />
                    </span>
                        <span class="mx-1.5">
                        <img
                            class="w-5 h-5"
                            src="../../../assets/img/emoji/face_with_head_bandage.png"
                            alt=""
                        />
                    </span>
                    </div>
                </div>
                <div class="mt-4">
                    <h3 class="font-bold">Come ti senti oggi?</h3>
                    <p class="text-text-color text-sm mt-1.5">
                        Rispondi quotidianamente ad un breve questionario
                    </p>
                </div>
            </div>
            <button
                class="w-full bg-primary mt-5 rounded-lg border-0 py-2 flex items-center justify-center"
                [routerLink]="feelingPath"
                [disabled]="hasTodayFeelingSurvey"
            >
                    <span class="font-bold text-sm text-white"
                    >Compila questionario</span
                    >
            </button>
        </div>
    </div>
</section>
