import {Component, inject} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';
import {ActivatedRoute, RouterLink} from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import {APP_ROUTES} from "../../app.routes.definition";

@Component({
    selector: 'payment-redirect-page',
    standalone: true,
    imports: [TranslocoModule, AsyncPipe, RouterLink, NgIf],
    templateUrl: './payment-redirect-page.component.html',
    styleUrl: './payment-redirect-page.component.css',
})
export default class PaymentRedirectPageComponent {
    #activatedRoute = inject(ActivatedRoute);

    isError: boolean = false;

    homePath = APP_ROUTES.HOME();
    supportPath = APP_ROUTES.SETTINGS_SUPPORT();

    constructor() {
        this.isError = this.#activatedRoute.snapshot.fragment === 'error';
    }
}
