import { Component, OnInit, inject } from '@angular/core';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataType,
} from '../../../../../../models/health.models';
import { NavigationService } from '../../../../../../services/navigation.service';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-weight-page',
    standalone: true,
    imports: [HealthDataPageComponent],
    templateUrl: './weight-page.component.html',
})
export default class WeightPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Peso';
    chartLabel = 'Peso';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bubble',
        [HealthChartPeriod.week]: 'line',
        [HealthChartPeriod.month]: 'line',
    };
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];
    weightHealthDataType = HealthDataType.WEIGHT;
    hasTwoDecimals = true;
    payloadTypes: dataType[] = ['body'];
    canAddHealthData = true;

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
