import {Injectable, inject, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, tap} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import * as CreateOrderActions from './create-order.actions';
import {LogService} from "../../services/log.service";
import {fetchUser} from "../user/user.actions";
import {OrderService} from "../../services/order.service";
import {Router} from "@angular/router";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {ModalService} from "../../services/modal.service";

@Injectable()
export class CreateOrderEffects {
    #actions$ = inject(Actions);
    #router = inject(Router);
    #orderService = inject(OrderService);
    #zone = inject(NgZone);
    #modalService = inject(ModalService);
    #logService = inject(LogService);

    createOrder$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(CreateOrderActions.createOrder),
            mergeMap((action) =>
                this.#orderService.createOrder(action.request).pipe(
                    mergeMap((response) => {
                        return [CreateOrderActions.createOrderSuccess({
                            data: response,
                        })];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(CreateOrderActions.createOrderFailure({error}));
                    })
                )
            )
        )
    );

    createOrderSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(CreateOrderActions.createOrderSuccess),
        tap(payload => {
            window.location.href = payload.data.paymentSession.redirectUrl;
        })
    ), {dispatch: false});

    createOrderFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(CreateOrderActions.createOrderFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error
                    }
                });
            });
        })
    ), {dispatch: false});

    completeOrder$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(CreateOrderActions.completeOrder),
            mergeMap((action) =>
                this.#orderService.getOrder(action.id).pipe(
                    mergeMap((response) => {
                        return [CreateOrderActions.createOrderSuccess({
                            data: response
                        })];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(CreateOrderActions.completeOrderFailure({error}));
                    })
                )
            )
        )
    );

    cancelOrder$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(CreateOrderActions.cancelOrder),
            mergeMap((action) =>
                this.#orderService.cancelOrder(action.id).pipe(
                    // updated also user info with new features
                    mergeMap((response) => {
                        return [CreateOrderActions.cancelOrderSuccess(), fetchUser()];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(CreateOrderActions.cancelOrderFailure({error}));
                    })
                )
            )
        )
    );

    cancelOrderSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(CreateOrderActions.cancelOrderSuccess),
        tap(payload => {
            if (this.#router.url.toString() !== '/settings/subscriptions') {
                this.#router.navigateByUrl('/home');
            }
        })
    ), {dispatch: false});

    cancelOrderFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(CreateOrderActions.cancelOrderFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error
                    }
                });
            });
        })
    ), {dispatch: false});
}
