import {Component, inject} from '@angular/core';
import {FileSaverService} from 'ngx-filesaver';
import {ModalService} from '../../../../../../../../../../services/modal.service';
import {NativeAPIService} from '../../../../../../../../../../services/native/native-api.service';
import {
    ShareNativeService,
    ShareResultStatus,
} from '../../../../../../../../../../services/native/share-native.service';
import {NgFor} from '@angular/common';
import {LogService} from '../../../../../../../../../../services/log.service';

@Component({
    selector: 'app-generate-recovery-codes-modal-content',
    standalone: true,
    imports: [NgFor],
    templateUrl: './generate-recovery-codes-modal-content.component.html',
    styleUrl: './generate-recovery-codes-modal-content.component.css',
})
export class GenerateRecoveryCodesModalContentComponent {
    #modalService = inject(ModalService);
    #loggerService = inject(LogService);
    #nativeAPIService = inject(NativeAPIService);
    #shareNativeService = inject(ShareNativeService);
    #fileSaver = inject(FileSaverService);

    recoveryCodes: string[] = [];

    constructor() {
    }

    async onSubmitDownloadTxt(recoveryCodes: string[]) {
        const filename = 'beourbest_recovery_codes.txt';
        const data = recoveryCodes.join('\n');
        const blob = new Blob([data], {type: 'text/plain;charset=utf-8'});
        if (this.#nativeAPIService.isAvailable()) {
            const file = new File([blob], filename, {
                type: 'text/plain',
            });
            const shareData: ShareData = {
                title: filename,
                files: [file],
            };
            if (navigator.share != null && navigator.canShare(shareData)) {
                await navigator.share(shareData);
            } else {
                const blobData = Array.from(
                    new Uint8Array(await blob.arrayBuffer())
                );
                const result = await this.#shareNativeService.shareXFiles({
                    files: [blobData],
                    subject: filename,
                });
                if (result.status !== ShareResultStatus.success) {
                    this.#loggerService.log('cannot download recovery codes');
                }
            }
        } else {
            this.#fileSaver.save(blob, filename);
            setTimeout(() => {
                this.onClose();
            }, 1000);
        }
    }

    onClose() {
        this.#modalService.close();
    }
}
