import {Component, inject} from '@angular/core';
import {NavigationService} from '../../../../../../../services/navigation.service';
import {Router, RouterModule} from '@angular/router';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {VerifyOtpForm} from '../../../../../../../models/formgroups/verify-otp-form.model';
import {TwoFactorAuthResponse} from '../../../../../../../models/responses/auth-response.model';
import {first} from 'rxjs';
import {UserService} from '../../../../../../../services/user.service';
import {fetchUser} from '../../../../../../../store/user/user.actions';
import {environment} from '../../../../../../../../environments/environment';
import {AppState} from '../../../../../../../store/app.state';
import {Store} from '@ngrx/store';
import {TranslocoModule} from '@ngneat/transloco';
import {APP_ROUTES} from '../../../../../../../app.routes.definition';
import {ModalService} from '../../../../../../../services/modal.service';
import {
    TwoFactorActivatedModalContentComponent
} from './two-factor-activated-modal-content/two-factor-activated-modal-content.component';
import ErrorModalComponent, {ErrorModalData} from '../../../../../../../components/error-modal/error-modal.component';
import {LogService} from '../../../../../../../services/log.service';
import {NgIf} from "@angular/common";
import {AuthService} from "../../../../../../../services/auth.service";

@Component({
    selector: 'app-end-two-factor-process-page',
    standalone: true,
    imports: [ReactiveFormsModule, TranslocoModule, RouterModule, NgIf],
    templateUrl: './end-two-factor-process-page.component.html',
    styleUrl: './end-two-factor-process-page.component.css',
})
export default class EndTwoFactorProcessPageComponent {
    #navigationService = inject(NavigationService);
    #modalService = inject(ModalService);
    #userService = inject(UserService);
    #state = inject(Store<AppState>);
    #fb = inject(FormBuilder);
    #router = inject(Router);
    #logService = inject(LogService);
    #authService = inject(AuthService);

    #numericPattern: string = '[0-9]*';
    accessTokenLabel = environment.accessTokenKey;
    settingsPath = APP_ROUTES.SETTINGS_SECURITY(false);
    twoFactorSetupPath = APP_ROUTES.SETTINGS_TWO_FACTOR_SETUP(false);
    recoveryCodes?: string[];
    verifyOtpForm!: FormGroup<VerifyOtpForm>;
    loading = false;

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
        this.initializeForm();
    }

    initializeForm() {
        this.verifyOtpForm = this.#fb.nonNullable.group({
            otp: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.#numericPattern),
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
            ],
        });
    }

    onSubmitVerifyToken() {
        if (this.verifyOtpForm.invalid) return;
        const {otp} = this.verifyOtpForm.value;

        if (otp) {
            this.loading = true;

            this.#userService
                .enableTwoFactorAuthentication(otp)
                .pipe(first())
                .subscribe({
                    next: (response: TwoFactorAuthResponse) => {
                        this.#authService.setAuthenticated(
                            response.jwt.access_token
                        );
                        this.recoveryCodes = response.recoveryCodes;
                        this.#state.dispatch(fetchUser());

                        this.loading = false;
                    },
                    error: (error) => {
                        this.#logService.error(error);
                        this.#modalService.open<
                            ErrorModalData,
                            ErrorModalComponent
                        >(ErrorModalComponent, {
                            data: {
                                error: error,
                                message: 'Codice OTP scaduto o non valido, riprovare.'
                            },
                        });

                        this.loading = false;
                    },
                    complete: () => {
                        this.#modalService.open(
                            TwoFactorActivatedModalContentComponent,
                            {
                                data: {
                                    recoveryCodes: this.recoveryCodes,
                                },
                            }
                        );
                    },
                });
        }
    }

    goBack() {
        this.#navigationService.navigateBack();
    }

    ngOnDestroy() {
        this.#modalService.closeAll();
    }
}
