<section class="top-0 w-full fixed px-5 py-5" #notification>
    <div class="w-full shadow-2xl border-2 border-f7f7f7 bg-white rounded-lg py-2 px-2" (click)="onClick($event)">
        <div class="flex">
            <div class="basis-2/12" *ngIf="options.data.imageUrl">
                <img
                    class="size-12 rounded-full border border-light-grey object-cover"
                    [src]="options.data.imageUrl"
                    alt=""
                />
            </div>
            <div [ngClass]="{'basis-10/12': options.data.imageUrl, 'ms-3.5': options.data.imageUrl, 'w-full': !options.data.imageUrl}">
                <div class="flex items-center justify-between">
                    <div>
                        <h2 class="font-bold text-base">
                            {{ options.data.title }}
                        </h2>
                    </div>
                    <div class="ml-2" (click)="onClose($event)">
                        <span class="text-sm text-icon-data-red cursor-pointer">
                            Chiudi
                        </span>
                    </div>
                </div>
                <div class="px-2 py-2.5 bg-light-linear rounded mt-2">
                        <span class="text-sm text-black">
                            {{ options.data.subTitle }}
                        </span>
                </div>
            </div>
        </div>
    </div>
</section>
