import { Component, Input } from '@angular/core';
import {RouterLink} from "@angular/router";
import {
    HealthDataPeriod, HealthDataType,
    HealthDataTypeToUnit,
    HealthDataUnit,
    HealthDataUnitToString
} from "../../models/health.models";
import {TranslocoPipe} from "@ngneat/transloco";
import LoaderComponent from "../loader/loader.component";

@Component({
    selector: 'health-statistic-card',
    standalone: true,
    imports: [
        RouterLink,
        TranslocoPipe,
        LoaderComponent
    ],
    templateUrl: './health-statistic-card.component.html',
})
export class HealthStatisticCardComponent {
    @Input({required: true}) title!: string;
    @Input({required: true}) iconPath!: string;
    @Input({required: true}) period!: HealthDataPeriod;
    @Input({required: true}) isLoading: boolean = true;
    @Input({required: true}) dataType!: HealthDataType;
    @Input() isSingleValue: boolean = false;
    @Input() healthTypePath?: string | null;
    @Input() value?: string | null;
    @Input() unitSymbolLabel?: string | null;

    HealthDataPeriod = HealthDataPeriod;
    HealthDataUnitToString = HealthDataUnitToString;
    HealthDataUnit = HealthDataUnit;
    HealthDataTypeToUnit = HealthDataTypeToUnit;
}
