import {Component, inject, signal} from '@angular/core';
import {Location, NgIf} from '@angular/common';
import {
    AbstractControlOptions,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {TranslocoModule} from '@ngneat/transloco';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {confirmPasswordValidator} from '../../shared/validators/confirm-password.validator';
import {delay, EMPTY, first, of} from 'rxjs';
import {VerifyEmailForm} from '../../models/formgroups/verify-email-form.model';
import {NewPasswordForm} from '../../models/formgroups/new-password-form.model';
import {GenericModalComponent} from '../../components/generic-modal/generic-modal.component';
import {APP_ROUTES} from '../../app.routes.definition';
import {ModalService} from '../../services/modal.service';
import {LogService} from '../../services/log.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../components/error-modal/error-modal.component';
import {InfoModalComponent, InfoModalData} from '../../components/info-modal/info-modal.component';

@Component({
    selector: 'reset-password-page',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        GenericModalComponent,
        RouterLink,
        NgIf,
    ],
    templateUrl: './reset-password-page.component.html',
})
export default class ResetPasswordPageComponent {
    #fb = inject(FormBuilder);
    #router = inject(Router);
    #userService = inject(UserService);
    #logService = inject(LogService);
    #modalService = inject(ModalService);

    loginPath = APP_ROUTES.LOGIN();

    newPasswordForm!: FormGroup<NewPasswordForm>;
    resetKeyDoneLabel = '';
    isLoading: boolean = false;
    showEmailValidationForm: boolean = false;
    showPasswordValidationForm: boolean = false;
    verifyEmailForm!: FormGroup<VerifyEmailForm>;
    actionCompleted = false;

    resetKey: string | null = null;

    constructor(
        private location: Location,
        private activatedRoute: ActivatedRoute
    ) {
        this.showEmailForm();
        this.resetKeyDoneLabel = '';
        const resetKey = this.activatedRoute.snapshot.queryParamMap.get('resetKey');
        if (resetKey) {
            this.resetKey = resetKey;
            this.showNewPasswordForm();
        }
        this.initializeForm();
    }

    initializeForm() {
        this.verifyEmailForm = this.#fb.nonNullable.group({
            email: ['', [Validators.required, Validators.email]],
        });
        this.newPasswordForm = this.#fb.nonNullable.group(
            {
                newPassword: [
                    '',
                    [Validators.required, Validators.minLength(8)],
                ],
                confirmPassword: ['', [Validators.required]],
            },
            {
                validator: confirmPasswordValidator(
                    'newPassword',
                    'confirmPassword'
                ),
            } as AbstractControlOptions
        );
    }

    showEmailForm() {
        this.showEmailValidationForm = true;
        this.showPasswordValidationForm = false;
    }

    showNewPasswordForm() {
        this.showEmailValidationForm = false;
        this.showPasswordValidationForm = true;
    }

    onSubmitVerifyEmail() {
        if (this.verifyEmailForm.invalid) return;
        const {email} = this.verifyEmailForm.value;

        this.isLoading = true;
        this.actionCompleted = false;

        this.#userService
            .sendEmailForReset(email!.toLowerCase())
            .pipe(first())
            .subscribe({
                next: () => {
                    this.#logService.log('Email sent');
                },
                error: (err) => {
                    this.#logService.error(err);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: err,
                            message: err.status === 404 ? 'L\'email inserita è errata o non esistente.' : undefined
                        },
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.#modalService.open<
                        InfoModalData,
                        InfoModalComponent
                    >(InfoModalComponent, {
                        data: {
                            title: 'Email inviata con successo!',
                            message: 'Controlla la tua casella di posta in arrivo. Tra poco verrai reindirizzato automaticamente alla pagina di login.',
                            onCloseCallback: () => {
                                this.#router.navigate([this.loginPath]);
                            }
                        },
                    });
                    this.actionCompleted = true;
                    this.isLoading = false;
                    of(EMPTY).pipe(delay(3000)).subscribe({
                        next: () => {
                            this.#modalService.close();
                            this.#router.navigate([this.loginPath]);
                        }
                    });
                },
            });
    }

    onSubmitConfirmPassword() {
        if (this.newPasswordForm.invalid) return;
        const {newPassword} = this.newPasswordForm.value;

        this.isLoading = true;
        this.actionCompleted = false;

        this.#userService
            .confirmResetPassword(this.resetKey!, newPassword!)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.#logService.log('New password updated');
                },
                error: (err) => {
                    this.#logService.error(err);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: err,
                            message: err.status === 404 ? 'La chiave di reset password è errata o non esistente.' : undefined
                        },
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.#modalService.open<
                        InfoModalData,
                        InfoModalComponent
                    >(InfoModalComponent, {
                        data: {
                            title: 'Aggiornamento password riuscito',
                            message: 'Password correttamente aggiornata. Tra poco verrai reindirizzato automaticamente alla pagina di login.',
                            onCloseCallback: () => {
                                this.#router.navigate([this.loginPath]);
                            }
                        },
                    });
                    this.actionCompleted = true;
                    this.isLoading = false;
                    of(EMPTY).pipe(delay(3000)).subscribe({
                        next: () => {
                            this.#modalService.close();
                            this.#router.navigate([this.loginPath]);
                        }
                    });
                },
            });
    }
}
