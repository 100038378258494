<div class="flex flex-col items-center">
    <div class="slider" [ngStyle]="{'text-align': textAlign}">
        <div
            class="slides"
            #sliderElement
            [ngStyle]="{ 'overflow-x': scrollable ? 'auto' : 'hidden' }"
            (scroll)="sliderScroll()"
        >
            @if (components && components.length > 0) {
                @for (component of
                    components; track $index) {
                    <div class="slide" #slideElements>
                        <ng-template [ngTemplateOutlet]="component"></ng-template>
                    </div>
                }
            } @else {
                @for (slide of slides; track $index) {
                    <div class="slide" #slideElements>
                        <div *ngIf="slide.img" class="w-full">
                            <!-- logica per la gestione dell'immagine -->
                            <img
                                [src]="slide.img"
                                class="basis-full h-full w-full rounded-3xl {{slide.objectCover ?? 'object-cover'}}"
                                style="height: {{ slideHeight }}px"
                                alt=""
                            />
                        </div>
                        <div class="text-center mt-6 px-2" *ngIf="slide.title || slide.text">
                            <div>
                                <h1
                                    *ngIf="slide.title"
                                    class="text-primary text-3xl"
                                    [innerHTML]="slide.title"
                                ></h1>
                                <p
                                    *ngIf="slide.text"
                                    class="mt-3"
                                    [innerHTML]="slide.text"
                                ></p>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>
    </div>

    <div class="flex items-center justify-between p-10 pt-0 w-full mt-6">
        <div class="flex items-center" *ngIf="!previousButtonDisabled" (click)="previousSlide()">
            <svg class="w-4 h-4 fill-primary me-2">
                <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
            </svg>
            <button
                class="text-primary text-sm font-bold"
            >
                {{ previousButtonLabel }}
            </button>
        </div>
        <div class="paginator-container" *ngIf="!paginationDisabled">
            @if (components && components.length > 0) {
                @for (component of
                    components; track $index) {
                    <div
                        class="paginator-item active"
                        [ngClass]="{ active: $index === currentSlide }"
                    ></div>
                }
            } @else {
                @for (slide of slides; track $index) {
                    <div
                        class="paginator-item active"
                        [ngClass]="{ active: $index === currentSlide }"
                    ></div>
                }
            }
        </div>
        <div class="flex items-center" *ngIf="!nextButtonDisabled" (click)="nextSlide()">
            <button
                class="text-primary text-sm font-bold"
            >
                {{ nextButtonLabel }}
            </button>
            <svg class="w-4 h-4 fill-primary ms-2">
                <use href="../../../assets/svg/svGP.svg#chevron-right"></use>
            </svg>
        </div>
    </div>
</div>
