import {
    Component,
    OnInit,
    inject,
} from '@angular/core';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataAggregateOperation, HealthDataType,
} from '../../../../../../models/health.models';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationService } from '../../../../../../services/navigation.service';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-steps-page',
    standalone: true,
    imports: [HealthDataPageComponent, TranslocoModule],
    templateUrl: './steps-page.component.html',
    styleUrl: './steps-page.component.css',
})
export default class StepsPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Passi';
    chartLabel = 'Passi';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bar',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar',
    }
    healthDataUnitSymbolLabel = 'passi';
    healthDataType = HealthDataType.STEPS;
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['daily', 'body', 'activity'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    protected readonly HealthDataAggregateOperation = HealthDataAggregateOperation;
}
