<h1>Autenticazione a due fattori abilitata!</h1>

<p>
    Per garantire la sicurezza del tuo account, ti consigliamo vivamente di
    scaricare e conservare in un luogo sicuro i codici di recupero per
    l'autenticazione a due fattori (2FA).
</p>

<button
    class="py-5 px-4 border-0 !bg-primary rounded-lg flex items-center justify-center w-full mt-2 text-center"
    (click)="onSubmitDownloadTxt(recoveryCodes)"
>
    <div class="flex items-center justify-center w-full">
        <span class="text-white text-sm font-bold">Download</span>
    </div>
</button>

<button
    class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
    (click)="onClose()"
>
    <div class="flex items-center justify-center w-full">
        <span class="text-primary text-sm font-bold">Salta</span>
    </div>
</button>
