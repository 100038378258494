import {Component, inject, OnInit} from '@angular/core';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataType,
} from '../../../../../../models/health.models';
import {NavigationService} from '../../../../../../services/navigation.service';
import {HealthDataPageComponent} from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-blood-pressure-systolic',
    standalone: true,
    imports: [HealthDataPageComponent],
    templateUrl: './blood-pressure-systolic-page.component.html',
    styleUrl: './blood-pressure-systolic-page.component.css',
})
export default class BloodPressureSystolicPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Pressione Arteriosa Sistolica';
    chartLabel = 'Pressione Arteriosa Sistolica';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bubble',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar',
    }
    heightHealthDataType = HealthDataType.BLOOD_PRESSURE_SYSTOLIC;
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['body'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
