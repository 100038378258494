<div class="flex flex-col h-full">
    <div class="custom-bg-white relative px-6 h-full pb-28">
        <div class="flex items-center justify-between my-5">
            <div>
                <h1 class="font-bold text-xl">Le tue chat</h1>
            </div>
        </div>

        <app-loader *ngIf="loading"></app-loader>

        @for (conversation of conversations; track $index) {
            <div
                class="border-2 border-f7f7f7 bg-white rounded-lg py-4 px-3 mt-2 card-shadow cursor-pointer"
                (click)="openConversation(conversation)"
            >
                <div class="flex items-centerw-full">
                    <div class="basis-2/12">
                        <img
                            class="size-12 rounded-full border border-light-grey object-cover"
                            [src]="conversation?.coachImageURL ?? defaultCoachImg"
                            alt=""
                        />
                    </div>
                    <div class="basis-10/12 ms-3.5">
                        <div class="flex items-center justify-between">
                            <div>
                                <h2 class="font-bold text-base">
                                    {{ conversation.coachName }}
                                </h2>
                                <span class="text-9f9d9d">
                                {{ conversation.coachQualification }}</span
                                >
                            </div>
                            @if (agoraLastMessages[conversation.id] || (conversation.chatMessages && conversation.chatMessages.length > 0)) {
                                <div>
                                    <span class="text-xs text-3b3b35">
                                    @if (!!(hasFeatureChatEnabled$ | async) === false && conversation.chatMessages) {
                                        {{ conversation.chatMessages[0].createdDate | date : "HH:mm" }}
                                    } @else if (agoraLastMessages[conversation.id]) {
                                        {{ agoraLastMessages[conversation.id].createdDate | date : "HH:mm" }}
                                    }
                                    </span>
                                </div>
                            }
                        </div>
                        @if (agoraLastMessages[conversation.id] || (conversation.chatMessages && conversation.chatMessages.length > 0)) {
                            <div class="px-2 py-2.5 bg-light-linear rounded mt-2">
                                <span class="text-sm text-black truncate inline-block w-full" style="container-type: inline-size">
                                    @if (!!(hasFeatureChatEnabled$ | async) === false && conversation.chatMessages) {
                                        {{ conversation.chatMessages[0].text }}
                                    } @else if (agoraLastMessages[conversation.id]) {
                                        {{ agoraLastMessages[conversation.id].text }}
                                    }
                                </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</div>
