import {Component, inject, OnInit} from '@angular/core';
import {SurveyService} from '../../services/survey.service';
import {combineLatest, first} from 'rxjs';
import {Survey} from '../../models/survey.models';
import {SurveyComponent} from '../../components/survey/survey.component';
import {LogService} from '../../services/log.service';
import {GoogleAnalyticsService} from "../../services/google-analytics.service";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {ModalService} from "../../services/modal.service";
import {NavigationService} from '../../services/navigation.service';
import LoaderComponent from "../../components/loader/loader.component";
import {NgIf} from "@angular/common";
import {InfoModalComponent, InfoModalData} from "../../components/info-modal/info-modal.component";

@Component({
    selector: 'survey-page',
    standalone: true,
    imports: [SurveyComponent, LoaderComponent, NgIf],
    templateUrl: './survey-page.component.html',
    styleUrl: './survey-page.component.css',
})
export default class SurveyPageComponent implements OnInit {
    #surveyService = inject(SurveyService);
    #loggerService = inject(LogService);
    #googleAnalyticsService = inject(GoogleAnalyticsService);
    #modalService = inject(ModalService);
    #navigationService = inject(NavigationService);

    initialSurvey?: Survey;
    isSendingSurvey = false;

    ngOnInit() {
        combineLatest([
            this.#surveyService.checkInitialSurveyValidity(),
            this.#surveyService.getInitialSurvey(),
        ]).pipe(first()).subscribe({
            next: ([isValid, survey]) => {
                if (isValid) {
                    this.#navigationService.navigateBack();
                    return;
                }
                this.initialSurvey = survey;
            },
            error: (err) => {
                this.#loggerService.error(err);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                            message: 'Non è stato possibile caricare il questionario',
                            onCloseCallback: () => {
                                this.#navigationService.navigateBack();
                            }
                        },
                    }
                );
            }
        });
    }

    onSurveyNextPage(event: { currentPage: number, prevPage: number }) {
        switch (event.prevPage) {
            case 0:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step0_inizia_survey',
                    eventParams: 'avanti'
                });
                break;
            case 1:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step1_età_genere',
                    eventParams: 'avanti'
                });
                break;
            case 2:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step2_obiettivo_ambito',
                    eventParams: 'avanti'
                });
                break;
            case 3:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step3_preferenza_health_coach',
                    eventParams: 'avanti'
                });
                break;
        }
    }

    onSurveyPrevPage(event: { currentPage: number, prevPage: number }) {
        switch (event.prevPage) {
            case 1:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step1_età_genere',
                    eventParams: 'indietro'
                });
                break;
            case 2:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step2_obiettivo_ambito',
                    eventParams: 'indietro'
                });
                break;
            case 3:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step3_preferenza_health_coach',
                    eventParams: 'indietro'
                });
                break;
            case 4:
                this.#googleAnalyticsService.sendAnalyticsEvent({
                    survey: 'funnel_survey',
                    eventName: 'step4_rivedi_scelte',
                    eventParams: 'indietro'
                });
                break;
        }
    }

    onCancelSurvey() {
        this.#googleAnalyticsService.sendAnalyticsEvent({
            survey: 'funnel_survey',
            eventName: 'step0_inizia_survey',
            eventParams: 'indietro'
        });
    }

    onSurveySubmit(survey: Survey) {
        if (this.isSendingSurvey) {
            return;
        }
        this.isSendingSurvey = true;

        this.#loggerService.log(survey);

        this.#googleAnalyticsService.sendAnalyticsEvent({
            survey: 'funnel_survey',
            eventName: 'step4_rivedi_scelte',
            eventParams: 'conferma'
        });

        this.#surveyService.postInitialSurvey(survey).subscribe({
            next: value => {
                this.#loggerService.log(value);
                this.isSendingSurvey = false;
                this.#modalService.open<InfoModalData, InfoModalComponent>(
                    InfoModalComponent,
                    {
                        data: {
                            title: 'Questionario inviato',
                            message: 'Grazie per aver completato il questionario.' + (value != null ? ' Ti è stato associato un coach.' : ''),
                            onCloseCallback: () => {
                                this.#navigationService.navigateBack();
                            }
                        },
                    }
                );
            },
            error: error => {
                this.#loggerService.error(error);
                this.isSendingSurvey = false;
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                            message: 'Non è stato possibile inviare il questionario.',
                        },
                    }
                );
            }
        });
    }
}
