import {Component, inject} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {APP_ROUTES} from "../../app.routes.definition";
import {GoogleAnalyticsService} from "../../services/google-analytics.service";

@Component({
    selector: 'initial-survey-card',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './initial-survey-card.component.html',
    styleUrl: './initial-survey-card.component.css',
})
export class InitialSurveyCardComponent {
    #router = inject(Router);
    #googleAnalyticsService = inject(GoogleAnalyticsService);

    surveyPath = APP_ROUTES.SURVEY();

    constructor() {
    }

    goToSurvey() {
        this.#googleAnalyticsService.sendAnalyticsEvent({
            eventName: 'accedi_survey',
        });

        this.#router.navigate([this.surveyPath]);
    }
}
