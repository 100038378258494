import {
    ApplicationRef,
    ComponentRef,
    EnvironmentInjector,
    Inject,
    Injectable,
    PLATFORM_ID,
    Type,
    createComponent, inject,
} from '@angular/core';
import {ModalComponent} from '../components/modal/modal.component';
import {Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {HealthDataType, HealthDataUnit} from "../models/health.models";
import {NotificationComponent} from "../components/notification/notification.component";
import {RemoteMessage} from "./native/firebase-messaging-native.service";
import {APP_ROUTES} from "../app.routes.definition";
import {Router} from "@angular/router";

export interface NotificationOptions {
    actions?: {
        click?: (remoteMessage?: RemoteMessage | null) => boolean | Promise<boolean>;
    };
    timeout?: number | null;
    data: {
        title: string;
        subTitle: string;
        imageUrl?: string | null;
        remoteMessage?: RemoteMessage | null;
    }
}

export interface SubjectNotification {
    subject: Subject<any>;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    #router = inject(Router);

    private notificationSubject!: Subject<any>;
    /**
     * Internal use only.
     */
    notificationInstances: { notificationComponent: ComponentRef<NotificationComponent> }[] = [];
    /**
     * Internal use only.
     */
    layerLevel = 0;
    private isBrowser = true;
    private notificationSubjects: SubjectNotification[] = [];

    constructor(
        private appRef: ApplicationRef,
        private injector: EnvironmentInjector,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    open<C extends NotificationComponent>(options: NotificationOptions) {
        // close all the notification components
        // before opening a new one
        this.closeAll();

        this.notificationSubject = new Subject();
        this.openComponent(options);
        return this.notificationSubject;
    }

    isAlreadyOpened(): boolean {
        return this.notificationInstances.length > 0;
    }

    private openComponent<C extends NotificationComponent>(options: NotificationOptions) {
        if (!this.isBrowser) return;

        const newNotificationComponent = createComponent(NotificationComponent, {
            environmentInjector: this.injector,
        });

        newNotificationComponent.instance.options = options;
        this.notificationSubjects.push({subject: this.notificationSubject});
        this.notificationInstances.push({
            notificationComponent: newNotificationComponent
        });

        document.body.appendChild(newNotificationComponent.location.nativeElement);
        this.appRef.attachView(newNotificationComponent.hostView);
    }

    private instantiateProps(component: ComponentRef<any>, data?: any) {
        if (data) {
            for (const key of Object.keys(data)) {
                // @ts-ignore
                component.instance[key] = data[key];
            }
        }
    }

    close(data?: unknown) {
        const modalInstance = this.notificationInstances.pop();
        modalInstance?.notificationComponent.instance.close(() => {
            modalInstance?.notificationComponent.destroy();
        });

        if (this.notificationSubjects.length === 0) return;

        const currentSubject = this.notificationSubjects.pop() as SubjectNotification;
        currentSubject.subject.next(data);
        currentSubject.subject.complete();
    }

    closeAll() {
        for (let i = this.notificationInstances.length - 1; i > -1; i--) {
            this.notificationInstances[i].notificationComponent.instance.close(() => {
                this.notificationInstances[i].notificationComponent.destroy();
            });
        }

        this.notificationSubjects = [];
    }

    shouldOpenNotificationPopup(remoteMessage: RemoteMessage) {
        if (this.isAgoraSfChatNotification(remoteMessage)) {
            return this.#router.url !== APP_ROUTES.MESSAGING_ID(false, remoteMessage.data['contactId']);
        } else if (this.isFreeChatNotification(remoteMessage)) {
            return this.#router.url !== APP_ROUTES.FREE_MESSAGING(false, remoteMessage.data['contactId']);
        }
        return true;
    }

    isAgoraSfChatNotification(remoteMessage: RemoteMessage) {
        const data = remoteMessage.data;
        return data['id'] === 'AGORA_SF_CHAT' && data['contactId'] != null;
    }

    isFreeChatNotification(remoteMessage: RemoteMessage) {
        const data = remoteMessage.data;
        return this.isMarketingCloudNotification(remoteMessage) && data['id'] === 'SF_FREE_CHAT' && data['contactId'] != null;
    }

    isMarketingCloudNotification(remoteMessage: RemoteMessage) {
        const data = remoteMessage.data;
        return data['_sid'] === 'SFMC';
    }
}
