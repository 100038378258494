<div class="flex flex-col h-full relative">
    <div
        class="flex flex-col items-center justify-center mb-3 custom-bg-illustration-blue"
    >
        <img
            class="h-20 w-auto"
            src="../../../../../../../../../../assets/img/illustrations/illustration-human-2fa.png"
            alt=""
        />
    </div>
    <div class="custom-bg-white py-7 px-6 relative pb-28 h-full">
        <div class="flex flex-col h-full justify-between">
            <div>
                <h2 class="font-semibold text-lg">
                    Aggiungi un ulteriore livello di sicurezza con l'Autenticazione
                    2FA.
                </h2>
                <p class="mt-5">
                    Per prevenire attacchi al tuo account, dobbiamo verificare la
                    tua identità nel caso in cui notiamo un accesso insolito.<br /><br />Per
                    fare ciò, riceverai un codice da un'app di autenticazione di
                    terze parti. Dovrai poi copiare quel codice nell'app Be Our
                    Best.
                </p>
            </div>
            <div>
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                    [routerLink]="beginTwoFactorProcessPath"
                >
                    <div class="flex items-center justify-center w-full">
                        <span class="text-white text-sm font-bold uppercase"
                            >Avanti</span
                        >
                    </div>
                </button>
                <button
                    class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center mt-2 w-full"
                    [routerLink]="settingsPath"
                >
                    <div class="flex items-center justify-center w-full">
                        <span class="text-primary text-sm font-bold"
                            >Annulla operazione</span
                        >
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
