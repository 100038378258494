import { Route } from '@angular/router';
import { UserHasProductFeatureGuard } from '../../guards/auth.guard';
import { ProductFeature } from '../../models/product.model';
import {APP_ROUTES} from "../../app.routes.definition";
import MonitorPageComponent from "./monitor-page.component";
import {HEART_CATEGORY_ROUTES} from "./pages/heart-category-page/heart-category.routes";
import {BODY_CATEGORY_ROUTES} from "./pages/body-category-page/body-category.routes";
import {ACTIVITY_CATEGORY_ROUTES} from "./pages/activity-category-page/activity-category.routes";
import {SLEEP_CATEGORY_ROUTES} from "./pages/sleep-category-page/sleep-category.routes";

export const MONITOR_ROUTES: Route[] = [
    {
        path: '',
        component: MonitorPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_HEART(true),
        data: { title: 'Heart ', showInNavbar: false, showAvatar: false },
        children: HEART_CATEGORY_ROUTES,
    },
    {
        path: APP_ROUTES.MONITOR_BODY(true),
        data: { title: 'Body', showInNavbar: false, showAvatar: false },
        children: BODY_CATEGORY_ROUTES,
    },
    {
        path: APP_ROUTES.MONITOR_ACTIVITY(true),
        data: { title: 'Activity', showInNavbar: false, showAvatar: false },
        children: ACTIVITY_CATEGORY_ROUTES,
    },
    {
        path: APP_ROUTES.MONITOR_SLEEP(true),
        data: { title: 'Sleep', showInNavbar: false, showAvatar: false },
        children: SLEEP_CATEGORY_ROUTES,
    },
];
