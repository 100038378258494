<div class="container max-w-3xl mx-auto">
    <div class="mt-5 text-center">
        <h1 class="font-bold text-xl">{{ title }}</h1>
    </div>
    <div class="mt-4 text-center">
        <p class="text-sm">
            {{ message }}
        </p>
    </div>
    <div class="mt-5">
        <button
            (click)="onClose()"
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-white text-sm font-bold uppercase">{{
                    buttonLabel ?? "Va bene"
                }}</span>
            </div>
        </button>
    </div>
</div>
