import {AppState} from '../app.state';
import {createSelector} from "@ngrx/store";

export const selectInternalHealthDataState = (state: AppState) => state.internalHealthData;

export const selectInternalHealthDataAddValueState = createSelector(
    selectInternalHealthDataState,
    (state) => state.createValue
);

export const selectCreateInternalHealthDataValueData = createSelector(
    selectInternalHealthDataAddValueState,
    (state) => state.data
);

export const selectCreateInternalHealthDataValueError = createSelector(
    selectInternalHealthDataAddValueState,
    (state) => state.error
);
export const selectCreateInternalHealthDataValueIsLoading = createSelector(
    selectInternalHealthDataAddValueState,
    (state) => state.isLoading
);

export const selectInternalHealthDataValuesState = createSelector(
    selectInternalHealthDataState,
    (state) => state.getValues
);

export const selectInternalHealthDataValuesData = createSelector(
    selectInternalHealthDataValuesState,
    (state) => state.data
);

export const selectInternalHealthDataValuesError = createSelector(
    selectInternalHealthDataValuesState,
    (state) => state.error
);

export const selectInternalHealthDataValuesIsLoading = createSelector(
    selectInternalHealthDataValuesState,
    (state) => state.isLoading
);

export const selectInternalHealthDataValuesCanLoadMore = createSelector(
    selectInternalHealthDataValuesState,
    (state) => state.canLoadMore
);

export const selectInternalHealthDataValuesIsLoadMoreLoading = createSelector(
    selectInternalHealthDataValuesState,
    (state) => state.isLoadMoreLoading
);

export const selectUpdateInternalHealthDataValueState = createSelector(
    selectInternalHealthDataState,
    (state) => state.updateValue
);

export const selectUpdateInternalHealthDataValueData = createSelector(
    selectUpdateInternalHealthDataValueState,
    (state) => state.data
);

export const selectUpdateInternalHealthDataValueError = createSelector(
    selectUpdateInternalHealthDataValueState,
    (state) => state.error
);
export const selectUpdateInternalHealthDataValueIsLoading = createSelector(
    selectUpdateInternalHealthDataValueState,
    (state) => state.isLoading
);

export const selectDeleteInternalHealthDataValueState = createSelector(
    selectInternalHealthDataState,
    (state) => state.deleteValue
);

export const selectDeleteInternalHealthDataValueError = createSelector(
    selectDeleteInternalHealthDataValueState,
    (state) => state.error
);
export const selectDeleteInternalHealthDataValueIsLoading = createSelector(
    selectDeleteInternalHealthDataValueState,
    (state) => state.isLoading
);
