<div class="survey-page bg-body-bg h-screen">
    @if (!startSurvey) {
    <app-registration-dash-icon>
        <svg class="w-14 h-auto fill-primary">
            <use href="../assets/svg/svGP.svg#paste-clipboard"></use>
        </svg>
    </app-registration-dash-icon>
    <div class="mt-10">
        <div class="text-center px-5">
            <h1 class="text-primary font-semibold text-3xl">Prima di continuare, completa il nostro questionario</h1>
        </div>
        <div class="mt-24">
            <img src="../assets/img/illustrations/illustration-survey.png" class="overflow-x-hidden w-18/12" alt="">
        </div>
        <div class="mx-5 mt-14 pb-10">
            <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full" (click)="startSurvey = true">
                <div class="flex items-center">
                    <span class="text-white text-sm font-bold uppercase">Inizia il questionario</span>
                </div>
            </button>
        </div>
    </div>
    } @if (initialSurvey && startSurvey) {
    <survey [survey]="initialSurvey" [completeSurveyButtonLabel]="'Completa il questionario'" (submitSurvey)="onSurveySubmit($event)" />
    }
</div>