<div class="flex flex-col h-full">
    <div class="bg-light-linear rounded-t-4xl relative px-6 h-full pb-32">
        <div class="flex items-center justify-between w-full mt-5">
            <h1 class="text-primary font-semibold text-lg">Abbonamenti</h1>
        </div>

        <app-loader
            *ngIf="isLoading$ | async as isLoading; else productsContainer"
        ></app-loader>

        <ng-template #productsContainer>
            <ng-container *ngIf="products$ | async as products">
                <app-carousel
                    [textAlign]="'left'"
                    [initialIndex]="0"
                    (slideChange)="currentProductSlide = $event"
                >
                    @for (product of products.baseProducts; track product) {
                    <ng-template #component>
                        <div class="mt-4">
                            <app-tier-card [product]="product"></app-tier-card>
                        </div>
                    </ng-template>
                    }
                </app-carousel>

                @if (!(userBasePlan$ | async)) {
                <div class="flex items-center">
                    <button
                        [routerLink]="buyBasePlanPath"
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                    >
                        <div class="flex items-center">
                            <div>
                                <span
                                    class="text-white text-sm font-bold uppercase"
                                    >Abbonati</span
                                >
                            </div>
                        </div>
                    </button>
                </div>
                }

                <!--                <div class="flex items-center">-->
                <!--                    @if (userBasePlan$ | async; as userBasePlan) {-->
                <!--                        <button-->
                <!--                            [routerLink]="selectPlanPath"-->
                <!--                            [disabled]="-->
                <!--                            userBasePlan.orderItems[0].product.productCode ===-->
                <!--                            products.baseProducts[currentProductSlide]-->
                <!--                                .productCode-->
                <!--                        "-->
                <!--                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"-->
                <!--                        >-->
                <!--                            <div class="flex items-center">-->
                <!--                                <div>-->
                <!--                                <span-->
                <!--                                    class="text-white text-sm font-bold uppercase"-->
                <!--                                >Cambia Piano</span-->
                <!--                                >-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </button>-->
                <!--                    } @else {-->
                <!--                        <button-->
                <!--                            [routerLink]="buyBasePlanPath"-->
                <!--                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"-->
                <!--                        >-->
                <!--                            <div class="flex items-center">-->
                <!--                                <div>-->
                <!--                                <span-->
                <!--                                    class="text-white text-sm font-bold uppercase"-->
                <!--                                >Abbonati</span-->
                <!--                                >-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </button>-->
                <!--                    }-->
                <!--                </div>-->

                <hr class="dashed my-7 -mx-6" />

                <div>
                    <h2 class="font-semibold text-lg">Servizi aggiuntivi</h2>
                    @if (products.addonProducts.length > 0) { @for (product of
                    products.addonProducts; track product) {
                    <div class="mt-4">
                        <product-card [product]="product" />
                    </div>
                    } } @else {
                    <p>Nessun prodotto presente</p>
                    }
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>
