import {Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {FreeChatService} from '../../../services/free-chat.service';
import {ChatMessage, PayloadChat} from '../../../models/chat.models';
import {MessageListComponent} from '../../../components/message-list/message-list.component';
import {MessageFormComponent} from '../../../components/message-form/message-form.component';
import {NavigationService} from '../../../services/navigation.service';
import {AppState} from '../../../store/app.state';
import {Store} from '@ngrx/store';
import {selectUserData} from '../../../store/user/user.selectors';
import {AsyncPipe, NgIf} from '@angular/common';
import {APP_ROUTES} from '../../../app.routes.definition';
import LoaderComponent from '../../../components/loader/loader.component';
import {first, Subscription} from 'rxjs';
import {LogService} from '../../../services/log.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../components/error-modal/error-modal.component';
import {ModalService} from '../../../services/modal.service';
import {FirebaseMessagingNativeService} from "../../../services/native/firebase-messaging-native.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'free-conversation',
    standalone: true,
    imports: [
        MessageListComponent,
        MessageFormComponent,
        AsyncPipe,
        LoaderComponent,
        NgIf,
        RouterLink,
    ],
    templateUrl: './free-conversation.component.html',
    styleUrl: './free-conversation.component.css',
})
export default class FreeConversationComponent implements OnInit, OnDestroy {
    @ViewChild('messageListComponent')
    messageListComponent!: MessageListComponent;

    #route = inject(ActivatedRoute);
    #router = inject(Router);
    #freeChatService = inject(FreeChatService);
    #navigationService = inject(NavigationService);
    #store = inject(Store<AppState>);
    #modalService = inject(ModalService);
    #logService = inject(LogService);
    #firebaseMessagingNativeService = inject(FirebaseMessagingNativeService);
    #notificationService = inject(NotificationService);

    user$ = this.#store.select(selectUserData);

    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();

    loading = true;
    sendingMessage = false;

    defaultAvatar = '/assets/img/pic/profile.png';

    conversationId: string | null = null;
    recipientId: string | null = null;
    chatMessages: ChatMessage[] = [];
    chatActive = false;
    data?: PayloadChat | null;

    #notificationSubscription?: Subscription;

    constructor() {
        const navigation = this.#router.getCurrentNavigation();
        this.data = navigation?.extras.state?.['data'];
        this.conversationId = this.#route.snapshot.paramMap.get('id');

        this.#logService.log('DATA: ', this.data);

        if (!this.data || !this.conversationId) {
            this.#router.navigate([APP_ROUTES.MESSAGING()]);
        } else {
            this.#navigationService.saveCurrentRoute();
        }
    }

    ngOnInit() {
        this.#freeChatService
            .getConversationById(this.conversationId!)
            .pipe(first())
            .subscribe({
                next: (res) => {
                    this.#logService.log('Free chat conversation', res);

                    this.recipientId = res.contactId;
                    this.chatMessages = res.chatMessages ?? [];
                    this.chatActive = res.status === 'Active';

                    setTimeout(() => {
                        // wait for angular render to update message-list to scroll to the bottom
                        this.messageListComponent.scrollToBottom();
                    });

                    this.loading = false;

                    if (this.#firebaseMessagingNativeService.isAvailable()) {
                        // add the message directly if we receive a push notification about a new chat message
                        // for the current free chat
                        this.#notificationSubscription = this.#firebaseMessagingNativeService.onMessage$.subscribe({
                            next: remoteMessage => {
                                if (this.#notificationService.isFreeChatNotification(remoteMessage) &&
                                    this.#router.url === APP_ROUTES.FREE_MESSAGING(false, remoteMessage.data['contactId']) && remoteMessage.notification?.body) {
                                    this.chatMessages.push({
                                        text: remoteMessage.notification.body,
                                        id: '',
                                        createdDate: '',
                                        recipientId: '',
                                        chatId: '',
                                        senderId: remoteMessage.data['contactId']
                                    });
                                }
                            },
                            error: err => {
                                this.#logService.error(err);
                            }
                        })
                    }
                },
                error: (err) => {
                    this.#logService.error(err);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: err,
                        },
                    });
                    this.loading = false;
                },
            });
    }

    sendFreeMessage(text: string, recipientId: string) {
        this.sendingMessage = true;

        this.#freeChatService.sendMessage(text, recipientId).subscribe({
            next: (res) => {
                if (res != null) {
                    this.#logService.log('send message response', res);
                    this.chatMessages.push(res);
                    this.chatActive = true;

                    setTimeout(() => {
                        // wait for angular render to update message-list to scroll to the bottom
                        this.messageListComponent.scrollToBottom();
                    });
                } else {
                    this.#logService.log('chat inactive');
                    this.chatActive = false;
                }

                this.sendingMessage = false;
            },
            error: (err) => {
                this.#logService.error(err);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                            message: 'Non è stato possibile inviare il messaggio.'
                        },
                    }
                );
                this.sendingMessage = false;
            },
        });
    }

    goBack() {
        this.#router.navigate([APP_ROUTES.MESSAGING()]);
    }

    ngOnDestroy() {
        this.#notificationSubscription?.unsubscribe();
    }
}
