import {Component, inject, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {SurveyService} from '../../services/survey.service';
import {first} from 'rxjs';
import {Survey} from '../../models/survey.models';
import {SurveyComponent} from '../../components/survey/survey.component';
import {Router} from '@angular/router';
import {APP_ROUTES} from '../../app.routes.definition';
import {RegistrationDashIconComponent} from '../../components/registration-dash-icon/registration-dash-icon.component';
import {LogService} from '../../services/log.service';
import {GoogleAnalyticsService} from "../../services/google-analytics.service";

@Component({
    selector: 'survey-page',
    standalone: true,
    imports: [SurveyComponent, RegistrationDashIconComponent],
    templateUrl: './survey-page.component.html',
    styleUrl: './survey-page.component.css',
})
export default class SurveyPageComponent implements OnInit {
    #apiUrl = `${environment.apiUrl}/surveys/initial`;

    #surveyService = inject(SurveyService);
    #loggerService = inject(LogService);
    #router = inject(Router);
    #googleAnalyticsService = inject(GoogleAnalyticsService);

    initialSurvey?: Survey;
    startSurvey = false;

    ngOnInit() {
        // if (localStorage.getItem('survey')) {
        //     this.#router.navigate([APP_ROUTES.REGISTER()]);
        // }
        this.#surveyService
            .getSurvey(this.#apiUrl)
            .pipe(first())
            .subscribe((res) => {
                this.initialSurvey = res;
            });
    }

    onSurveyNextPage(event: {currentPage: number, prevPage: number}) {
        switch (event.prevPage) {
            case 0:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step1_avanti'
                );
                break;
            case 1:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step2_età_genere', {eventParams: 'avanti'}
                );
                break;
            case 2:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step3_motivazione_ambito', {eventParams: 'avanti'}
                );
                break;
            case 3:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step4_preferenza_health_coach', {eventParams: 'avanti'}
                );
                break;
        }
    }

    onSurveyPrevPage(event: {currentPage: number, prevPage: number}) {
        switch (event.prevPage) {
            case 1:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step2_età_genere', {eventParams: 'indietro'}
                );
                break;
            case 2:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step3_motivazione_ambito', {eventParams: 'indietro'}
                );
                break;
            case 3:
                this.#googleAnalyticsService.sendAnalyticsEvent(
                    'funnel_iscrizione', 'step4_preferenza_health_coach', {eventParams: 'indietro'}
                );
                break;
        }
    }

    onSurveySubmit(survey: Survey) {
        this.#loggerService.log(survey);
        localStorage.setItem('survey', JSON.stringify(survey));
        // Save the time the survey was taken
        localStorage.setItem('surveyTime', new Date().toISOString());

        this.#googleAnalyticsService.sendAnalyticsEvent(
            'funnel_iscrizione', 'step5_completa_registrazione'
        );
        this.#router.navigate([APP_ROUTES.REGISTER()]);
    }

    goBack() {
        this.#router.navigate(['/']);
    }

    startSurveyEvent() {
        this.#googleAnalyticsService.sendAnalyticsEvent(
            'funnel_iscrizione', 'inizia_questionario'
        );
        this.startSurvey = true;
    }
}
