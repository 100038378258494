import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";
import {Subject} from "rxjs";
import {LogService} from "../log.service";

export interface RemoteMessage {
    senderId?: string | null;
    category: string | null;
    collapseKey: string | null;
    contentAvailable: boolean;
    data: { [key: string]: any };
    from: string | null;
    messageId: string | null;
    messageType: string | null;
    mutableContent: boolean;
    notification?: RemoteNotification | null,
    sentTime: string | null;
    threadId: string | null;
    ttl?: number | null;
}

export interface RemoteNotification {
    android?: { [key: string]: any } | null;
    apple?: { [key: string]: any } | null;
    web?: { [key: string]: any } | null;
    title?: string | null;
    titleLocArgs: string[],
    titleLocKey: string | null;
    body: string | null;
    bodyLocArgs: string[];
    bodyLocKey?: string | null;
}

/**
 * This service allows interaction with Firebase Messaging native APIs.
 *
 * Official API: https://pub.dev/packages/firebase_messaging
 */
@Injectable({
    providedIn: 'root'
})
export class FirebaseMessagingNativeService {
    #nativeAPIService = inject(NativeAPIService);
    #logService = inject(LogService);

    onMessage$ = new Subject<RemoteMessage>();
    onMessageOpenedApp$ = new Subject<RemoteMessage>();

    constructor() {
        if (this.isAvailable()) {
            window.addEventListener('message', ev => {
                if (ev.origin === document.location.origin && ev.data) {
                    this.#logService.log(ev);
                    switch (ev.data.type) {
                        case 'FirebaseMessaging.onMessage':
                            this.onMessage$.next(ev.data.message);
                            break;
                        case 'FirebaseMessaging.onMessageOpenedApp':
                            this.onMessageOpenedApp$.next(ev.data.message);
                            break;
                    }
                }
            });
        }
    }

    /**
     * Checks if the native Firebase Messaging Native Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }

    getInitialMessage() {
        return this.#nativeAPIService.callHandler<RemoteMessage | null>('FirebaseMessaging.getInitialMessage');
    }
}
