import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
    inject,
    Renderer2,
    Output,
    EventEmitter, OnDestroy,
} from '@angular/core';
import {AgoraService} from '../../services/agora.service';
import {VideoCallTracks} from '../../models/agora.models';
import AgoraRTC, {IAgoraRTCRemoteUser} from 'agora-rtc-sdk-ng';
import {TranslocoModule} from '@ngneat/transloco';
import {LogService} from '../../services/log.service';
import {IVirtualBackgroundProcessor} from "agora-extension-virtual-background";

const AUDIO = 'audio';
const VIDEO = 'video';

@Component({
    selector: 'video-call-player',
    standalone: true,
    imports: [TranslocoModule],
    templateUrl: './video-call-player.component.html',
    styleUrl: './video-call-player.component.css',
})
export class VideoCallPlayerComponent implements AfterViewInit, OnDestroy {
    @ViewChild('remotePlayerContainer') remotePlayerContainer: ElementRef =
        {} as ElementRef;
    @ViewChild('localPlayerContainer') localPlayerContainer: ElementRef =
        {} as ElementRef;

    @Input() meetingIdentifier: string = '';
    @Input() sfAccountIdentifier: string = '';
    @Input() access_token: string = '';

    @Output() leaveEvent = new EventEmitter<{
        id: string;
        time: string;
        elapsedCallTime: number;
    }>();

    #agoraService = inject(AgoraService);
    #domRenderer = inject(Renderer2);
    #logService = inject(LogService);
    videoCallParameters: VideoCallTracks = {
        remoteParticipants: {},
    };

    callStartTime: number = 0;
    callTimerId: ReturnType<typeof setTimeout> | null = null;
    elapsedCallTime: number = 0;
    callTimeDisplay: string = '00:00 min';
    cameras = this.#agoraService.cameras;
    frontCamera = true;
    virtualBackgroundProcessor?: IVirtualBackgroundProcessor;

    ngAfterViewInit() {
        this.startTimer();
        this.#agoraService.videoEngine.on(
            'user-published',
            (user, mediaType) => {
                this.#agoraService.videoEngine
                    ?.massSubscribe([
                        {
                            user,
                            mediaType: mediaType as typeof AUDIO | typeof VIDEO,
                        },
                    ])
                    .then(() => {
                        let remoteUser = this.getOrCreateRemoteUser(user);

                        const divContainer =
                            this.#domRenderer.createElement('div');
                        this.#domRenderer.setProperty(
                            divContainer,
                            'id',
                            user.uid.toString()
                        );

                        if (mediaType === VIDEO) {
                            remoteUser.remoteVideo = user.videoTrack;
                            remoteUser.remoteAudio = user.audioTrack;

                            const div = this.createVideoElement(user);
                            this.#domRenderer.appendChild(divContainer, div);
                            this.#domRenderer.appendChild(
                                this.remotePlayerContainer.nativeElement,
                                divContainer
                            );

                            remoteUser.remoteVideo?.play(div);
                        }

                        if (mediaType === AUDIO) {
                            remoteUser.remoteAudio = user.audioTrack;
                            remoteUser.remoteAudio?.play();
                        }
                    });
            }
        );

        // this.#agoraService.videoEngine?.on('user-unpublished', (user) => {
        //     this.#agoraService.videoEngine
        //         ?.massUnsubscribe([{ user }])
        //         .then(() => {
        //             const element =
        //                 this.remotePlayerContainer.nativeElement.querySelector(
        //                     `video-${user.uid.toString()}`
        //                 );
        //             if (element) {
        //                 this.#domRenderer.removeChild(
        //                     this.remotePlayerContainer.nativeElement,
        //                     element
        //                 );
        //             }
        //             delete this.videoCallParameters?.remoteParticipants[
        //                 user.uid.toString()
        //             ];
        //         });
        // });

        this.#agoraService
            .joinMeeting(
                this.videoCallParameters,
                this.meetingIdentifier,
                this.sfAccountIdentifier,
                this.localPlayerContainer.nativeElement,
                this.access_token
            )
            .subscribe({
                next: async (videoCallTracks) => {
                    this.videoCallParameters = videoCallTracks;
                    this.#logService.log('Joined the meeting successfully');

                    if (this.videoCallParameters.localVideo) {
                        this.virtualBackgroundProcessor = this.#agoraService.virtualBackgroundExtension?.createProcessor();
                        if (this.virtualBackgroundProcessor) {
                            await this.virtualBackgroundProcessor.init();
                            this.virtualBackgroundProcessor.setOptions({type: 'blur', blurDegree: 3});
                            this.videoCallParameters.localVideo
                                .pipe(this.virtualBackgroundProcessor)
                                .pipe(this.videoCallParameters.localVideo.processorDestination);
                        }
                    }
                },
                error: () => {
                    this.#logService.error('Failed to join the meeting');
                    this.onLeaveMeeting();
                },
            });
    }

    private getOrCreateRemoteUser(user: IAgoraRTCRemoteUser) {
        let remoteUser =
            this.videoCallParameters?.remoteParticipants[user.uid.toString()];
        if (!remoteUser) {
            remoteUser = {
                remoteVideo: user.videoTrack,
                remoteAudio: user.audioTrack,
            };
            this.videoCallParameters!.remoteParticipants[user.uid.toString()] =
                remoteUser;
        }
        return remoteUser;
    }

    private createVideoElement(user: IAgoraRTCRemoteUser) {
        const existingElement =
            this.remotePlayerContainer.nativeElement.querySelector(
                `#video-${user.uid.toString()}`
            );

        if (existingElement) {
            return existingElement;
        }

        const div = this.#domRenderer.createElement('div');
        this.#domRenderer.setProperty(
            div,
            'id',
            'video-' + user.uid.toString()
        );
        this.#domRenderer.setStyle(div, 'width', '100%');
        this.#domRenderer.setStyle(div, 'height', '100vh');

        return div;
    }

    onLeaveMeeting() {
        this.#agoraService.leaveMeeting(this.videoCallParameters!).subscribe({
            next: () => {
                this.#logService.log('Left the meeting successfully');
            },
            error: (error) => {
                this.#logService.error('Failed to leave the meeting', error);
            },
            complete: () => {
                this.leaveEvent.emit({
                    id: this.meetingIdentifier,
                    time: this.callTimeDisplay,
                    elapsedCallTime: this.elapsedCallTime,
                });
            },
        });
    }

    onSwitchCamera() {
        this.frontCamera = !this.frontCamera;
        const localVideo = this.videoCallParameters?.localVideo;
        if (localVideo) {
            this.#agoraService.setCamera(localVideo, this.frontCamera).then(() => {
                this.#logService.log(`Switched camera for local video successfully. Front Camera: ${this.frontCamera}`);
            }).catch(reason => {
                this.#logService.error(reason);
            });
        }
    }

    onToggleMicrophone() {
        const localAudio = this.videoCallParameters?.localAudio;
        if (localAudio) {
            this.#agoraService.enableMicrophone(localAudio, !localAudio.enabled).then(() => {
                this.#logService.log(`Updated microphone state for local audio successfully. Enabled: ${localAudio.enabled}`);
            }).catch(reason => {
                this.#logService.error(reason);
            });
        }
    }

    onToggleCamera() {
        const localVideo = this.videoCallParameters?.localVideo;
        if (localVideo) {
            this.#agoraService.enableCamera(localVideo, !localVideo.enabled).then(() => {
                this.#logService.log(`Updated camera state for local video successfully. Enabled: ${localVideo.enabled}`);
            }).catch(reason => {
                this.#logService.error(reason);
            });
        }
    }

    onToggleBlur() {
        if (this.virtualBackgroundProcessor) {
            if (this.virtualBackgroundProcessor.enabled) {
                this.virtualBackgroundProcessor.disable();
            } else {
                this.virtualBackgroundProcessor.enable();
            }
        }
    }

    private startTimer() {
        this.callStartTime = Date.now();
        this.callTimerId = setInterval(() => {
            this.elapsedCallTime = Math.floor(
                (Date.now() - this.callStartTime) / 1000
            );
            this.callTimeDisplay = this.formatTime(this.elapsedCallTime);
        }, 1000);
    }

    stopTimer() {
        if (this.callTimerId) {
            clearInterval(this.callTimerId);
            this.callTimerId = null;
        }
    }

    formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        if (hours > 0) {
            // Format as "HH:MM:SS min" if hours > 0
            return `${this.padZero(hours)}:${this.padZero(
                minutes
            )}:${this.padZero(secs)} min`;
        } else {
            // Format as "MM:SS min" if hours = 0
            return `${this.padZero(minutes)}:${this.padZero(secs)} min`;
        }
    }

    padZero(num: number): string {
        return num.toString().padStart(2, '0');
    }

    ngOnDestroy() {
        this.onLeaveMeeting();
        this.stopTimer();
        this.virtualBackgroundProcessor?.release();
    }
}
