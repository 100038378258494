import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { ModalService } from '../../../../../services/modal.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.state';
import { selectCreateOrderIsLoading } from '../../../../../store/create-order/create-order.selectors';
import { createOrder } from '../../../../../store/create-order/create-order.actions';
import { AsyncPipe, NgIf } from '@angular/common';
import { TierCardComponent } from '../../../../../components/tier-card/tier-card.component';
import { GenericModalComponent } from '../../../../../components/generic-modal/generic-modal.component';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MembershipFormModalContentComponent } from './membership-form-modal-content/membership-form-modal-content.component';
import {selectUserBillingInfoIsValid} from "../../../../../store/user/user.selectors";
import {APP_ROUTES} from "../../../../../app.routes.definition";
import {RouterLink} from "@angular/router";
import {environment} from "../../../../../../environments/environment";

export interface TermsAndConditionsForm {
    termsAndConditions: FormControl<boolean>;
}

@Component({
    selector: 'app-buy-product-modal-content',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        TierCardComponent,
        GenericModalComponent,
        MembershipFormModalContentComponent,
        ReactiveFormsModule,
        RouterLink
    ],
    templateUrl: './buy-product-modal-content.component.html',
    styleUrl: './buy-product-modal-content.component.css',
})
export default class BuyProductModalContentComponent {
    #store = inject(Store<AppState>);
    #modalService = inject(ModalService);
    #fb = inject(FormBuilder);

    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);
    isUserBillingInfoValid$ = this.#store.select(selectUserBillingInfoIsValid);

    product!: Product;
    termsAndConditionsForm!: FormGroup<TermsAndConditionsForm>;
    iubenda = environment.iubenda;

    billingInfoPath = APP_ROUTES.SETTINGS_BILLING_INFO();

    constructor() {
        this.termsAndConditionsForm = this.#fb.nonNullable.group({
            termsAndConditions: this.#fb.nonNullable.control(false, [
                Validators.requiredTrue,
            ]),
        });
    }

    createOrder() {
        if (this.termsAndConditionsForm.invalid) return;

        this.#store.dispatch(
            createOrder({
                request: {
                    productSKU: this.product.productCode,
                },
            })
        );
    }

    onClose() {
        this.#modalService.close();
    }
}
