import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    inject,
    OnInit,
    Output,
    EventEmitter,
    AfterViewInit, OnChanges, SimpleChanges,
} from '@angular/core';
import {AgoraChat} from 'agora-chat';
import {ChatMessage} from '../../models/chat.models';
import {AppState} from '../../store/app.state';
import {Store} from '@ngrx/store';
import {AsyncPipe, DatePipe, NgStyle} from '@angular/common';
import {selectUserData} from '../../store/user/user.selectors';
import {LogService} from '../../services/log.service';
import {Renderer2, RendererFactory2} from '@angular/core';
import dayjs from 'dayjs';
import {ScrollDirective} from "../../shared/directives/scroll.directive";
import {debounceTime, Subject, throttle, throttleTime} from "rxjs";

@Component({
    selector: 'message-list',
    standalone: true,
    imports: [AsyncPipe, NgStyle, DatePipe, ScrollDirective],
    templateUrl: './message-list.component.html',
    styleUrls: ['./message-list.component.css'],
})
export class MessageListComponent implements OnInit, AfterViewInit {
    @ViewChild('chatContainer') private chatContainer!: ElementRef;
    @ViewChild(ScrollDirective) scrollDirective!: ScrollDirective;

    @Input() messages?: AgoraChat.TextMsgBody[] | null = [];
    @Input() freeMessages?: ChatMessage[] | null = [];
    @Input() myId?: string | null = '';
    @Input() disabled = false;
    @Input() loadingOldMessages = false;
    @Output() onScrollToTop = new EventEmitter<void>();

    private renderer: Renderer2;
    #store = inject(Store<AppState>);
    #loggerService = inject(LogService);

    user$ = this.#store.select(selectUserData);
    scrolledTop$: Subject<void> = new Subject<void>();

    myIdLowerCase = '';

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);

        this.scrolledTop$.pipe(throttleTime(1000)).subscribe(() => {
            this.#loggerService.log(
                '[MessageListComponent] Scrolled to top'
            );
            this.onScrollToTop.emit();
        });
    }

    ngOnInit() {
        this.myIdLowerCase = this.myId?.toLowerCase() || '';
    }

    ngAfterViewInit() {
        this.renderer.listen(
            this.chatContainer?.nativeElement,
            'scroll',
            (event: Event) => {
                if ((event.target as Element).scrollTop <= 0) {
                    this.scrolledTop$.next();
                }
            }
        );
    }

    scrollToBottom(onlyIfAlreadyBottom: boolean = false): void {
        if (this.chatContainer) {
            const el = this.chatContainer.nativeElement;
            if (onlyIfAlreadyBottom && el.scrollHeight - el.scrollTop !== el.clientHeight) {
                return;
            }
            el.scrollTop = el.scrollHeight;
        }
    }

    getDateLabel(messageDate: string | number): string {
        const dayDate = dayjs(messageDate);
        if (!dayDate.isValid()) return '';
        if (dayDate.isToday()) {
            return 'Oggi';
        } else if (dayDate.isYesterday()) {
            return 'Ieri';
        }
        return dayDate.format('DD/MM/YY');
    }
}
