import { Order } from './order.model';
import { ProductFeature } from './product.model';

export interface User {
    id: number;
    email: string;
    sfAccountId: string;
    sfContactId: string;
    loginProvider: string | null;
    profileId: string | null;
    authorities: string[];
    firstName: string;
    lastName: string;
    birthDate: string;
    fiscalCode: string;
    freeTrialEndDate?: string | null;
    isTwoFactorAuthenticationEnabled: boolean;
    mobilePhone: string;
    openOrders: Order[];
    activatedOrders: Order[];
    enabledFeatures: ProductFeature[];
    hasLoggedMobile: boolean;
    avatar?: string | null;
    goals: UserGoal[];
    billingProvince?: string | null;
    billingStreet?: string | null;
    billingPostalCode?: string | null;
    billingCountry?: SupportedBillingCountry | null;
    billingCity?: string | null;
    marketingConsent: boolean;
}

export interface UserGoal {
    id: string;
    accountId: string;
    coachingSessionId?: string | null;
    goal?: string | null;
    goalSmart?: string | null;
    otherGoal?: string | null;
}

export interface UserUpdateInfoData {
    firstName: string;
    lastName: string;
    birthDate: string;
    mobilePhone: string;
    marketingConsent: boolean;
}

export enum SupportedBillingCountry {
    Italia = 'Italia',
    Altro = 'Altro'
}

export interface UserUpdateBillingInfoData {
    billingProvince?: string | null;
    billingStreet: string;
    billingPostalCode: string;
    billingCountry: SupportedBillingCountry;
    billingCity: string;
    fiscalCode: string;
}
