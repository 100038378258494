<div class="flex flex-col">
    <div class="mt-5">
        <h1 class="font-bold text-xl">
            Abilita Health Connect
        </h1>
    </div>
    <div class="px-4 pt-4 text-sm">
        <ol class="list-decimal list-outside">
            <li>Assicurati di avere l'app Connessione Salute (Health Connect) sul tuo dispositivo. Puoi scaricarla dal
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.healthdata"
                   target="_blank" class="text-primary underline font-bold text-center">
                    Google Play Store</a>.
            </li>
            <li>Apri l'app Connessione Salute.</li>
            <li>Segui le istruzioni sullo schermo per configurare l'app.</li>
            <li>
                Gestisci le app connesse:
                <ul class="list-disc list-inside">
                    <li>Nell'app Connessione Salute, tocca App consentite.</li>
                    <li>Scegli l'app di fitness che vuoi collegare, ad esempio Fitbit o Google Fit.</li>
                </ul>
            </li>
            <li>
                Concedi le autorizzazioni:
                <ul class="list-disc list-inside">
                    <li>Quando selezioni l'app da collegare, vedrai un elenco delle autorizzazioni richieste.</li>
                    <li>Attiva le autorizzazioni necessarie per sincronizzare i dati tra le due app, come attività
                        fisica, sonno, calorie, ecc.
                    </li>
                </ul>
            </li>
            <li>
                Controlla le autorizzazioni:
                <ul class="list-disc list-inside">
                    <li>Puoi rivedere e modificare in qualsiasi momento le autorizzazioni accedendo alla sezione App
                        consentite all'interno di Connessione Salute.
                    </li>
                </ul>
            </li>

            <li>
                Sincronizza i dati:
                <ul class="list-disc list-inside">
                    <li>Una volta configurate le autorizzazioni, i dati tra Connessione Salute e l'app collegata
                        inizieranno a sincronizzarsi automaticamente.
                    </li>
                </ul>
            </li>
        </ol>
        <button
            (click)="onClose()"
            class="mt-5 py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-primary text-sm font-bold capitalize">Ok, ho capito</span>
            </div>
        </button>
    </div>
</div>
