import {Component, inject} from '@angular/core';
import {first} from 'rxjs';
import {Survey} from '../../models/survey.models';
import {SurveyComponent} from '../../components/survey/survey.component';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {APP_ROUTES} from '../../app.routes.definition';
import {RegistrationDashIconComponent} from '../../components/registration-dash-icon/registration-dash-icon.component';
import {LogService} from '../../services/log.service';
import {SurveyInvitationService} from "../../services/survey-invitation.service";
import LoaderComponent from "../../components/loader/loader.component";
import {NgIf} from "@angular/common";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {ModalService} from "../../services/modal.service";
import {InfoModalComponent, InfoModalData} from "../../components/info-modal/info-modal.component";

@Component({
    selector: 'survey-invitation-page',
    standalone: true,
    imports: [SurveyComponent, RegistrationDashIconComponent, LoaderComponent, NgIf, RouterLink],
    templateUrl: './survey-invitation-page.component.html',
    styleUrl: './survey-invitation-page.component.css',
})
export default class SurveyInvitationPageComponent {
    #surveyInvitationService = inject(SurveyInvitationService);
    #logService = inject(LogService);
    #router = inject(Router);
    #activatedRoute = inject(ActivatedRoute);
    #modalService = inject(ModalService);

    id: string;
    survey?: Survey;
    loading = true;
    postSurveyLoading = false;

    homePath = APP_ROUTES.HOME();

    constructor() {
        this.id = this.#activatedRoute.snapshot.params['id'] ?? '';
        if (!this.id || this.id.trim().length === 0) {
            this.goBack();
        } else {
            this.#surveyInvitationService
                .getSurvey(this.id)
                .pipe(first())
                .subscribe({
                    next: (res) => {
                        this.survey = res;
                        this.loading = false;
                    },
                    error: err => {
                        this.#logService.error(err);
                        this.loading = false;
                    },
                });
        }
    }

    onSurveySubmit(survey: Survey) {
        this.#logService.log(survey);

        this.postSurveyLoading = true;
        this.#surveyInvitationService.postSurvey({
            survey: survey,
            surveyInvitationId: this.id
        }).pipe(first()).subscribe({
            next: value => {
                this.#modalService.open<
                    InfoModalData,
                    InfoModalComponent
                >(InfoModalComponent, {
                    data: {
                        title: 'Questionario inviato con successo!',
                        onCloseCallback: () => {
                            this.#router.navigate(['/']);
                        }
                    },
                });
                this.postSurveyLoading = false;
            },
            error: err => {
                this.#logService.error(err);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                            message: 'Il questionario potrebbe non essere più disponibile, scaduto o già compilato.'
                        },
                    }
                );
                this.postSurveyLoading = false;
            },
        })
    }

    goBack() {
        this.#router.navigate(['/']);
    }
}
