import { AbstractControl, ValidatorFn } from '@angular/forms';
import CodiceFiscale from 'codice-fiscale-js';
import {User} from "../../models/users.models";
import dayjs from "dayjs";

export function fiscalCodeValidator(user?: User | null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }

        let fiscalCode: CodiceFiscale;
        try {
            fiscalCode = new CodiceFiscale(control.value);
        } catch (error) {
            return { invalidFiscalCode: error };
        }

        const fiscalCodeObj = fiscalCode.reverse();
        if (user) {
            const birthDate = dayjs(user.birthDate);
            const partialFiscalCode = new CodiceFiscale({
                name: user.firstName,
                surname: user.lastName,
                day: birthDate.date(),
                month: birthDate.month() + 1,
                year: birthDate.year(),
                gender: 'M', // it won't be used for the partial check
                birthplace: 'ROMA', // it won't be used for the partial check
                birthplaceProvincia: 'RM', // it won't be used for the partial check
            });
            const partialFiscalCodeObj = partialFiscalCode.reverse();
            // partial check
            if (fiscalCodeObj.surname !== partialFiscalCodeObj.surname) {
                return { invalidFiscalCode: 'Surname does not match' };
            } else if (fiscalCodeObj.name !== partialFiscalCodeObj.name) {
                return { invalidFiscalCode: 'Firstname does not match' };
            } else if (fiscalCodeObj.day !== partialFiscalCodeObj.day ||
                fiscalCodeObj.month !== partialFiscalCodeObj.month ||
                fiscalCodeObj.year !== partialFiscalCodeObj.year) {
                return { invalidFiscalCode: 'Birth date does not match' };
            }
        }

        return null;
    };
}
