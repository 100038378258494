import {
    Component,
    inject,
    OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationService } from '../../../../services/navigation.service';
import { HealthStatisticStepsCardComponent } from '../../../../components/health-statistic-steps-card/health-statistic-steps-card.component';
import { HealthStatisticActiveEnergyBurnedCardComponent } from '../../../../components/health-statistic-active-energy-burned-card/health-statistic-active-energy-burned-card.component';
import {NavbarCategoriesComponent} from "../../components/navbar-categories/navbar-categories.component";
import { HealthStatisticExerciseTimeCardComponent } from '../../../../components/health-statistic-exercise-time-card/health-statistic-exercise-time-card.component';
import {
    HealthStatisticActivityAggregationCardComponent
} from "../../../../components/health-statistic-activity-aggregation-card/health-statistic-activity-aggregation-card.component";

@Component({
    selector: 'app-activity-category-page',
    standalone: true,
    imports: [
        HealthStatisticStepsCardComponent,
        HealthStatisticExerciseTimeCardComponent,
        HealthStatisticActiveEnergyBurnedCardComponent,
        RouterModule,
        TranslocoModule,
        NavbarCategoriesComponent,
        HealthStatisticActivityAggregationCardComponent,
    ],
    templateUrl: './activity-category-page.component.html',
    styleUrl: './activity-category-page.component.css',
})
export default class ActivityCategoryPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
