import { Route } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import {APP_ROUTES} from "../../app.routes.definition";
import BuyBasePlanComponent from "./buy-base-plan.component";
import PlanDescriptionPageComponent from "./pages/plan-description-page/plan-description-page.component";
import SelectPlanPageComponent from "./pages/select-plan-page/select-plan-page.component";

export const BUY_BASE_PLAN_ROUTES: Route[] = [
    {
        path: '',
        component: BuyBasePlanComponent,
    },
    {
        path: APP_ROUTES.BUY_BASE_PLAN_DESCRIPTION(true),
        data: { title: 'Plan Description' },
        component: PlanDescriptionPageComponent,
    },
    {
        path: APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN(true),
        data: { title: 'Select Plan' },
        component: SelectPlanPageComponent,
    },
];
