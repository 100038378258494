<a [routerLink]="healthTypePath" class="cursor-pointer">
    <section
        class="border-2 border-white bg-white rounded-lg mt-4 card-shadow-bottom py-2.5 px-3"
    >
        <div class="bg-light-linear py-3 px-4 rounded-2xl">
            <h3 class="font-bold">{{ title }}</h3>
            <p class="text-xs text-595959">
                {{
                    "dumb-components.health-statistic-card.period." +
                        period +
                        (isSingleValue &&
                        period === HealthDataPeriod.last24Hours
                            ? "SingleValue"
                            : "") | transloco
                }}
            </p>
        </div>
        <div class="flex items-center px-4 mt-1.5">
            <div class="basis-8/12 flex items-end text-primary">
                @if (value) {
                <div>
                    <span class="font-bold text-3xl">
                        {{ value }}
                    </span>
                </div>
                <div class="ms-1">
                    <span class="text-xl">{{ unitSymbolLabel }}</span>
                </div>
                } @else {
                <div>
                    <span class="text-sm">Dato non rilevato</span>
                </div>
                }
            </div>
            <div class="basis-4/12 flex items-center justify-end">
                <img class="w-16 h-auto" [src]="iconPath" alt="" />
            </div>
        </div>
    </section>
</a>
