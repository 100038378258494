import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { selectUserData } from '../../store/user/user.selectors';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SurveyComponent } from '../../components/survey/survey.component';
import { Survey } from '../../models/survey.models';
import { Router } from '@angular/router';
import { SurveyService } from '../../services/survey.service';
import { ModalService } from '../../services/modal.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../components/error-modal/error-modal.component';
import LoaderComponent from '../../components/loader/loader.component';

@Component({
    selector: 'feeling-page',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslocoModule,
        SurveyComponent,
        LoaderComponent,
        NgIf,
    ],
    templateUrl: './feeling-page.component.html',
})
export default class FeelingPageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #router = inject(Router);
    #surveyService = inject(SurveyService);
    #modalService = inject(ModalService);

    user$ = this.#store.select(selectUserData);
    feelingSurvey?: Survey;
    isLoading = true;
    postSurveyLoading = false;

    ngOnInit() {
        this.isLoading = true;
        this.#surveyService.getFeelingSurvey().subscribe({
            next: (survey) => {
                this.feelingSurvey = survey;
                this.isLoading = false;
            },
            error: (err) => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                        },
                    }
                );
                this.isLoading = false;
            },
        });
    }

    onSurveySubmit(survey: Survey) {
        this.postSurveyLoading = true;   
        this.#surveyService.postSurvey(survey).subscribe({
            next: () => {
                this.postSurveyLoading = false;
                this.#router.navigate(['/']);
            },
            error: (err) => {
                this.postSurveyLoading = false;
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                        },
                    }
                );
            },
        });
    }
}
