import {createAction, props} from '@ngrx/store';
import {User, UserUpdateBillingInfoData, UserUpdateInfoData} from '../../models/users.models';
import {HttpErrorResponse} from "@angular/common/http";
import {AgoraConversation} from "../../models/agora.models";
import {TryTerraUser} from "../../models/try-terra.models";

export const login = createAction('[User] Login', props<{ email: string; password: string }>());

export const setLoading = createAction('[User] Set Loading', props<{ isLoading: boolean }>());

export const loginProvider = createAction('[User] Login Provider', props<{ accessToken: string }>());
export const loginOtp = createAction('[User] Login OTP', props<{ otp: string }>());
export const loginRecoveryCode = createAction('[User] Login Recovery Code', props<{ recoveryCode: string }>());
export const loginSuccess = createAction('[User] Login Success', props<{ data: User }>());
export const loginFailure = createAction('[User] Login Failure', props<{ error: HttpErrorResponse }>());
export const loginOtpFailure = createAction('[User] Login OTP Failure', props<{ error: HttpErrorResponse }>());

export const loginAgoraChat = createAction('[User] Login Agora Chat', props<{ userId: string; }>());
export const loginAgoraChatSuccess = createAction('[User] Login Agora Chat Success');
export const loginAgoraChatFailure = createAction('[User] Login Agora Chat Failure', props<{ error: Error }>());
export const logoutAgoraChat = createAction('[User] Logout Agora Chat');

export const logout = createAction('[User] Logout');

export const fetchUser = createAction('[User] Fetch User');

export const fetchUserSuccess = createAction(
    '[User] Fetch User Success',
    props<{ data: User }>()
);

export const fetchUserFailure = createAction(
    '[User] Fetch User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateUser = createAction(
    '[User] Update User',
    props<{ data: UserUpdateInfoData }>()
);

export const updateUserSuccess = createAction(
    '[User] Update User Success',
    props<{ data: UserUpdateInfoData }>()
);

export const updateUserFailure = createAction(
    '[User] Update User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateUserBillingInfo = createAction(
    '[User] Update User Billing Info',
    props<{ data: UserUpdateBillingInfoData }>()
);

export const updateUserBillingInfoSuccess = createAction(
    '[User] Update User Billing Info Success',
    props<{ data: UserUpdateBillingInfoData }>()
);

export const updateUserBillingInfoFailure = createAction(
    '[User] Update User Billing Info Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateUserAvatar = createAction(
    '[User] Update User Avatar',
    props<{ avatar: Blob }>()
);

export const updateUserAvatarSuccess = createAction(
    '[User] Update User Avatar Success'
);

export const updateUserAvatarFailure = createAction(
    '[User] Update User Avatar Failure',
    props<{ error: HttpErrorResponse }>()
);

export const deleteUser = createAction(
    '[User] Delete User',
    props<{ password: string | null }>()
);

export const deleteUserSuccess = createAction(
    '[User] Delete User Success'
);

export const deleteUserFailure = createAction(
    '[User] Delete User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const cancelUserSubscription = createAction(
    '[User] Cancel User Subscription',
    props<{ id: string }>()
);

export const cancelUserSubscriptionSuccess = createAction(
    '[User] Cancel User Subscription Success'
);

export const cancelUserSubscriptionFailure = createAction(
    '[User] Cancel User Subscription Failure',
    props<{ error: HttpErrorResponse }>()
);

export const initializeTryTerraUserInfo = createAction('[User] Initialize TryTerra UserInfo');
export const initializeTryTerraUserInfoSuccess = createAction('[User] Initialize TryTerra UserInfo Success', props<{
    userInfo: TryTerraUser[]
}>());
export const initializeTryTerraUserInfoFailure = createAction('[User] Initialize TryTerra UserInfo Failure', props<{ error: Error }>());

export const initializeTryTerra = createAction('[User] Initialize TryTerra', props<{
    triggerAllDataWebhook: boolean, daysBeforeNow?: number | null
}>());
export const initializeTryTerraSuccess = createAction('[User] Initialize TryTerra Success');
export const initializeTryTerraFailure = createAction('[User] Initialize TryTerra Failure', props<{ error: Error }>());

export const triggerTryTerraWebhook = createAction('[User] Trigger TryTerra Webhook', props<{
    daysBeforeNow: number
}>());
export const triggerTryTerraWebhookSuccess = createAction('[User] Trigger TryTerra Webhook Success');
export const triggerTryTerraWebhookFailure = createAction('[User] Trigger TryTerra Webhook Failure', props<{ error: Error }>());

export const updateAgoraConversationList = createAction('[User] Update Agora Conversation List');
export const updateAgoraConversationListSuccess = createAction('[User]  Update Agora Conversation List Data Success', props<{
    conversationList: AgoraConversation[]
}>());
export const updateAgoraConversationListFailure = createAction('[User]  Update Agora Conversation List Data Failure', props<{
    error: Error
}>());
