import {
    AsyncPipe,
    CommonModule,
    DatePipe,
    NgClass,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import {
    selectUserData,
    selectUserFreeTrialEndDate,
    selectUserHasBasePlan,
    selectUserHasLoginProvider,
    selectUserIsLoading,
} from '../../store/user/user.selectors';
import { environment } from '../../../environments/environment';
import { TranslocoModule } from '@ngneat/transloco';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import {
    deleteUser,
    fetchUser,
    updateUserAvatar,
} from '../../store/user/user.actions';
import { APP_ROUTES } from '../../app.routes.definition';
import packageJson from '../../../../package.json';
import { PackageInfoNativeService } from '../../services/native/package-info-native.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {IubendaService} from "../../services/iubenda.service";
import {EnvironmentName} from "../../models/app-environment.model";

@Component({
    selector: 'settings-page',
    standalone: true,
    imports: [AsyncPipe, RouterModule, TranslocoModule, NgIf, DatePipe],
    templateUrl: './settings-page.component.html',
    styleUrl: './settings-page.component.css',
})
export default class SettingsPageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #authService = inject(AuthService);
    #navigationService = inject(NavigationService);
    #packageInfoNativeService = inject(PackageInfoNativeService);
    #iubendaService = inject(IubendaService);

    user$ = this.#store.select(selectUserData);
    hasBasePlan$ = this.#store.select(selectUserHasBasePlan);
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    freeTrialEndDate$ = this.#store.select(selectUserFreeTrialEndDate);

    profilePath = APP_ROUTES.SETTINGS_PROFILE();
    billingInfo = APP_ROUTES.SETTINGS_BILLING_INFO();
    securityPath = APP_ROUTES.SETTINGS_SECURITY();
    subscriptionsPath = APP_ROUTES.SETTINGS_SUBSCRIPTIONS();
    deletePath = APP_ROUTES.SETTINGS_DELETE();
    consentPath = APP_ROUTES.SETTINGS_CONSENT();
    supportPath = APP_ROUTES.SETTINGS_SUPPORT();
    healthProvidersPath = APP_ROUTES.HEALTH_PROVIDERS();
    webAppVersion = packageJson.version;
    environmentName = environment.name !== EnvironmentName.prod ? environment.name.toUpperCase() : '';
    appNativeVersion?: string | null = null;
    freeTrialEndDate?: string | null = null;

    iubenda = environment.iubenda;

    constructor() {
        // request to update user info
        this.#store.dispatch(fetchUser());
        if (this.#packageInfoNativeService.isAvailable()) {
            this.#packageInfoNativeService
                .fromPlatform()
                .then((packageInfo) => {
                    this.appNativeVersion =
                        packageInfo.version + '+' + packageInfo.buildNumber;
                });
        }

        this.freeTrialEndDate$.pipe(takeUntilDestroyed()).subscribe({
            next: (freeTrialEndDate) => {
                this.freeTrialEndDate = freeTrialEndDate;
            },
        });
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute(true);
    }

    logout() {
        this.#authService.logout();
    }

    uploadAvatar(event: Event) {
        const element = event.target as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList && fileList?.length > 0) {
            this.#store.dispatch(updateUserAvatar({ avatar: fileList[0] }));
            element.value = '';
        }
    }

    iubendaIsAvailable() {
        return this.#iubendaService.isAvailable();
    }

    openIubendaPref($event: MouseEvent) {
        $event.preventDefault();
        this.#iubendaService.openPreferences();
    }
}
