import { Component, ViewContainerRef, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { CommonModule } from '@angular/common';
import {
    selectUserData,
    selectUserHasBasePlan,
} from '../../store/user/user.selectors';
import { catchError, first, of, switchMap, throwError } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { AgoraService } from '../../services/agora.service';
import { VideoCallPlayerComponent } from '../../components/video-call-player/video-call-player.component';
import { AppointmentCardComponent } from '../../components/appointment-card/appointment-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CoachingSessionService } from '../../services/coaching-session.service';
import { CoachingSession } from '../../models/coaching-session.models';
import { NavigationService } from '../../services/navigation.service';
import { RatingModalComponent } from '../../components/rating-modal/rating-modal.component';
import { ModalService } from '../../services/modal.service';
import { Router, RouterLink } from '@angular/router';
import { APP_ROUTES } from '../../app.routes.definition';
import LoaderComponent from '../../components/loader/loader.component';
import { LogService } from '../../services/log.service';
import dayjs from 'dayjs';

@Component({
    selector: 'appointments-page',
    standalone: true,
    imports: [
        AsyncPipe,
        VideoCallPlayerComponent,
        AppointmentCardComponent,
        TranslocoModule,
        CommonModule,
        RatingModalComponent,
        RouterLink,
        LoaderComponent,
        NgIf,
    ],
    templateUrl: './appointments-page.component.html',
})
export default class AppointmentsPageComponent {
    #store = inject(Store<AppState>);
    #coachingSessionService = inject(CoachingSessionService);
    #agoraService = inject(AgoraService);
    #navigationService = inject(NavigationService);
    #viewRef = inject(ViewContainerRef);
    #modalService = inject(ModalService);
    #loggerService = inject(LogService);

    #router = inject(Router);

    appointments: CoachingSession[] = [];
    currentUser$ = this.#store.select(selectUserData);
    userHasBasePlan$ = this.#store.select(selectUserHasBasePlan);

    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();
    messagingPath = APP_ROUTES.MESSAGING();

    selectedVideoId?: string;
    videoAccessKey?: string;
    sfAccountId?: string;
    loading = true;

    constructor() {}

    ngOnInit() {
        this.#navigationService.saveCurrentRoute(true);
        const subscription = this.currentUser$
            .pipe(
                switchMap((user) => {
                    if (user) {
                        this.sfAccountId = user.sfAccountId;
                        return this.#coachingSessionService.getAllCoachingSessions();
                    }
                    return of(null);
                }),
                catchError((error) => {
                    this.#loggerService.error(
                        'Error getting appointments:',
                        error
                    );
                    return throwError(() => error);
                })
            )
            .subscribe({
                next: (response) => {
                    this.appointments = response ?? [];
                    this.loading = false;
                    // subscription.unsubscribe();
                },
                error: (error) => {
                    this.#loggerService.error('Error in subscription:', error);
                    this.loading = false;
                },
            });
    }

    /**
     * Joins a video meeting with the specified meeting ID.
     * @param meetingId The ID of the meeting to join.
     */
    joinVideoMeeting(meetingId: string) {
        this.#agoraService
            .fetchVideoAccessKey(meetingId)
            .pipe(
                first(),
                catchError((error) => {
                    this.#loggerService.error(
                        'Error fetching video access key:',
                        error
                    );
                    return throwError(() => error);
                })
            )
            .subscribe({
                next: (response) => {
                    this.selectedVideoId = meetingId;
                    this.videoAccessKey = response.token;
                },
                error: (error) => {
                    this.#loggerService.error('Error in subscription:', error);
                },
                complete: () => {
                    this.loadVideoCallPlayerComponent();
                },
            });
    }

    /**
     * Loads the VideoCallPlayerComponent and sets the necessary properties.
     */
    private async loadVideoCallPlayerComponent() {
        await this.#agoraService.createClientVideoEngine();

        this.#viewRef.clear();

        const compRef = this.#viewRef.createComponent(VideoCallPlayerComponent);
        if (compRef && this.selectedVideoId && this.videoAccessKey && this.sfAccountId) {
            (<VideoCallPlayerComponent>compRef.instance).meetingIdentifier =
                this.selectedVideoId;
            (<VideoCallPlayerComponent>compRef.instance).access_token =
                this.videoAccessKey;
            (<VideoCallPlayerComponent>compRef.instance).sfAccountIdentifier =
                this.sfAccountId;
            (<VideoCallPlayerComponent>compRef.instance).leaveEvent.subscribe(
                (emittedObject) => {
                    this.#viewRef.clear();

                    const navigation = this.#router.getCurrentNavigation();
                    const state = navigation?.extras.state as {
                        data: string;
                    };

                    if (emittedObject.elapsedCallTime > 30) {
                        this.#router.navigate([APP_ROUTES.RATING()], {
                            state: { data: emittedObject.id },
                        });
                    }
                }
            );
        }
    }

    /**
     * Opens the rating modal for the specified appointment.
     * @param appointment The appointment to rate.
     */
    // openRatingModal(emittedObject: { id: string; time: string }) {
    //     const compRef = this.#viewRef.createComponent(RatingModalComponent);
    //     if (compRef) {
    //         (<any>compRef.instance).time = emittedObject.time;
    //         (<any>compRef.instance).id = emittedObject.id;
    //         (<any>compRef.instance).leaveFeedback.subscribe(() => {
    //             this.#viewRef.clear();
    //         });
    //     }
    // }
}
