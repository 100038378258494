<section class="mt-24">
    <div
        #chatContainer
        appScrollDirective
        class="pt-5 px-5 overflow-y-auto pb-20 bg-section-messages-cyan"
        [ngStyle]="{
            'max-height': !disabled
                ? 'calc(100vh - 52px - 122px)'
                : 'calc(100vh - 52px - 142px)',

            'min-height': !disabled ? 'calc(100vh - 52px - 122px)' : 'calc(100vh - 52px - 142px)',
        }"
    >
        @if (loadingOldMessages) {
            <div class="flex justify-center items-center pb-5">
                <span class="loader"></span>
            </div>
        }
        <!-- Free -->
        @for (message of freeMessages; track $index) {
            @if (message.senderId !== myId) {
                <div
                    class="coach-chat w-fit max-w-60 p-3 bg-white rounded-r-2xl rounded-t-2xl me-auto mt-3"
                >
                    <p class="text-black break-words">{{ message.text }}</p>
                    <p class="text-595959 text-right text-xs mt-1">
                        <span class="font-semibold">{{ getDateLabel(message.createdDate) }}</span>
                        {{ message.createdDate | date : "HH:mm" }}
                    </p>
                </div>
            } @else {
                <div
                    class="user-chat w-fit max-w-60 p-3 bg-primary rounded-l-2xl rounded-t-2xl ms-auto mt-3"
                >
                    <p class="text-white break-words">{{ message.text }}</p>
                    <p class="text-white text-right text-xs mt-1">
                        <span class="font-semibold ">{{ getDateLabel(message.createdDate) }}</span>
                        {{ message.createdDate | date : "HH:mm" }}
                    </p>
                </div>
            }
        }
        <!-- Premium -->
        @for (message of messages; track $index) {
            @if (message.from === myId || message.from === myIdLowerCase || !message.from) {
                <div
                    class="user-chat w-fit max-w-60 p-3 bg-primary rounded-l-2xl rounded-t-2xl ms-auto mt-3"
                >
                    <p class="text-white break-words">{{ message.msg }}</p>
                    <p class="text-white text-right text-xs mt-1">
                        <span class="font-semibold">{{ getDateLabel(message.time) }}</span>
                        {{ message.time | date : "HH:mm" }}
                    </p>

                </div>
            } @else {

                <div
                    class="coach-chat w-fit max-w-60 p-3 bg-white rounded-r-2xl rounded-t-2xl me-auto mt-3"
                >
                    <p class="text-black break-words">{{ message.msg }}</p>
                    <p class="text-595959 text-right text-xs mt-1">
                        <span class="font-semibold text-xs">{{ getDateLabel(message.time) }}</span>
                        {{ message.time | date : "HH:mm" }}
                    </p>
                </div>
            }
        }
        @if (disabled) {
            <div class="flex items-center justify-center my-5">
                <div>
                    <span class="font-semibold">La chat è terminata</span>
                </div>
            </div>
        }
    </div>
</section>
