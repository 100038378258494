import { Component, inject } from '@angular/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { HealthChartComponent } from '../../../../../../components/health-chart/health-chart.component';
import {
    HealthChartPeriod,
    HealthDataPeriod, HealthDataType,
    HealthDataUnit,
    HealthDataUnitToString,
    HealthDataValue,
} from '../../../../../../models/health.models';
import { HealthDataService } from '../../../../../../services/health-data.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { Store } from '@ngrx/store';
import { ProductFeature } from '../../../../../../models/product.model';
import { AppState } from '../../../../../../store/app.state';
import { selectUserHasFeature } from '../../../../../../store/user/user.selectors';
import { AsyncPipe, NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-resting-heart-rate-page',
    standalone: true,
    imports: [HealthDataPageComponent, AsyncPipe, NgClass],
    templateUrl: './resting-heart-rate-page.component.html',
    styleUrl: './resting-heart-rate-page.component.css',
})
export default class RestingHeartRatePageComponent {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Battito cardiaco a riposo';
    chartLabel = 'Battiti cardiaci a riposo';
    healthDataUnitSymbolLabel = 'bpm';
    restingHeartRateHealthDataType = HealthDataType.RESTING_HEART_RATE;
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['body', 'activity', 'sleep'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
