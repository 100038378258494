<app-loader *ngIf="loading"></app-loader>

<div class="flex flex-col h-full relative">
    <div
        class="flex flex-col items-center justify-center mb-3 custom-bg-illustration-blue"
    >
        <img
            class="h-20 w-auto"
            src="../../../../../../../../../../assets/img/illustrations/illustration-2fa.png"
            alt=""
        />
    </div>
    <div class="custom-bg-white py-7 px-6 relative pb-28 h-full">
        <div class="flex flex-col h-full justify-between">
            <div>
                <h2 class="font-semibold text-lg">
                    Inserisci la Secret Key e l’Email del tuo Account nell’Applicazione
                    di Autenticazione di tua scelta
                </h2>
                <p class="mt-5">
                    Se non hai un'app di autenticazione,
                    <a
                        class="text-primary"
                        [href]="
                        targetPlatform === TargetPlatform.iOS
                            ? 'https://apps.apple.com/it/app/google-authenticator/id388497605'
                            : 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=it&gl=US'
                    "
                    >Google Authenticator</a
                    >,
                    <a
                        class="text-primary"
                        [href]="
                        targetPlatform === TargetPlatform.iOS
                            ? 'https://apps.apple.com/it/app/microsoft-authenticator/id983156458'
                            : 'https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=it&gl=US'
                    "
                    >Microsoft Authenticator</a
                    >
                    sono opzioni affidabili.
                </p>
                <div class="flex flex-col py-3.5">
                    <label for="secretKey" class="text-595959 font-bold text-sm"
                    >Secret key:</label
                    >
                    <div class="relative">
                        <input
                            id="secretKey"
                            type="text"
                            [disabled]="true"
                            class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-a7a7a7"
                            [value]="secretKey"
                            #secretinput
                        />
                        <button
                            class="absolute copy-button flex items-center"
                            (click)="copyInputValue(secretinput.value)"
                        >
                            <svg class="w-4 h-4 fill-black me-2">
                                <use href="../../../assets/svg/svGP.svg#copy"></use>
                            </svg>
                            <span>Copia</span>
                        </button>
                    </div>
                </div>
                <div class="flex flex-col py-3.5">
                    <label for="email" class="text-595959 font-bold text-sm"
                    >Email dell'account:</label
                    >
                    <div class="relative">
                        <input
                            id="email"
                            type="text"
                            [disabled]="true"
                            class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-a7a7a7"
                            [value]="accountName"
                            #accountinput
                        />
                        <button
                            class="absolute copy-button flex items-center"
                            (click)="copyInputValue(accountinput.value)"
                        >
                            <svg class="w-4 h-4 fill-black me-2">
                                <use href="../../../assets/svg/svGP.svg#copy"></use>
                            </svg>
                            <span>Copia</span>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="otpAuthUrl">
                    <a
                        [href]="otpAuthUrl"
                        class="my-2 text-primary underline text-sm font-bold text-center"
                    >
                        Utilizza il software di generazione codici di sistema
                    </a>
                    <span class="my-3 text-center text-xs">oppure</span>
                    <a
                        (click)="handleQRCode()"
                        class="mb-5 text-primary underline text-sm font-bold text-center cursor-pointer"
                    >
                        Voglio scansionare il Codice QR
                    </a>
                </ng-container>
            </div>
            <div class="pt-7">
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full text-center"
                    [routerLink]="endTwoFactorProcessPath"
                >
                    <div class="flex items-center justify-center w-full">
                        <span class="text-white text-sm font-bold">Avanti</span>
                    </div>
                </button>
                <button
                    class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
                    [routerLink]="settingsPath"
                >
                    <div class="flex items-center justify-center w-full">
                    <span class="text-primary text-sm font-bold"
                    >Annulla operazione</span
                    >
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
