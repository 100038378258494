<section class="custom-bg-white py-7 px-6 relative h-full pb-28">
    <div class="flex items-center justify-between">
        <h1 class="font-semibold text-lg">
            {{
                "pages.settings-page.pages.profile-page.yourDataLabel"
                    | transloco
            }}
        </h1>
        <button (click)="toggleReadOnly()">
            @if (isReadOnly) {
                <div class="flex items-center">
                    <div>
                        <svg class="size-4 me-2">
                            <use href="../../../assets/svg/svGP.svg#edit"></use>
                        </svg>
                    </div>
                    <div>
                        <span>Modifica</span>
                    </div>
                </div>
            } @else {
                <div class="flex items-center bg-light-danger py-2 px-3 rounded-lg">
                    <div>
                        <span class="text-danger text-sm font-bold">Annulla</span>
                    </div>
                </div>
            }
        </button>
    </div>
    <br/>
    <form [formGroup]="personalDataForm" (ngSubmit)="onInfoFormSubmit()">
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm"
                   for="firstName">{{ "pages.settings-page.pages.profile-page.firstNameLabel"| transloco }}
            </label>
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="firstName"
                placeholder="{{'pages.settings-page.pages.profile-page.firstNameLabel'| transloco}}"
                formControlName="firstName"
                style="text-transform: capitalize"
                tabindex="0"
                [readOnly]="isReadOnly"
            />
            <!-- Gestione errore campo form firstname da stilizzare -->
            @if (personalDataForm.controls.firstName.invalid && personalDataForm.controls.firstName.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci un nome valido.</span>
            }
            <!-- Fine gestion errore campo form firstname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="lastName">
                {{ "pages.settings-page.pages.profile-page.lastNameLabel"| transloco }}</label
            >
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="lastName"
                placeholder="{{'pages.settings-page.pages.profile-page.lastNameLabel'| transloco}}"
                formControlName="lastName"
                style="text-transform: capitalize"
                tabindex="0"
                [readOnly]="isReadOnly"
            />
            <!-- Gestione errore campo form lastname da stilizzare -->
            @if (personalDataForm.controls.lastName.invalid && personalDataForm.controls.lastName.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci un cognome valido.</span>
            }
            <!-- Fine gestion errore campo form lastname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col pt-3.5 pb-1.5">
            <label class="text-595959 font-bold text-sm" for="birthDate">
                {{ "pages.settings-page.pages.profile-page.birthDateLabel"| transloco }}</label
            >
            @if (!isReadOnly) {
                <input
                    type="date"
                    class="w-full py-2 form-control text-sm mt-2.5"
                    id="birthDate"
                    placeholder="{{'pages.settings-page.pages.profile-page.birthDateLabel'| transloco}}"
                    formControlName="birthDate"
                    tabindex="0"
                />
            } @else {
                <span class="py-4 border-b border-light-grey">{{
                        personalDataForm.value.birthDate | date : "dd/MM/yyyy"
                    }}</span>
            }
            <!-- Gestione errore campo form birthDate da stilizzare -->
            @if (personalDataForm.controls['birthDate'].errors?.['invalidAge'] && personalDataForm.controls['birthDate'].touched) {
                <span class="text-danger text-sm mt-2">La data di nascita non è valida: devi essere maggiorenne per proseguire.</span>
            }
            <!-- Fine gestion errore campo form birthdate-->
        </div>
        <div class="flex flex-col py-3.5">
            <label for="mobilePhone" class="text-595959 font-bold text-sm">
                {{ "pages.settings-page.pages.profile-page.mobilePhoneLabel"| transloco }}</label>
            @if (isReadOnly) {
                <input
                    type="phone"
                    class="form-control text-sm mt-2.5"
                    [value]="personalDataForm.controls.mobilePhone.value.number"
                    placeholder="{{'pages.settings-page.pages.profile-page.mobilePhoneLabel'| transloco}}"
                    [readOnly]="isReadOnly"
                    tabindex="0"
                />
            } @else {
                <div class="ngx-tel mt-3.5 w-100">
                    <ngx-intl-tel-input
                        [cssClass]="'form-control'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryPlaceholder]="'Search Country'"
                        [selectFirstCountry]="true"
                        [phoneValidation]="true"
                        [separateDialCode]="true"
                        [disabled]="isReadOnly"
                        [required]="true"
                        id="mobilePhone"
                        formControlName="mobilePhone"
                        tabindex="0"
                    ></ngx-intl-tel-input>
                </div>
            }
            <!-- Gestione errore campo form mobilePhone da stilizzare -->
            @if (personalDataForm.controls.mobilePhone.invalid && personalDataForm.controls.mobilePhone.touched) {
                <span
                    class="text-danger text-sm mt-2">Inserire un numero di telefono valido per il prefisso selezionato.</span>
            }
            <!-- Fine gestione errore campo form mobilePhone -->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex py-3.5">
            <input
                type="checkbox"
                class="me-2"
                formControlName="marketingConsent"
                id="marketingConsent"
                tabindex="0"
                [disabled]="isReadOnly"
            />
            <span class="text-sm">
                <label class="text-595959 font-bold text-sm"
                       for="marketingConsent">{{ "pages.settings-page.pages.profile-page.marketingConsentLabel"| transloco }}</label>
            </span>
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        @if (!isReadOnly) {
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                [disabled]="personalDataForm.invalid || (userIsLoading$ | async)"
                type="submit"
            >
                <div class="flex items-center justify-center w-full">
                    @if (userIsLoading$ | async) {
                        <span class="button-loader"></span>
                    } @else {
                        <span
                            class="text-white text-sm font-bold uppercase">{{ "pages.settings-page.pages.profile-page.updateUserLabel"| transloco }}</span>
                    }
                </div>
            </button>
            <button
                class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center mt-2 w-full"
                (click)="toggleReadOnly()"
            >
                <div class="flex items-center justify-center w-full">
                    <span class="text-primary text-sm font-bold">Annulla modifiche</span>
                </div>
            </button>
        }
    </form>
</section>
