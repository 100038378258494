import {Component, OnInit, inject} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {selectUserData, selectUserIsLoading} from '../../../../store/user/user.selectors';
import {User, UserUpdateInfoData} from '../../../../models/users.models';
import {first} from 'rxjs';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {updateUser} from '../../../../store/user/user.actions';
import {PersonalDataForm} from '../../../../models/formgroups/persona-data-form.model';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {CountryISO} from 'ngx-intl-tel-input-gg';
import {InternationalPhone} from '../../../../models/international-phone.model';
import {NavigationService} from '../../../../services/navigation.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ageValidator} from '../../../../shared/validators/majority-age.validator';
import {AsyncPipe, DatePipe} from '@angular/common';
import {NoWhitespaceValidator} from '../../../../shared/validators/no-whitespace.validator';

@Component({
    selector: 'profile-page',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        RouterModule,
        NgxIntlTelInputModule,
        AsyncPipe,
        DatePipe,
    ],
    templateUrl: './profile-page.component.html',
})
export default class ProfilePageComponent implements OnInit {
    #fb = inject(FormBuilder);
    #store = inject(Store);
    #navigationService = inject(NavigationService);
    user$ = this.#store.select(selectUserData);
    userIsLoading$ = this.#store.select(selectUserIsLoading);

    user?: User | null;

    isReadOnly = true;
    personalDataForm!: FormGroup<PersonalDataForm>;
    preferredCountries: CountryISO[] = [CountryISO.Italy];

    constructor() {
        this.initializeForm();
        this.fetchUserData();
    }

    fetchUserData() {
        this.user$.pipe(takeUntilDestroyed()).subscribe({
            next: (user) => {
                if (user && this.user == null) {
                    this.populateForm(user);
                }
                if (user) {
                    this.user = user;
                }
            },
        });
    }

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    private initializeForm(): void {
        this.personalDataForm = this.#fb.nonNullable.group({
            firstName: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            lastName: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            birthDate: this.#fb.nonNullable.control('', [
                Validators.required,
                ageValidator(),
            ]),
            mobilePhone: this.#fb.nonNullable.control(
                {
                    value: {} as InternationalPhone,
                    disabled: this.isReadOnly,
                },
                [Validators.required]
            ),
            marketingConsent: this.#fb.nonNullable.control({
                value: false,
                disabled: this.isReadOnly,
            }, [
                Validators.required,
            ]),
        });
    }

    populateForm(user: User) {
        this.personalDataForm.patchValue({
            firstName: user.firstName,
            lastName: user.lastName,
            birthDate: user.birthDate,
            mobilePhone: {
                number: user.mobilePhone,
            },
            marketingConsent: user.marketingConsent,
        });

        // unsubscribe before apply next patch on mobile phone
        this.personalDataForm.controls.mobilePhone.valueChanges
            .pipe(
                first((value) => value.dialCode != null && value.number != null)
            )
            .subscribe((value) => {
                // patch mobile phone removing the dial code from the phone number
                this.personalDataForm.patchValue({
                    mobilePhone: {
                        ...value,
                        number: value.number!.replace(value.dialCode!, ''),
                    },
                });
            });
    }

    toggleReadOnly() {
        this.isReadOnly = !this.isReadOnly;
        if (this.isReadOnly) {
            this.disableForm();
            this.personalDataForm.reset({
                firstName: this.user?.firstName,
                lastName: this.user?.lastName,
                birthDate: this.user?.birthDate,
                mobilePhone: {
                    number: this.user?.mobilePhone,
                },
                marketingConsent: this.user?.marketingConsent,
            });
        } else {
            this.personalDataForm.controls.mobilePhone.enable();
            this.personalDataForm.controls.marketingConsent.enable();
        }
    }

    onInfoFormSubmit() {
        if (this.personalDataForm.invalid || this.isReadOnly) return;

        const {
            firstName,
            lastName,
            birthDate,
            mobilePhone,
            marketingConsent
        } = this.personalDataForm.value;

        const newUserInfo: UserUpdateInfoData = {
            firstName: firstName!,
            lastName: lastName!,
            birthDate: birthDate!,
            mobilePhone: mobilePhone!.e164Number!,
            marketingConsent: marketingConsent!,
        };

        this.#store.dispatch(updateUser({data: newUserInfo}));
        this.userIsLoading$.pipe(first(isLoading => !isLoading)).subscribe(value => {
            this.disableForm();
        });
    }

    private disableForm() {
        this.isReadOnly = true;
        this.personalDataForm.controls.mobilePhone.disable();
        this.personalDataForm.controls.marketingConsent.disable();
    }
}
