import {Component, OnInit, inject} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HealthDataService} from '../../services/health-data.service';
import dayjs from 'dayjs';
import {
    HealthDataPeriod, HealthDataType,
    HealthDataValue,
} from '../../models/health.models';
import {APP_ROUTES} from '../../app.routes.definition';
import {LogService} from '../../services/log.service';
import {
    HealthStatisticCardComponent
} from "../health-statistic-card/health-statistic-card.component";
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'health-statistic-sleep-in-bed-card',
    standalone: true,
    imports: [RouterModule, HealthStatisticCardComponent],
    templateUrl: './health-statistic-sleep-in-bed-card.component.html',
    styleUrl: './health-statistic-sleep-in-bed-card.component.css',
})
export class HealthStatisticSleepInBedCardComponent implements OnInit {
    #healthDataService = inject(HealthDataService);
    #logService = inject(LogService);

    title = 'Ore a Letto';
    healthTypePath = APP_ROUTES.MONITOR_SLEEP_SLEEP_IN_BED(false);
    dataType = HealthDataType.SLEEP_IN_BED;
    unitSymbolLabel = 'ore';
    sumData = '';
    dateFrom = dayjs(new Date());
    period: HealthDataPeriod = HealthDataPeriod.day;
    payloadTypes: dataType[] = ['sleep'];
    isLoading = true;

    ngOnInit(): void {
        this.#healthDataService
            .fetchHealthUserData(
                this.dataType,
                {
                    period: this.period,
                    dateFrom: this.dateFrom.format('YYYY-MM-DD'),
                    payloadTypes: this.payloadTypes,
                    useMetadataEndTime: true
                }
            )
            .subscribe({
                next: (response: HealthDataValue[]) => {
                    if (response.length > 0) {
                        const values = response.map((entry) => entry.value);
                        const filteredValues = values.filter(
                            (value) => value === null
                        );

                        if (filteredValues.length > 0) {
                            this.sumData = '-';
                        } else {
                            const totalSeconds = values.reduce((a, b) => a + b, 0);
                            const hours = Math.floor(totalSeconds / 3600);
                            const minutes = Math.floor((totalSeconds % 3600) / 60);
                            this.sumData = Math.floor(hours).toString().padStart(2, '0') + ':' + Math.floor(minutes).toString().padStart(2, '0');
                        }
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    this.#logService.error(error);
                    this.isLoading = false;
                },
            });
    }
}
