import {
    Component,
    OnInit,
    inject, OnChanges, SimpleChanges,
} from '@angular/core';
import {
    ActivityAggregation,
    HealthChartPeriod,
    HealthDataAggregateOperation,
    HealthDataPeriod,
    HealthDataType,
    HealthDataUnitToString,
} from '../../../../../../models/health.models';
import { TranslocoModule } from '@ngneat/transloco';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../store/app.state";
import {HealthDataPageStore} from "../../../../../../components/health-data-page/health-data-page.store";
import {
    selectUserIsLoading,
    selectUserTryTerraHasProviders,
    selectUserTryTerraIsGettingUserInfo, selectUserTryTerraIsNativeInitializing
} from "../../../../../../store/user/user.selectors";
import dayjs from "dayjs";
import {Chart, registerables} from "chart.js";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {
    NoWearableConnectedCardComponent
} from "../../../../../../components/no-wearable-connected-card/no-wearable-connected-card.component";
import {provideComponentStore} from "@ngrx/component-store";
import {
    HealthActivityAggregationChartComponent
} from "../../../../../../components/health-activity-aggregation-chart/health-activity-aggregation-chart.component";

@Component({
    selector: 'app-activity-aggregation-page',
    standalone: true,
    imports: [TranslocoModule, AsyncPipe, NoWearableConnectedCardComponent, NgClass, NgIf, HealthActivityAggregationChartComponent],
    templateUrl: './activity-aggregation-page.component.html',
    styleUrl: './activity-aggregation-page.component.css',
    providers: [provideComponentStore(HealthDataPageStore)],
})
export default class ActivityAggregationPageComponent implements OnInit, OnChanges {
    healthDataLabel: string = 'Allenamento';
    hasTwoDecimals: boolean = false;
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];

    HealthDataAggregateOperation = HealthDataAggregateOperation;
    HealthDataType = HealthDataType;
    HealthDataUnitToString = HealthDataUnitToString;

    #store = inject(Store<AppState>);
    #componentStore = inject(HealthDataPageStore);

    activityAggregationDataValues$ = this.#componentStore.activityAggregationData$;
    isLoading$ = this.#componentStore.isLoading$;
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    userTryTerraIsLoading$ = this.#store.select(selectUserTryTerraIsGettingUserInfo);
    userTryTerraHasProviders$ = this.#store.select(selectUserTryTerraHasProviders);
    userTryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);

    dateFrom = dayjs(new Date());
    dateLabel = '';
    dateLastMonitored = '';
    lastDurationMonitored = '';
    lastAvgHeartBeatMonitored = '';
    sumTotalValuesMonitored = '';
    avgHeartBeatValuesMonitored = '';
    period: HealthChartPeriod = HealthChartPeriod.day;
    HealthChartPeriod = HealthChartPeriod;
    basisClass = this.getBasisClass();

    constructor() {
        Chart.register(...registerables);
        this.updateUserData();
    }

    ngOnInit(): void {
        this.period = this.supportedPeriods[0];
        this.fetchHealthDataWithStore();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['supportedPeriods']) {
            this.basisClass = this.getBasisClass();
        }
    }

    fetchHealthDataWithStore() {
        this.dateLastMonitored = '';
        this.lastDurationMonitored = '';
        this.lastAvgHeartBeatMonitored = '';
        this.sumTotalValuesMonitored = '';
        this.avgHeartBeatValuesMonitored = '';

        this.#componentStore.fetchHealthUserActivityAggregationData({
            filters: {
                period: HealthDataPeriod[this.period],
                dateFrom: this.dateFrom.format('YYYY-MM-DD'),
                payloadTypes: ['activity'],
            },
        });
        this.updateDateLabel();
    }

    updateUserData() {
        this.activityAggregationDataValues$.pipe(takeUntilDestroyed()).subscribe((data) => {
            if (data && data.length > 0) {
                const groupedData = this.groupByKey(data, 'date');
                const lastGroupedValue = groupedData[data[data.length - 1].date] as ActivityAggregation[];
                this.sumTotalValuesMonitored = this.formatTime(
                    data.reduce((a, b) => a + b.totalDurationSeconds, 0));
                this.avgHeartBeatValuesMonitored = Math.round(
                    data.reduce((a, b) => a + b.averageHeartRate, 0) / data.length).toString();

                this.dateLastMonitored = dayjs(lastGroupedValue[lastGroupedValue.length - 1].date).format('DD/MM');
                this.lastDurationMonitored = this.formatTime(
                    lastGroupedValue.reduce((a, b) => a + b.totalDurationSeconds, 0));
                this.lastAvgHeartBeatMonitored = Math.round(
                    lastGroupedValue.reduce((a, b) => a + b.averageHeartRate, 0) / lastGroupedValue.length).toString();
            } else {
                this.dateLastMonitored = '-';
                this.lastDurationMonitored = '-';
                this.lastAvgHeartBeatMonitored = '-';
                this.avgHeartBeatValuesMonitored = '-';
                this.sumTotalValuesMonitored = '-';
            }
        });
    }

    updateDateLabel() {
        const now = dayjs(new Date());
        switch (this.period) {
            case 'day':
                this.dateLabel = dayjs(this.dateFrom)
                    .format('dddd D MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                if (now.year() !== this.dateFrom.year()) {
                    this.dateLabel += ` ${this.dateFrom.year()}`;
                }
                break;
            case 'week':
                const startWeek = dayjs(this.dateFrom)
                    .startOf('week');
                const endWeek = dayjs(this.dateFrom).endOf('week');
                const month = startWeek
                    .format('MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                const endMonth = endWeek
                    .format('MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                this.dateLabel = `${startWeek.format('D')}`;
                if (startWeek.month() !== endWeek.month()) {
                    this.dateLabel += ` ${month}`;
                }
                if (startWeek.year() !== endWeek.year()) {
                    this.dateLabel += ` ${startWeek.year()}`;
                }
                this.dateLabel += ` - ${endWeek.format('D')} ${endMonth}`;
                if (startWeek.year() !== endWeek.year() || endWeek.year() !== now.year()) {
                    this.dateLabel += ` ${endWeek.year()}`;
                }
                break;
            case 'month':
                this.dateLabel = dayjs(this.dateFrom)
                    .format('MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                if (now.year() !== this.dateFrom.year()) {
                    this.dateLabel += ` ${this.dateFrom.year()}`;
                }
                break;
        }
    }

    previousDate() {
        this.dateFrom = this.dateFrom.add(-1, this.period);
        this.fetchHealthDataWithStore();
    }

    nextDate() {
        this.dateFrom = this.dateFrom.add(1, this.period);
        this.fetchHealthDataWithStore();
    }

    filterData(period: HealthChartPeriod) {
        if (period !== this.period) {
            this.period = period;
            this.fetchHealthDataWithStore();
        }
    }

    private getBasisClass(): string {
        if (this.supportedPeriods.length > 1) {
            return this.supportedPeriods.length === 2
                ? 'basis-6/12'
                : 'basis-4/12';
        } else {
            return 'basis-12/12';
        }
    }

    private formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    private groupByKey<T extends {[key: string]: any}>(array: T[], key: string): { [key: string]: T[] } {
        return array
            .reduce((hash: {[key: string]: any}, obj) => {
                if(obj[key] === undefined) return hash;
                return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
            }, {}) as { [key: string]: T[] }
    }
}
