import { Injectable, inject } from '@angular/core';
import {InitialSurveyResponse, Survey} from '../models/survey.models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    #http = inject(HttpClient);

    #apiUrl = `${environment.apiUrl}/surveys`;
    #initialSurveyUrl = `${this.#apiUrl}/initial`;
    #feelingGetUrl = `${this.#apiUrl}/how-you-feel-today`;
    #lifeWheelGetUrl = `${this.#apiUrl}/life-wheel`;

    #account = '/account';
    #checkInitialSurveyValidity = '/check-initial-survey-validity';
    #checkLifeWheelSurveyValidity = '/check-life-wheel-survey-validity';

    constructor() {}

    getInitialSurvey(): Observable<Survey> {
        return this.#http.get<Survey>(this.#initialSurveyUrl);
    }

    getLifeWheelSurvey(): Observable<Survey> {
        return this.#http.get<Survey>(this.#lifeWheelGetUrl);
    }

    getFeelingSurvey(): Observable<Survey> {
        return this.#http.get<Survey>(this.#feelingGetUrl);
    }

    postInitialSurvey(survey: Survey) {
        return this.#http.post<InitialSurveyResponse | null>(`${this.#initialSurveyUrl}`, survey);
    }

    postSurvey(survey: Survey) {
        return this.#http.post(`${this.#apiUrl}`, survey);
    }

    checkInitialSurveyValidity() {
        return this.#http.get<boolean>(`${environment.apiUrl}${this.#account}${this.#checkInitialSurveyValidity}`);
    }

    checkLifeWheelSurveyValidity() {
        return this.#http.get<boolean>(`${environment.apiUrl}${this.#account}${this.#checkLifeWheelSurveyValidity}`);
    }
}
