import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { first } from 'rxjs';
import { UserService } from '../../services/user.service';
import { APP_ROUTES } from '../../app.routes.definition';
import { LogService } from '../../services/log.service';
import LoaderComponent from "../../components/loader/loader.component";
import {
    RegistrationDashIconComponent
} from "../../components/registration-dash-icon/registration-dash-icon.component";

@Component({
    selector: 'activate-account',
    standalone: true,
    imports: [RouterLink, LoaderComponent, RegistrationDashIconComponent],
    templateUrl: './activate-account-page.component.html',
})
export default class ActivateAccountPageComponent implements OnInit {
    #activatedRoute = inject(ActivatedRoute);
    #router = inject(Router);
    #userService = inject(UserService);
    #loggerService = inject(LogService);

    activationKey?: string;
    authPath = APP_ROUTES.AUTH();
    accountActivationErrorLabel = 'loading...';
    isLoading = true;

    constructor() {
        const activationKey =
            this.#activatedRoute.snapshot.queryParamMap.get('activationKey');
        if (activationKey) {
            this.activationKey = activationKey;
        } else {
            this.isLoading = false;
            this.#router.navigate([this.authPath]);
        }
    }

    ngOnInit(): void {
        if (this.activationKey) {
            this.#userService
                .activateAccount(this.activationKey)
                .pipe(first())
                .subscribe({
                    error: (err) => {
                        this.#loggerService.log(err);
                        this.accountActivationErrorLabel =
                            'Account già attivato o inesistente';
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                        this.#userService.isRegistered = true;
                        this.#router.navigate([this.authPath]);
                    },
                });
        } else {
            this.isLoading = false;
            this.#router.navigate([this.authPath]);
        }
    }
}
