import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {combineLatest, EMPTY, from, of, switchMap, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {
    TryTerraAuthTokenResponse,
    TryTerraAuthWithProviderResponse, TryTerraSupportedHealthProvider, TryTerraTriggerWebhooksRequest,
    TryTerraUserInfoResponse
} from "../models/try-terra.models";
import {Connection, TryTerraNativeService} from "./native/try-terra-native.service";
import {NativeAPIService, TargetPlatform} from "./native/native-api.service";
import dayjs from "dayjs";
import {TerraAuthUserResponse} from "terra-api/lib/cjs/API/AuthUser";
import {HealthConnectNotAvailableException} from "../shared/exceptions/health-connect-not-available.exception";
import {HealthConnectNotImplementedException} from "../shared/exceptions/health-connect-not-implemented.exception";
import {initializeNativeTryTerra} from "../store/user/user.actions";

@Injectable({
    providedIn: 'root',
})
export class TryTerraService {
    #http = inject(HttpClient);
    #nativeAPIService = inject(NativeAPIService);
    #tryTerraNativeService = inject(TryTerraNativeService);

    #apiUrl = `${environment.apiUrl}/try-terra`;
    #generateAuthToken = `${this.#apiUrl}/auth/generate-auth-token`;
    #authWithProvider = `${this.#apiUrl}/auth/provider/`;
    #deauthProvider = `${this.#apiUrl}/deauth/provider/`;
    #userInfo = `${this.#apiUrl}/user-info`;
    #triggerWebhooks = `${this.#apiUrl}/trigger-webhooks`;

    constructor() {
    }

    generateAuthToken() {
        return this.#http.post<TryTerraAuthTokenResponse>(`${this.#generateAuthToken}`, {});
    }

    authWithProvider(provider: TryTerraSupportedHealthProvider) {
        return this.#http.post<TerraAuthUserResponse>(`${this.#authWithProvider}${provider}?isMobileNative=${this.#nativeAPIService.isAvailable()}`, {});
    }

    deauthProvider(provider: TryTerraSupportedHealthProvider) {
        return this.#http.post(`${this.#deauthProvider}${provider}`, {});
    }

    getUserInfo() {
        return this.#http.post<TryTerraUserInfoResponse>(`${this.#userInfo}`, {});
    }

    triggerWebhooks(request: TryTerraTriggerWebhooksRequest) {
        return this.#http.post(`${this.#triggerWebhooks}`, request);
    }

    nativeIsAvailable() {
        return this.#tryTerraNativeService.isAvailable();
    }

    initializeNativeConnection(userId: string) {
        if (!this.#tryTerraNativeService.isAvailable()) {
            return of(true);
        }
        return combineLatest([
            from(this.#nativeAPIService.defaultTargetPlatform()),
            from(this.#tryTerraNativeService.isHealthConnectAvailable()),
            from(this.#tryTerraNativeService.isConnectionTypeImplemented({connection: Connection.healthConnect})),
            from(this.#tryTerraNativeService.initTerra({
                devId: environment.tryTerra.devId,
                referenceID: userId
            })),
            from(this.generateAuthToken()),
        ]).pipe(
            switchMap(([platform, isHealthConnectAvailable, isHealthConnectTypeImplemented, terraMessage, tokenRes]) => {
                if (terraMessage == null || terraMessage.error || !terraMessage.success) {
                    return throwError(() => new Error(terraMessage?.error ?? 'Failed to init TryTerra'));
                }
                let connection: Connection;
                switch (platform) {
                    case TargetPlatform.iOS:
                        connection = Connection.appleHealth;
                        break;
                    case TargetPlatform.android:
                        if (!isHealthConnectAvailable) {
                            return throwError(() => new HealthConnectNotAvailableException());
                        }
                        if (!isHealthConnectTypeImplemented) {
                            return throwError(() => new HealthConnectNotImplementedException());
                        }
                        connection = Connection.healthConnect;
                        break;
                }
                return from(this.#tryTerraNativeService.initConnection({
                    connection: connection!,
                    token: tokenRes.token
                })).pipe(
                    switchMap(value => {
                        if (value == null || value.error || !value.success) {
                            return throwError(() => new Error(value?.error ?? 'Failed to init TryTerra Connection'));
                        }
                        return of(true);
                    })
                );
            })
        );
    }

    triggerNativeAllDataWebhook(daysBeforeNow: number) {
        if (!this.#tryTerraNativeService.isAvailable()) {
            return EMPTY;
        }
        const startDate = dayjs().add(-daysBeforeNow, 'day').startOf('day').toDate();
        const endDate = dayjs().toDate();

        return combineLatest([
            from(this.#nativeAPIService.defaultTargetPlatform()),
            from(this.#tryTerraNativeService.isHealthConnectAvailable()),
            from(this.#tryTerraNativeService.isConnectionTypeImplemented({connection: Connection.healthConnect})),
        ]).pipe(
            switchMap(([platform, isHealthConnectAvailable, isHealthConnectTypeImplemented]) => {
                let connection: Connection;
                switch (platform) {
                    case TargetPlatform.iOS:
                        connection = Connection.appleHealth;
                        break;
                    case TargetPlatform.android:
                        if (!isHealthConnectAvailable || !isHealthConnectTypeImplemented) {
                            // Take health data only from Connection.healthConnect, so returns empty array here.
                            return of([]);
                        }
                        connection = Connection.healthConnect;
                        break;
                }

                return combineLatest([
                    from(this.#tryTerraNativeService.getBody({
                        connection: connection!,
                        startDate: startDate,
                        endDate: endDate
                    })),
                    from(this.#tryTerraNativeService.getDaily({
                        connection: connection!,
                        startDate: startDate,
                        endDate: endDate
                    })),
                    from(this.#tryTerraNativeService.getActivity({
                        connection: connection!,
                        startDate: startDate,
                        endDate: endDate
                    })),
                    from(this.#tryTerraNativeService.getSleep({
                        connection: connection!,
                        startDate: startDate,
                        endDate: endDate
                    })),
                    from(this.#tryTerraNativeService.getNutrition({
                        connection: connection!,
                        startDate: startDate,
                        endDate: endDate
                    })),
                ]);
            })
        );
    }
}
