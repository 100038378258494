<div
    class="border-2 border-f7f7f7 bg-white rounded-lg py-4 px-3 mt-2 card-shadow cursor-pointer"
    (click)="openConversation(data.conversation)"
>
    <div class="flex items-centerw-full">
        <div class="basis-2/12">
            <img
                class="size-12 rounded-full border border-light-grey object-cover"
                [src]="data.conversation.coachImageURL ?? defaultCoachImg"
                alt=""
            />
        </div>
        <div class="basis-10/12 ms-3.5">
            <div class="flex items-center justify-between">
                <div>
                    <h2 class="font-bold text-base">
                        {{ data.conversation.coachName }}
                    </h2>
                    <span class="text-9f9d9d">
                                {{ data.conversation.coachQualification }}</span
                    >
                </div>
                @if (data.lastMessage || (data.conversation.chatMessages && data.conversation.chatMessages.length > 0)) {
                    <div>
                        <span class="text-xs text-3b3b35">
                        @if (!!(hasFeatureChatEnabled$ | async) === false && data.conversation.chatMessages) {
                            {{ getDateLabel(data.conversation.chatMessages[0].createdDate) }}
                        } @else if (data.lastMessage) {
                            {{ getDateLabel(data.lastMessage.time) }}
                        }
                        </span>
                    </div>
                }
            </div>
            @if (data.lastMessage || (data.conversation.chatMessages && data.conversation.chatMessages.length > 0)) {
                <div class="px-2 py-2.5 bg-light-linear rounded mt-2">
                    <span class="text-sm text-black truncate inline-block w-full"
                          style="container-type: inline-size">
                        @if (!!(hasFeatureChatEnabled$ | async) === false && data.conversation.chatMessages) {
                            {{ data.conversation.chatMessages[0].text }}
                        } @else if (data.lastMessage) {
                            {{ data.lastMessage.msg }}
                        }
                    </span>
                </div>
            }
        </div>
    </div>
</div>
