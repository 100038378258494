import {inject, Injectable} from '@angular/core';
import {LogService} from "./log.service";

declare global {
    interface Window {
        dataLayer?: GoogleAnalyticsEvent[] | undefined;
    }
}

export interface GoogleAnalyticsEvent {
    funnel: string;
    eventName: string;
    event: 'analytics_event';

    [key: string]: any;
}

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    #logService = inject(LogService);

    constructor() {
    }

    sendAnalyticsEvent(funnel: string, eventName: string, data?: { [key: string]: any } | null) {
        window.dataLayer?.push({
            'funnel': funnel,
            'event': 'analytics_event',
            'eventName': eventName,
            ...data
        });
    }
}
