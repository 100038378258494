export const APP_ROUTES = {
    RESET_PASSWORD: (relative = false) =>
        (relative ? '' : '/') + 'reset-password',
    AUTH: (relative = false) => (relative ? '' : '/') + 'auth',
    LOGIN: (relative = false) => (relative ? '' : '/') + 'login',
    LOGIN_VERIFY_OTP: (relative = false) =>
        relative ? 'verify-otp' : `${APP_ROUTES.LOGIN(relative)}/verify-otp`,
    REGISTER: (relative = false) => (relative ? '' : '/') + 'register',
    SURVEY: (relative = false) => (relative ? '' : '/') + 'survey',
    EXPIRED_INITIAL_SURVEY: (relative = false) =>
        (relative ? '' : '/') + 'expired-initial-survey',
    LIFE_WHEEL_SURVEY: (relative = false) =>
        (relative ? '' : '/') + 'life-wheel-survey',
    ACTIVATE: (relative = false) => (relative ? '' : '/') + 'activate',
    HOME: (relative = false) => (relative ? '' : '/') + 'home',
    APPOINTMENTS: (relative = false) => (relative ? '' : '/') + 'appointments',
    MONITOR: (relative = false) => (relative ? '' : '/') + 'monitor',
    MONITOR_HEART: (relative = false) =>
        relative ? 'heart' : `${APP_ROUTES.MONITOR(relative)}/heart`,
    MONITOR_HEART_RATE: (relative = false) =>
        `${
            relative
                ? 'heart-rate'
                : `${APP_ROUTES.MONITOR_HEART(relative)}/heart-rate`
        }`,
    MONITOR_RESTING_HEART_RATE: (relative = false) =>
        `${
            relative
                ? 'resting-heart-rate'
                : `${APP_ROUTES.MONITOR_HEART(relative)}/resting-heart-rate`
        }`,
    MONITOR_BODY: (relative = false) =>
        `${relative ? 'body' : `${APP_ROUTES.MONITOR(relative)}/body`}`,
    MONITOR_BODY_WEIGHT: (relative = false) =>
        `${
            relative ? 'weight' : `${APP_ROUTES.MONITOR_BODY(relative)}/weight`
        }`,
    MONITOR_BODY_HEIGHT: (relative = false) =>
        `${
            relative ? 'height' : `${APP_ROUTES.MONITOR_BODY(relative)}/height`
        }`,
    MONITOR_BODY_RESPIRATORY_RATE: (relative = false) =>
        `${
            relative
                ? 'respiratory-rate'
                : `${APP_ROUTES.MONITOR_BODY(relative)}/respiratory-rate`
        }`,
    MONITOR_ACTIVITY: (relative = false) =>
        `${relative ? 'activity' : `${APP_ROUTES.MONITOR(relative)}/activity`}`,
    MONITOR_ACTIVITY_STEPS: (relative = false) =>
        `${
            relative
                ? 'steps'
                : `${APP_ROUTES.MONITOR_ACTIVITY(relative)}/steps`
        }`,
    MONITOR_ACTIVITY_EXERCISE_TIME: (relative = false) =>
        `${
            relative
                ? 'exercise-time'
                : `${APP_ROUTES.MONITOR_ACTIVITY(relative)}/exercise-time`
        }`,
    MONITOR_ACTIVITY_ACTIVE_ENERGY_BURNED: (relative = false) =>
        `${
            relative
                ? 'active-energy-burned'
                : `${APP_ROUTES.MONITOR_ACTIVITY(
                    relative
                )}/active-energy-burned`
        }`,
    MONITOR_SLEEP: (relative = false) =>
        `${relative ? 'sleep' : `${APP_ROUTES.MONITOR(relative)}/sleep`}`,
    MONITOR_SLEEP_SLEEP_IN_BED: (relative = false) =>
        `${
            relative
                ? 'sleep-in-bed'
                : `${APP_ROUTES.MONITOR_SLEEP(relative)}/sleep-in-bed`
        }`,
    MESSAGING: (relative = false) => (relative ? '' : '/') + 'messaging',
    MESSAGING_ID: (relative = false, id: string) =>
        (relative ? '' : '/') + `messaging/${id}`,
    FREE_MESSAGING: (relative = false, id: string) =>
        (relative ? '' : '/') + `free-messaging/${id}`,
    MARKETPLACE: (relative = false) => (relative ? '' : '/') + 'marketplace',
    MARKETPLACE_PRODUCT: (relative = false, id: string) =>
        `${
            relative
                ? `product/${id}`
                : `${APP_ROUTES.MARKETPLACE(relative)}/product/${id}`
        }`,
    PAYMENT_REDIRECT: (relative = false) =>
        (relative ? '' : '/') + 'payment-redirect',
    FEELING: (relative = false) => (relative ? '' : '/') + 'feeling',
    SETTINGS: (relative = false) => (relative ? '' : '/') + 'settings',
    SETTINGS_PROFILE: (relative = false) =>
        `${relative ? 'profile' : `${APP_ROUTES.SETTINGS(relative)}/profile`}`,
    SETTINGS_BILLING_INFO: (relative = false) =>
        `${relative ? 'billing-info' : `${APP_ROUTES.SETTINGS(relative)}/billing-info`}`,
    SETTINGS_SECURITY: (relative = false) =>
        `${
            relative ? 'security' : `${APP_ROUTES.SETTINGS(relative)}/security`
        }`,
    SETTINGS_TWO_FACTOR_SETUP: (relative = false) =>
        `${
            relative
                ? 'two-factor-setup'
                : `${APP_ROUTES.SETTINGS_SECURITY(relative)}/two-factor-setup`
        }`,
    SETTINGS_BEGIN_TWO_FACTOR_PROCESS: (relative = false) =>
        `${
            relative
                ? 'begin-two-factor-process'
                : `${APP_ROUTES.SETTINGS_TWO_FACTOR_SETUP(
                    relative
                )}/begin-two-factor-process`
        }`,
    SETTINGS_END_TWO_FACTOR_PROCESS: (relative = false) =>
        `${
            relative
                ? 'end-two-factor-process'
                : `${APP_ROUTES.SETTINGS_BEGIN_TWO_FACTOR_PROCESS(
                    relative
                )}/end-two-factor-process`
        }`,
    SETTINGS_DELETE_TWO_FACTOR: (relative = false) =>
        `${
            relative
                ? 'delete-two-factor'
                : `${APP_ROUTES.SETTINGS}/delete-two-factor`
        }`,

    SETTINGS_SECURITY_CHANGE_EMAIL: (relative = false) =>
        `${
            relative
                ? 'change-email'
                : `${APP_ROUTES.SETTINGS_SECURITY(relative)}/change-email`
        }`,
    SETTINGS_SECURITY_CHANGE_PASSWORD: (relative = false) =>
        `${
            relative
                ? 'change-password'
                : `${APP_ROUTES.SETTINGS_SECURITY(relative)}/change-password`
        }`,
    SETTINGS_SECURITY_2FA: (relative = false) =>
        `${
            relative
                ? 'two-factor'
                : `${APP_ROUTES.SETTINGS_SECURITY(relative)}/two-factor`
        }`,
    SETTINGS_SUBSCRIPTIONS: (relative = false) =>
        `${
            relative
                ? 'subscriptions'
                : `${APP_ROUTES.SETTINGS(relative)}/subscriptions`
        }`,
    SETTINGS_DELETE: (relative = false) =>
        `${relative ? 'delete' : `${APP_ROUTES.SETTINGS(relative)}/delete`}`,

    // Consent page
    SETTINGS_CONSENT: (relative = false) =>
        `${relative ? 'consent' : `${APP_ROUTES.SETTINGS(relative)}/consent`}`,
    SETTINGS_CONSENT_VIDEO_AND_MICROPHONE: (relative = false) =>
        `${
            relative
                ? 'video-and-microphone'
                : `${APP_ROUTES.SETTINGS_CONSENT(
                    relative
                )}/video-and-microphone`
        }`,
    SETTINGS_CONSENT_DATA_HEALTH_PERMISSION: (relative = false) =>
        `${
            relative
                ? 'health-data-permission'
                : `${APP_ROUTES.SETTINGS_CONSENT(
                    relative
                )}/health-data-permission`
        }`,
    // End Consent page
    // Support page
    SETTINGS_SUPPORT: (relative = false) =>
        `${relative ? 'support' : `${APP_ROUTES.SETTINGS(relative)}/support`}`,
    // End Support page
    // End Terms and conditions page
    HEALTH_PROVIDERS: (relative = false) =>
        `${relative ? 'health-providers' : `${APP_ROUTES.SETTINGS(relative)}/health-providers`}`,
    BUY_BASE_PLAN: (relative = false) =>
        (relative ? '' : '/') + 'buy-base-plan',
    BUY_BASE_PLAN_DESCRIPTION: (relative = false) =>
        `${
            relative
                ? 'description'
                : `${APP_ROUTES.BUY_BASE_PLAN(relative)}/description`
        }`,
    BUY_BASE_PLAN_SELECT_PLAN: (relative = false) =>
        `${
            relative
                ? 'select-plan'
                : `${APP_ROUTES.BUY_BASE_PLAN(relative)}/select-plan`
        }`,
    PENDING_ORDER: (relative = false, id: string) =>
        (relative ? '' : '/') + `pending-order/${id}`,
    ASK_IMPORT_HEALTH_DATA: (relative = false) =>
        (relative ? '' : '/') + 'ask-import-health-data',
    ASK_IMPORT_HEALTH_DATA_TUTORIAL: (relative = false) =>
        `${
            relative
                ? 'tutorial'
                : `${APP_ROUTES.ASK_IMPORT_HEALTH_DATA(relative)}/tutorial`
        }`,
    ASK_IMPORT_HEALTH_DATA_IMPORT: (relative = false) =>
        `${
            relative
                ? 'import'
                : `${APP_ROUTES.ASK_IMPORT_HEALTH_DATA(relative)}/import`
        }`,

    RATING: (relative = false) => (relative ? '' : '/') + 'rating',
    SURVEY_INVITATION: (relative = false, id: string) =>
        `${(relative ? '' : '/') + `survey-invitation/${id}`}`,
};
