import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";

export enum TrackingStatus {
    /// The user has not yet received an authorization request dialog
    notDetermined,

    /// The device is restricted, tracking is disabled and the system can't show a request dialog
    restricted,

    /// The user denies authorization for tracking
    denied,

    /// The user authorizes access to tracking
    authorized,

    /// The platform is not iOS or the iOS version is below 14.0
    notSupported,
}

/**
 * This service allows interaction with iOS App Tracking Transparency native APIs.
 *
 * Official API: https://pub.dev/documentation/app_tracking_transparency/latest/
 */
@Injectable({
    providedIn: 'root'
})
export class AppTrackingTransparencyNativeService {
    #nativeAPIService = inject(NativeAPIService);

    constructor() {
    }

    /**
     * Checks if the native App Tracking Transparency Service is available.
     */
    async isAvailable(): Promise<boolean> {
        return this.#nativeAPIService.isAvailable() && await this.#nativeAPIService.callHandler<boolean>('AppTrackingTransparency.isAvailable');
    }

    /**
     * Call this function to display tracking authorization dialog on ios 14+ devices. User's choice is returned as {@link TrackingStatus}.
     * You can call this function as many as you want but it will display the dialog only once after the user mades his decision.
     *
     * Official API: https://pub.dev/documentation/app_tracking_transparency/latest/app_tracking_transparency/AppTrackingTransparency/requestTrackingAuthorization.html
     */
    requestTrackingAuthorization(): Promise<TrackingStatus> {
        return this.#nativeAPIService.callHandler<TrackingStatus>('AppTrackingTransparency.requestTrackingAuthorization');
    }

    /**
     * Get tracking authorization status without showing a dialog.
     *
     * Consider using this value if you want to show a custom tracking explainer dialog before the system dialog.
     *
     * Official API: https://pub.dev/documentation/app_tracking_transparency/latest/app_tracking_transparency/AppTrackingTransparency/trackingAuthorizationStatus.html
     */
    trackingAuthorizationStatus(): Promise<TrackingStatus> {
        return this.#nativeAPIService.callHandler<TrackingStatus>('AppTrackingTransparency.trackingAuthorizationStatus');
    }

    /**
     * Call this function to get advertising identifier (ie tracking data).
     *
     * Official API: https://pub.dev/documentation/app_tracking_transparency/latest/app_tracking_transparency/AppTrackingTransparency/getAdvertisingIdentifier.html
     */
    getAdvertisingIdentifier(): Promise<string> {
        return this.#nativeAPIService.callHandler<string>('AppTrackingTransparency.getAdvertisingIdentifier');
    }
}
