import { Component, Input, inject } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { selectUserData } from '../../store/user/user.selectors';
import { AppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { CustomDatePipe } from '../../shared/pipes/custom-date.pipe';
import { CoachingSessionService } from '../../services/coaching-session.service';
import { LogService } from '../../services/log.service';

@Component({
    selector: 'app-reschedule-modal',
    standalone: true,
    imports: [AsyncPipe, CustomDatePipe],
    templateUrl: './reschedule-modal.component.html',
    styleUrl: './reschedule-modal.component.css',
})
export class RescheduleModalComponent {
    #modalService = inject(ModalService);
    #store = inject(Store<AppState>);
    userImg$ = this.#store.select(selectUserData);
    defaultImg = '/assets/img/pic/profile.png';
    #videoCallService = inject(CoachingSessionService);
    #loggerService = inject(LogService);

    @Input() appointmentId: string = '';
    @Input() appointmentDate: Date = new Date();
    @Input() appointmentTime: string = '';
    @Input() coachImg: string = '';

    toggleModal() {
        this.#modalService.closeAll();
    }

    rescheduleAppointment() {
        this.#videoCallService.deleteSession(this.appointmentId).subscribe({
            next: () => {
                this.#modalService.closeAll();
            },
            error: (error) => {
                this.#loggerService.error(error);
            },
        });
    }
}
