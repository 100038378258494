<div class="flex items-center justify-start p-4">
    <button class="p-2 bg-white rounded-full" (click)="goToLoginPage()">
        <svg class="size-4 fill-primary">
            <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
        </svg>
    </button>
</div>
<div class="flex flex-col -mt-14 h-full">
    <div
        class="flex flex-col items-center justify-center custom-bg-illustration-blue mt-28"
    >
        <img
            class="h-20 w-auto"
            src="../../../../../../../../../../assets/img/illustrations/illustration-2fa.png"
            alt=""
        />
    </div>
    <div
        class="custom-bg-white pt-7 px-6 pb-28 relative mt-12 flex flex-col h-full"
    >
        <p class="text-danger">{{ errorMessage }}</p>
        <h2 class="font-semibold text-lg">Conferma codice</h2>
        <p class="mt-5">
            Conferma la tua identità inserendo il codice visualizzato nella tua
            app di autenticazione.
        </p>
        <div class="flex items-center py-1.5 px-3 rounded-lg bg-white my-5">
            <div class="me-2">
                <svg class="size-6 fill-bcd9ea">
                    <use href="../../../assets/svg/svGP.svg#info"></use>
                </svg>
            </div>
            <div>
                <p class="text-595959 text-sm">
                    Codice monouso valido solo per pochi secondi alla volta.
                </p>
            </div>
        </div>
        <form [formGroup]="verifyOtpForm">
            <div class="flex flex-col">
                <label class="text-595959 font-bold text-sm" for="otp">
                    {{ "pages.auth-page.otpLabel" | transloco }}</label
                >
                <input
                    class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-a7a7a7"
                    type="text"
                    id="otp"
                    placeholder="{{ 'pages.auth-page.otpLabel' | transloco }}"
                    formControlName="otp"
                    autocomplete="one-time-code"
                />
            </div>
            @if (verifyOtpForm.controls['otp'].invalid &&
            verifyOtpForm.controls['otp'].touched) {
            <span class="text-danger"
                >Per favore inserisci un otp valido formato da 6 caratteri</span
            >
            }
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                [disabled]="verifyOtpForm.invalid"
                (click)="onSubmitVerifyOtp()"
            >
                <div class="flex items-center justify-center w-full">
                    <span class="text-white text-sm font-bold uppercase">{{
                        "pages.auth-page.otpLabel" | transloco
                    }}</span>
                </div>
            </button>
        </form>
        <div class="mt-5">
            <p>Non riesci ad entrare? Prova con il tuo codice di recupero:</p>
        </div>
        <div>
            <form [formGroup]="verifyRecoveryCodeForm">
                <div class="flex flex-col mt-7">
                    <label
                        class="text-595959 font-bold text-sm"
                        for="verifyCode"
                        >{{
                            "pages.auth-page.recoveryCodeLabel" | transloco
                        }}</label
                    >
                    <input
                        class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-a7a7a7"
                        type="text"
                        id="verifyCode"
                        placeholder="{{
                            'pages.auth-page.recoveryCodeLabel' | transloco
                        }}"
                        formControlName="recoveryCode"
                    />
                </div>
                @if (verifyRecoveryCodeForm.controls['recoveryCode'].invalid &&
                verifyRecoveryCodeForm.controls['recoveryCode'].touched) {
                <span class="text-danger"
                    >Perfavore inserisci un codice di recupero valido</span
                >
                }
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                    [disabled]="verifyRecoveryCodeForm.invalid"
                    (click)="onSubmitVerifyRecoveryCode()"
                >
                    <div class="flex items-center justify-center w-full">
                        <span class="text-white text-sm font-bold uppercase">{{
                            "pages.auth-page.verifyRecoveryCodeLabel"
                                | transloco
                        }}</span>
                    </div>
                </button>
            </form>
        </div>
        <button
            class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center mt-2 w-full"
            (click)="goToLoginPage()"
        >
            <div class="flex items-center justify-center w-full">
                <span class="text-primary text-sm font-bold">Indietro</span>
            </div>
        </button>
    </div>
</div>
