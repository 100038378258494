<div>
    <div class="flex items-center justify-center">
        <img
            class="size-14"
            src="../../../../../../assets/img/emoji/slightly_smiling_face.png"
            alt=""
        />
    </div>
    <div class="mt-5 text-center">
        <h1 class="font-bold text-xl">Modulo inviato con successo!</h1>
    </div>
    <div class="mt-4 text-center">
        <p class="text-sm">
            Abbiamo ricevuto il tuo modulo di supporto. Ti risponderemo il prima possibile.
        </p>
    </div>
    <!--        @if (error) {-->
    <!--        <div class="mt-4 text-center">-->
    <!--            <h3>Maggiori dettagli</h3>-->
    <!--            <p class="text-sm">{{ error.name }}: {{ error.message }}</p>-->
    <!--        </div>-->
    <!--        }-->

    <div class="mt-5">
        <button
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
            (click)="goToSettings()"
        >
            <div>
                <span class="text-white text-sm font-bold uppercase"
                    >Torna alle impostazioni</span
                >
            </div>
        </button>
    </div>
</div>
