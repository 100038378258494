import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class IubendaService {

    constructor() {
    }

    isAvailable() {
        //@ts-ignore
        return window?._iub != null;
    }

    openPreferences() {
        //@ts-ignore
        window?._iub?.cs?.ui?.openPreferences();
    }
}
