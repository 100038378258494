<health-statistic-card
    [healthTypePath]="healthTypePath"
    [title]="title"
    [value]="sumData"
    [dataType]="dataType"
    [period]="period"
    [isSingleValue]="true"
    [isLoading]="isLoading"
    [iconPath]="'../../../assets/img/monitoring/activity.png'">
</health-statistic-card>
