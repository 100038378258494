import {Component, inject, OnInit} from '@angular/core';
import {APP_ROUTES} from "../../app.routes.definition";
import {Router, RouterLink} from "@angular/router";
import {NativeAPIService, TargetPlatform} from "../../services/native/native-api.service";
import {LogService} from "../../services/log.service";
import {ModalService} from "../../services/modal.service";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {TryTerraNativeService} from "../../services/native/try-terra-native.service";
import {
    AndroidHealthConnectModalContentComponent
} from "./android-health-connect-modal-content/android-health-connect-modal-content.component";

@Component({
    selector: 'app-ask-import-health-data-page',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './ask-import-health-data-page.component.html',
    styleUrl: './ask-import-health-data-page.component.css'
})
export default class AskImportHealthDataPageComponent implements OnInit {
    #nativeAPIService = inject(NativeAPIService);
    #tryTerraNativeService = inject(TryTerraNativeService);
    #logService = inject(LogService);
    #router = inject(Router);
    #modalService = inject(ModalService);

    tutorialPath = APP_ROUTES.ASK_IMPORT_HEALTH_DATA_TUTORIAL();

    isCheckingNativePlatform = true;
    healthConnectAvailable = false;
    currentPlatform?: TargetPlatform;

    TargetPlatform = TargetPlatform;

    constructor() {
    }

    ngOnInit() {
        if (this.#nativeAPIService.isAvailable()) {
            this.#nativeAPIService.defaultTargetPlatform().then(targetPlatform => {
                this.currentPlatform = targetPlatform;
                if (targetPlatform === TargetPlatform.android) {
                    this.#tryTerraNativeService.isHealthConnectAvailable().then(healthConnectAvailable => {
                        this.healthConnectAvailable = healthConnectAvailable;
                        this.isCheckingNativePlatform = false;
                    }).catch(reason => {
                        this.#logService.error(reason);
                        this.isCheckingNativePlatform = false;
                    });
                } else {
                    this.isCheckingNativePlatform = false;
                }
            }).catch(reason => {
                this.#logService.error(reason);
                this.isCheckingNativePlatform = false;
            });
        } else {
            this.isCheckingNativePlatform = false;
        }
    }

    enableHealthConnect() {
        if (this.currentPlatform === TargetPlatform.android) {
            this.#modalService.open(AndroidHealthConnectModalContentComponent);
        }
    }

    checkAndGoNext() {
        if (this.#tryTerraNativeService.isAvailable() && this.currentPlatform === TargetPlatform.android) {
            this.#tryTerraNativeService.isHealthConnectAvailable().then(healthConnectAvailable => {
                if (healthConnectAvailable) {
                    this.#router.navigate([this.tutorialPath]);
                } else {
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                        data: {
                            message: 'L\'App "Connessione Salute" non risulta installata.'
                        }
                    });
                }
            }).catch(reason => {
                this.#logService.error(reason);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: reason,
                        message: 'Non è stato possibile verificare l\'installazione dell\'App "Connessione Salute".'
                    }
                });
            });
        } else {
            this.#router.navigate([this.tutorialPath]);
        }
    }
}
