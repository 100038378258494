import {Component, inject, OnInit} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HealthDataService} from '../../services/health-data.service';
import dayjs from 'dayjs';
import {
    HealthDataValue,
    HealthDataPeriod,
    HealthDataType,
} from '../../models/health.models';
import {APP_ROUTES} from '../../app.routes.definition';
import {LogService} from '../../services/log.service';
import {
    HealthStatisticCardComponent
} from "../health-statistic-card/health-statistic-card.component";
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'health-statistic-height-card',
    standalone: true,
    imports: [RouterModule, HealthStatisticCardComponent],
    templateUrl: './health-statistic-height-card.component.html',
    styleUrl: './health-statistic-height-card.component.css',
})
export class HealthStatisticHeightCardComponent implements OnInit {
    #healthDataService = inject(HealthDataService);
    #logService = inject(LogService);

    title = 'Altezza';
    healthTypePath = APP_ROUTES.MONITOR_BODY_HEIGHT(false);
    dataType = HealthDataType.HEIGHT;
    lastMonitoredData = '';
    dateFrom = dayjs(new Date());
    period: HealthDataPeriod = HealthDataPeriod.lastValue;
    payloadTypes: dataType[] = ['body'];
    isLoading = true;

    ngOnInit(): void {
        this.#healthDataService
            .fetchHealthUserData(
                this.dataType,
                {
                    period: this.period,
                    dateFrom: this.dateFrom.format('YYYY-MM-DD'),
                    payloadTypes: this.payloadTypes
                }
            )
            .subscribe({
                next: (response: HealthDataValue[]) => {
                    if (response.length > 0) {
                        const lastValue = response[response.length - 1];
                        const values = response.map((entry) => entry.value);
                        const filteredValues = values.filter(
                            (value) => value === null
                        );

                        if (filteredValues.length > 0) {
                            this.lastMonitoredData = '-';
                        } else {
                            this.lastMonitoredData = lastValue.value
                                .toFixed(0)
                                .toString();
                        }
                    }
                    this.isLoading = false;
                },
                error: (error) => {
                    this.#logService.error(error);
                    this.isLoading = false;
                },
            });
    }
}
