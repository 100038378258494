import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmPasswordValidator(
    passwordControl: string,
    confirmPasswordControl: string
): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
        const control = group.get(passwordControl);
        const matchingControl = group.get(confirmPasswordControl);
        if (
            matchingControl!.errors &&
            !matchingControl!.errors['confirmPasswordValidator']
        ) {
            return null;
        }
        if (control!.value !== matchingControl!.value) {
            const error = {
                confirmedValidator: 'Le password non corrispondono',
            };
            matchingControl!.setErrors(error);
            return error;
        } else {
            matchingControl!.setErrors(null);
            return null;
        }
    };
}
