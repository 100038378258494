import {Injectable, inject, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, tap} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import * as CreateOrderActions from './cancel-subscription.actions';
import {LogService} from "../../services/log.service";
import {fetchUser} from "../user/user.actions";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {ModalService} from "../../services/modal.service";
import {SubscriptionService} from "../../services/subscription.service";

@Injectable()
export class CancelSubscriptionEffects {
    #actions$ = inject(Actions);
    #subscriptionService = inject(SubscriptionService);
    #zone = inject(NgZone);
    #modalService = inject(ModalService);
    #logService = inject(LogService);

    cancelSubscription$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(CreateOrderActions.cancelSubscription),
            mergeMap((action) =>
                this.#subscriptionService.cancelSubscription(action.id).pipe(
                    mergeMap((response) => {
                        // updated also user info with new features
                        return [CreateOrderActions.cancelSubscriptionSuccess(), fetchUser()];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(CreateOrderActions.cancelSubscriptionFailure({error}));
                    })
                )
            )
        )
    );

    cancelSubscriptionSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(CreateOrderActions.cancelSubscriptionSuccess),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.close();
            });
        })
    ), {dispatch: false});

    cancelSubscriptionFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(CreateOrderActions.cancelSubscriptionFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error
                    }
                });
            });
        })
    ), {dispatch: false});
}
