import {Component, inject, OnDestroy} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';

export interface InfoModalData {
    title?: string;
    message?: string;
    buttonLabel?: string;
    onCloseCallback?: () => void;
}

@Component({
    selector: 'app-info-modal',
    standalone: true,
    imports: [],
    templateUrl: './info-modal.component.html',
})
export class InfoModalComponent implements InfoModalData, OnDestroy {
    #modalService = inject(ModalService);
    #router = inject(Router);

    title?: string;
    message?: string;
    buttonLabel?: string;
    onCloseCallback?: () => void;

    constructor() {}

    onClose() {
        this.#modalService.close();
    }

    ngOnDestroy(): void {
        if (this.onCloseCallback) this.onCloseCallback();
    }
}
