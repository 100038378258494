import {
    HttpEvent,
    HttpRequest,
    HttpInterceptorFn,
    HttpHandlerFn, HttpErrorResponse, HttpStatusCode,
} from '@angular/common/http';
import {
    Observable,
    retry,
    throwError,
    timer
} from 'rxjs';
import {environment} from '../../environments/environment';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";

export const AuthInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    if (environment.useAccessTokenAsCookie) {
        req = req.clone({
            withCredentials: true,
        });
    } else {
        const authService = inject(AuthService);

        const accessToken = authService.getAccessToken();
        const otpAccessToken = authService.getOTPAccessToken();

        if (accessToken != null || otpAccessToken != null) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken ?? otpAccessToken}`,
                },
            });
        }
    }

    return next(req).pipe(
        retry({
                count: environment.httpInterceptor.retryCount,
                delay: (error, retryCount) => {
                    if (retryCount < environment.httpInterceptor.retryCount &&
                        error instanceof HttpErrorResponse &&
                        [HttpStatusCode.RequestTimeout, HttpStatusCode.TooManyRequests,
                            HttpStatusCode.GatewayTimeout, HttpStatusCode.ServiceUnavailable].includes(error.status)) {
                        return timer(environment.httpInterceptor.retryDelay);
                    }
                    return throwError(() => error);
                }
            }
        ),
    );
};
