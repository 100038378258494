import {createReducer, on} from '@ngrx/store';
import {CancelSubscriptionState} from './cancel-subscription.state';
import {
    cancelSubscription, cancelSubscriptionFailure, cancelSubscriptionSuccess, resetCancelSubscription,
} from './cancel-subscription.actions';

const initialState: CancelSubscriptionState = {
    data: null,
    error: null,
    isLoading: false,
};

export const cancelSubscriptionReducer = createReducer(
    initialState,
    on(cancelSubscription, (state) => ({
        ...state,
        error: null,
        isLoading: true,
    })),
    on(cancelSubscriptionSuccess, (state) => ({
        ...state,
        error: null,
        isLoading: false,
    })),
    on(cancelSubscriptionFailure, (state, {error}) => ({
        ...state,
        error,
        isLoading: false,
    })),
    on(resetCancelSubscription, () => initialState),
);
