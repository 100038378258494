import { Component, inject } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { APP_ROUTES } from '../../../../app.routes.definition';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../../../../services/navigation.service';
import { NativeAPIService } from '../../../../services/native/native-api.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-consent-page',
    standalone: true,
    imports: [TranslocoPipe, RouterModule, NgIf],
    templateUrl: './consent-page.component.html',
    styleUrl: './consent-page.component.css',
})
export default class ConsentPageComponent {
    videoAndMicrophonePath = APP_ROUTES.SETTINGS_CONSENT_VIDEO_AND_MICROPHONE();
    dataHealthPermissionPath =
        APP_ROUTES.SETTINGS_CONSENT_DATA_HEALTH_PERMISSION();
    #nativeAPIService = inject(NativeAPIService);

    #navigationService = inject(NavigationService);
    nativeAPIAvailability = this.#nativeAPIService.isAvailable();

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }
}
