import { Component, inject } from '@angular/core';
import { ModalService } from '../../../../../services/modal.service';
import { Store } from '@ngrx/store';
import { cancelSubscription } from '../../../../../store/cancel-subscription/cancel-subscription.actions';
import { Product } from '../../../../../models/product.model';
import { TierCardComponent } from '../../../../../components/tier-card/tier-card.component';
import { Order, OrderItem } from '../../../../../models/order.model';

@Component({
    selector: 'app-begin-cancel-subscription-modal',
    standalone: true,
    imports: [TierCardComponent],
    templateUrl: './begin-cancel-subscription-modal.component.html',
    styleUrl: './begin-cancel-subscription-modal.component.css',
})
export class BeginCancelSubscriptionModalComponent {
    #store = inject(Store);
    #modalService = inject(ModalService);

    order!: Order;

    cancelSubscription() {
        this.#store.dispatch(
            cancelSubscription({
                id: this.order.subscriptions[0].id,
            })
        );
    }

    getProduct(orderItem: OrderItem): Product {
        return {
            ...orderItem.product,
            isActive: true,
            isFeaturedProduct: false,
            price: orderItem.unitPrice,
        };
    }

    onClose() {
        this.#modalService.close();
    }
}
