import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Product} from "../models/product.model";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class MarketplaceService {
    #apiUrl = environment.apiUrl;
    #http = inject(HttpClient);
    #products = '/products';

    constructor() {}

    getProducts(searchType: 'purchasable' | 'all' = 'all') {
        return this.#http.get<Product[]>(`${this.#apiUrl}${this.#products}?searchType=${searchType}`);
    }

    getProduct(productCode: string) {
        return this.#http.get<Product>(`${this.#apiUrl}${this.#products}/${productCode}`);
    }
}
