<div class="flex items-center justify-between w-full mt-5">
    <p class="text-lg">
        <span class="flex items-center">
            Nessun dispositivo connesso
            <img class="ms-2 img-smartwatch" src="../../../assets/img/utility/smartwatch.png" alt="" />
        </span>
        <span class="block mt-1">
            <a class="text-primary cursor-pointer underline" [routerLink]="healthProvidersPath">Collega un dispositivo</a>&nbsp;per monitorare i tuoi progressi.
        </span>
    </p>
</div>