import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";

/**
 * TryTerra Official API: https://pub.dev/packages/terra_flutter_bridge
 */
@Injectable({
    providedIn: 'root'
})
export class TryTerraNativeService {
    #nativeAPIService = inject(NativeAPIService);

    constructor() {
    }

    /**
     * Checks if the native TryTerra Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }

    initTerra(options: {
        devId: string,
        referenceID: string
    }) {
        return this.#nativeAPIService.callHandler<SuccessMessage | null>('TryTerraService.initTerra', options);
    }

    initConnection(options: {
        connection: Connection,
        token: string,
        schedulerOn?: boolean | null,
        customPermissions?: CustomPermission[] | null,
    }) {
        return this.#nativeAPIService.callHandler<SuccessMessage | null>('TryTerraService.initConnection', options);
    }

    async isConnectionTypeImplemented(options: {
        connection: Connection,
    }) {
        try {
            return this.#nativeAPIService.callHandler<boolean>('TryTerraService.isConnectionTypeImplemented', options);
        } catch (error) {
            return false;
        }
    }

    isHealthConnectAvailable() {
        return this.#nativeAPIService.callHandler<boolean>('TryTerraService.isHealthConnectAvailable');
    }

    getUserId(options: {
        connection: Connection,
    }) {
        return this.#nativeAPIService.callHandler<UserId | null>('TryTerraService.getUserId', options);
    }

    getDaily(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getDaily', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getNutrition(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getNutrition', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getBody(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getBody', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getSleep(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getSleep', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getActivity(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getActivity', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getMenstruation(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getMenstruation', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getAthlete(options: {
        connection: Connection,
        startDate: Date,
        endDate: Date,
        toWebhook?: boolean | null,
    }) {
        return this.#nativeAPIService.callHandler<DataMessage | null>('TryTerraService.getAthlete', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
        });
    }

    getGivenPermissions() {
        return this.#nativeAPIService.callHandler<string[]>('TryTerraService.getGivenPermissions');
    }
}

export interface SuccessMessage {
    success?: boolean | null;
    error?: string | null;
}

export interface DataMessage {
    data?: { [key: string]: any } | null;
    success?: boolean | null;
    error?: string | null;
}

export interface UserId {
    success?: boolean | null;
    userId?: string | null;
}

export enum Connection {
    appleHealth = 'appleHealth',
    googleFit = 'googleFit',
    samsung = 'samsung',
    healthConnect = 'healthConnect',
    freestyleLibre = 'freestyleLibre',
}

export enum PlannedWorkoutStepDurationType {
    time = 'time',
    distanceMeters = 'distanceMeters',
    hrLessThan = 'hrLessThan',
    hrGreaterThan = 'hrGreaterThan',
    calories = 'calories',
    open = 'open',
    powerLessThan = 'powerLessThan',
    powerGreaterThan = 'powerGreaterThan',
    repetitionTime = 'repetitionTime',
    reps = 'reps',
    fixedRest = 'fixedRest',
    timeAtValidCda = 'timeAtValidCda',
    steps = 'steps',
}

export enum PlannedWorkoutStepTargetType {
    speed = 'speed',
    heartRate = 'heartRate',
    open = 'open',
    cadence = 'cadence',
    power = 'power',
    grade = 'grade',
    resistance = 'resistance',
    powerLap = 'powerLap',
    swimStroke = 'swimStroke',
    speedLap = 'speedLap',
    heartRateLap = 'heartRateLap',
    pace = 'pace',
    heartRateThresholdPercentage = 'heartRateThresholdPercentage',
    heartRateMaxPercentage = 'heartRateMaxPercentage',
    speedPercentage = 'speedPercentage',
    powerPercentage = 'powerPercentage',
    repetition = 'repetition',
    tss = 'tss',
    iF = 'iF',
}

export enum TerraActivityType {
    inVehicle = 'inVehicle',
    biking = 'biking',
    still = 'still',
    unknown = 'unknown',
    tilting = 'tilting',
    walking = 'walking',
    running = 'running',
    aerobics = 'aerobics',
    badminton = 'badminton',
    baseball = 'baseball',
    basketball = 'basketball',
    biathlon = 'biathlon',
    handbiking = 'handbiking',
    mountainBiking = 'mountainBiking',
    roadBiking = 'roadBiking',
    spinning = 'spinning',
    stationaryBiking = 'stationaryBiking',
    utilityBiking = 'utilityBiking',
    boxing = 'boxing',
    calisthenics = 'calisthenics',
    circuitTraining = 'circuitTraining',
    cricket = 'cricket',
    dancing = 'dancing',
    elliptical = 'elliptical',
    fencing = 'fencing',
    americanFootball = 'americanFootball',
    australianFootball = 'australianFootball',
    englishFootball = 'englishFootball',
    frisbee = 'frisbee',
    gardening = 'gardening',
    golf = 'golf',
    gymnastics = 'gymnastics',
    handball = 'handball',
    hiking = 'hiking',
    hockey = 'hockey',
    horsebackRiding = 'horsebackRiding',
    housework = 'housework',
    jumpingRope = 'jumpingRope',
    kayaking = 'kayaking',
    kettlebellTraining = 'kettlebellTraining',
    kickboxing = 'kickboxing',
    kitesurfing = 'kitesurfing',
    martialArts = 'martialArts',
    meditation = 'meditation',
    mixedMartialArts = 'mixedMartialArts',
    p90xExercises = 'p90xExercises',
    paragliding = 'paragliding',
    pilates = 'pilates',
    polo = 'polo',
    racquetball = 'racquetball',
    rockClimbing = 'rockClimbing',
    rowing = 'rowing',
    rowingMachine = 'rowingMachine',
    rugby = 'rugby',
    jogging = 'jogging',
    runningOnSand = 'runningOnSand',
    treadmillRunning = 'treadmillRunning',
    sailing = 'sailing',
    scubaDiving = 'scubaDiving',
    skateboarding = 'skateboarding',
    skating = 'skating',
    crossSkating = 'crossSkating',
    indoorRollerblading = 'indoorRollerblading',
    skiing = 'skiing',
    backCountrySkiing = 'backCountrySkiing',
    crossCountrySkiing = 'crossCountrySkiing',
    downhillSkiing = 'downhillSkiing',
    kiteSkiing = 'kiteSkiing',
    rollerSkiing = 'rollerSkiing',
    sledding = 'sledding',
    snowboarding = 'snowboarding',
    snowmobile = 'snowmobile',
    snowshoeing = 'snowshoeing',
    squash = 'squash',
    stairClimbing = 'stairClimbing',
    stairClimbingMachine = 'stairClimbingMachine',
    standUpPaddleboarding = 'standUpPaddleboarding',
    strengthTraining = 'strengthTraining',
    surfing = 'surfing',
    swimming = 'swimming',
    swimmingSwimmingPool = 'swimmingSwimmingPool',
    swimmingOpenWater = 'swimmingOpenWater',
    tableTennis = 'tableTennis',
    teamSports = 'teamSports',
    tennis = 'tennis',
    treadmill = 'treadmill',
    volleyball = 'volleyball',
    volleyballBeach = 'volleyballBeach',
    volleyballIndoor = 'volleyballIndoor',
    wakeboarding = 'wakeboarding',
    walkingFitness = 'walkingFitness',
    nordicWalking = 'nordicWalking',
    walkingTreadmill = 'walkingTreadmill',
    waterpolo = 'waterpolo',
    weightlifting = 'weightlifting',
    wheelchair = 'wheelchair',
    windsurfing = 'windsurfing',
    yoga = 'yoga',
    zumba = 'zumba',
    diving = 'diving',
    ergometer = 'ergometer',
    iceSkating = 'iceSkating',
    indoorSkating = 'indoorSkating',
    curling = 'curling',
    other = 'other',
    crossfit = 'crossfit',
    hiit = 'hiit',
    intervalTraining = 'intervalTraining',
    walkingStroller = 'walkingStroller',
    elevator = 'elevator',
    escalator = 'escalator',
    archery = 'archery',
    softball = 'softball',
    guidedBreathing = 'guidedBreathing',
    cardioTraining = 'cardioTraining',
    lacrosse = 'lacrosse',
    stretching = 'stretching',
    triathlon = 'triathlon',
    inlineSkating = 'inlineSkating',
    skyDiving = 'skyDiving',
    paddling = 'paddling',
    mountaineering = 'mountaineering',
    fishing = 'fishing',
    waterSkiing = 'waterSkiing',
    indoorRunning = 'indoorRunning',
    padelTennis = 'padelTennis',
}

export enum CustomPermission {
    workoutTypes = 'workoutTypes',
    activitySummary = 'activitySummary',
    location = 'location',
    calories = 'calories',
    steps = 'steps',
    heartRate = 'heartRate',
    heartRateVariability = 'heartRateVariability',
    vo2max = 'vo2max',
    height = 'height',
    activeDurations = 'activeDurations',
    weight = 'weight',
    flightsClimbed = 'flightsClimbed',
    bmi = 'bmi',
    bodyFat = 'bodyFat',
    exerciseDistance = 'exerciseDistance',
    gender = 'gender',
    dateOfBirth = 'dateOfBirth',
    basalEnergyBurned = 'basalEnergyBurned',
    swimmingSummary = 'swimmingSummary',
    restingHeartRate = 'restingHeartRate',
    bloodPressure = 'bloodPressure',
    bloodGlucose = 'bloodGlucose',
    bodyTemperature = 'bodyTemperature',
    leanBodyMass = 'leanBodyMass',
    oxygenSaturation = 'oxygenSaturation',
    sleepAnalysis = 'sleepAnalysis',
    respiratoryRate = 'respiratoryRate',
    nutritionSodium = 'nutritionSodium',
    nutritionProtein = 'nutritionProtein',
    nutritionCarbohydrates = 'nutritionCarbohydrates',
    nutritionFibre = 'nutritionFibre',
    nutritionFatTotal = 'nutritionFatTotal',
    nutritionSugar = 'nutritionSugar',
    nutritionVitaminC = 'nutritionVitaminC',
    nutritionVitaminA = 'nutritionVitaminA',
    nutritionCalories = 'nutritionCalories',
    nutritionWater = 'nutritionWater',
    nutritionCholesterol = 'nutritionCholesterol',
    menstruation = 'menstruation',
    speed = 'speed',
    power = 'power',
    electrocardiogram = 'electrocardiogram',
}
