<form [formGroup]="messageForm" (ngSubmit)="onMessageFormSubmit()">
    <div class="fixed w-full bottom-0 inset-x-0 m-0-auto max-w-screen-md">
        <div class="bg-f5f5f5 p-7 flex flex-col">
            <div class="flex items-center">
                <div class="w-full">
                    <input
                        class="py-5 px-4 border border-light-grey rounded-lg w-full"
                        type="text"
                        placeholder="Scrivi qui il tuo messaggio"
                        formControlName="message"
                        [maxlength]="messageMaxLength"
                    />
                </div>
                <div>
                    <button
                        [disabled]="disabled"
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center ms-5"
                        type="submit"
                    >
                        <div class="flex items-center">
                            <svg class="w-6 h-6 fill-white">
                                <use href="../assets/svg/svGP.svg#send"></use>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
