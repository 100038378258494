import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import {
    selectUserHasFeature,
    selectUserIsLoading, selectUserTryTerraHasProviders,
    selectUserTryTerraIsGettingUserInfo, selectUserTryTerraIsNativeInitializing
} from '../../store/user/user.selectors';
import { ProductFeature } from '../../models/product.model';
import { AsyncPipe } from '@angular/common';
import { HealthStatisticHeartRateCardComponent } from '../../components/health-statistic-heart-rate-card/health-statistic-heart-rate-card.component';
import { HealthStatisticStepsCardComponent } from '../../components/health-statistic-steps-card/health-statistic-steps-card.component';
import { HealthStatisticActiveEnergyBurnedCardComponent } from '../../components/health-statistic-active-energy-burned-card/health-statistic-active-energy-burned-card.component';
import { APP_ROUTES } from '../../app.routes.definition';
import {UserService} from "../../services/user.service";
import {
    NoWearableConnectedCardComponent
} from "../../components/no-wearable-connected-card/no-wearable-connected-card.component";

@Component({
    selector: 'monitor-page',
    standalone: true,
    imports: [
        HealthStatisticHeartRateCardComponent,
        HealthStatisticStepsCardComponent,
        HealthStatisticActiveEnergyBurnedCardComponent,
        RouterModule,
        TranslocoModule,
        AsyncPipe,
        NoWearableConnectedCardComponent,
    ],
    templateUrl: './monitor-page.component.html',
    styleUrl: './monitor-page.css',
})
export default class MonitorPageComponent implements OnInit {
    #navigationService = inject(NavigationService);
    #store = inject(Store<AppState>);
    #userService = inject(UserService);

    userIsLoading$ = this.#store.select(selectUserIsLoading);
    userTryTerraIsLoading$ = this.#store.select(selectUserTryTerraIsGettingUserInfo);
    userTryTerraHasProviders$ = this.#store.select(selectUserTryTerraHasProviders);
    userTryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);

    hasTodayFeelingSurvey = true;

    heartPath = APP_ROUTES.MONITOR_HEART(true);
    bodyPath = APP_ROUTES.MONITOR_BODY(true);
    activityPath = APP_ROUTES.MONITOR_ACTIVITY(true);
    sleepPath = APP_ROUTES.MONITOR_SLEEP(true);
    feelingPath = APP_ROUTES.FEELING();
    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();

    constructor() {}

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute(true);

        this.#userService.hasTodayFeelingSurvey().subscribe(value => {
            this.hasTodayFeelingSurvey = value;
        });
    }
}
