import { Component, inject, HostListener, OnInit } from '@angular/core';
import { RegistrationFormComponent } from '../../components/registration-form/registration-form.component';
import { RegistrationDashIconComponent } from '../../components/registration-dash-icon/registration-dash-icon.component';
import { environment } from '../../../environments/environment';
import { TranslocoModule } from '@ngneat/transloco';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { VerifyOtpForm } from '../../models/formgroups/verify-otp-form.model';
import { VerifyRecoveryCodeForm } from '../../models/formgroups/verify-recovery-code-form.model';
import ActivateAccountPageComponent from '../activate-account-page/activate-account-page.component';
import { UserService } from '../../services/user.service';
import CarouselComponent, { CarouselSlide } from "../../components/carousel/carousel.component";
import { APP_ROUTES } from "../../app.routes.definition";
import { NativeAPIService, TargetPlatform } from "../../services/native/native-api.service";
import { LogService } from "../../services/log.service";
import { NgClass, NgIf } from "@angular/common";
import { IubendaService } from "../../services/iubenda.service";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";

@Component({
    selector: 'auth-page',
    standalone: true,
    imports: [
        ActivateAccountPageComponent,
        RegistrationFormComponent,
        RegistrationDashIconComponent,
        TranslocoModule,
        ReactiveFormsModule,
        CarouselComponent,
        RouterLink,
        NgIf,
        NgClass,
    ],
    templateUrl: './auth-page.component.html',
    styleUrl: './auth-page.component.css',
})
export default class AuthPageComponent {
    #fb = inject(FormBuilder);
    #userService = inject(UserService);
    #nativeAPIService = inject(NativeAPIService);
    #iubendaService = inject(IubendaService);
    #googleAnalyticsService = inject(GoogleAnalyticsService);

    #numericPattern: string = '[0-9]*';
    isRegistrationCompleted = false;
    loginPath = APP_ROUTES.LOGIN();
    registerPath = APP_ROUTES.REGISTER();

    verifyOtpForm!: FormGroup<VerifyOtpForm>;
    verifyRecoveryCodeForm!: FormGroup<VerifyRecoveryCodeForm>;

    slideHeight = 420;
    slides: CarouselSlide[] = [
        {
            img: '../assets/img/registration-carousel/v2/step1.png',
            text: '<strong>Combina monitoraggio dati e soluzioni personalizzate per il tuo benessere.</strong>',
        },
        {
            img: '../assets/img/registration-carousel/v2/step2.png',
            text: '<strong>Segui i tuoi progressi e ritrova il tuo benessere in uno spazio dedicato.</strong>',
        },
        {
            img: '../assets/img/registration-carousel/v2/step3.png',
            text: '<strong>Gestisci le tue sessioni in pochi click: programma e partecipa quando vuoi.</strong>',
        },
        {
            img: '../assets/img/registration-carousel/v2/step4.png',
            text: '<strong>Connettiti in ogni momento: chat e video con i nostri professionisti della salute.</strong>',
        },
        {
            img: '../assets/img/registration-carousel/v2/step5.png',
            text: '<strong>I tuoi dati vitali sempre sotto controllo del tuo professionista della salute.</strong>',
        },
        {
            img: '../assets/img/registration-carousel/v2/step6.png',
            text: '<strong>Personalizza il tuo percorso con soluzioni aggiuntive su misura per te.</strong>',
        }
    ];
    isNativeAPIAvailable = this.#nativeAPIService.isAvailable();
    storeUrl = environment.storeUrl;

    constructor() {
        this.initializeForm();
        this.isRegistrationCompleted = this.#userService.isRegistered;
    }

    initializeForm() {
        this.verifyOtpForm = this.#fb.nonNullable.group({
            otp: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.#numericPattern),
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
            ],
        });
        this.verifyRecoveryCodeForm = this.#fb.nonNullable.group({
            recoveryCode: ['', [Validators.required]],
        });
    }

    iubendaIsAvailable() {
        return this.#iubendaService.isAvailable();
    }

    openIubendaPref($event: MouseEvent) {
        $event.preventDefault();
        this.#iubendaService.openPreferences();
    }

    sendGoogleAnalyticsClickLoginEvent() {
        this.#googleAnalyticsService.sendAnalyticsEvent({
            eventName: 'login',
        });
    }

    sendGoogleAnalyticsClickRegisterEvent() {
        this.#googleAnalyticsService.sendAnalyticsEvent({
            eventName: 'inizia_percorso',
        });
    }
}
