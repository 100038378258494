@if (showEmailValidationForm) {
    <section class="flex flex-col h-screen">
        <div class="flex items-center justify-start pb-6 px-3 basis-1/12">
            <div
                class="p-2 border border-white rounded-full mt-3"
                [routerLink]="loginPath"
            >
                <svg class="w-4 h-4 fill-primary">
                    <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
            </div>
        </div>
        <div class="basis-3/12 p-10 pt-0">
            <img
                src="../../../assets/img/illustrations/illustration-forgot.png"
                alt=""
            />
        </div>
        <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl basis-8/12">
            <h1 class="text-primary font-semibold text-3xl">Recupera password</h1>
            <p class="mt-3">Inserisci la tua mail per recuperare la password</p>
            <form [formGroup]="verifyEmailForm">
                <div class="flex flex-col mt-3.5">
                    <input
                        class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5"
                        type="text"
                        id="email"
                        [disabled]="actionCompleted"
                        placeholder="{{'pages.reset-password-page.emailLabel' | transloco}}"
                        formControlName="email"
                    />
                    <!-- Gestione errore campo form email da stilizzare -->
                    @if (verifyEmailForm.controls['email'].invalid && verifyEmailForm.controls['email'].touched) {
                        <span class="text-danger text-sm">Per favore inserisci una email valida</span>
                    }
                    <!-- Fine gestion errore campo email -->
                    <button
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                        [disabled]="verifyEmailForm.invalid || actionCompleted"
                        (click)="onSubmitVerifyEmail()"
                    >
                        <div class="flex items-center">
                            @if (isLoading) {
                                <span class="button-loader"></span>
                            } @else {
                                <span
                                    class="text-white text-sm font-bold uppercase">{{ "pages.reset-password-page.sendEmailLabel"| transloco }}</span>
                            }
                        </div>
                    </button>
                    <div
                        class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center"
                    >
                        <a class="flex items-center" [routerLink]="loginPath">
                            <span class="text-primary text-sm font-bold">{{actionCompleted ? 'Accedi' : 'Annulla operazione'}}</span>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </section>
} @else {
    <section class="flex flex-col h-screen">
        <div class="flex items-center justify-start pb-6 px-3 basis-1/12">
            <div
                class="p-2 border border-white rounded-full mt-3"
                [routerLink]="loginPath"
            >
                <svg class="w-4 h-4 fill-primary">
                    <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
            </div>
        </div>
        <div class="basis-3/12 p-16 pt-5">
            <img
                src="../../../assets/img/illustrations/illustration-newpassword.png"
                alt=""
            />
        </div>
        <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl basis-8/12">
            <h1 class="text-primary font-semibold text-3xl">Crea nuova password</h1>
            <p class="mt-3">
                Inserisci la tua nuova password. Assicurati che contenga almeno 8 caratteri.
            </p>
            <form [formGroup]="newPasswordForm">
                <div class="flex flex-col mt-3.5">
                    <input
                        class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5"
                        type="password"
                        id="newPassword"
                        [disabled]="actionCompleted"
                        placeholder="{{
                        'pages.reset-password-page.newPasswordLabel' | transloco
                    }}"
                        formControlName="newPassword"
                        autocomplete="new-password"
                    />
                    <!-- Gestione errore campo form password da stilizzare -->
                    @if (newPasswordForm.controls['newPassword'].invalid && newPasswordForm.controls['newPassword'].touched) {
                        <span
                            class="text-danger text-sm">Per favore inserisci una password di almeno 8  caratteri</span>
                    }
                    <!-- fine  gestione errore campo form password -->
                    <input
                        class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5"
                        type="password"
                        id="confirmPassword"
                        [disabled]="actionCompleted"
                        placeholder="{{'pages.reset-password-page.confirmPasswordLabel'| transloco}}"
                        formControlName="confirmPassword"
                        autocomplete="new-password"
                    />
                    <!-- Gestione errore campo form confirmPassword da stilizzare -->
                    @if (newPasswordForm.controls['confirmPassword'].errors && newPasswordForm.controls['confirmPassword'].touched) {
                        <span
                            class="text-danger text-sm">{{ newPasswordForm.controls["confirmPassword"].errors["confirmedValidator"] }}</span>
                    }
                    <!-- Fine gestione errore campo form confirmPassword -->
                    <button
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                        [disabled]="newPasswordForm.invalid || actionCompleted"
                        (click)="onSubmitConfirmPassword()"
                    >
                        <div class="flex items-center">
                            @if (isLoading) {
                                <span class="button-loader"></span>
                            } @else {
                                <span
                                    class="text-white text-sm font-bold uppercase">{{ "pages.reset-password-page." + (actionCompleted ? "login" : "confirmPasswordLabel") | transloco }}</span>
                            }
                        </div>
                    </button>
                </div>
            </form>
        </div>
    </section>
}
