@if (user$ | async; as user) {
    <div class="flex flex-col h-full relative">
        <div
            class="flex flex-col items-center justify-center mb-3 custom-bg-illustration-red"
        >
            <svg class="size-20 fill-danger">
                <use href="../assets/svg/svGP.svg#warning-circle"></use>
            </svg>
        </div>
        <div class="custom-bg-white py-7 px-6 relative pb-32 h-full">
            <div class="flex flex-col h-full justify-between">
                <div>
                    <div class="bg-light-linear rounded-lg py-4 px-3 mt-2 card-shadow-bottom">
                        <div class="flex items-center w-full">
                            <div>
                                <div class="flex items-center justify-between">
                                    <div>
                                        <h2 class="font-bold text-base">
                                            {{ user.firstName }} {{ user.lastName }}
                                        </h2>
                                        <div class="flex items-center">
                                            <div class="me-2">
                                                <svg class="size-4 fill-primary">
                                                    <use
                                                        href="../assets/svg/svGP.svg#mail"
                                                    ></use>
                                                </svg>
                                            </div>
                                            <div>
                                                <span class="text-9f9d9d">{{ user.email }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <h1 class="font-semibold text-xl">
                            Vuoi davvero eliminare il tuo profilo?
                        </h1>
                    </div>
                    <div class="mt-5">
                        <p class="m-0">
                            Se prosegui con l'eliminazione del tuo profilo, tutti i tuoi dati saranno cancellati e non potranno essere recuperati.
                        </p>
                    </div>
                    @if (userHasActiveSubscriptions$ | async) {
                        <div class="mt-5">
                            <p class="m-0 text-danger">
                                Attenzione, prima di poter procedere alla eliminazione del tuo profilo, devi annullare tutti gli
                                <a class="underline" [routerLink]="subscriptionsPath">abbonamenti attivi</a>!
                            </p>
                        </div>
                    }
                </div>
                <form [formGroup]="deleteAccountForm" (submit)="deleteAccount()">
                    <div class="flex flex-col mt-5" *ngIf="!(hasLoginProvider$ | async)">
                        <label for="password" class="text-595959 font-bold text-sm">Inserisci la password *</label>
                        <input
                            formControlName="password"
                            type="password"
                            class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full"
                            id="password"
                            placeholder="Inserisci password"
                            autocomplete="current-password"
                        />
                    </div>
                    <div class="mt-5">
                        <button
                            [disabled]="(userIsLoading$ | async) || (userHasActiveSubscriptions$ | async) || deleteAccountForm.invalid"
                            type="submit"
                            class="py-5 px-4 border-0 bg-danger rounded-lg flex items-center justify-center w-full"
                        >
                            <div>
                                @if (userIsLoading$ | async) {
                                    <span class="button-loader"></span>
                                } @else {
                                    <span class="text-white text-sm font-bold uppercase">Elimina profilo</span>
                                }
                            </div>
                        </button>
                        <button
                            [disabled]="userIsLoading$ | async"
                            (click)="goBack()"
                            type="button"
                            class="mt-3 py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
                        >
                            <div>
                                <span class="text-danger text-sm font-bold">Annulla operazione</span>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
}
