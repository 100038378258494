import {Component, inject, OnInit} from '@angular/core';
import {ChatConversation} from '../../models/chat.models';
import {NavigationService} from '../../services/navigation.service';
import {AsyncPipe, CommonModule, DatePipe} from '@angular/common';
import LoaderComponent from '../../components/loader/loader.component';
import {LogService} from '../../services/log.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ChatService} from "../../services/chat.service";
import {ChatConversationCardComponent} from "../../components/chat-conversation-card/chat-conversation-card.component";
import {AgoraChat} from "agora-chat";

@Component({
    selector: 'messaging-page',
    standalone: true,
    imports: [CommonModule, AsyncPipe, DatePipe, LoaderComponent, ChatConversationCardComponent],
    templateUrl: './messaging-page.component.html',
    styleUrl: './messaging-page.component.css',
})
export default class MessagingPageComponent implements OnInit {
    #navigationService = inject(NavigationService);
    #logService = inject(LogService);
    #chatService = inject(ChatService);

    loading = true;
    conversations: ChatConversation[] = [];
    lastMessages: {
        [key: string]: AgoraChat.TextMsgBody
    } = {};

    constructor() {
        this.getConversations();
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute(true);
    }

    getConversations() {
        this.#chatService.getConversations().pipe(takeUntilDestroyed()).subscribe({
            next: (response) => {
                this.conversations = response.conversations;
                this.lastMessages = response.lastMessages;
                this.loading = false;
            },
            error: (error) => {
                this.#logService.error(error);
                this.loading = false;
            },
        });
    }
}
