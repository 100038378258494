import { Component, inject } from '@angular/core';
import { NavigationService } from '../../../../../../services/navigation.service';
import {
    NativeAPIService,
    TargetPlatform,
} from '../../../../../../services/native/native-api.service';

@Component({
    selector: 'app-video-and-microphone',
    standalone: true,
    imports: [],
    templateUrl: './data-health-permission.component.html',
})
export default class DataHealthPermissionComponent {
    #navigationService = inject(NavigationService);
    #nativeAPIService = inject(NativeAPIService);
    targetPlatform?: TargetPlatform;
    nativeAPIAvailability = this.#nativeAPIService.isAvailable();
    TargetPlatform = TargetPlatform;

    constructor() {
        if (this.nativeAPIAvailability) {
            this.#nativeAPIService.defaultTargetPlatform().then((platform) => {
                this.targetPlatform = platform;
            });
        }
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }
}
