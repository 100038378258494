@if (fullPage) {
    <section
        class="fixed w-screen h-screen overflow-hidden z-50 bg-body-bg inset-0 flex items-center justify-center full-screen"
    >
        <span class="loader"></span>
    </section>
} @else {
    <div
        class="w-full inset-0 flex items-center justify-center"
    >
        <span class="loader"></span>
    </div>
}
