import {Injectable, inject} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../store/app.state';
import {
    AuthResponse,
    LoginResponse,
} from '../models/responses/auth-response.model';
import {logout} from "../store/user/user.actions";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    #http = inject(HttpClient);
    #store = inject(Store<AppState>);

    #apiUrl = environment.apiUrl;
    #authenticate = '/authenticate';
    #recoveryCode = '/recovery-code';
    #twoFactor = '/2fa';

    // Authentication for retrieving the access token
    requestAccessToken(
        email: string,
        password: string
    ): Observable<LoginResponse> {
        return this.#http.post<LoginResponse>(
            `${this.#apiUrl}${this.#authenticate}`,
            {email, password}
        );
    }

    // 2fa Authentication for retrieving the access token
    twoFactorAuthentication(otp: string): Observable<AuthResponse> {
        return this.#http.post<AuthResponse>(
            `${this.#apiUrl}${this.#authenticate}${this.#twoFactor}`,
            {token: otp}
        );
    }

    // Login with recovery codes for retrieving the access token
    loginWithRecoveryCode(recoveryCode: string): Observable<LoginResponse> {
        return this.#http.post<LoginResponse>(
            `${this.#apiUrl}${this.#authenticate}${this.#recoveryCode}`,
            {recoveryCode: recoveryCode}
        );
    }

    setAuthenticated(accessToken: string | null) {
        if (environment.useAccessTokenAsCookie) {
            localStorage.setItem(environment.authenticatedKey, 'true');
        } else if (accessToken) {
            localStorage.setItem(environment.accessTokenKey, accessToken);
        }
    }

    setOTPToken(otpAccessToken: string | null) {
        if (environment.useAccessTokenAsCookie) {
            sessionStorage.setItem(environment.authenticatedKey, 'true');
        } else if (otpAccessToken) {
            sessionStorage.setItem(environment.otpAccessTokenKey, otpAccessToken);
        }
    }

    getAccessToken() {
        return !environment.useAccessTokenAsCookie ? localStorage.getItem(environment.accessTokenKey) : null;
    }

    getOTPAccessToken() {
        return !environment.useAccessTokenAsCookie ? sessionStorage.getItem(environment.otpAccessTokenKey) : null;
    }

    clearLocalStorage() {
        localStorage.removeItem(environment.authenticatedKey);
        localStorage.removeItem(environment.accessTokenKey);
    }

    clearSessionStorage() {
        sessionStorage.removeItem(environment.otpAccessTokenKey);
    }

    isAuthenticated(): boolean {
        return !!localStorage.getItem(environment.useAccessTokenAsCookie ? environment.authenticatedKey : environment.accessTokenKey);
    }

    logout(): void {
        this.#store.dispatch(logout());
    }
}
