import {Injectable, inject} from '@angular/core';
import {Survey} from '../models/survey.models';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {SurveyInvitation} from "../models/survey-invitation.models";

@Injectable({
    providedIn: 'root',
})
export class SurveyInvitationService {
    #http = inject(HttpClient);

    #apiUrl = `${environment.apiUrl}/survey-invitations`;

    constructor() {
    }

    getSurvey(id: string): Observable<Survey> {
        return this.#http.get<Survey>(`${this.#apiUrl}/${id}`);
    }

    postSurvey(surveyInvitation: SurveyInvitation) {
        return this.#http.post(`${this.#apiUrl}/${surveyInvitation.surveyInvitationId}`, surveyInvitation);
    }
}
