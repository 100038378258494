import { Route } from "@angular/router";
import { UserHasProductFeatureGuard } from "../../../../guards/auth.guard";
import { ProductFeature } from "../../../../models/product.model";
import {APP_ROUTES} from "../../../../app.routes.definition";
import HeartCategoryPageComponent from "./heart-category-page.component";
import HeartRatePageComponent from "./pages/heart-rate-page/heart-rate-page.component";
import RestingHeartRatePageComponent from "./pages/resting-heart-rate-page/resting-heart-rate-page.component";

export const HEART_CATEGORY_ROUTES: Route[] = [
    {
        path: '',
        component: HeartCategoryPageComponent,
        data: {
            customSectionClass: 'bg-section-data-red'
        },
    },
    {
        path: APP_ROUTES.MONITOR_HEART_RATE(true),
        data: { title: 'Heart Rate ' },
        component: HeartRatePageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_RESTING_HEART_RATE(true),
        data: { title: 'Resting Heart Rate' },
        component: RestingHeartRatePageComponent,
    },

]
