import {CurrencyPipe, NgClass, NgIf} from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Product } from '../../models/product.model';
import { APP_ROUTES } from '../../app.routes.definition';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'product-card',
    standalone: true,
    imports: [NgClass, NgIf, RouterModule, TranslocoModule, CurrencyPipe],
    templateUrl: './product-card.component.html',
})
export class ProductCardComponent {
    @Input({required: true}) product!: Product;

    productPath = APP_ROUTES.MARKETPLACE_PRODUCT;
}
