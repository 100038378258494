<div class="basis-11/12">
    <div class="w-full h-full pb-28">
        @if (isLoading$ | async) {
            <app-loader></app-loader>
        } @else {
            @if (product$ | async; as product) {
                <div>
                    <div>
                        <!-- cambiano solo le classi in questo div per la parte in evidenza e quella normale in evidenza: flex flex-col normale: flex oppure flex flex-row-->
                        <div class="flex flex-col">
                            <div
                                *ngIf="product.imageUrl"
                                class="basis-4/12 flex items-center justify-center"
                            >
                                <img
                                    class="w-full h-full max-h-72 object-cover rounded-t-4xl"
                                    [src]="product.imageUrl"
                                    src="../assets/img/illustrations/illustration-consulenza-nutrizionale.png"
                                    alt=""
                                />
                            </div>
                            <div class="basis-8/12 py-5 px-6">
                                <div class="mt-4">
                                    <h3 class="font-bold text-lg">
                                        {{ product.name }}
                                    </h3>
                                    <p class="mt-1.5">
                                        {{ product.description }}
                                    </p>
                                    <div class="flex items-center justify-between w-full mt-6">
                                        <div>
                                            <span class="font-bold text-primary text-2xl">
                                                @if (voucher) {
                                                    <span class="text-sm line-through">{{ voucher.originalPrice | currency: '':'':(voucher.originalPrice % 1 == 0) ? '1.0-0': '1.2-2' }} €</span>
                                                    {{ voucher.discountedPrice | currency: '':'':(voucher.discountedPrice % 1 == 0) ? '1.0-0': '1.2-2' }} €
                                                } @else {
                                                    {{ product.price | currency: '':'':(product.price % 1 == 0) ? '1.0-0': '1.2-2'  }} €
                                                }
                                                <span class="text-lg">
                                                    @switch (product.family) {
                                                        @case ('Subscription') {
                                                            /
                                                            {{
                                                                "pages.marketplace-page.pages.product-details-page." + (product.subscriptionRecurringInterval == null || product.subscriptionRecurringInterval === 1 ?
                                                                    product.subscriptionRecurringPeriod :
                                                                    ('' + product.subscriptionRecurringInterval + product.subscriptionRecurringPeriod)) + "Label" | transloco
                                                            }}
                                                        }
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="userSubscription$ | async"
                                            class="flex items-center"
                                        >
                                            <div>
                                                <svg
                                                    class="size-6 fill-success me-2 translate-y-px"
                                                >
                                                    <use
                                                        href="../../../assets/svg/svGP.svg#shopping-bag-add"
                                                    ></use>
                                                </svg>
                                            </div>
                                            <div>
                                                <span class="text-sm font-bold text-success">Abbonato</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="flex items-center justify-center mt-5"
                                    *ngIf="userHasBasePlan$ | async; else upgradeButton"
                                >
                                    @if (userSubscription$ | async) {
                                        <div class="flex items-center justify-center mt-5">
                                            <button
                                                (click)="cancelSubscription()"
                                                [disabled]="isCreatingOrder$ | async"
                                                class="py-5 px-4 border-0 bg-danger rounded-lg flex items-center justify-center w-full"
                                            >
                                                <div>
                                                    <svg class="size-6 fill-white me-2">
                                                        <use
                                                            href="../../../assets/svg/svGP.svg#shopping-bag-remove"
                                                        ></use>
                                                    </svg>
                                                </div>
                                                <div>
                                                    @if (isCreatingOrder$ |async) {
                                                        <span class="button-loader"></span>
                                                    } @else {
                                                        <span class="text-white text-sm font-bold uppercase">Disdici abbonamento</span>
                                                    }
                                                </div>
                                            </button>
                                        </div>
                                    } @else {
                                        @if (!(isUserBillingInfoValid$ | async)) {
                                            <div class="flex flex-col">
                                                <p>Prima di poter effettuare qualsiasi acquisto, hai bisogno di
                                                    aggiornare i tuoi dati di fatturazione.</p>
                                                <button
                                                    [routerLink]="billingInfoPath"
                                                    [queryParams]="{isReadOnly: false, shouldRedirectBackOnSave: true}"
                                                    class="mt-3 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                                                >
                                                    <div>
                                                        <span class="text-white text-sm font-bold uppercase">Dati di fatturazione</span>
                                                    </div>
                                                </button>
                                            </div>
                                        } @else {
                                            <div class="flex flex-col w-full">
                                                <form class="mb-4" [formGroup]="discountCodeForm" (submit)="applyDiscountCode()">
                                                    <div class="flex flex-row">
                                                        <div class="relative flex-grow">
                                                            <input
                                                                id="discountCode"
                                                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                                                type="text"
                                                                formControlName="discountCode"
                                                                placeholder=" "
                                                                [readOnly]="voucher != null || (isCreatingOrder$ | async) || isApplyingDiscountCode"
                                                            />
                                                            <label
                                                                for="discountCode"
                                                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                            >Codice Sconto {{voucher ? '✔️' : ''}}</label>
                                                        </div>

                                                        @if (voucher) {
                                                            <button
                                                                class="py-5 px-4 border-0 bg-black rounded-lg flex items-center justify-center ml-1.5 mt-6"
                                                                [disabled]="(isCreatingOrder$ | async) || isApplyingDiscountCode"
                                                                (click)="removeDiscountCode()"
                                                                type="reset">
                                                                <div>
                                                                    <span class="text-white text-sm font-bold uppercase">Cancella</span>
                                                                </div>
                                                            </button>
                                                        } @else {
                                                            <button
                                                                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center ml-1.5 mt-6"
                                                                [disabled]="(isCreatingOrder$ | async) || isApplyingDiscountCode || discountCodeForm.invalid"
                                                                type="submit">
                                                                <div>
                                                                    <span class="text-white text-sm font-bold uppercase">Applica</span>
                                                                </div>
                                                            </button>
                                                        }
                                                    </div>
                                                </form>

                                                <button
                                                    (click)="createOrder()"
                                                    [disabled]="(isCreatingOrder$ | async) || isApplyingDiscountCode"
                                                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                                                >
                                                    <div>
                                                        <svg class="size-6 fill-white me-2">
                                                            <use
                                                                href="../../../assets/svg/svGP.svg#shopping-bag"
                                                            ></use>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        @if (isCreatingOrder$ | async) {
                                                            <span class="button-loader"></span>
                                                        } @else {
                                                            <span
                                                                class="text-white text-sm font-bold uppercase">Acquista</span>
                                                        }
                                                    </div>
                                                </button>
                                            </div>
                                        }
                                    }
                                </div>

                                <ng-template #upgradeButton>
                                    <div class="flex items-center justify-center mt-5">
                                        <button
                                            [routerLink]="buyBasePlanPath"
                                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                                        >
                                            <div>
                                                <svg class="size-6 fill-white me-2">
                                                    <use
                                                        href="../../../assets/svg/svGP.svg#shopping-bag"
                                                    ></use>
                                                </svg>
                                            </div>
                                            <div>
                                                <span class="text-white text-sm font-bold uppercase">
                                                    Abbonati
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            } @else {
                <div class="py-5 px-6">
                    <h1 class="text-center">Prodotto non trovato</h1>
                    <div class="flex items-center justify-center mt-5">
                        <button
                            [routerLink]="marketplacePath"
                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                        >
                            <div>
                                <svg class="size-6 fill-white me-2">
                                    <use
                                        href="../../../assets/svg/svGP.svg#shopping-bag"
                                    ></use>
                                </svg>
                            </div>
                            <div>
                                <span class="text-white text-sm font-bold uppercase">
                                    Torna al Marketplace
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            }
        }
    </div>
</div>
