import {Component, inject, OnInit} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NavigationService} from "../../services/navigation.service";
import {APP_ROUTES} from "../../app.routes.definition";

@Component({
  selector: 'app-buy-base-plan',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './buy-base-plan.component.html',
  styleUrl: './buy-base-plan.component.css'
})
export default class BuyBasePlanComponent implements OnInit {
    #navigationService = inject(NavigationService);

    descriptionPath = APP_ROUTES.BUY_BASE_PLAN_DESCRIPTION();

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute(true);
    }

}
