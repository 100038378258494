<app-loader *ngIf="loading"></app-loader>

@if (survey) {
    <section class="survey-invitation-page h-screen">
        <survey
            [survey]="survey"
            [canFirstPageGoBack]="true"
            (submitSurvey)="onSurveySubmit($event)"
            [isLoading]="postSurveyLoading"
            completeSurveyButtonLabel="Invia questionario"
        />
    </section>
} @else {
    <section class="h-screen mx-6">
        <div class="flex flex-col justify-center h-full">
            <div class="items-center">
                <div class="mt-5 text-center">
                    <h1 class="font-bold text-xl">Non è stato possibile recuperare il questionario!</h1>
                </div>
                <div class="mt-4 text-center">
                    <p class="text-sm">
                        Il questionario potrebbe non essere più disponibile, scaduto o già compilato.
                    </p>
                </div>
                <div class="mt-5">
                    <a
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                        [routerLink]="homePath">
                        <div>
                            <span class="text-white text-sm font-bold uppercase">Torna alla home</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
}

