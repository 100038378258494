<section class="px-6 relative pb-0">
    @if (surveyData) {
        <survey
            [survey]="surveyData"
            [completeSurveyButtonLabel]="'INVIA QUESTIONARIO'"
            (submitSurvey)="submitFeedback($event)"
        ></survey>
    }
    <button
        class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full text-primary"
        [routerLink]="homePath"
    >
        <div class="flex items-center justify-center w-full">
            <span class="text-sm font-bold uppercase">Salta</span>
        </div>
    </button>
</section>
