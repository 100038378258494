import {Component, Input, OnInit, inject} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {first} from 'rxjs';
import {RegistrationWithProviderForm} from '../../models/formgroups/registration-with-provider-form.model';
import {RegistrationWithProviderData} from '../../models/registration-with-provider-data.model';
import {UserService} from '../../services/user.service';
import {TranslocoModule} from '@ngneat/transloco';
import {ProviderResponseDecoded} from '../../models/provider-response-decoded.model';
import {Survey} from '../../models/survey.models';
import {jwtDecode} from 'jwt-decode';
import {InternationalPhone} from '../../models/international-phone.model';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {CountryISO} from 'ngx-intl-tel-input-gg';
import {Router} from '@angular/router';
import {RegistrationDashIconComponent} from '../registration-dash-icon/registration-dash-icon.component';
import {APP_ROUTES} from '../../app.routes.definition';
import {LogService} from '../../services/log.service';
import {ModalService} from '../../services/modal.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../error-modal/error-modal.component';
import {ageValidator} from '../../shared/validators/majority-age.validator';
import {NativeAPIService, TargetPlatform} from '../../services/native/native-api.service';
import {NoWhitespaceValidator} from '../../shared/validators/no-whitespace.validator';
import {environment} from "../../../environments/environment";
import {GoogleAnalyticsService} from "../../services/google-analytics.service";

@Component({
    selector: 'registration-with-provider-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        NgxIntlTelInputModule,
        RegistrationDashIconComponent,
    ],
    templateUrl: './registration-with-provider-form.component.html',
    styleUrl: './registration-with-provider-form.component.css',
})
export class RegistrationWithProviderFormComponent implements OnInit {
    @Input() accessToken?: string;
    #fb = inject(FormBuilder);
    #userService = inject(UserService);
    #router = inject(Router);
    #logService = inject(LogService);
    #modalService = inject(ModalService);
    #nativeAPIService = inject(NativeAPIService);
    #googleAnalyticsService = inject(GoogleAnalyticsService);

    authPath = APP_ROUTES.AUTH(true);
    isPrivateEmail = false;
    providerUserInfoDecoded?: ProviderResponseDecoded;
    preferredCountries: CountryISO[] = [CountryISO.Italy];
    registrationWithProviderForm!: FormGroup<RegistrationWithProviderForm>;
    targetPlatform?: TargetPlatform;
    TargetPlatform = TargetPlatform;
    initialSurvey?: Survey;
    isLoading = false;
    iubenda = environment.iubenda;

    constructor() {
        this.initializeForm();
        this.detectDeviceType();
    }

    ngOnInit(): void {
        if (this.accessToken) {
            this.providerUserInfoDecoded = jwtDecode(this.accessToken);
            this.isPrivateEmail =
                this.providerUserInfoDecoded.isPrivateEmail ?? false;
            this.populateForm(this.providerUserInfoDecoded);
        }
    }

    detectDeviceType() {
        if (this.#nativeAPIService.isAvailable()) {
            this.#nativeAPIService.defaultTargetPlatform().then((platform) => {
                this.targetPlatform = platform;
            });
        }
    }

    private initializeForm(): void {
        this.registrationWithProviderForm = this.#fb.nonNullable.group({
            firstName: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator()
            ]),
            lastName: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator()
            ]),
            email: this.#fb.nonNullable.control('', [
                Validators.required,
                Validators.email,
            ]),
            birthDate: this.#fb.nonNullable.control('', [
                Validators.required,
                ageValidator(),
            ]),
            mobilePhone: this.#fb.nonNullable.control(
                {} as InternationalPhone,
                [Validators.required]
            ),
            policies: this.#fb.nonNullable.control(false, [
                Validators.requiredTrue,
            ]),
            healthDataConsent: this.#fb.nonNullable.control(false, [
                Validators.requiredTrue,
            ]),
            marketingConsent: this.#fb.nonNullable.control(false),
        });
    }

    populateForm(accessTokenDecoded: ProviderResponseDecoded) {
        this.registrationWithProviderForm.patchValue({
            firstName: accessTokenDecoded.firstName,
            lastName: accessTokenDecoded.lastName,
            email: accessTokenDecoded.email,
        });
        // if the email is correct, then the user cannot change it, so disable it
        if (this.registrationWithProviderForm.controls.email.valid) {
            this.registrationWithProviderForm.controls.email.disable();
        }
    }

    onRegistrationFormSubmit(): void {
        if ((this.registrationWithProviderForm.invalid && !this.initialSurvey) || this.isLoading) return;
        this.isLoading = true;

        const {
            firstName,
            lastName,
            email,
            birthDate,
            mobilePhone,
            healthDataConsent,
            marketingConsent,
        } = this.registrationWithProviderForm.value;

        const userData: RegistrationWithProviderData = {
            firstName: firstName!,
            lastName: lastName!,
            birthDate: birthDate!,
            mobilePhone: mobilePhone!.e164Number!,
            survey: this.initialSurvey!,
            jwt: this.accessToken!,
            healthDataConsent: healthDataConsent!,
            marketingConsent: marketingConsent ?? false,
        };

        this.#googleAnalyticsService.sendAnalyticsEvent({
            eventName: 'registrazione',
            email: email
        });

        this.#userService
            .registerAccountWithProvider(userData)
            .pipe(first())
            .subscribe({
                error: (error) => {
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                            message: 'Errore durante la registrazione',
                        },
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.#userService.isRegistered = true;
                    this.#router.navigate([this.authPath]);
                    this.isLoading = false;
                },
            });
    }
}
