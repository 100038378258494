<div class="flex flex-col justify-between h-full">
    <div class="flex flex-col items-center justify-between px-6 h-full">
        <div class="flex flex-col items-center justify-center">
            
            <div [ngClass]="{ hidden: currentStep !== 0 }">
                <div>
                    <img
                        src="../../../../../assets/img/buy-base-plan-carousel/monitoraggio-continuo.png"
                        class="basis-full h-full w-full object-cover object-top rounded-3xl"
                        style="max-height: 500px"
                        alt=""
                    />
                </div>
                <div class="mt-8 text-center">
                    <h1 class="text-primary font-semibold text-xl">
                        Monitoraggio continuo
                    </h1>
                    <p class="mt-3">
                        Tieni traccia del tuo benessere 24/7 con i nostri avanzati strumenti di monitoraggio.
                    </p>
                </div>
            </div>

            <div [ngClass]="{ hidden: currentStep !== 1 }">
                <div>
                    <img
                        src="../../../../../assets/img/buy-base-plan-carousel/health-coaching.png"
                        class="basis-full h-full w-full object-cover rounded-3xl"
                        style="max-height: 500px"
                        alt=""
                    />
                </div>
                <div class="mt-8 text-center">
                    <h1 class="text-primary font-semibold text-xl">
                        Health Coaching personalizzato
                    </h1>
                    <p class="mt-3">
                        Ricevi supporto su misura dai nostri Health Coach certificati.
                    </p>
                </div>
            </div>

            <div [ngClass]="{ hidden: currentStep !== 2 }">
                <div>
                    <img
                        src="../../../../../assets/img/buy-base-plan-carousel/servizi-aggiuntivi.png"
                        class="basis-full h-full w-full object-cover rounded-3xl"
                        style="max-height: 500px"
                        alt=""
                    />
                </div>
                <div class="mt-8 text-center">
                    <h1 class="text-primary font-semibold text-xl">
                        Servizi aggiuntivi per te
                    </h1>
                    <p class="mt-3">
                        Esplora una vasta gamma di servizi extra nel nostro marketplace.
                    </p>
                </div>
            </div>

            <div class="paginator-container mt-8">
                @for(step of steps; track $index) {
                <div
                    class="paginator-item"
                    [ngClass]="{ active: currentStep === $index }"
                ></div>
                }
            </div>
        </div>
        <div class="flex items-center justify-between w-full pb-10 mt-7">
            <div class="flex items-center" *ngIf="currentStep > 0">
                <svg class="w-4 h-4 fill-primary me-2">
                    <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
                <button
                    class="text-primary text-sm font-bold"
                    (click)="previousStep()"
                >
                    Indietro
                </button>
            </div>
            <div class="flex items-center">
                <button
                    [routerLink]="selectPlanPath"
                    class="text-primary text-sm font-bold"
                >
                    Salta
                </button>
            </div>
            <div class="flex items-center">
                <button
                    class="text-primary text-sm font-bold"
                    (click)="nextStep()"
                >
                    Avanti
                </button>
                <svg class="w-4 h-4 fill-primary ms-2">
                    <use
                        href="../../../assets/svg/svGP.svg#chevron-right"
                    ></use>
                </svg>
            </div>
        </div>
    </div>
</div>
