<section
    class="bg-white border-2 border-light-linear rounded-lg py-4 px-3 mx-6 card-shadow-bottom"
>
    <div
        class="flex items-center justify-between w-full bg-light-linear rounded-lg p-1.5"
    >
        <div class="flex items-center justify-start">
            <ng-container *ngIf="isNextAppointment">
                <div class="flex items-center py-0.5 px-2.5 bg-light-green">
                    <div>
                        <svg class="fill-primary size-3.5">
                            <use href="../../../assets/svg/svGP.svg#pin"></use>
                        </svg>
                    </div>
                    <div class="ms-1.5">
                        <span class="text-primary text-xs whitespace-nowrap">In evidenza</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="flex items-center justify-end">
            <div class="flex items-center justify-start">
                <div class="flex items-center me-3">
                    <div class="flex items-center">
                        <svg class="size-3.5 appointment-icon translate-y-px">
                            <use
                                href="../../../assets/svg/svGP.svg#Appointments"
                            ></use>
                        </svg>
                    </div>
                    <div class="ms-1.5">
                        <span class="appointment-day font-bold text-xs">{{
                                appointmentDate | customDate
                            }}</span>
                    </div>
                </div>
                <div class="flex items-center me-3">
                    <div class="flex items-center">
                        <svg class="size-3.5 appointment-icon translate-y-px">
                            <use
                                href="../../../assets/svg/svGP.svg#clock"
                            ></use>
                        </svg>
                    </div>
                    <div class="ms-1.5">
                        <span class="appointment-hour text-xs font-normal">{{
                                appointmentTime
                            }}</span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isNextAppointment">
                <div class="flex items-center photo-user-container">
                    <div class="photo-user-item">
                        <img
                            class="w-5 h-5 rounded-full border border-light-grey object-cover"
                            [src]="appointment.coach!.profileImageUrl ?? defaultImg"
                            alt=""
                        />
                    </div>
                    <div class="photo-user-item">
                        <img
                            class="w-5 h-5 rounded-full border border-light-grey object-cover"
                            [src]="(userImg$ | async)?.avatar ?? defaultImg"
                            alt=""
                        />
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="flex items-center justify-start w-full mt-5">
        <ng-container *ngIf="isNextAppointment">
            <div>
                <button
                    class="flex items-center py-2 px-3 bg-primary disabled:bg-black-60 rounded-lg"
                    (click)="joinAppointment()"
                    [disabled]="!isNextAppointment || isCallTimeInvalid()"
                >
                    <div class="flex items-center">
                        <svg class="w-4 h-4 fill-white appointment-join__icon">
                            <use
                                href="../../../assets/svg/svGP.svg#videocall-cam-on"
                            ></use>
                        </svg>
                    </div>
                    <div class="ms-2">
                        <span
                            class="text-xs text-white appointment-join__text font-bold"
                        >
                            {{ "dumb-components.appointment-card.joinLabel" | transloco }}
                        </span>
                    </div>
                </button>
            </div>
            @if (isRescheduleTimeValid()) {
                <div class="ms-4">
                    <button (click)="toggleCancelSession()">
                        <span
                            class="text-xs font-bold">{{ "dumb-components.appointment-card.rescheduleCallLabel"| transloco }}</span>
                    </button>
                </div>
            }
        </ng-container>
        <ng-container *ngIf="!isNextAppointment">
            @if (isRescheduleTimeValid()) {
                <div>
                    <button
                        class="flex items-center py-2 px-3 bg-primary disabled:bg-black-60 rounded-lg"
                        (click)="toggleCancelSession()"
                    >
                        <div class="flex items-center">
                            <svg class="w-4 h-4 fill-white appointment-join__icon">
                                <use
                                    href="../../../assets/svg/svGP.svg#Appointments"
                                ></use>
                            </svg>
                        </div>
                        <div class="ms-2">
                        <span
                            class="text-xs text-white appointment-join__text font-bold"
                        >
                            {{ "dumb-components.appointment-card.rescheduleButton"| transloco }}
                        </span>
                        </div>
                    </button>
                </div>
            }
            <div class="flex items-center photo-user-container ms-2">
                <div class="photo-user-item">
                    <img
                        class="w-7 h-7 rounded-full border border-light-grey object-cover"
                        [src]="appointment.coach!.profileImageUrl ?? defaultImg"
                        alt=""
                    />
                </div>
                <div class="photo-user-item">
                    <img
                        class="w-7 h-7 rounded-full border border-light-grey object-cover"
                        [src]="(userImg$ | async)?.avatar ?? defaultImg"
                        alt=""
                    />
                </div>
            </div>
        </ng-container>
    </div>
</section>
