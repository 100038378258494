import {Component, inject} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {
    selectUserBillingInfoIsValid,
    selectUserFirstPendingOrder,
    selectUserHasBasePlan,
    selectUserSubscriptionByProductCode
} from "../../../../store/user/user.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/app.state";
import {AsyncPipe, CurrencyPipe, NgIf} from "@angular/common";
import {createOrder} from "../../../../store/create-order/create-order.actions";
import {selectCreateOrderIsLoading} from "../../../../store/create-order/create-order.selectors";
import {ProductDetailsPageStore} from "./product-details-page.store";
import {provideComponentStore} from "@ngrx/component-store";
import {firstValueFrom, Observable} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {cancelSubscription} from "../../../../store/cancel-subscription/cancel-subscription.actions";
import {Order} from "../../../../models/order.models";
import LoaderComponent from "../../../../components/loader/loader.component";
import {NavigationService} from "../../../../services/navigation.service";
import {VoucherService} from "../../../../services/voucher.service";
import {Voucher} from "../../../../models/voucher.models";
import {LogService} from "../../../../services/log.service";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ModalService} from "../../../../services/modal.service";
import ErrorModalComponent, {ErrorModalData} from "../../../../components/error-modal/error-modal.component";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";

@Component({
    selector: 'product-details',
    standalone: true,
    imports: [TranslocoModule, AsyncPipe, RouterLink, NgIf, LoaderComponent, ReactiveFormsModule, NgxIntlTelInputModule, CurrencyPipe],
    templateUrl: './product-details-page.component.html',
    styleUrl: './product-details-page.component.css',
    providers: [provideComponentStore(ProductDetailsPageStore)]
})
export default class ProductDetailsPageComponent {
    #activatedRoute = inject(ActivatedRoute);
    #store = inject(Store<AppState>);
    #componentStore = inject(ProductDetailsPageStore);
    #navigationService = inject(NavigationService);
    #router = inject(Router);
    #voucherService = inject(VoucherService);
    #fb = inject(FormBuilder);
    #modalService = inject(ModalService);
    #logService = inject(LogService);

    userHasBasePlan$ = this.#store.select(selectUserHasBasePlan);
    userSubscription$: Observable<Order | null>;
    product$ = this.#componentStore.data$;
    isLoading$ = this.#componentStore.isLoading$;
    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);
    isUserBillingInfoValid$ = this.#store.select(selectUserBillingInfoIsValid);

    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();
    billingInfoPath = APP_ROUTES.SETTINGS_BILLING_INFO();
    marketplacePath = APP_ROUTES.MARKETPLACE();

    productCode: string;
    discountCodeForm: FormGroup<{
        discountCode: FormControl<string>
    }>;
    voucher?: Voucher | null;
    isApplyingDiscountCode = false;

    constructor() {
        this.#navigationService.saveCurrentRoute();

        this.discountCodeForm = this.#fb.group({
            discountCode: this.#fb.nonNullable.control('', [Validators.required])
        });

        this.productCode = this.#activatedRoute.snapshot.params['id'] ?? '';
        this.#componentStore.fetchProduct(this.productCode);

        this.userSubscription$ = this.#store.select(selectUserSubscriptionByProductCode(this.productCode)).pipe(takeUntilDestroyed());
    }

    createOrder() {
        if (this.isApplyingDiscountCode) {
            return;
        }

        firstValueFrom(this.#store.select(selectUserFirstPendingOrder)).then(pendingOrder => {
            if (pendingOrder) {
                this.#router.navigate([APP_ROUTES.PENDING_ORDER(false, pendingOrder.id)]);
            } else {
                this.#store.dispatch(createOrder({
                    request: {
                        productSKU: this.productCode,
                        discountCode: this.voucher?.code
                    }
                }));
            }
        });
    }

    applyDiscountCode() {
        if (this.isApplyingDiscountCode || this.discountCodeForm.invalid) {
            return;
        }
        this.isApplyingDiscountCode = true;
        this.voucher = null;
        const discountCode = this.discountCodeForm.controls.discountCode.value.trim();
        this.#voucherService.getOne(discountCode, this.productCode).subscribe({
            next: voucher => {
                this.voucher = voucher;
                this.#logService.log('Discount code applied', voucher);
                this.isApplyingDiscountCode = false;
            },
            error: error => {
                this.#logService.error('Failed to apply discount code', error);
                this.isApplyingDiscountCode = false;
                this.discountCodeForm.reset();
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                            message: 'Il codice sconto inserito non è valido oppure è scaduto.',
                        },
                    }
                );
            }
        });
    }

    removeDiscountCode() {
        this.voucher = null;
        this.discountCodeForm.reset();
    }

    cancelSubscription() {
        firstValueFrom(this.userSubscription$).then(order => {
            if (!order || order.subscriptions.length === 0) return;
            this.#store.dispatch(cancelSubscription({
                id: order.subscriptions[0].id
            }));
        });
    }
}
