import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild,} from '@angular/core';
import {
    ActivityAggregation,
    HealthChartConfiguration,
    HealthChartPeriod,
    HealthDataType,
    HealthDataValue
} from '../../models/health.models';
import {Chart, ChartItem, registerables, TooltipItem} from 'chart.js';
import dayjs from 'dayjs';

export type HealthChartDataType = undefined | number | { x: string, y: number } | {
    x: undefined,
    y: undefined
};

@Component({
    selector: 'health-activity-aggregation-chart',
    standalone: true,
    imports: [],
    templateUrl: './health-activity-aggregation-chart.component.html',
})
export class HealthActivityAggregationChartComponent implements OnChanges, AfterViewInit {
    @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild('chartContainer') chartContainer!: ElementRef<HTMLDivElement>;
    @Input() healthDataValues: ActivityAggregation[] = [];
    @Input() chartPeriod: HealthChartPeriod = HealthChartPeriod.day;
    @Input() dateFrom = dayjs(new Date());
    @Input() isLoading = true;

    chartInstance: Chart | null = null;

    constructor() {
        Chart.register(...registerables);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.chartCanvas) {
            if (changes['isLoading']?.currentValue && this.chartInstance) {
                this.chartInstance.data.datasets = [];
                this.chartInstance.data.labels = [];
                this.chartInstance.update();
            } else if (!this.isLoading) {
                this.upsertChart(this.healthDataValues);
            }
        }
    }

    ngAfterViewInit() {
        this.upsertChart(this.healthDataValues);
    }

    upsertChart(data: ActivityAggregation[]) {
        let labels: string[] = [];
        const durationValues: HealthChartDataType[] = [];
        const avgHeartBeatValues: HealthChartDataType[] = [];
        const groupedData = this.groupByKey(data, 'date');

        switch (this.chartPeriod) {
            case 'week':
                for (let i = 1; i < 8; i++) {
                    const date = dayjs(this.dateFrom)
                        .startOf('week')
                        .add(i - 1, 'day');
                    labels.push(date.format('dddd'));
                    const dayData = data.find(
                        (entry) => dayjs(entry.date).day() === i % 7
                    );

                    if (dayData) {
                        durationValues.push({
                            x: date.toString(),
                            y: Math.floor(groupedData[dayData.date].reduce((a, b) => a + b.totalDurationSeconds, 0))
                        });

                        const avgValue = groupedData[dayData.date].reduce((a, b) => a + b.averageHeartRate, 0) / groupedData[dayData.date].length;
                        avgHeartBeatValues.push({
                            x: date.toString(),
                            y: avgValue
                        });
                    } else {
                        durationValues.push({x: undefined, y: undefined});
                    }
                }
                break;
            case 'month':
                const daysInMonth = dayjs(this.dateFrom).daysInMonth();
                for (let i = 0; i < daysInMonth; i++) {
                    const date = dayjs(this.dateFrom)
                        .startOf('month')
                        .add(i, 'day');
                    labels.push(date.format('D'));
                    const dayData = data.find(
                        (entry) => dayjs(entry.date).date() === i + 1
                    );
                    if (dayData) {
                        durationValues.push({
                            x: date.toString(),
                            y: Math.floor(groupedData[dayData.date].reduce((a, b) => a + b.totalDurationSeconds, 0))
                        });

                        const avgValue = groupedData[dayData.date].reduce((a, b) => a + b.averageHeartRate, 0) / groupedData[dayData.date].length;
                        avgHeartBeatValues.push({
                            x: date.toString(),
                            y: avgValue
                        });
                    } else {
                        durationValues.push({x: undefined, y: undefined});
                    }
                }
                break;
        }


        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        const ctx = this.chartCanvas.nativeElement.getContext('2d');
        const myChart = ctx as ChartItem;

        const noDataPlugin = {
            id: 'noDataPlugin',
            beforeDraw: (chart: any) => {
                if (
                    chart.data.datasets.length === 0 ||
                    chart.data.datasets[0].data.length === 0 ||
                    chart.data.datasets[0].data.filter((entry: HealthChartDataType) => typeof entry === 'number' || (
                        typeof entry === 'object' && entry.x !== undefined && entry.y !== undefined)).length === 0
                ) {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    if (this.isLoading) {
                        ctx.fillText(
                            'Caricamento dati..',
                            width / 2,
                            height / 2
                        );
                    } else {
                        ctx.fillText(
                            'Nessun dato disponibile',
                            width / 2,
                            height / 2
                        );
                    }
                    ctx.restore();
                    return;
                }
            },
        };

        // @ts-ignore
        this.chartInstance = new Chart(myChart, {
            data: {
                labels: this.chartPeriod !== HealthChartPeriod.day ? labels : undefined,
                datasets: [
                    {
                        type: 'bar',
                        order: 1,
                        label: 'Durata Allenamento',
                        data: durationValues,
                        borderWidth: 1,
                        spanGaps: true,
                        // This binds the dataset to the left y axis
                        yAxisID: 'left-y-axis'
                    },
                    {
                        type: 'line',
                        order: 0,
                        label: 'Media Battito',
                        data: avgHeartBeatValues,
                        borderWidth: 1,
                        spanGaps: true,
                        // This binds the dataset to the right y axis
                        yAxisID: 'right-y-axis'
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    x: this.chartPeriod === HealthChartPeriod.day ? {
                        type: 'time',
                        min: this.dateFrom.startOf('day').toString(),
                        max: this.dateFrom.endOf('day').toString(),
                        beginAtZero: true,
                        time: {
                            unit: 'hour',
                            stepSize: 1,
                            tooltipFormat: 'HH:mm',
                            displayFormats: {
                                hour: 'HH:mm'
                            }
                        },
                    } : {
                        type: 'time',
                        min: this.chartPeriod === HealthChartPeriod.week ? this.dateFrom.startOf('week').toString() : this.dateFrom.startOf('month').toString(),
                        max: this.chartPeriod === HealthChartPeriod.week ? this.dateFrom.endOf('week').toString() : this.dateFrom.endOf('month').toString(),
                        time: this.chartPeriod === HealthChartPeriod.week ? {
                            unit: 'day',
                            stepSize: 1,
                            tooltipFormat: 'dddd',
                            displayFormats: {
                                day: 'dddd'
                            }
                        } : {
                            unit: 'day',
                            stepSize: 1,
                            tooltipFormat: 'D',
                            displayFormats: {
                                day: 'D'
                            }
                        }
                    },
                    'left-y-axis': {
                        type: 'linear',
                        position: 'left',
                        beginAtZero: true,
                        ticks: {
                            callback: (tickValue: any, index: any, ticks: any) => {
                                if (typeof tickValue === 'number') {
                                    return this.formatTime((tickValue as number));
                                }
                                return tickValue;
                            }
                        }
                    },
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        beginAtZero: true,
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems: TooltipItem<any>[]): string | string[] | void => {
                                if (tooltipItems.length > 0 && this.chartPeriod === HealthChartPeriod.day) {
                                    let rawValue: string | null = null;
                                    if (typeof tooltipItems[0].raw === 'string') {
                                        rawValue = tooltipItems[0].raw;
                                    } else if (typeof (tooltipItems[0].raw as any)?.x === 'string') {
                                        rawValue = (tooltipItems[0].raw as any).x;
                                    }
                                    if (rawValue != null) {
                                        const date = dayjs(rawValue);
                                        if (date.isValid()) {
                                            return `${date.startOf('hour').format('HH:mm')} - ${date.endOf('hour').format('HH:mm')}`;
                                        }
                                    }
                                }
                            },
                            label: (tooltipItem: TooltipItem<any>): string | string[] | void => {
                                let rawValue: number | null = null;
                                if (typeof tooltipItem.raw === 'number') {
                                    rawValue = tooltipItem.raw;
                                } else if (typeof (tooltipItem.raw as any)?.y === 'number') {
                                    rawValue = (tooltipItem.raw as any).y;
                                }
                                if (rawValue != null) {
                                    if (tooltipItem.datasetIndex === 0) {
                                        return `Durata Allenamento: ${this.formatTime(rawValue)} ore`;
                                    } else if (tooltipItem.datasetIndex === 1) {
                                        return `Battito Medio: ${Math.round(rawValue)} bpm`;
                                    }
                                }
                            }
                        }
                    }
                }
            },
            plugins: [noDataPlugin],
        });
    }

    private formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    private groupByKey<T extends { [key: string]: any }>(array: T[], key: string): { [key: string]: T[] } {
        return array
            .reduce((hash: { [key: string]: any }, obj) => {
                if (obj[key] === undefined) return hash;
                return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
            }, {}) as { [key: string]: T[] }
    }
}
