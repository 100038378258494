import {Component, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {loginOtp, loginRecoveryCode} from "../../../../store/user/user.actions";
import {VerifyOtpForm} from "../../../../models/formgroups/verify-otp-form.model";
import {VerifyRecoveryCodeForm} from "../../../../models/formgroups/verify-recovery-code-form.model";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/app.state";
import {selectUserError} from "../../../../store/user/user.selectors";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {APP_ROUTES} from "../../../../app.routes.definition";

@Component({
    selector: 'verify-otp',
    standalone: true,
    imports: [TranslocoModule, ReactiveFormsModule],
    templateUrl: './verify-otp.component.html',
    styleUrl: './verify-otp.component.css'
})
export default class VerifyOtpComponent {
    #router = inject(Router);
    #fb = inject(FormBuilder);
    #route = inject(Router);
    #store = inject(Store<AppState>);

    loginError$ = this.#store.select(selectUserError);
    errorMessage: string | null = null;

    #numericPattern: string = '[0-9]*';
    #loginPath = APP_ROUTES.LOGIN();

    verifyOtpForm!: FormGroup<VerifyOtpForm>;
    verifyRecoveryCodeForm!: FormGroup<VerifyRecoveryCodeForm>;

    constructor() {
        this.initializeForm();

        this.loginError$.pipe(takeUntilDestroyed()).subscribe(value => {
            if (value != null) {
                if (value.status >= 400 && value.status < 500) {
                    this.errorMessage =
                        'Codice OTP errato. Si prega di riprovare.';
                } else if (value.status >= 500) {
                    // Use else if for the second condition
                    this.errorMessage =
                        'Ops, si è verificato un errore, contattare il supporto';
                }
            } else {
                this.errorMessage = '';
            }
        });
    }

    initializeForm() {
        this.verifyOtpForm = this.#fb.nonNullable.group({
            otp: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.#numericPattern),
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
            ],
        });
        this.verifyRecoveryCodeForm = this.#fb.nonNullable.group({
            recoveryCode: ['', [Validators.required]],
        });
    }

    onSubmitVerifyOtp() {
        if (this.verifyOtpForm.invalid) return;
        const {otp} = this.verifyOtpForm.value;

        this.#store.dispatch(loginOtp({otp: otp!}));
    }

    onSubmitVerifyRecoveryCode() {
        if (this.verifyRecoveryCodeForm.invalid) return;
        const {recoveryCode} = this.verifyRecoveryCodeForm.value;

        this.#store.dispatch(loginRecoveryCode({recoveryCode: recoveryCode!}));
    }

    goToLoginPage() {
        this.#router.navigate([this.#loginPath]);
    }
}
