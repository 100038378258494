import {Component, Input, inject} from '@angular/core';
import {AsyncPipe, CommonModule} from '@angular/common';
import {CustomDatePipe} from '../../shared/pipes/custom-date.pipe';
import {TranslocoModule} from '@ngneat/transloco';
import {AppState} from '../../store/app.state';
import {Store} from '@ngrx/store';
import {selectUserHasFeature} from '../../store/user/user.selectors';
import {Router} from "@angular/router";
import {ProductFeature} from "../../models/product.model";
import {ChatConversation, PayloadChat} from "../../models/chat.models";
import {APP_ROUTES} from "../../app.routes.definition";
import {firstValueFrom} from "rxjs";
import {AgoraChat} from "agora-chat";
import dayjs from "dayjs";

@Component({
    selector: 'chat-conversation-card',
    standalone: true,
    imports: [CommonModule, CustomDatePipe, TranslocoModule, AsyncPipe],
    templateUrl: './chat-conversation-card.component.html',
    styleUrl: './chat-conversation-card.component.css',
})
export class ChatConversationCardComponent {
    @Input({required: true}) data!: {
        conversation: ChatConversation;
        lastMessage?: AgoraChat.TextMsgBody | null;
    };

    #router = inject(Router);
    #store = inject(Store<AppState>);
    defaultCoachImg = '/assets/img/pic/profile.png';

    hasFeatureChatEnabled$ = this.#store.select(
        selectUserHasFeature(ProductFeature.CHAT)
    );

    openChatWithUser(user: ChatConversation): void {
        let payloadForChat: PayloadChat = {
            userId: user.contactId,
            userName: user.coachName,
            userAvatar: user.coachImageURL,
        };
        this.#router.navigate(
            [APP_ROUTES.MESSAGING_ID(false, user.contactId)],
            {state: {data: payloadForChat}}
        );
    }

    openFreeConversation(conversation: ChatConversation): void {
        let payloadForChat: PayloadChat = {
            userId: conversation.contactId,
            userName: conversation.coachName,
            userAvatar: conversation.coachImageURL,
        };
        this.#router.navigate(
            [APP_ROUTES.FREE_MESSAGING(false, conversation.contactId)],
            {state: {data: payloadForChat}}
        );
    }

    openConversation(conversation: ChatConversation) {
        firstValueFrom(this.hasFeatureChatEnabled$).then((value) => {
            if (value) {
                this.openChatWithUser(conversation);
            } else {
                this.openFreeConversation(conversation);
            }
        });
    }

    getDateLabel(messageDate: string | number): string {
        const dayDate = dayjs(messageDate);
        if (!dayDate.isValid()) return '';
        if (dayDate.isToday()) {
            return dayDate.format('HH:mm');
        } else if (dayDate.isYesterday()) {
            return 'Ieri, ' + dayDate.format('HH:mm');
        }
        return dayDate.format('DD/MM/YY');
    }
}
