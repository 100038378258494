import {Component, inject, OnInit} from '@angular/core';
import {RegistrationFormComponent} from '../../components/registration-form/registration-form.component';
import {RegistrationDashIconComponent} from '../../components/registration-dash-icon/registration-dash-icon.component';
import {environment} from '../../../environments/environment';
import {TranslocoModule} from '@ngneat/transloco';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators,} from '@angular/forms';
import {Router, RouterLink} from '@angular/router';
import {VerifyOtpForm} from '../../models/formgroups/verify-otp-form.model';
import {VerifyRecoveryCodeForm} from '../../models/formgroups/verify-recovery-code-form.model';
import ActivateAccountPageComponent from '../activate-account-page/activate-account-page.component';
import {UserService} from '../../services/user.service';
import CarouselComponent, {CarouselSlide} from "../../components/carousel/carousel.component";
import {APP_ROUTES} from "../../app.routes.definition";
import {NativeAPIService, TargetPlatform} from "../../services/native/native-api.service";
import {LogService} from "../../services/log.service";
import {NgClass, NgIf} from "@angular/common";
import {IubendaService} from "../../services/iubenda.service";
import {GoogleAnalyticsService} from "../../services/google-analytics.service";

@Component({
    selector: 'auth-page',
    standalone: true,
    imports: [
        ActivateAccountPageComponent,
        RegistrationFormComponent,
        RegistrationDashIconComponent,
        TranslocoModule,
        ReactiveFormsModule,
        CarouselComponent,
        RouterLink,
        NgIf,
        NgClass,
    ],
    templateUrl: './auth-page.component.html',
    styleUrl: './auth-page.component.css',
})
export default class AuthPageComponent {
    #fb = inject(FormBuilder);
    #userService = inject(UserService);
    #nativeAPIService = inject(NativeAPIService);
    #iubendaService = inject(IubendaService);
    #googleAnalyticsService = inject(GoogleAnalyticsService);

    #numericPattern: string = '[0-9]*';
    isRegistrationCompleted = false;
    loginPath = APP_ROUTES.LOGIN();
    registerPath = APP_ROUTES.SURVEY();

    verifyOtpForm!: FormGroup<VerifyOtpForm>;
    verifyRecoveryCodeForm!: FormGroup<VerifyRecoveryCodeForm>;

    slides: CarouselSlide[] = [
        {
            img: '../assets/img/registration-carousel/coach.png',
            title: 'Benvenuto in<br/><strong class="font-semibold">Be Our Best</strong>',
            text: '<strong>Health Coaching</strong> per il benessere mentale e fisico, personalizzato per te.',
        },
        {
            img: '../assets/img/registration-carousel/migliora-salute.jpeg',
            title: 'Migliora la tua salute',
            text: 'Trasforma la tua vita con il monitoraggio avanzato della salute.'
        },
        {
            img: '../assets/img/registration-carousel/ritrova-equilibrio.jpg',
            title: 'Ritrova l\'equilibrio',
            text: 'Migliora il tuo stile di vita e ritrova l\'armonia.'
        },
        {
            img: '../assets/img/registration-carousel/raggiungi-obiettivi.jpeg',
            title: 'Raggiungi i tuoi obiettivi',
            text: 'Affidati agli Health Coach, il tuo successo è a portata di mano.'
        }
    ];
    isNativeAPIAvailable = this.#nativeAPIService.isAvailable();
    storeUrl = environment.storeUrl;

    constructor() {
        this.initializeForm();
        this.isRegistrationCompleted = this.#userService.isRegistered;
    }

    initializeForm() {
        this.verifyOtpForm = this.#fb.nonNullable.group({
            otp: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.#numericPattern),
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
            ],
        });
        this.verifyRecoveryCodeForm = this.#fb.nonNullable.group({
            recoveryCode: ['', [Validators.required]],
        });
    }

    iubendaIsAvailable() {
        return this.#iubendaService.isAvailable();
    }

    openIubendaPref($event: MouseEvent) {
        $event.preventDefault();
        this.#iubendaService.openPreferences();
    }

    sendGoogleAnalyticsClickLoginEvent() {
        this.#googleAnalyticsService.sendAnalyticsEvent(
            'funnel_iscrizione', 'accedi'
        );
    }

    sendGoogleAnalyticsClickRegisterEvent() {
        this.#googleAnalyticsService.sendAnalyticsEvent(
            'funnel_iscrizione', 'iscriviti'
        );
    }
}
