<div class="flex flex-col items-center">
    <div class="slider" [ngStyle]="{'text-align': textAlign}">
        <div class="slides" #sliderElement [ngStyle]="{ 'overflow-x': scrollable ? 'auto' : 'hidden' }" (scroll)="sliderScroll()">
            @if (components && components.length > 0) {
            @for (component of
            components; track $index) {
            <div class="slide" #slideElements>
                <ng-template [ngTemplateOutlet]="component"></ng-template>
            </div>
            }
            } @else {
            @for (slide of slides; track $index) {
            <div class="slide" #slideElements>
                <div *ngIf="slide.img" class="w-full">
                    <!-- logica per la gestione dell'immagine -->
                    <img [src]="slide.img" class="basis-full h-full w-full rounded-3xl {{slide.objectCover ?? 'object-cover'}}" style="height: {{ slideHeight }}px" alt="" />
                </div>
                <div class="text-center mt-6 px-2" *ngIf="slide.title || slide.text">
                    <div>
                        <h1 *ngIf="slide.title" class="text-primary text-3xl" [innerHTML]="slide.title"></h1>
                        <p *ngIf="slide.text" class="mt-3" [innerHTML]="slide.text"></p>
                    </div>
                </div>
            </div>
            }
            }
        </div>
    </div>

    <div class="flex items-center justify-between p-10 pt-0 w-full mt-6">
        <div class="flex items-center" *ngIf="!previousButtonDisabled" (click)="previousSlide()" [ngClass]="{invisible: currentSlide === 0}">
            <svg class="w-4 h-4 fill-primary me-2">
                <use href="../../../assets/svg/svGP.svg#arrow-left"></use>
            </svg>
            <button class="text-primary text-sm font-bold">
                {{ previousButtonLabel }}
            </button>
        </div>
        <div class="paginator-container" *ngIf="!paginationDisabled && (slides.length > 0 || (components && components.length > 0))">
            <div class="paginator-item" [ngClass]="{ active: currentSlide === 0 }"></div>
            @if (components && components.length > 0 ? components!.length > 2 : slides.length > 2) {
            <div class="paginator-item" [ngClass]="{ active: currentSlide > 0 && currentSlide < (components && components.length > 0 ? components!.length - 1 : slides.length - 1) }"></div>
            }
            @if (components && components.length > 0 ? components!.length > 1 : slides.length > 1) {
            <div class="paginator-item" [ngClass]="{ active: currentSlide === (components && components.length > 0 ? components!.length - 1 : slides.length - 1) }"></div>
            }
        </div>
        <div class="flex items-center" *ngIf="!nextButtonDisabled" (click)="nextSlide()" [ngClass]="{invisible: currentSlide === (components && components.length > 0 ? components!.length - 1 : slides.length - 1)}">
            <button class="text-primary text-sm font-bold">
                {{ nextButtonLabel }}
            </button>
            <svg class="w-4 h-4 fill-primary ms-2">
                <use href="../../../assets/svg/svGP.svg#arrow-right"></use>
            </svg>
        </div>
    </div>
</div>