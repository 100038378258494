import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogLevel } from '../models/log-level.model';
import * as Sentry from "@sentry/angular";

@Injectable({
    providedIn: 'root',
})
export class LogService {
    constructor() {}

    log(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel >= LogLevel.debug) {
            console.log(...[message, ...optionalParams]);
        }
    }

    trace(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel >= LogLevel.debug) {
            console.trace(...[message, ...optionalParams]);
        }
    }

    error(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel >= LogLevel.error) {
            console.error(...[message, ...optionalParams]);
        }

        let shouldReport = true;
        if (message instanceof Error) {
            shouldReport = !message.message.endsWith('Exception: code: 200 desc: The user is already logged in');
        }
        if (shouldReport) {
            Sentry.captureException(message, {
                data: optionalParams,
            });
        }
    }

    debug(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel >= LogLevel.debug) {
            console.debug(...[message, ...optionalParams]);
        }
    }

    info(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel >= LogLevel.info) {
            console.info(...[message, ...optionalParams]);
        }
    }

    warn(message?: any, ...optionalParams: any[]) {
        if (environment.logLevel >= LogLevel.warn) {
            console.warn(...[message, ...optionalParams]);
        }
    }
}
