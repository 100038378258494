import {UserEffects} from './user/user.effects';
import {CreateOrderEffects} from "./create-order/create-order.effects";
import {CancelSubscriptionEffects} from "./cancel-subscription/cancel-subscription.effects";
import {InternalHealthDataEffects} from "./internal-health-data/internal-health-data.effects";

export const effects = [
    UserEffects,
    CreateOrderEffects,
    CancelSubscriptionEffects,
    InternalHealthDataEffects,
];
