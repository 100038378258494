import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";

/**
 * Enables reading and writing health data from/to Apple Health, Google Fit and Health Connect.
 *
 * Google Fitness API is deprecated and will be turned down in 2024, thus this package will also transition to only support Health Connect.
 *
 * Note that for Android, the target phone needs to have Google Fit or Health Connect (which is currently in beta)
 * installed and have access to the internet, otherwise this plugin will not work.
 *
 * Official API: https://pub.dev/documentation/health/latest/
 */
@Injectable({
    providedIn: 'root'
})
export class SfmcNativeService {
    #nativeAPIService = inject(NativeAPIService);

    constructor() {
    }

    /**
     * Checks if the native SFMC Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }

    setup(options: {
        appId?: string | null,
        accessToken?: string | null,
        mid?: string | null,
        sfmcURL?: string | null,
        senderId?: string | null,
        locationEnabled?: boolean | null,
        inboxEnabled?: boolean | null,
        analyticsEnabled?: boolean | null,
        delayRegistration?: boolean | null
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.setup', options);
    }

    setContactKey(options: {
        contactId: string,
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.setContactKey', options);
    }

    setAttribute(options: {
        attrName: string,
        attrValue: string,
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.setAttribute', options);
    }

    clearAttribute(options: {
        attrName: string,
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.clearAttribute', options);
    }

    setTag(options: {
        tagName: string,
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.setTag', options);
    }

    removeTag(options: {
        tagName: string,
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.removeTag', options);
    }

    enablePush() {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.enablePush');
    }

    disablePush() {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.disablePush');
    }

    pushEnabled() {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.pushEnabled');
    }

    getDeviceToken() {
        return this.#nativeAPIService.callHandler<string | null>('SFMCSdk.getDeviceToken');
    }

    setDeviceToken(options: {
        deviceToken: string,
    }) {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.setDeviceToken', options);
    }

    getDeviceIdentifier() {
        return this.#nativeAPIService.callHandler<string | null>('SFMCSdk.getDeviceIdentifier');
    }

    getPushToken() {
        return this.#nativeAPIService.callHandler<string | null>('SFMCSdk.getPushToken');
    }

    enableLocationWatching() {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.enableLocationWatching');
    }

    disableLocationWatching() {
        return this.#nativeAPIService.callHandler<boolean | null>('SFMCSdk.disableLocationWatching');
    }

    sdkState() {
        return this.#nativeAPIService.callHandler<string | null>('SFMCSdk.sdkState');
    }
}
