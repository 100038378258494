import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Product} from "../models/product.model";
import {environment} from "../../environments/environment";
import {CreateOrderRequest, OrderResponse, Order} from "../models/order.model";

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    #apiUrl = environment.apiUrl;
    #http = inject(HttpClient);
    #orders = '/orders';

    constructor() {}

    getOrder(id: string) {
        return this.#http.get<OrderResponse>(`${this.#apiUrl}${this.#orders}/${id}`);
    }

    createOrder(order: CreateOrderRequest) {
        return this.#http.post<OrderResponse>(`${this.#apiUrl}${this.#orders}/order`, order);
    }

    cancelOrder(id: string) {
        return this.#http.post<Order>(`${this.#apiUrl}${this.#orders}/${id}/cancel`, null);
    }
}
