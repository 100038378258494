<div class="custom-bg-white pt-7 pb-32 px-6 relative">
    @if (user$ | async; as user) {
        <div class="flex flex-col items-center justify-center mb-3">
            <div class="relative">
                <img
                    class="size-20 object-cover rounded-full"
                    [src]="user.avatar ?? 'assets/img/pic/profile.png'"
                    alt=""
                />
                <button
                    class="absolute bg-primary p-2 rounded-full bottom-0 right-0"
                    [disabled]="userIsLoading$ | async"
                    (click)="fileInput.click()"
                >
                    <svg class="size-3 fill-white">
                        <use href="../../../assets/svg/svGP.svg#camera"></use>
                    </svg>
                    <input
                        class="hidden"
                        #fileInput
                        type="file"
                        accept="image/png, image/jpeg"
                        (change)="uploadAvatar($event)"
                    />
                </button>
            </div>
            <div class="mt-6">
                <h1 class="font-semibold text-xl">
                    {{ user.firstName }} {{ user.lastName }}
                </h1>
            </div>
            <p class="text-9f9d9d text-xs font-medium flex justify-center break-words">{{ user.email }}</p>
            <div
                *ngIf="hasBasePlan$ | async; else hasFreeTrial"
                class="flex items-center bg-f5f5f5 px-3 py-1 mt-3 rounded"
            >
                <div>
                    <svg class="verified-user size-4 me-2">
                        <use
                            href="../../../assets/svg/svGP.svg#verified-user"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span>Abbonamento Attivo</span>
                </div>
            </div>
            <ng-template #hasFreeTrial>
                <div *ngIf="freeTrialEndDate$ | async"
                     class="flex items-center bg-f5f5f5 px-3 py-1 mt-3 rounded">
                    <div>
                        <svg class="verified-user size-4 me-2">
                            <use
                                href="../../../assets/svg/svGP.svg#verified-user"
                            ></use>
                        </svg>
                    </div>
                    <div>
                        <span>Scadenza periodo di prova: {{ freeTrialEndDate | date : "dd/MM/yyyy" }}</span>
                    </div>
                </div>
            </ng-template>
        </div>
    }

    <!-- *ngIf="isLoading$ | async as isLoading; else productsContainer"
     <ng-template #productsContainer>
            <ng-container *ngIf="products$ | async as products">
            -->

    <section class="mt-12">
        <h2 class="font-semibold text-lg">Profilo utente</h2>
        <ul class="mt-4">
            <li>
                <a
                    [routerLink]="profilePath"
                    class="flex items-center justify-between py-4 border-b border-light-grey"
                >
                    <div>
                        <span>{{
                                "pages.settings-page.personalDataLabel" | transloco
                            }}</span>
                    </div>
                    <div>
                        <svg class="size-4 fill-black">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-right"
                            ></use>
                        </svg>
                    </div>
                </a>
            </li>
            <li>
                <a
                    [routerLink]="billingInfo"
                    class="flex items-center justify-between py-4 border-b border-light-grey"
                >
                    <div>
                        <span>{{
                                "pages.settings-page.billingInfoDataLabel" | transloco
                            }}</span>
                    </div>
                    <div>
                        <svg class="size-4 fill-black">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-right"
                            ></use>
                        </svg>
                    </div>
                </a>
            </li>
            <li>
                <div>
                    <a
                        [routerLink]="securityPath"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.securityLabel" | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    <a
                        [routerLink]="subscriptionsPath"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.subscriptionListLabel"
                                        | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    <a
                        [routerLink]="healthProvidersPath"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.healthProviders" | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    <a
                        [routerLink]="consentPath"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.consentLabel" | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    <a
                        [routerLink]="supportPath"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.supportLabel" | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>

            <li>
                <div>
                    <a
                        [href]="iubenda.termsAndConditions" target="_blank"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>Termini e condizioni</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    <a
                        [href]="iubenda.privacyPolicy" target="_blank"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.privacyPolicyLabel"
                                        | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li>
                <div>
                    <a
                        [href]="iubenda.cookiePolicy" target="_blank"
                        class="flex items-center justify-between py-4 border-b border-light-grey"
                    >
                        <div>
                            <span>{{
                                    "pages.settings-page.cookiePolicyLabel"
                                        | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
            <li *ngIf="iubendaIsAvailable()">
                <div>
                    <a href="#" (click)="openIubendaPref($event)"
                       class="iubenda-cs-preferences-link flex items-center justify-between py-4">
                        <div>
                            <span>{{
                                    "pages.settings-page.cookiePolicyPreferencesLabel"
                                        | transloco
                                }}</span>
                        </div>
                        <div>
                            <svg class="size-4 fill-black">
                                <use
                                    href="../../../assets/svg/svGP.svg#chevron-right"
                                ></use>
                            </svg>
                        </div>
                    </a>
                </div>
            </li>
        </ul>
    </section>
    <div>
        <div class="mt-6 w-full">
            <button
                class="py-5 px-4 border-0 bg-danger rounded-lg flex items-center justify-center mt-7 w-full"
                (click)="logout()"
            >
                <div class="flex items-center">
                    <div>
                        <svg class="size-4 me-2 fill-white">
                            <use
                                href="../../../assets/svg/svGP.svg#logout"
                            ></use>
                        </svg>
                    </div>
                    <div>
                        <span class="text-white text-sm font-bold uppercase">{{
                                "pages.settings-page.logoutLabel" | transloco
                            }}</span>
                    </div>
                </div>
            </button>
        </div>
        <div class="my-6 text-center">
            <button
                [disabled]="userIsLoading$ | async"
                [routerLink]="deletePath"
                class="text-sm text-danger font-semibold"
            >
                {{ "pages.settings-page.deleteLabel" | transloco }}
            </button>
        </div>
        <p class="text-9f9d9d text-xs font-medium flex justify-center">
            Versione:
            <ng-container *ngIf="appNativeVersion">
                {{ appNativeVersion }},
            </ng-container>
            {{ webAppVersion }}
            {{ environmentName }}
        </p>
    </div>
</div>
