import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';
import {HealthProviderName, TryTerraSupportedHealthProvider} from "../../models/try-terra.models";
import {TryTerraService} from "../../services/try-terra.service";
import {LogService} from '../../services/log.service';
import {NativeAPIService, TargetPlatform} from "../../services/native/native-api.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {InfoModalComponent, InfoModalData} from "../info-modal/info-modal.component";
import ErrorModalComponent, {ErrorModalData} from "../error-modal/error-modal.component";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.state";
import {fetchTryTerraUserInfo} from "../../store/user/user.actions";
import {selectUserTryTerraIsNativeInitializing} from "../../store/user/user.selectors";
import {AsyncPipe, CommonModule} from "@angular/common";
import {APP_ROUTES} from "../../app.routes.definition";

export interface HealthProviderConnectModalData {
    provider: TryTerraSupportedHealthProvider;
    isConnecting: boolean;
}

@Component({
    selector: 'app-health-provider-connect-modal',
    standalone: true,
    imports: [
        AsyncPipe,
        CommonModule
    ],
    templateUrl: './health-provider-connect-modal.component.html',
})
export class HealthProviderConnectModalComponent implements OnInit, HealthProviderConnectModalData {
    #modalService = inject(ModalService);
    #router = inject(Router);
    #tryTerraService = inject(TryTerraService);
    #logService = inject(LogService);
    #nativeAPIService = inject(NativeAPIService);
    #destroyRef = inject(DestroyRef);
    #store = inject(Store<AppState>);

    tryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);

    HealthProviderName = HealthProviderName;
    TryTerraSupportedHealthProvider = TryTerraSupportedHealthProvider;

    provider!: TryTerraSupportedHealthProvider;
    isConnecting!: boolean;
    isRequestingAuthLink = false;
    isRequestingDeauth = false;
    isMobileNative = this.#nativeAPIService.isAvailable();

    authLink: string | null = null

    constructor() {
    }

    ngOnInit() {
        if (this.isConnecting && ![TryTerraSupportedHealthProvider.HEALTH_CONNECT, TryTerraSupportedHealthProvider.APPLE].includes(this.provider)) {
            this.getAuthLink();
        }
    }

    getAuthLink() {
        if (this.isRequestingAuthLink || this.isRequestingDeauth) {
            return;
        }
        this.isRequestingAuthLink = true;

        this.#tryTerraService.authWithProvider(this.provider)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe({
                next: value => {
                    this.authLink = value.auth_url;
                    this.isRequestingAuthLink = false;
                },
                error: error => {
                    this.#logService.error(error);
                    this.#modalService.closeAll();
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                        ErrorModalComponent,
                        {
                            data: {
                                error: error,
                                message: `Non è possibile connettersi a ${HealthProviderName[this.provider]} in questo momento. Riprovare più tardi.`
                            },
                        }
                    );
                    this.isRequestingAuthLink = false;
                }
            });
    }

    authLinkClick(event: MouseEvent) {
        if (this.authLink == null) {
            event.preventDefault();
        }
    }

    async authWithNativeProvider() {
        if (this.isRequestingAuthLink || this.isRequestingDeauth) {
            return;
        }
        this.isRequestingAuthLink = true;

        const platform = await this.#nativeAPIService.defaultTargetPlatform();
        if (this.provider === TryTerraSupportedHealthProvider.HEALTH_CONNECT && platform !== TargetPlatform.android) {
            this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                data: {
                    title: `Non è possibile connettersi a "${HealthProviderName[this.provider]}" tramite ${
                        platform === TargetPlatform.web ? 'browser' : 'questo dispobitivo.'
                    }.`,
                    message: `"${HealthProviderName[this.provider]}" è disponibile solo usando l\'App scaricabile dal Google Play Store e solo su dispositivi Android.`,
                },
            });
        } else if (this.provider === TryTerraSupportedHealthProvider.APPLE && platform !== TargetPlatform.iOS) {
            this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                data: {
                    title: `Non è possibile connettersi a "${HealthProviderName[this.provider]}" tramite ${
                        platform === TargetPlatform.web ? 'browser' : 'questo dispobitivo.'
                    }.`,
                    message: `"${HealthProviderName[this.provider]}" è disponibile solo usando l\'App scaricabile dall\' Apple Store e solo su dispositivi Apple.`,
                },
            });
        } else {
            this.#modalService.closeAll();
            this.#router.navigate([APP_ROUTES.ASK_IMPORT_HEALTH_DATA()]);
        }
        this.isRequestingAuthLink = false;
    }

    deauthProvider() {
        if (this.isRequestingAuthLink || this.isRequestingDeauth) {
            return;
        }
        this.isRequestingDeauth = true;

        this.#tryTerraService.deauthProvider(this.provider)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe({
                next: value => {
                    this.#logService.log(value);
                    this.#modalService.closeAll();
                    this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                        data: {
                            title: `Disconnessione a ${HealthProviderName[this.provider]} avvenuta con successo.`
                        },
                    });
                    this.isRequestingDeauth = false;
                    this.#store.dispatch(fetchTryTerraUserInfo({initializeNative: false}));
                },
                error: error => {
                    this.#logService.error(error);
                    this.#modalService.closeAll();
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                        ErrorModalComponent,
                        {
                            data: {
                                error: error,
                                message: `Non è stato possibile disconnettersi a ${HealthProviderName[this.provider]}.`
                            },
                        }
                    );
                    this.isRequestingDeauth = false;
                }
            });
    }

    onClose() {
        this.#modalService.close();
    }
}
