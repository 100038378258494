<div class="flex flex-col">
    <div class="mt-5">
        <h1 class="font-bold text-xl">
            Stai per abbonarti con il seguente pacchetto
        </h1>
    </div>
    <div class="mt-4">
        <p class="text-sm">Sei sicuro di voler procedere?</p>
    </div>
    <div class="mt-5 bg-light-linear p-4 rounded-lg">
        <app-tier-card
            [product]="product"
            [showDescription]="false"
        ></app-tier-card>
    </div>
    <div class="mt-2">
        @if (!(isUserBillingInfoValid$ | async)) {
            <p>Prima di poter effettuare qualsiasi acquisto, hai bisogno di aggiornare i tuoi dati di fatturazione.</p>
            <button
                [routerLink]="billingInfoPath" [queryParams]="{isReadOnly: false, shouldRedirectBackOnSave: true}"
                class="mt-3 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
            >
                <div>
                    <span class="text-white text-sm font-bold uppercase">Dati di fatturazione</span>
                </div>
            </button>
        } @else {
            <form [formGroup]="termsAndConditionsForm" (ngSubmit)="createOrder()">
                <!-- Membership Form -->
                <div>
                    <div class="flex items-center justify-center">
                        <input
                            type="checkbox"
                            class="me-2"
                            formControlName="termsAndConditions"
                            id="termsAndConditions"
                        />
                        <span class="text-sm">
                            <label
                                for="termsAndConditions">* Dichiaro di aver preso visione e di accettare i </label>
                            <a class="text-primary cursor-pointer"
                               [href]="iubenda.termsAndConditions" target="_blank">termini e le condizioni</a>.
                        </span>
                    </div>
                </div>
                <!-- END  -->
                <button
                    [disabled]="(isCreatingOrder$ | async) || termsAndConditionsForm.invalid"
                    type="submit"
                    class="mt-3 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                >
                    <div>
                        @if (isCreatingOrder$ | async) {
                            <span class="button-loader"></span>
                        } @else {
                            <span class="text-white text-sm font-bold uppercase">Abbonati</span>
                        }
                    </div>
                </button>
            </form>
        }
        <button
            [disabled]="isCreatingOrder$ | async"
            (click)="onClose()"
            class="mt-5 py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-primary text-sm font-bold capitalize">Annulla</span>
            </div>
        </button>
    </div>
</div>
