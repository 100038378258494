import {Component, inject} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/app.state";
import {NavigationService} from "../../../../services/navigation.service";
import {
    selectUserActivatedOrders,
    selectUserData, selectUserHasActiveSubscriptions,
    selectUserHasLoginProvider,
    selectUserIsLoading, selectUserSubscriptions
} from "../../../../store/user/user.selectors";
import {deleteUser} from "../../../../store/user/user.actions";
import {AsyncPipe, NgIf} from "@angular/common";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {RouterLink} from "@angular/router";

export interface DeleteAccountForm {
    password: FormControl<string | null>;
}

@Component({
  selector: 'app-delete-account-page',
  standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        RouterLink
    ],
  templateUrl: './delete-account-page.component.html',
  styleUrl: './delete-account-page.component.css'
})
export default class DeleteAccountPageComponent {
    #store = inject(Store<AppState>);
    #navigationService = inject(NavigationService);
    #fb = inject(FormBuilder);

    user$ = this.#store.select(selectUserData);
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    hasLoginProvider$ = this.#store.select(selectUserHasLoginProvider);
    userHasActiveSubscriptions$ = this.#store.select(selectUserHasActiveSubscriptions);

    subscriptionsPath = APP_ROUTES.SETTINGS_SUBSCRIPTIONS();

    deleteAccountForm!: FormGroup<DeleteAccountForm>;

    constructor() {
        this.#navigationService.saveCurrentRoute();

        this.deleteAccountForm = this.#fb.group({
            password: this.#fb.control<string | null>('', [
                Validators.required,
            ]),
        });

        this.hasLoginProvider$.pipe(takeUntilDestroyed()).subscribe(hasLoginProvider => {
            if (hasLoginProvider) {
                this.deleteAccountForm.controls.password.removeValidators([Validators.required]);
                this.deleteAccountForm.controls.password.setErrors(null);
                this.deleteAccountForm.controls.password.patchValue(null);
            } else {
                this.deleteAccountForm.controls.password.addValidators([Validators.required]);
                this.deleteAccountForm.controls.password.patchValue('');
            }
            this.deleteAccountForm.controls.password.updateValueAndValidity({emitEvent: true});
        });
    }

    deleteAccount() {
        if (this.deleteAccountForm.invalid) return;

        this.#store.dispatch(deleteUser({password: this.deleteAccountForm.value.password ?? null}));
    }

    goBack() {
        this.#navigationService.navigateBack();
    }
}
