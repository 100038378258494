{
  "name": "bob-web-app-frontend",
  "version": "1.7.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:ngrok": "ngrok http 4200",
    "start:uat": "ng serve --configuration uat",
    "build": "ng build",
    "build:dev": "ng build --configuration development && npm run sentry:sourcemaps",
    "build:uat": "ng build --configuration uat && npm run sentry:sourcemaps",
    "build:prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org gunpowder-srl-gm --project bob-web-app ./dist/bob_web_app_frontend/browser && sentry-cli sourcemaps upload --org gunpowder-srl-gm --project bob-web-app ./dist/bob_web_app_frontend/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.8",
    "@angular/common": "^17.3.8",
    "@angular/compiler": "^17.3.8",
    "@angular/core": "^17.3.8",
    "@angular/forms": "^17.3.8",
    "@angular/platform-browser": "^17.3.8",
    "@angular/platform-browser-dynamic": "^17.3.8",
    "@angular/router": "^17.3.8",
    "@angular/service-worker": "^17.3.8",
    "@ngneat/transloco": "^6.0.4",
    "@ngrx/component-store": "^17.2.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/operators": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.34.0",
    "@sentry/cli": "^2.37.0",
    "agora-chat": "^1.2.2-1",
    "agora-extension-virtual-background": "^1.2.0",
    "agora-rtc-sdk-ng": "^4.20.0",
    "angularx-qrcode": "^17.0.1",
    "chart.js": "^4.4.2",
    "chartjs-adapter-dayjs-4": "^1.0.4",
    "codice-fiscale-js": "^2.3.22",
    "dayjs": "^1.11.12",
    "file-saver": "^2.0.5",
    "jwt-decode": "^4.0.0",
    "ngx-filesaver": "^17.0.0",
    "ngx-intl-tel-input-gg": "^1.0.10",
    "rxjs": "~7.8.1",
    "terra-api": "^1.4.5",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.7",
    "@angular/cli": "^17.3.7",
    "@angular/compiler-cli": "^17.3.8",
    "@types/jasmine": "~5.1.4",
    "@types/qrcode": "^1.5.5",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ngrok": "^5.0.0-beta.2",
    "postcss": "^8.4.38",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.5"
  }
}
