import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { RegistrationFormComponent } from '../../components/registration-form/registration-form.component';
import { RegistrationWithProviderFormComponent } from '../../components/registration-with-provider-form/registration-with-provider-form.component';
import { RegistrationDashIconComponent } from '../../components/registration-dash-icon/registration-dash-icon.component';
import { TranslocoModule } from '@ngneat/transloco';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {APP_ROUTES} from "../../app.routes.definition";

@Component({
    selector: 'registration-page',
    standalone: true,
    imports: [
        RegistrationFormComponent,
        RegistrationWithProviderFormComponent,
        RegistrationDashIconComponent,
        TranslocoModule,
        RouterLink,
    ],
    templateUrl: './registration-page.component.html',
})
export default class RegistrationPageComponent {
    #activatedRoute = inject(ActivatedRoute);

    loginPath = APP_ROUTES.LOGIN();

    accessToken?: string;
    showRegistrationWithProvider: boolean = false;
    showVerifyEmailLabel = false;
    userEmail: string = '';

    constructor() {
        const access_token =
            this.#activatedRoute.snapshot.queryParamMap.get('access_token');
        if (access_token) {
            this.accessToken = access_token;
            this.showRegistrationWithProviderInitialization();
        }
    }

    showRegistrationWithProviderInitialization() {
        this.showRegistrationWithProvider = true;
    }

    hideProviderRegistrationForm() {
        this.showRegistrationWithProvider = false;
    }

    isVerifyingStepEmitted(isVerifyingStepEmitted: boolean) {
        this.showVerifyEmailLabel = isVerifyingStepEmitted;
    }

    isUserEmailEmitted(userEmail: string) {
        this.userEmail = userEmail;
    }
}
