<health-data-page
    [chartLabel]="chartLabel"
    [chartConfiguration]="chartConfiguration"
    [healthDataLabel]="healthDataLabel"
    [prefixAggregateLegendLabel]="''"
    [healthDataType]="heightHealthDataType"
    [supportedPeriods]="supportedPeriods"
    [hasTwoDecimals]="hasTwoDecimals"
    [payloadTypes]="payloadTypes"
    [canAddHealthData]="canAddHealthData"
/>
