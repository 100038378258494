<div>
    <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl pb-32">
        @if (targetPlatform === TargetPlatform.android) {
        <!-- Permessi Android-->
        <h1 class="text-primary font-semibold text-3xl">
            Gestione app Health Connect Android
        </h1>
        <div class="mt-6">
            <p>Puoi accedere all'app Health Connect nei seguenti modi:</p>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    Sul dispositivo, vai a <strong>Impostazioni</strong> e poi
                    <strong>App</strong> e poi <strong>Health Connect</strong> e
                    tocca <strong>Apri</strong>.
                </li>
                <li>
                    Nelle <strong>Impostazioni rapide</strong>, se hai aggiunto
                    Health Connect.
                </li>
                <li>
                    Nelle impostazioni <strong>Privacy</strong>, se l'app Health
                    Connect è precaricata sul tuo dispositivo.
                </li>
            </ol>
            <h3 class="font-bold text-lg text-text-color mt-6">
                Collegare le tue app
            </h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    Apri l'app <strong>Health Connect</strong> sul dispositivo.
                </li>
                <li>Tocca <strong>Autorizzazioni app</strong>.</li>
                <li>
                    Attiva le app che vuoi sincronizzare con Health Connect.
                </li>
                <li>
                    Seleziona le autorizzazioni relative ai dati che vuoi
                    condividere.
                </li>
                <li>Tocca <strong>Autorizza</strong>.</li>
            </ol>
            <p class="mt-2">
                <strong>Suggerimento:</strong> per iniziare a sincronizzare le
                app, alcune app potrebbero richiederti di iniziare nell'app
                collegata.
            </p>

            <h3 class="font-bold text-lg text-text-color mt-6">
                Modificare le autorizzazioni app
            </h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    Sul tuo dispositivo, apri l'app
                    <strong>Health Connect</strong>.
                </li>
                <li>Tocca <strong>Autorizzazioni app</strong>.</li>
                <li>
                    Seleziona l'app per cui vuoi concedere o revocare le
                    autorizzazioni.
                </li>
                <li>
                    Attiva o disattiva l'opzione
                    <strong>Consenti tutto</strong>.
                </li>
            </ol>
            <p class="mt-2">
                <strong>Suggerimento:</strong> quando rimuovi collegamenti delle
                app, vengono revocate tutte le autorizzazioni di lettura e
                scrittura per le app in questione.
            </p>

            <h3 class="font-bold text-lg text-text-color mt-6">
                Modificare le autorizzazioni per tipi di dati specifici
            </h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    Apri l'app <strong>Health Connect</strong> sul dispositivo.
                </li>
                <li>Tocca <strong>Autorizzazioni app</strong>.</li>
                <li>Seleziona l'app che vuoi modificare.</li>
                <li>
                    Per determinati tipi di dati, disattiva le autorizzazioni di
                    lettura e scrittura.
                </li>
            </ol>
            <p class="mt-2">
                <strong>Suggerimento:</strong> quando rimuovi le autorizzazioni
                dei dati, non vengono rimossi i dati archiviati.
            </p>
        </div>
        } @else if (targetPlatform === TargetPlatform.iOS) {
        <!-- permessi iOS -->
        <h1 class="text-primary font-semibold text-3xl">
            Accedere all'app Salute iOS
        </h1>
        <div class="mt-6">
            <p>Puoi accedere all'app Salute nei seguenti modi:</p>
            <ol class="mt-6 list-decimal ml-5">
                <li>Vai in <strong>Impostazioni</strong>.</li>
                <li>Tocca <strong>Privacy e sicurezza</strong>.</li>
                <li>Tocca <strong>Salute</strong>.</li>
                <li>Seleziona l'app per cui desideri modificare i permessi.</li>
                <li>Attiva o disattiva l'accesso per i dati di salute.</li>
            </ol>
        </div>
        }
    </div>
</div>
