import {Component, SecurityContext, inject, OnInit} from '@angular/core';
import {NavigationService} from '../../../../../../../services/navigation.service';
import {RouterModule} from '@angular/router';
import {UserService} from '../../../../../../../services/user.service';
import {TwoFactorInitResponse} from '../../../../../../../models/responses/two-factor-init-response.model';
import {first} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {APP_ROUTES} from '../../../../../../../app.routes.definition';
import {QRCodeModule} from 'angularx-qrcode';
import {NgClass, NgIf} from '@angular/common';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../../../../../components/error-modal/error-modal.component';
import {LogService} from '../../../../../../../services/log.service';
import {ModalService} from '../../../../../../../services/modal.service';
import QrCodeModalContentComponent from './qr-code-modal-content/qr-code-modal-content.component';
import LoaderComponent from '../../../../../../../components/loader/loader.component';
import {
    NativeAPIService,
    TargetPlatform,
} from '../../../../../../../services/native/native-api.service';

@Component({
    selector: 'app-continue-two-factor-process-page',
    standalone: true,
    imports: [RouterModule, QRCodeModule, NgClass, NgIf, LoaderComponent],
    templateUrl: './begin-two-factor-process-page.component.html',
    styleUrl: './begin-two-factor-process-page.component.css',
})
export default class BeginTwoFactorProcessPageComponent implements OnInit {
    #navigationService = inject(NavigationService);
    #userService = inject(UserService);
    #sanitizer = inject(DomSanitizer);
    #logService = inject(LogService);
    #modalService = inject(ModalService);
    #nativeAPIService = inject(NativeAPIService);

    accountName = '';
    otpAuthUrlSafe?: SafeUrl;
    otpAuthUrl?: string;
    secretKey = '';
    settingsPath = APP_ROUTES.SETTINGS_SECURITY(false);
    endTwoFactorProcessPath = APP_ROUTES.SETTINGS_END_TWO_FACTOR_PROCESS(true);
    targetPlatform?: TargetPlatform;
    TargetPlatform = TargetPlatform;
    loading = true;

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
        this.start2faProcess();
        this.detectDeviceType();

    }

    detectDeviceType() {
        if (this.#nativeAPIService.isAvailable()) {
            this.#nativeAPIService.defaultTargetPlatform().then((platform) => {
                this.targetPlatform = platform;
            });
        }
    }

    handleQRCode() {
        this.#modalService.open(QrCodeModalContentComponent, {
            data: {
                otpAuthUrl: this.otpAuthUrl ?? '',
            },
        });
    }

    copyInputValue(value: string) {
        navigator.clipboard.writeText(value).catch((reason) => {
            this.#logService.error(reason);
        });
    }

    start2faProcess() {
        this.#userService
            .initiateTwoFactorAuthentication()
            .pipe(first())
            .subscribe({
                next: (response: TwoFactorInitResponse) => {
                    this.accountName = response.email;
                    this.secretKey = response.secret;
                    this.otpAuthUrlSafe =
                        this.#sanitizer.bypassSecurityTrustUrl(
                            response.otpAuthUrl
                        );
                    this.otpAuthUrl =
                        this.#sanitizer.sanitize(
                            SecurityContext.URL,
                            this.otpAuthUrlSafe
                        ) || undefined;
                    this.loading = false;
                },
                error: (error) => {
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                        },
                    });
                    this.loading = false;
                },
            });
    }
}
