import { Route } from "@angular/router";
import { UserHasProductFeatureGuard } from "../../../../guards/auth.guard";
import { ProductFeature } from "../../../../models/product.model";
import {APP_ROUTES} from "../../../../app.routes.definition";
import SleepCategoryPageComponent from "./sleep-category-page.component";
import SleepInBedPageComponent from "./pages/sleep-in-bed-page/sleep-in-bed-page.component";

export const SLEEP_CATEGORY_ROUTES: Route[] = [
    {
        path: '',
        data: {
            customSectionClass: 'bg-section-data-purple'
        },
        component: SleepCategoryPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_SLEEP_SLEEP_IN_BED(true),
        data: { title: 'Sleep in bed' },
        component: SleepInBedPageComponent,
    }
]
