import {
    Component,
    ElementRef,
    ViewChild,
    AfterViewInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, filter, fromEvent } from 'rxjs';
import { NotificationService, NotificationOptions } from '../../services/notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.css'],
    imports: [CommonModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('notification') notification!: ElementRef<HTMLDivElement>;
    options!: NotificationOptions;
    notificationAnimationEnd!: Observable<Event>;
    notificationLeaveAnimation = '';
    notificationClosed = false;
    layerLevel = 0;
    timoutID?: number | null = null;

    constructor(
        private notificationService: NotificationService,
        private element: ElementRef<HTMLElement>
    ) {}

    /**
     * Initialise variable and escape key on document.
     * Multiple modals might register multiple event listener, hence the 'layerLevel' variable and two times the condition check for the escape option.
     */
    ngOnInit() {
        this.notificationService.layerLevel += 1;
        this.layerLevel = this.notificationService.layerLevel;

        if (this.options.timeout) {
            this.timoutID = setTimeout(() => {
                this.notificationService.close();
            }, this.options.timeout);
        }
    }

    async onClick($event: MouseEvent) {
        const shouldClose = await this.options?.actions?.click?.(this.options.data.remoteMessage) ?? true;
        if (shouldClose) {
            this.onClose($event);
        }
    }

    onClose($event: MouseEvent) {
        $event.stopPropagation();
        this.notificationService.close();
    }

    ngAfterViewInit() {
        this.addOptionsAndAnimations();
    }

    ngOnDestroy() {
        if (this.timoutID) {
            clearTimeout(this.timoutID);
        }
    }

    /**
     * Add options and animations
     * Apply user style and animations, listen to animation ends. Apply z-indexes on overlay and modal, with 1000 as incremental value.
     */
    addOptionsAndAnimations() {
        const overlayZIndex = 1000 * this.notificationService.notificationInstances.length;
        this.notification.nativeElement.style.zIndex = `${overlayZIndex + 1001}`;

        this.notificationAnimationEnd = fromEvent(
            this.notification.nativeElement,
            'animationend'
        );
    }

    removeElementIfNotAnimated(
        element: HTMLDivElement,
        animation: string,
        destroyCallback: () => void
    ) {
        if (!animation) {
            element.remove();

            this.notificationClosed = true;

            destroyCallback();
        }
    }

    /**
     * Clean the DOM
     * Apply the leaving animations and clean the DOM. Three different use cases.
     * Last In First Out
     */
    close(destroyCallback: () => void): void {
        this.notificationService.layerLevel -= 1;
        this.notification.nativeElement.style.animation = this.notificationLeaveAnimation;

        // First: no animations on both elements
        if (!this.notificationLeaveAnimation) {
            this.element.nativeElement.remove();
            destroyCallback();
            return;
        }

        // Second: 1/2 animated, remove directly element if not animated
        this.removeElementIfNotAnimated(
            this.notification.nativeElement,
            this.notificationLeaveAnimation,
            destroyCallback
        );

        // Third: Both animated with differents animation time, remove modal component as soon as last one ends
        this.notificationAnimationEnd.subscribe(() => {
            this.notification.nativeElement.remove();
            this.notificationClosed = true;
            this.removeNotificationComponent(true, destroyCallback);
        });
    }

    removeNotificationComponent(
        notificationClosed: boolean,
        destroyCallback: () => void
    ) {
        if (notificationClosed) {
            this.element.nativeElement.remove();
            destroyCallback();
        }
    }
}
