import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {AgoraService} from '../../../services/agora.service';
import {MessageListComponent} from '../../../components/message-list/message-list.component';
import {MessageFormComponent} from '../../../components/message-form/message-form.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AsyncPipe, LowerCasePipe, NgIf} from '@angular/common';
import {AgoraChat} from 'agora-chat';
import {
    selectUserAgoraChatState,
    selectUserData,
} from '../../../store/user/user.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {NavigationService} from '../../../services/navigation.service';
import {APP_ROUTES} from '../../../app.routes.definition';
import LoaderComponent from '../../../components/loader/loader.component';
import {PayloadChat} from '../../../models/chat.models';
import {LogService} from '../../../services/log.service';
import {ModalService} from '../../../services/modal.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../components/error-modal/error-modal.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {take} from "rxjs";

@Component({
    selector: 'conversation',
    standalone: true,
    imports: [
        MessageListComponent,
        MessageFormComponent,
        AsyncPipe,
        LowerCasePipe,
        LoaderComponent,
        NgIf,
    ],
    templateUrl: './conversation.component.html',
})
export default class ConversationComponent implements OnInit {
    @ViewChild('messageListComponent')
    messageListComponent!: MessageListComponent;

    #store = inject(Store<AppState>);
    #activatedRoute = inject(ActivatedRoute);
    #router = inject(Router);
    #navigationService = inject(NavigationService);
    #modalService = inject(ModalService);
    destroyRef = inject(DestroyRef);
    agoraService = inject(AgoraService);
    #logService = inject(LogService);

    user$ = this.#store.select(selectUserData);
    userAgoraChatState$ = this.#store.select(selectUserAgoraChatState);

    loading = true;
    sendingMessage = false;
    conversationId: string | null = null;
    loadingOldMessages = false;
    defaultAvatar = '/assets/img/pic/profile.png';
    userSfAccountId = '';
    data?: PayloadChat | null;
    chatMessages: AgoraChat.TextMsgBody[] = [];
    chatPaginator?: AgoraChat.HistoryMessages;

    constructor() {
        const navigation = this.#router.getCurrentNavigation();
        this.data = navigation?.extras.state?.['data'];
        this.conversationId = this.#activatedRoute.snapshot.paramMap.get('id');
        this.#logService.log('DATA: ', this.data);

        if (!this.data || !this.conversationId) {
            this.#router.navigate([APP_ROUTES.MESSAGING()]);
            return;
        }

        this.userAgoraChatState$.pipe(take(1)).subscribe({
            next: (agoraChatState) => {
                if (agoraChatState?.isLogged) {
                    this.agoraService.onTextMessage$.pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe(message => {
                            if (this.conversationId && message.from && this.conversationId?.toLowerCase() === message.from?.toLowerCase()) {
                                this.chatMessages.push(message);
                                setTimeout(() => {
                                    // wait for angular render to update message-list to scroll to the bottom
                                    this.messageListComponent.scrollToBottom(true);
                                });
                            }
                        });

                    this.#logService.log('Send Agora read receipt');
                    this.agoraService.sendReadChatReceipt(this.conversationId!, this.userSfAccountId)
                        .then(value => {
                            this.#logService.log('Agora read receipt response', value);
                        })
                        .catch(error => {
                            this.#logService.error(error);
                        });

                    this.agoraService.getHistoryMessages({
                        targetId: this.conversationId!,
                        chatType: 'singleChat',
                        searchDirection: 'up',
                    }).then(value => {
                        this.#logService.log('Agora get initial messages', value);
                        this.chatPaginator = value;
                        this.chatMessages = (this.chatPaginator?.messages.reverse() ?? []) as AgoraChat.TextMsgBody[];

                        setTimeout(() => {
                            // wait for angular render to update message-list to scroll to the bottom
                            this.messageListComponent.scrollToBottom();
                        });
                        this.loading = false;
                    }).catch(error => {
                        this.#logService.error('Error fetching messages', error);
                        this.#modalService.open<
                            ErrorModalData,
                            ErrorModalComponent
                        >(ErrorModalComponent, {
                            data: {
                                error: error,
                            },
                        });
                        this.loading = false;
                    });
                } else if (agoraChatState?.loginError) {
                    this.#logService.error(agoraChatState.loginError);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: agoraChatState.loginError,
                        },
                    });
                    this.loading = false;
                }
            },
        });
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }

    sendMessage(text: string) {
        if (!this.conversationId) {
            return;
        }
        this.sendingMessage = true;

        this.messageListComponent.scrollToBottom();

        this.agoraService
            .sendMessage(text, this.conversationId, this.userSfAccountId)
            .then((result) => {
                this.#logService.log('messaggio inviato con successo', result);
                if (result?.message) {
                    this.chatMessages.push(result.message as AgoraChat.TextMsgBody);
                }
                this.sendingMessage = false;
                setTimeout(() => {
                    // wait for angular render to update message-list to scroll to the bottom
                    this.messageListComponent.scrollToBottom();
                });
            })
            .catch((err) => {
                this.#logService.error(err);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: err,
                            message: 'Non è stato possibile inviare il messaggio.'
                        },
                    }
                );
                this.sendingMessage = false;
            });
    }

    goBack() {
        this.#router.navigate([APP_ROUTES.MESSAGING()]);
    }

    loadMoreMessages() {
        if (this.chatPaginator?.isLast) {
            this.#logService.log('No more agora messages available');
            return;
        }

        this.loadingOldMessages = true;

        this.messageListComponent.scrollDirective.prepareFor('up');

        this.agoraService.getHistoryMessages({
            chatType: 'singleChat',
            searchDirection: 'up',
            targetId: this.conversationId!,
            cursor: this.chatPaginator?.cursor,
        }).then((value) => {
            this.#logService.log('Agora get old messages', value);
            this.chatPaginator = value;

            const newMessages =
                (this.chatPaginator?.messages.reverse() ?? []) as AgoraChat.TextMsgBody[];
            this.chatMessages = newMessages.concat(this.chatMessages);
            this.loadingOldMessages = false;
            setTimeout(() => {
                this.messageListComponent.scrollDirective.restore();
            })
        }).catch((err) => {
            this.#logService.error(err);
            this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                ErrorModalComponent,
                {
                    data: {
                        error: err,
                        message: 'Non è stato possibile caricare i messaggi precedenti.'
                    },
                }
            );
            this.sendingMessage = false;
        });
    }
}
