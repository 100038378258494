import {
    ApplicationRef,
    ComponentRef,
    EnvironmentInjector,
    Inject,
    Injectable,
    PLATFORM_ID,
    Type,
    createComponent,
} from '@angular/core';
import {ModalComponent} from '../components/modal/modal.component';
import {Subject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

export interface ModalOptions<T> {
    modal?: {
        enter?: string;
        leave?: string;
        top?: string;
        left?: string;
    };
    overlay?: {
        enter?: string;
        leave?: string;
        backgroundColor?: string;
    };
    size?: {
        height?: string;
        maxHeight?: string;
        width?: string;
        maxWidth?: string;
        padding?: string;
    };
    actions?: {
        escape?: boolean;
        click?: boolean;
    };
    data?: T
}

export interface SubjectModal {
    subject: Subject<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private modalSubject!: Subject<any>;
    /**
     * Internal use only.
     */
    modalInstances: { modalComponent: ComponentRef<ModalComponent>, component: ComponentRef<any> }[] = [];
    /**
     * Internal use only.
     */
    layerLevel = 0;
    private isBrowser = true;
    private modalSubjects: SubjectModal[] = [];

    constructor(
        private appRef: ApplicationRef,
        private injector: EnvironmentInjector,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    /**
     * Opens a custom component within a modal.
     * @param componentToCreate The custom component to display within the modal.
     * @param options Additional options for configuring the modal appearance and animations.
     * @returns A RxJs Subject that will emit custom data on closing the modal.
     * ```
     * this.modalService.open(ModalContentComponent, {
     *   modal: {
     *     enter: 'enter-scale-down 0.1s ease-out',
     *     leave: 'fade-out 0.5s',
     *   },
     *   overlay: {
     *     leave: 'fade-out 0.3s',
     *   },
     *   data: {
     *     type: 'Angular modal library',
     *   },
     * })
     * .subscribe((dataFromComponent) => {
     *    ...
     * });
     * ```
     */
    open<T, C>(componentToCreate: Type<C>, options?: ModalOptions<T>) {
        this.modalSubject = new Subject();
        this.openComponent(componentToCreate, options);
        return this.modalSubject;
    }

    isAlreadyOpened<C>(componentType: Type<C>): boolean {
        return this.modalInstances.some(instance => instance.component.componentType === componentType);
    }

    private openComponent<T, C>(componentToCreate: Type<C>, options?: ModalOptions<T>) {
        if (!this.isBrowser) return;

        const newComponent = createComponent(componentToCreate, {
            environmentInjector: this.injector,
        });

        const newModalComponent = createComponent(ModalComponent, {
            environmentInjector: this.injector,
            projectableNodes: [[newComponent.location.nativeElement]],
        });

        newModalComponent.instance.options = options;
        this.instantiateProps(newComponent, options?.data);
        this.modalSubjects.push({subject: this.modalSubject});
        this.modalInstances.push({
            modalComponent: newModalComponent,
            component: newComponent
        });

        document.body.appendChild(newModalComponent.location.nativeElement);

        this.appRef.attachView(newComponent.hostView);
        this.appRef.attachView(newModalComponent.hostView);
    }

    private instantiateProps(component: ComponentRef<any>, data?: any) {
        if (data) {
            for (const key of Object.keys(data)) {
                // @ts-ignore
                component.instance[key] = data[key];
            }
        }
    }

    /**
     * Close the current modal.
     * @param data The optional data to emit on closing the modal (communication from modal to caller).
     */
    close(data?: unknown) {
        const modalInstance = this.modalInstances.pop();
        modalInstance?.modalComponent.instance.close(() => {
            modalInstance?.component.destroy();
            modalInstance?.modalComponent.destroy();
        });

        if (this.modalSubjects.length === 0) return;

        const currentSubject = this.modalSubjects.pop() as SubjectModal;
        currentSubject.subject.next(data);
        currentSubject.subject.complete();
    }

    /**
     * Close all modal instances.
     * Respective animations will be applied.
     */
    closeAll() {
        for (let i = this.modalInstances.length - 1; i > -1; i--) {
            this.modalInstances[i].modalComponent.instance.close(() => {
                this.modalInstances[i].component.destroy();
                this.modalInstances[i].modalComponent.destroy();
            });
        }

        this.modalSubjects = [];
    }
}
