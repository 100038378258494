import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChatMessage, ChatConversation } from '../models/chat.models';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class FreeChatService {
    #apiUrl = `${environment.apiUrl}/free-chat`;
    #http = inject(HttpClient);

    getConversations(): Observable<ChatConversation[]> {
        return this.#http.get<ChatConversation[]>(`${this.#apiUrl}`);
    }

    getConversationById(id: string): Observable<ChatConversation> {
        return this.#http.get<ChatConversation>(`${this.#apiUrl}/${id}`);
    }

    sendMessage(
        text: string,
        recipientId: string
    ): Observable<ChatMessage | null> {
        return this.#http.post<ChatMessage | null>(`${this.#apiUrl}`, {
            text,
            recipientId,
        });
    }
}
