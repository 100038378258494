import {Component, inject, OnInit} from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../../../../services/navigation.service';
import {TranslocoPipe} from "@ngneat/transloco";
import {AsyncPipe, NgIf} from "@angular/common";
import {APP_ROUTES} from "../../../../app.routes.definition";
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { selectUserHasLoginProvider } from '../../../../store/user/user.selectors';

@Component({
    selector: 'security-page',
    standalone: true,
    imports: [RouterModule, TranslocoPipe, AsyncPipe, NgIf],
    templateUrl: './security-page.component.html',
    styleUrl: './security-page.component.css',
})
export default class SecurityPageComponent implements OnInit {
    #navigationService = inject(NavigationService);
    #store = inject(Store<AppState>);

    changeEmailPath = APP_ROUTES.SETTINGS_SECURITY_CHANGE_EMAIL(true);
    changePasswordPath = APP_ROUTES.SETTINGS_SECURITY_CHANGE_PASSWORD(true);
    twoFactorPath = APP_ROUTES.SETTINGS_TWO_FACTOR_SETUP(true);

    hasLoginProvider$ = this.#store.select(selectUserHasLoginProvider);

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
