import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, isDevMode} from '@angular/core';
import {
    InMemoryScrollingFeature,
    InMemoryScrollingOptions, PreloadAllModules,
    provideRouter, Router,
    withInMemoryScrolling, withNavigationErrorHandler, withPreloading,
    withViewTransitions,
} from '@angular/router';

import {routes} from './app.routes';
import {provideStore} from '@ngrx/store';
import {provideEffects} from '@ngrx/effects';
import {effects} from './store/app.effects';
import {reducers} from './store/app.reducers';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {provideSalesforceService} from './app.providers';
import {TranslocoHttpLoader} from './transloco-loader';
import {provideTransloco} from '@ngneat/transloco';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideServiceWorker} from '@angular/service-worker';
import * as Sentry from "@sentry/angular";

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
    providers: [
        provideSalesforceService,
        provideHttpClient(withInterceptors([
            AuthInterceptor,
            // preventDuplicateRequests
        ])),
        provideRouter(routes, withViewTransitions(), inMemoryScrollingFeature, withPreloading(PreloadAllModules), withNavigationErrorHandler(event => {
            if ((event.error instanceof TypeError && event.error.message.includes('Failed to fetch dynamically imported module')) ||
                (event.error instanceof Error && event.error.name == 'ChunkLoadError')) {
                window.history.pushState(window.history.state, '', event.url);
                window.location.reload();
            }
        })),
        provideStore(reducers),
        provideEffects(effects),
        provideStoreDevtools({maxAge: 25, logOnly: !isDevMode()}),
        provideHttpClient(),
        provideTransloco({
            config: {
                availableLangs: ['en', 'it'],
                defaultLang: 'it',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader,
        }),
        provideAnimations(),
        provideServiceWorker('ngsw-worker.js', {
            // enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
};
