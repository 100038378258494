import { userReducer } from './user/user.reducer';
import {createOrderReducer} from "./create-order/create-order.reducer";
import {cancelSubscriptionReducer} from "./cancel-subscription/cancel-subscription.reducer";
import {internalHealthDataReducer} from "./internal-health-data/internal-health-data.reducer";

export const reducers = {
    user: userReducer,
    createOrder: createOrderReducer,
    cancelSubscription: cancelSubscriptionReducer,
    internalHealthData: internalHealthDataReducer,
};
