import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild,} from '@angular/core';
import {HealthChartConfiguration, HealthChartPeriod, HealthDataType, HealthDataValue} from '../../models/health.models';
import {Chart, ChartItem, registerables, TooltipItem} from 'chart.js';
import dayjs from 'dayjs';

@Component({
    selector: 'health-chart',
    standalone: true,
    imports: [],
    templateUrl: './health-chart.component.html',
})
export class HealthChartComponent implements OnChanges, AfterViewInit {
    @ViewChild('chartCanvas') chartCanvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild('chartContainer') chartContainer!: ElementRef<HTMLDivElement>;
    @Input() healthDataValues: HealthDataValue[] = [];
    @Input() chartPeriod: HealthChartPeriod = HealthChartPeriod.day;
    @Input() dateFrom = dayjs(new Date());
    @Input() chartLabel = '';
    @Input({required: true}) chartValueUnitSymbol = '';
    @Input() prefixAggregateLegendLabel = '';
    @Input() chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'line',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar'
    }
    @Input() isLoading = true;

    chartInstance: Chart | null = null;

    constructor() {
        Chart.register(...registerables);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.chartCanvas) {
            if (changes['isLoading']?.currentValue && this.chartInstance) {
                this.chartInstance.data.datasets = [];
                this.chartInstance.data.labels = [];
                this.chartInstance.update();
            } else {
                this.upsertChart(this.healthDataValues);
            }
        }
    }

    ngAfterViewInit() {
        this.upsertChart(this.healthDataValues);
    }

    upsertChart(data: HealthDataValue[]) {
        let labels: string[] = [];
        const values: (number | { x: string, y: number })[] = [];
        let legendLabel = this.chartLabel;
        const prefixAggregateLegendLabel =
            this.prefixAggregateLegendLabel != null &&
            this.prefixAggregateLegendLabel.trim() !== ''
                ? this.prefixAggregateLegendLabel + ' '
                : '';
        switch (this.chartPeriod) {
            case 'day':
                if (this.chartConfiguration[this.chartPeriod] === 'bar') {
                    for (let i = 0; i < 24; i++) {
                        const startInterval = dayjs(this.dateFrom).set('hour', i).startOf('hour');
                        const endInterval = dayjs(this.dateFrom).set('hour', i).endOf('hour');
                        const dayData = {
                            x: startInterval.toString(),
                            y: data!.filter(entry => dayjs(entry.date).isBetween(startInterval, endInterval, null, '[]'))
                                .map((entry) => [HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(entry.type.name)
                                    ? (Math.floor(entry.value) / 60)
                                    : entry.value)
                                .reduce((a, b) => a + b, 0)
                        };
                        values.push(dayData);
                    }
                } else {
                    values.push(
                        ...data!.map((entry) => ({
                                x: entry.date,
                                y: [HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(entry.type.name)
                                    ? (Math.floor(entry.value) / 60)
                                    : entry.value
                            })
                        )
                    );
                }
                break;
            case 'week':
                legendLabel = prefixAggregateLegendLabel + this.chartLabel;
                for (let i = 1; i < 8; i++) {
                    const date = dayjs(this.dateFrom)
                        .startOf('week')
                        .add(i - 1, 'day');
                    labels.push(date.format('dddd'));
                    const dayData = data.find(
                        (entry) => dayjs(entry.date).day() === i % 7
                    );
                    if (dayData) {
                        [HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(dayData.type.name)
                            ? values.push(Math.floor(dayData.value) / 60)
                            : values.push(dayData.value);
                    } else {
                        values.push(0);
                    }
                }
                break;
            case 'month':
                const daysInMonth = dayjs(this.dateFrom).daysInMonth();
                legendLabel = prefixAggregateLegendLabel + this.chartLabel;
                for (let i = 0; i < daysInMonth; i++) {
                    const date = dayjs(this.dateFrom)
                        .startOf('month')
                        .add(i, 'day');
                    labels.push(date.format('D'));
                    const dayData = data.find(
                        (entry) => dayjs(entry.date).date() === i + 1
                    );
                    if (dayData) {
                        [HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(dayData.type.name)
                            ? values.push(Math.floor(dayData.value) / 60)
                            : values.push(dayData.value);
                    } else {
                        values.push(0);
                    }
                }
                break;
        }

        if (this.chartInstance) {
            this.chartInstance.destroy();
        }

        const ctx = this.chartCanvas.nativeElement.getContext('2d');
        const myChart = ctx as ChartItem;

        const noDataPlugin = {
            id: 'noDataPlugin',
            beforeDraw: (chart: any) => {
                if (
                    chart.data.datasets.length === 0 ||
                    chart.data.datasets[0].data.length === 0
                ) {
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height;
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    if (this.isLoading) {
                        ctx.fillText(
                            'Caricamento dati..',
                            width / 2,
                            height / 2
                        );
                    } else {
                        ctx.fillText(
                            'Nessun dato disponibile',
                            width / 2,
                            height / 2
                        );
                    }
                    ctx.restore();
                    return;
                }
            },
        };

        // @ts-ignore
        this.chartInstance = new Chart(myChart, {
            type: this.chartConfiguration[this.chartPeriod],
            data: {
                labels: this.chartPeriod !== HealthChartPeriod.day ? labels : undefined,
                datasets: [
                    {
                        label: legendLabel,
                        data: values,
                        borderWidth: 1,
                        pointStyle: this.chartConfiguration[this.chartPeriod] !== 'line',
                        segment: this.chartPeriod === HealthChartPeriod.day && this.chartConfiguration[this.chartPeriod] === 'line' ? {
                            borderColor: (ctx: any) => {
                                const diffInMinutes = dayjs(ctx.p1.raw.x).diff(dayjs(ctx.p0.raw.x), 'minutes');
                                if (diffInMinutes > 10) {
                                    return 'rgb(0,0,0,0.2)';
                                } else {
                                    return;
                                }
                            },
                            borderDash: (ctx: any) => {
                                const diffInMinutes = dayjs(ctx.p1.raw.x).diff(dayjs(ctx.p0.raw.x), 'minutes');
                                return diffInMinutes > 10 ? [3, 3] : undefined;
                            },
                        } : undefined,
                        spanGaps: true
                    },
                ],
            },
            options: {
                responsive: true,
                scales: {
                    x: this.chartPeriod === HealthChartPeriod.day ? {
                        type: 'time',
                        min: this.dateFrom.startOf('day').toString(),
                        max: this.dateFrom.endOf('day').toString(),
                        beginAtZero: true,
                        time: {
                            unit: 'hour',
                            stepSize: 1,
                            tooltipFormat: 'HH:mm',
                            displayFormats: {
                                hour: 'HH:mm'
                            }
                        },
                    } : {},
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (tickValue: any, index: any, ticks: any) => {
                                if (typeof tickValue === 'number' &&
                                    [HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(this.healthDataValues[0]?.type.name)) {
                                    return this.formatTime((tickValue as number) * 60);
                                }
                                return tickValue;
                            }
                        }
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems: TooltipItem<any>[]): string | string[] | void => {
                                if (tooltipItems.length > 0 && this.chartPeriod === HealthChartPeriod.day && this.chartConfiguration[this.chartPeriod] === 'bar') {
                                    let rawValue: string | null = null;
                                    if (typeof tooltipItems[0].raw === 'string') {
                                        rawValue = tooltipItems[0].raw;
                                    } else if (typeof (tooltipItems[0].raw as any)?.x === 'string') {
                                        rawValue = (tooltipItems[0].raw as any).x;
                                    }
                                    if (rawValue != null) {
                                        const date = dayjs(rawValue);
                                        if (date.isValid()) {
                                            return `${date.startOf('hour').format('HH:mm')} - ${date.endOf('hour').format('HH:mm')}`;
                                        }
                                    }
                                }
                            },
                            label: (tooltipItem: TooltipItem<any>): string | string[] | void => {
                                if ([HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(this.healthDataValues[0]?.type.name)) {
                                    let rawValue: number | null = null;
                                    if (typeof tooltipItem.raw === 'number') {
                                        rawValue = tooltipItem.raw;
                                    } else if (typeof (tooltipItem.raw as any)?.y === 'number') {
                                        rawValue = (tooltipItem.raw as any).y;
                                    }
                                    if (rawValue != null) {
                                        return `${tooltipItem.dataset.label}: ${this.formatTime(rawValue * 60)} ${this.chartValueUnitSymbol}`;
                                    }
                                }
                                if (this.chartConfiguration[this.chartPeriod] === 'bubble') {
                                    let rawValue: number | null = null;
                                    if (typeof tooltipItem.raw === 'number') {
                                        rawValue = tooltipItem.raw;
                                    } else if (typeof (tooltipItem.raw as any)?.y === 'number') {
                                        rawValue = (tooltipItem.raw as any).y;
                                    }
                                    if (rawValue != null) {
                                        return `${tooltipItem.dataset.label}: ${rawValue} ${this.chartValueUnitSymbol}`;
                                    }
                                }
                                return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ${this.chartValueUnitSymbol}`;
                            }
                        }
                    }
                }
            },
            plugins: [noDataPlugin],
        });
    }

    private formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };
}
