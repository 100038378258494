import { Injectable, inject } from '@angular/core';
import { Survey } from '../models/survey.models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
import {Subscription} from "../models/order.models";

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {
    #http = inject(HttpClient);
    #apiUrl = environment.apiUrl;

    #subscriptions = '/subscriptions';

    constructor() {}

    cancelSubscription(id: string) {
        return this.#http.post<Subscription>(`${this.#apiUrl}${this.#subscriptions}/${id}/end`, null);
    }
}
