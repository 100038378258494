import {Component, EventEmitter, Input, Output, inject, DestroyRef, OnInit} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import {
    Survey,
    SurveyPage,
    SurveyQuestionResponse,
} from '../../models/survey.models';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NavigationService} from "../../services/navigation.service";

@Component({
    selector: 'survey',
    standalone: true,
    imports: [ReactiveFormsModule, CommonModule],
    templateUrl: './survey.component.html',
    styleUrl: './survey.component.css',
})
export class SurveyComponent implements OnInit {
    @Input({required: true}) survey!: Survey;
    @Input() completeSurveyButtonLabel?: string;
    @Input() isLoading? = false;
    @Input() canFirstPageGoBack? = false;
    @Input() firstPageGoBackLabel?: string;
    @Input() lastPageHasImage = true;
    @Output() submitSurvey = new EventEmitter<Survey>();
    @Output() nextPage = new EventEmitter<{ currentPage: number, prevPage: number }>();
    @Output() prevPage = new EventEmitter<{ currentPage: number, prevPage: number }>();

    #destroyRef = inject(DestroyRef);
    #router = inject(Router);
    #fb = inject(FormBuilder);
    #navigationService = inject(NavigationService);

    form: FormGroup;
    currentPage = 0;
    currentPageValid = true;
    surveyPages: SurveyPage[] = [];

    constructor() {
        this.form = this.#fb.group({});
    }

    get isLessThanThree(): boolean {
        return this.surveyPages.length < 3;
    }

    ngOnInit() {
        this.buildForm(this.survey);

        this.form.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(value => {
            // update current page validity when the form value changes
            this.currentPageValid = this.isCurrentPageFormValid();
        });
    }

    buildForm(survey: Survey) {
        this.surveyPages = survey.surveyVersion.surveyPages;
        const group: { [key: string]: AbstractControl } = {};

        survey.surveyVersion.surveyPages.forEach((page) => {
            page.surveyQuestions?.forEach((question) => {
                let control!: AbstractControl;

                switch (question.questionType) {
                    case 'MultiPicklist':
                        const multiPicklistGroup: {
                            [key: string]: FormControl;
                        } = {};
                        question.surveyQuestionChoices?.forEach((choice) => {
                            multiPicklistGroup[choice.name] = this.#fb.control(
                                choice.isDefault || false
                            );
                        });
                        control = this.#fb.group(multiPicklistGroup, {
                            validators: question.isRequired ? [this.atLeastOneOptionSelected] : null,
                        });
                        break;

                    case 'Picklist':
                        const defaultChoice =
                            question.surveyQuestionChoices?.find(
                                (choice) => choice.isDefault
                            );
                        control = this.#fb.control(
                            defaultChoice ? defaultChoice.value : '',
                            question.isRequired ? Validators.required : null
                        );
                        break;

                    case 'FreeText':
                        control = this.#fb.control('', question.isRequired ? Validators.required : null);
                        break;
                }

                group[question.id] = control;

                question.surveyQuestionDependencies?.forEach((dependency) => {
                    const dependencyFormControl = group[dependency.controllingQuestionId];
                    if (dependencyFormControl) {
                        let shouldDisable = (typeof dependencyFormControl.value === 'object' &&
                                dependencyFormControl.value[dependency.choiceName] !== true) ||
                            dependencyFormControl.value !== dependency.choiceName;
                        if (shouldDisable) {
                            control.disable();
                        }

                        dependencyFormControl.valueChanges.subscribe(
                            (value: any) => {
                                let shouldEnable = (typeof value === 'object' && value[dependency.choiceName] === true) ||
                                    value === dependency.choiceName;

                                if (shouldEnable) {
                                    control.enable();
                                } else {
                                    control.disable();
                                }
                            }
                        );
                    }
                });
            });
        });

        this.form = this.#fb.group(group);

        // update current page validity
        this.currentPageValid = this.isCurrentPageFormValid();
    }

    atLeastOneOptionSelected(group: AbstractControl): ValidationErrors | null {
        const values = Object.values(group.value);
        return values.some((value) => value)
            ? null
            : {noOptionSelected: true};
    }

    buildResponse() {
        const surveyResponses: SurveyQuestionResponse[] = [];
        this.surveyPages.forEach((page) => {
            if (page.surveyQuestions) {
                page.surveyQuestions.forEach((question) => {
                    let response = this.form.value[question.id];
                    let numericValue = null;
                    if (
                        question.questionType === 'MultiPicklist' &&
                        typeof response === 'object'
                    ) {
                        response = Object.entries(response)
                            .filter(([key, value]) => value)
                            .map(([key, value]) => key)
                            .join(';');
                    } else if (question.questionType === 'Picklist') {
                        numericValue = question.surveyQuestionChoices?.find(choice => choice.name === response)?.value ?? null;
                    }
                    surveyResponses.push({
                        surveyQuestionId: question.id,
                        response: response,
                        numericValue: numericValue,
                    });
                });
            }
        });

        const response: Survey = {
            ...this.survey,
            surveyVersion: {
                ...this.survey!.surveyVersion,
                surveyResponses: [
                    {
                        surveyVersionId: this.survey.surveyVersion.id,
                        accountId: '',
                        contactId: '',
                        coachingSessionId: null,
                        surveyQuestionResponses: surveyResponses,
                    },
                ],
            },
        };

        return response;
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }
        this.submitSurvey.emit(this.buildResponse());
    }

    goToNextPage() {
        this.currentPageValid = this.isCurrentPageFormValid();
        if (!this.currentPageValid) {
            return;
        }

        const prevPage = this.currentPage;

        this.currentPage =
            this.currentPage < this.surveyPages.length - 1
                ? this.currentPage + 1
                : this.surveyPages.length - 1;

        // update current page validity when going back
        this.currentPageValid = this.isCurrentPageFormValid();

        if (prevPage != this.currentPage) {
            this.nextPage.next({currentPage: this.currentPage, prevPage: prevPage});
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    goToPreviousPage() {
        if (this.currentPage === 0 && this.canFirstPageGoBack) {
            this.#navigationService.navigateBack();
            return;
        }

        const prevPage = this.currentPage;

        this.currentPage = this.currentPage > 0 ? this.currentPage - 1 : 0;

        // update current page validity when going back
        this.currentPageValid = this.isCurrentPageFormValid();

        if (prevPage != this.currentPage) {
            this.prevPage.next({currentPage: this.currentPage, prevPage: prevPage});
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    isCurrentPageFormValid() {
        const surveyPage = this.surveyPages[this.currentPage];
        if (surveyPage && surveyPage.surveyQuestions) {
            return !surveyPage.surveyQuestions.some(question => this.form.controls[question.id].invalid);
        }
        return true;
    }
}
