import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../../../../services/navigation.service';
import { HealthStatisticWeightCardComponent } from '../../../../components/health-statistic-weight-card/health-statistic-weight-card.component';
import { HealthStatisticHeightCardComponent } from '../../../../components/health-statistic-height-card/health-statistic-height-card.component';
import {NavbarCategoriesComponent} from "../../components/navbar-categories/navbar-categories.component";
import { HealthStatisticRespiratoryRateCardComponent } from '../../../../components/health-statistic-respiratory-rate-card/health-statistic-respiratory-rate-card.component';

@Component({
    selector: 'app-body-category-page',
    standalone: true,
    imports: [
        HealthStatisticWeightCardComponent,
        HealthStatisticHeightCardComponent,
        HealthStatisticRespiratoryRateCardComponent,
        RouterModule,
        NavbarCategoriesComponent,
    ],
    templateUrl: './body-category-page.component.html',
    styleUrl: './body-category-page.component.css',
})
export default class BodyCategoryPageComponent {
    #navigationService = inject(NavigationService);

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
