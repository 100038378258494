import {Component, inject} from '@angular/core';
import {ChartConfiguration} from 'chart.js';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataType,
} from '../../../../../../models/health.models';
import {NavigationService} from '../../../../../../services/navigation.service';
import {AsyncPipe, NgClass} from '@angular/common';
import {HealthDataPageComponent} from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-height-page',
    standalone: true,
    imports: [HealthDataPageComponent, AsyncPipe, NgClass],
    templateUrl: './height-page.component.html',
    styleUrl: './height-page.component.css',
})
export default class HeightPageComponent {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Altezza';
    chartLabel = 'Altezza';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bubble',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar',
    }
    healthDataUnitSymbolLabel = 'cm';
    heightHealthDataType = HealthDataType.HEIGHT;
    hasTwoDecimals = true;
    payloadTypes: dataType[] = ['body'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
