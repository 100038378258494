import {Route} from '@angular/router';
import {APP_ROUTES} from '../../../../app.routes.definition';
import ConsentPageComponent from "./consent-page.component";
import VideoAndMicrophoneComponent from "./pages/video-and-microphone/video-and-microphone.component";
import DataHealthPermissionComponent from "./pages/data-health-permission/data-health-permission.component";

export const CONSENT_ROUTES: Route[] = [
    {
        path: '',
        component: ConsentPageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_CONSENT_VIDEO_AND_MICROPHONE(true),
        data: {
            title: 'Consent Video and Microphone',
            showInNavbar: false,
            showAvatar: false,
        },
        component: VideoAndMicrophoneComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_CONSENT_DATA_HEALTH_PERMISSION(true),
        data: {
            title: 'Consent Data Health Permission',
            showInNavbar: false,
            showAvatar: false,
        },
        component: DataHealthPermissionComponent,
    },
];
