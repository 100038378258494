<div class="m-0-auto max-w-screen-md fixed bottom-7 right-0 left-0 z-10 overflow-hidden">
    <div class="gradient-blur border-2 border-white p-5 flex items-center justify-between mx-6 rounded-xl">
        @for (route of routes; track $index) {
        <a [routerLink]="'/' + route.path" routerLinkActive="fill-primary" [ngClass]="{'active-notification': (userHasUnreadMessages$ | async) && ('/' + route.path) === messagingPath}" class="flex flex-col items-center justify-center">
            <svg class="w-6 h-6">
                <use [attr.href]="
                        '../../../assets/svg/svGP.svg#' + route.data!['title']
                    "></use>
            </svg>
            <strong *ngIf="route.data!['title'] != null" class="text-xxs mt-1" routerLinkActive="text-primary">
                {{ 'dumb-components.bottom-navigation-bar.' + route.data!['title'].toLowerCase() + 'Label' | transloco }}
            </strong>
        </a>
        }
    </div>
    <div class="bottom-gradient"></div>
</div>
