<div>
    <div class="flex items-center justify-center">
        <img
            class="size-14"
            src="../../../assets/img/emoji/dizzy_face.png"
            alt=""
        />
    </div>
    <div class="mt-5 text-center">
        <h1 class="font-bold text-xl">Abbiamo riscontrato un errore!</h1>
    </div>
    <div class="mt-4 text-center">
        <p class="text-sm">
            {{
                message
                    ? message
                    :  "Contattare il supporto."
            }}
        </p>
        <p class="text-sm mt-4" *ngIf="!isOnline">
            L'App richiede una connessione ad Internet per poter funzionare correttamente.
        </p>
    </div>
    @if (user$ | async) {
    <div class="mt-4 text-center">
        <p class="text-sm">Clicca sul link sottostante per accedere al modulo di supporto.</p>
        <a class="text-primary cursor-pointer" (click)="goToSupportPage()"
            ><u>Compila il modulo di supporto</u></a
        >
    </div>
    }

    <!--        @if (error) {-->
    <!--        <div class="mt-4 text-center">-->
    <!--            <h3>Maggiori dettagli</h3>-->
    <!--            <p class="text-sm">{{ error.name }}: {{ error.message }}</p>-->
    <!--        </div>-->
    <!--        }-->

    <div class="mt-5">
        <button
            (click)="onClose()"
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-white text-sm font-bold uppercase"
                    >Va bene</span
                >
            </div>
        </button>
    </div>
</div>
