import {
    Component,
    EventEmitter,
    Output,
    inject,
} from '@angular/core';
import {
    AbstractControlOptions,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { confirmPasswordValidator } from '../../shared/validators/confirm-password.validator';
import { first } from 'rxjs';
import { RegistrationForm } from '../../models/formgroups/registration-form.model';
import { RegistrationData } from '../../models/registration-data.model';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
import { Survey } from '../../models/survey.models';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
    RegistrationDashIconComponent
} from '../registration-dash-icon/registration-dash-icon.component';
import { InternationalPhone } from '../../models/international-phone.model';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import { AsyncPipe, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { APP_ROUTES } from '../../app.routes.definition';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ageValidator } from '../../shared/validators/majority-age.validator';
import { ModalService } from '../../services/modal.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../error-modal/error-modal.component';
import {
    NativeAPIService,
    TargetPlatform,
} from '../../services/native/native-api.service';
import { NoWhitespaceValidator } from '../../shared/validators/no-whitespace.validator';
import { LogService } from "../../services/log.service";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";

@Component({
    selector: 'registration-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        RegistrationDashIconComponent,
        NgxIntlTelInputModule,
        AsyncPipe,
        RouterLink,
        NgIf,
    ],
    templateUrl: './registration-form.component.html',
    styleUrl: './registration-form.component.css',
})
export class RegistrationFormComponent {
    @Output() isVerifyingStep = new EventEmitter<boolean>(false);
    @Output() userEmail = new EventEmitter<string>();

    #logService = inject(LogService);
    #userService = inject(UserService);
    #modalService = inject(ModalService);
    #activatedRoute = inject(ActivatedRoute);
    #nativeAPIService = inject(NativeAPIService);
    #fb = inject(FormBuilder);
    #googleAnalyticsService = inject(GoogleAnalyticsService);

    apiUrl = environment.apiUrl;
    appleAuth = '/apple-auth';
    googleAuth = '/google-auth';
    isLoading = false;
    isRegistrationCompleted = false;
    errorMessage: string | null = null;
    preferredCountries: CountryISO[] = [CountryISO.Italy];
    registrationForm!: FormGroup<RegistrationForm>;
    targetPlatform?: TargetPlatform;
    TargetPlatform = TargetPlatform;

    authPath = APP_ROUTES.AUTH();
    isError: boolean = false;
    iubenda = environment.iubenda;

    showPassword = false;
    showConfirmPassword = false;

    constructor() {
        if (this.#nativeAPIService.isAvailable()) {
            this.googleAuth = '/google-native-app-auth';
            this.appleAuth = '/apple-native-app-auth';
            this.detectDeviceType();
        }

        this.initializeForm();

        this.isError = this.#activatedRoute.snapshot.fragment === 'error';
        if (this.isError) {
            this.showRegistrationError();
        } else {
            this.#activatedRoute.fragment
                .pipe(takeUntilDestroyed())
                .subscribe((value) => {
                    if (value === 'error') {
                        this.showRegistrationError();
                    }
                });
        }
        this.isRegistrationCompleted = this.#userService.isRegistered;
    }

    detectDeviceType() {
        if (this.#nativeAPIService.isAvailable()) {
            this.#nativeAPIService.defaultTargetPlatform().then((platform) => {
                this.targetPlatform = platform;
            });
        }
    }

    private initializeForm(): void {
        this.registrationForm = this.#fb.nonNullable.group(
            {
                firstName: this.#fb.nonNullable.control('', [
                    Validators.required,
                    NoWhitespaceValidator(),
                ]),
                lastName: this.#fb.nonNullable.control('', [
                    Validators.required,
                    NoWhitespaceValidator(),
                ]),
                email: this.#fb.nonNullable.control('', [
                    Validators.required,
                    Validators.email,
                ]),
                birthDate: this.#fb.nonNullable.control('', [
                    Validators.required,
                    ageValidator(),
                ]),
                password: this.#fb.nonNullable.control('', [
                    Validators.required,
                    Validators.minLength(8),
                ]),
                confirmPassword: this.#fb.nonNullable.control('', [
                    Validators.required,
                ]),
                mobilePhone: this.#fb.nonNullable.control(
                    {} as InternationalPhone,
                    [Validators.required]
                ),
                policies: this.#fb.nonNullable.control(false, [
                    Validators.requiredTrue,
                ]),
                healthDataConsent: this.#fb.nonNullable.control(false, [
                    Validators.requiredTrue,
                ]),
                marketingConsent: this.#fb.nonNullable.control(false),
            },
            {
                validator: confirmPasswordValidator(
                    'password',
                    'confirmPassword'
                ),
            } as AbstractControlOptions
        );
    }

    onRegistrationFormSubmit(): void {
        if (this.registrationForm.invalid || this.isLoading) return;
        this.isLoading = true;

        const {
            firstName,
            lastName,
            email,
            birthDate,
            password,
            mobilePhone,
            healthDataConsent,
            marketingConsent,
        } = this.registrationForm.value;

        const userData: RegistrationData = {
            firstName: firstName!,
            lastName: lastName!,
            email: email!.toLowerCase(),
            birthDate: birthDate!,
            password: password!,
            mobilePhone: mobilePhone!.e164Number!,
            healthDataConsent: healthDataConsent!,
            marketingConsent: marketingConsent ?? false,
        };

        this.#googleAnalyticsService.sendAnalyticsEvent({
            eventName: 'registrazione',
            email: userData.email
        });

        this.#userService
            .registerAccount(userData)
            .pipe(first())
            .subscribe({
                next: (response: string) => {
                },
                error: (error: HttpErrorResponse) => {
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                            message: error.status === 409 ? 'Email già registrata.' : 'Errore durante la registrazione.',
                        },
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                    this.emitUserEmail(userData.email);
                    this.emitVerificationStep();
                },
            });
    }

    showLoginForm() {
        this.isRegistrationCompleted = false;
        this.#userService.isRegistered = false;
    }

    togglePassword(field: 'password' | 'confirmPassword'): void {
        if (field === 'password') {
            this.showPassword = !this.showPassword;
        } else {
            this.showConfirmPassword = !this.showConfirmPassword;
        }
    }

    emitVerificationStep() {
        this.isVerifyingStep.emit(true);
    }

    emitUserEmail(userEmail: string) {
        this.userEmail.emit(userEmail);
    }

    private showRegistrationError() {
        this.#modalService.open<ErrorModalData, ErrorModalComponent>(
            ErrorModalComponent,
            {
                data: {
                    error: new Error('Email già registrata. Si prega di riprovare.'),
                    message: 'Email già registrata. Si prega di riprovare.',
                },
            }
        );
    }
}
