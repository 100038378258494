@if (isRegistrationCompleted) {
    <app-registration-dash-icon>
        <svg class="w-14 h-auto fill-primary">
            <use href="../../../assets/svg/svGP.svg#paste-clipboard"></use>
        </svg>
    </app-registration-dash-icon>
    <div class="mt-10">
        <div class="text-center px-5">
            <h1 class="text-primary font-semibold text-3xl">
                Complimenti! Hai completato la registrazione
            </h1>
        </div>
        <div class="mt-24">
            <img src="../assets/img/illustrations/illustration-registration-complete.png" class="w-full" alt=""/>
        </div>
        <div class="mx-5">
            <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-14 w-full"
                    (click)="showLoginForm()">
                <div class="flex items-center justify-center">
                    <span class="text-white text-sm font-bold uppercase">Accedi</span>
                </div>
            </button>
        </div>
    </div>
} @else {
    <div class="flex items-center justify-between px-3">
        <button class="p-2 border border-primary rounded-full my-3" [routerLink]="authPath">
            <svg class="w-4 h-4 fill-primary">
                <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
            </svg>
        </button>
        <div>
            <img class="h-4 w-auto" src="../../../assets/img/registration-carousel/v2/logo-wide.png" alt="">
        </div>
        <div class="p-2 border border-primary rounded-full my-3 invisible pointer-events-none select-none">
            <svg class="w-4 h-4 fill-primary">
                <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
            </svg>
        </div>
    </div>

    <section class="mt-10 relative">
        <div>
            <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl">
                <h1 class="mt-2 font-semibold text-3xl text-center">Tutto inizia con te.</h1>
                <span class="text-danger">{{ errorMessage }}</span>
                <p class="mt-3 text-center">
                    Inserisci i tuoi dati e scopri come creare il tuo percorso di benessere.
                </p>

                <div class="my-7">
                    <a class="py-5 px-4 border border-light-grey rounded-lg flex items-center justify-center mb-2.5"
                       href="{{ apiUrl }}{{ googleAuth }}">
                        <div>
                            <svg class="w-4 h-4 translate-y-px me-2">
                                <use href="../../../assets/svg/svGP.svg#google-logo"></use>
                            </svg>
                        </div>
                        <div>
                            <span
                                class="font-titillium text-sm font-bold uppercase">{{ "components.registration-form.registerWithGoogleLabel" | transloco }}</span>
                        </div>
                    </a>
                    <a class="py-5 px-4 border border-light-grey rounded-lg flex items-center justify-center mb-2.5 "
                       href="{{ apiUrl }}{{ appleAuth }}">
                        <div>
                            <svg class="w-4 h-4 translate-y-px me-2">
                                <use href="../../../assets/svg/svGP.svg#apple-logo"></use>
                            </svg>
                        </div>
                        <div>
                            <span
                                class="font-titillium text-sm font-bold uppercase">{{ "components.registration-form.registerWithAppleLabel" | transloco }}</span>
                        </div>
                    </a>
                </div>

                <div class="mb-2 text-center">
                    <span>Oppure</span>
                </div>

                <form [formGroup]="registrationForm" (ngSubmit)="onRegistrationFormSubmit()">
                    <div class="flex flex-col mt-1.5">
                        <div class="relative mt-4">
                            <label for="firstName"
                                   class=" text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">
                                {{ "components.registration-form.firstNameLabel" | transloco }}
                            </label>
                            <input id="firstName"
                                   class="py-5 px-4 border border-light-grey rounded-lg mt-2.5 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                   type="text" formControlName="firstName" placeholder="Inserisci il tuo nome"/>
                        </div>
                        @if (registrationForm.controls['firstName'].invalid && registrationForm.controls['firstName'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci un nome valido.</span>
                        }
                        <div class="relative mt-4">
                            <label for="lastName"
                                   class="text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">
                                {{ "components.registration-form.lastNameLabel" | transloco }}
                            </label>
                            <input id="lastName"
                                   class="py-5 px-4 border border-light-grey rounded-lg mt-2.5 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                   type="text" formControlName="lastName" placeholder="Inserisci il tuo cognome"/>
                        </div>
                        @if (registrationForm.controls['lastName'].invalid && registrationForm.controls['lastName'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci un cognome valido.</span>
                        }
                        <div class="relative mt-4">
                            <label for="emailLabel"
                                   class="text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">
                                {{ "components.registration-form.emailLabel" | transloco }}
                            </label>
                            <input id="emailLabel"
                                   class="py-5 px-4 border border-light-grey rounded-lg mt-2.5 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                   type="email" formControlName="email" placeholder="Inserisci la tua mail"/>
                        </div>
                        @if (registrationForm.controls['email'].invalid && registrationForm.controls['email'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci una email valida.</span>
                        }
<!--                        <div class="relative mt-4">-->
<!--                            <label for="birthDate"-->
<!--                                   class="text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">-->
<!--                                {{ "components.registration-form.birthDateLabel"| transloco }}-->
<!--                            </label>-->
<!--                            <input id="birthDate"-->
<!--                                   [class]="targetPlatform === TargetPlatform.iOS ? 'ios-device' : 'not-ios-device'"-->
<!--                                   class="py-5 px-4 bg-transparent border border-light-grey rounded-lg mt-2.5 w-full"-->
<!--                                   type="date" formControlName="birthDate" placeholder=" "/>-->
<!--                        </div>-->
<!--                        @if (registrationForm.controls['birthDate'].errors?.['invalidAge'] && registrationForm.controls['birthDate'].touched) {-->
<!--                            <span class="text-danger text-sm mt-2">La data di nascita non è valida: devi essere maggiorenne per proseguire con l'iscrizione.</span>-->
<!--                        }-->
                        <div class="relative mt-4">
                            <label for="passwordLabel"
                                   class="text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">
                                {{ "components.registration-form.passwordLabel" | transloco }}
                            </label>
                            <input id="passwordLabel"
                                   class="py-5 px-4 border border-light-grey rounded-lg mt-2.5 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                   [type]="showPassword ? 'text' : 'password'" formControlName="password"
                                   placeholder="Password"/>
                            <svg class="absolute size-6 bottom-[20px] right-[20px] fill-primary cursor-pointer"
                                 (click)="togglePassword('password')">
                                <use
                                    [attr.href]="showPassword ? '../../../assets/svg/svGP.svg#password-hide' : '../../../assets/svg/svGP.svg#password'"></use>
                            </svg>
                        </div>
                        @if (registrationForm.controls['password'].invalid && registrationForm.controls['password'].touched) {
                            <span class="text-danger text-sm mt-2">Per favore inserisci una password di almeno 8 caratteri.</span>
                        } @else {
                            <span class="text-xs mt-2">Minimo 8 caratteri</span>
                        }
                        <div class="relative mt-4">
                            <label for="confirmPasswordLabel"
                                   class="text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">
                                {{ "components.registration-form.confirmPasswordLabel" | transloco }}
                            </label>
                            <input id="confirmPasswordLabel"
                                   class="py-5 px-4 border border-light-grey rounded-lg mt-2.5 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                   [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword"
                                   placeholder="Conferma password"/>
                            <svg class="absolute size-6 bottom-[20px] right-[20px] fill-primary cursor-pointer"
                                 (click)="togglePassword('confirmPassword')">
                                <use
                                    [attr.href]="showConfirmPassword ? '../../../assets/svg/svGP.svg#password-hide' : '../../../assets/svg/svGP.svg#password'"></use>
                            </svg>
                        </div>
                        @if (registrationForm.controls['confirmPassword'].errors && registrationForm.controls['confirmPassword'].touched) {
                            <span
                                class="text-danger text-sm mt-2">{{ registrationForm.controls["confirmPassword"].errors["confirmedValidator"] }}</span>
                        }
<!--                        <div class="relative ngx-tel mt-4 w-100">-->
<!--                            <label for="mobilePhone"-->
<!--                                   class="text-[#6e6e5e] duration-300 transform z-10 origin-[0] px-0 text-sm peer-focus:text-primary font-bold">-->
<!--                                {{ "components.registration-form.mobilePhoneLabel" | transloco }}-->
<!--                            </label>-->
<!--                            <ngx-intl-tel-input [cssClass]="'form-control mt-2.5'"-->
<!--                                                [customPlaceholder]="'components.registration-form.mobilePhoneLabel' | transloco"-->
<!--                                                [preferredCountries]="preferredCountries"-->
<!--                                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true"-->
<!--                                                [searchCountryFlag]="true"-->
<!--                                                [searchCountryPlaceholder]="'components.registration-form.searchCountry' | transloco"-->
<!--                                                [selectFirstCountry]="true" [phoneValidation]="true"-->
<!--                                                [separateDialCode]="true"-->
<!--                                                [required]="false" id="mobilePhone"-->
<!--                                                formControlName="mobilePhone"></ngx-intl-tel-input>-->
<!--                        </div>-->
<!--                        @if (registrationForm.controls['mobilePhone'].invalid && registrationForm.controls['mobilePhone'].touched) {-->
<!--                            <span class="text-danger text-sm mt-2">Inserire un numero di telefono valido per il prefisso selezionato.</span>-->
<!--                        }-->
                        <div>
                            <div class="flex items-center justify-start mt-5">
                                <input type="checkbox" class="me-2" formControlName="policies" id="policies"/>
                                <span class="text-sm">
                                <label for="policies">* Dichiaro di aver preso visione e di accettare la </label>
                                <a class="text-primary cursor-pointer" [href]="iubenda.privacyPolicy" target="_blank">privacy policy</a> e i
                                <a class="text-primary cursor-pointer" [href]="iubenda.termsAndConditions"
                                   target="_blank">termini e le condizioni</a>.
                            </span>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center justify-start mt-5">
                                <input type="checkbox" class="me-2" formControlName="healthDataConsent"
                                       id="healthDataConsent"/>
                                <span class="text-sm">
                                <label for="healthDataConsent">* Acconsento al trattamento dei miei dati personali relativi alla mia salute che deciderò di comunicare durante l’utilizzo di Be Our Best.</label>
                            </span>
                            </div>
                        </div>

                        <div>
                            <div class="flex items-center justify-start mt-5">
                                <input type="checkbox" class="me-2" formControlName="marketingConsent"
                                       id="marketingConsent"/>
                                <span class="text-sm">
                                <label for="marketingConsent">Acconsento al trattamento dei miei dati personali per ricevere e-mail aventi contenuto promozionale in relazione a nuovi prodotti e servizi promossi da Be Our Best.</label>
                            </span>
                            </div>
                        </div>

                        <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                                type="submit" [disabled]="registrationForm.invalid || isLoading">
                            <div class="flex items-center">
                                @if (isLoading) {
                                    <span class="button-loader"></span>
                                } @else {
                                    <span
                                        class="text-white text-sm font-bold uppercase">{{ "components.registration-form.registerLabel" | transloco }}</span>
                                }
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
}
