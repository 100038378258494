import {createAction, props} from '@ngrx/store';
import {AddHealthDataRequest} from "../../models/add-health-data.models";
import {HttpErrorResponse} from "@angular/common/http";
import {HealthDataType, InternalHealthDataValue} from "../../models/health.models";
import {GetInternalValuesRequestOptions} from "../../services/health-data.service";

export const createInternalHealthDataValue = createAction('[InternalHealthData] Create Internal Health Data Value',
    props<{ request: AddHealthDataRequest }>()
);

export const createInternalHealthDataValueSuccess = createAction('[InternalHealthData] Create Internal Health Data Value Success',
    props<{ data: InternalHealthDataValue }>()
);

export const createInternalHealthDataValueFailure = createAction('[InternalHealthData] Create Internal Health Data Value Failure',
    props<{ error: HttpErrorResponse }>()
);

export const getInternalHealthDataValue = createAction('[InternalHealthData] Get Internal Health Data Value',
    props<{ id: string, typeName: HealthDataType }>()
);

export const getInternalHealthDataValueSuccess = createAction('[InternalHealthData] Get Internal Health Data Value Success',
    props<{ data: InternalHealthDataValue }>()
);

export const getInternalHealthDataValueFailure = createAction('[InternalHealthData] Get Internal Health Data Value Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateInternalHealthDataValue = createAction('[InternalHealthData] Update Internal Health Data Value',
    props<{ id: string, request: AddHealthDataRequest }>()
);

export const updateInternalHealthDataValueSuccess = createAction('[InternalHealthData] Update Internal Health Data Value Success');

export const updateInternalHealthDataValueFailure = createAction('[InternalHealthData] Update Internal Health Data Value Failure',
    props<{ error: HttpErrorResponse }>()
);

export const deleteInternalHealthDataValue = createAction('[InternalHealthData] Delete Internal Health Data Value',
    props<{ data: InternalHealthDataValue }>()
);

export const deleteInternalHealthDataValueSuccess = createAction('[InternalHealthData] Delete Internal Health Data Value Success',
    props<{ data: InternalHealthDataValue }>()
);

export const deleteInternalHealthDataValueFailure = createAction('[InternalHealthData] Delete Internal Health Data Value Failure',
    props<{ error: HttpErrorResponse }>()
);

export const getInternalHealthDataValues = createAction('[InternalHealthData] Get Internal Health Data Values',
    props<{ typeName: HealthDataType; options: GetInternalValuesRequestOptions; }>()
);

export const getInternalHealthDataValuesSuccess = createAction('[InternalHealthData] Get Internal Health Data Values Success',
    props<{ data: InternalHealthDataValue[]; options: GetInternalValuesRequestOptions; }>()
);

export const getInternalHealthDataValuesFailure = createAction('[InternalHealthData] Get Internal Health Data Values Failure',
    props<{ error: HttpErrorResponse }>()
);

export const loadMoreInternalHealthDataValues = createAction('[InternalHealthData] Load More Internal Health Data Values',
    props<{ typeName: HealthDataType; options: GetInternalValuesRequestOptions; }>()
);
