import {
    AfterViewInit,
    Component,
    OnInit,
    inject,
    signal,
} from '@angular/core';
import {
    ActivatedRoute,
    ActivationStart,
    NavigationEnd,
    Router,
    RouterModule,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { selectUserData } from '../../store/user/user.selectors';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NavigationService } from '../../services/navigation.service';
import { Subscription, filter } from 'rxjs';
import { routes } from '../../app.routes';
import {APP_ROUTES} from "../../app.routes.definition";

@Component({
    selector: 'top-navigation-bar',
    standalone: true,
    imports: [RouterModule, AsyncPipe, CommonModule],
    templateUrl: './top-navigation-bar.component.html',
    styleUrl: './top-navigation-bar.component.css',
})
export class TopNavigationBarComponent {
    #store = inject(Store<AppState>);
    #navigationService = inject(NavigationService);
    #activatedRoute = inject(ActivatedRoute);
    #router = inject(Router);

    user$ = this.#store.select(selectUserData);

    homePath = APP_ROUTES.HOME();
    settingsPath = APP_ROUTES.SETTINGS();

    showAvatar = signal(true);
    showTopBarLinks = signal(true);

    ngOnInit() {
        // Check if the avatar should be shown on init
        this.checkRouteData();

        // Check if the avatar should be shown on navigation end
        this.#router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.checkRouteData();
            });
    }

    checkRouteData() {
        let currentRoute: ActivatedRoute | null = this.#activatedRoute.root;
        let showAvatar = false;
        let showTopBarLinks = true;
        do {
            const childrenRoutes = currentRoute.children;
            currentRoute = null;
            childrenRoutes.forEach((route) => {
                if (route.outlet === 'primary') {
                    currentRoute = route;
                    showAvatar = route.snapshot.data['showAvatar'] ?? showAvatar;
                    showTopBarLinks = route.snapshot.data['showTopBarLinks'] ?? showTopBarLinks;
                }
            });
        } while (currentRoute);

        this.showAvatar.update((v) => (v = showAvatar));
        this.showTopBarLinks.update((v) => (v = showTopBarLinks));
    }

    goBack() {
        this.#navigationService.navigateBack();
    }
}
