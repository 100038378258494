import {Component, inject} from '@angular/core';
import {ModalService} from "../../../services/modal.service";
import {AsyncPipe} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TierCardComponent} from "../../../components/tier-card/tier-card.component";

@Component({
  selector: 'app-android-health-connect-modal-content',
  standalone: true,
    imports: [
        AsyncPipe,
        ReactiveFormsModule,
        TierCardComponent
    ],
  templateUrl: './android-health-connect-modal-content.component.html',
  styleUrl: './android-health-connect-modal-content.component.css'
})
export class AndroidHealthConnectModalContentComponent {
    #modalService = inject(ModalService);

    onClose() {
        this.#modalService.close();
    }
}
