import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    selectUserBaseSubscription,
    selectUserOpenOrders,
    selectUserOtherSubscriptions,
} from '../../../../store/user/user.selectors';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {AsyncPipe, CurrencyPipe, NgClass, NgIf} from '@angular/common';
import { NavigationService } from '../../../../services/navigation.service';
import {
    cancelOrder,
    completeOrder,
} from '../../../../store/create-order/create-order.actions';
import { selectCreateOrderIsLoading } from '../../../../store/create-order/create-order.selectors';
import { APP_ROUTES } from '../../../../app.routes.definition';
import { cancelSubscription } from '../../../../store/cancel-subscription/cancel-subscription.actions';
import { TierCardComponent } from '../../../../components/tier-card/tier-card.component';
import { Product, ProductFeature } from '../../../../models/product.model';
import { ModalService } from '../../../../services/modal.service';
import { BeginCancelSubscriptionModalComponent } from './begin-cancel-subscription-modal/begin-cancel-subscription-modal.component';
import { Order, OrderItem, OrderItemProduct } from '../../../../models/order.models';

@Component({
    selector: 'profile-page',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        RouterModule,
        NgxIntlTelInputModule,
        TierCardComponent,
        AsyncPipe,
        NgIf,
        NgClass,
        CurrencyPipe,
    ],
    templateUrl: './subscriptions-page.component.html',
})
export default class SubscriptionsPageComponent {
    #store = inject(Store);
    #navigationService = inject(NavigationService);
    #modalService = inject(ModalService);

    userBaseSubscription$ = this.#store.select(selectUserBaseSubscription);
    userOtherSubscriptions$ = this.#store.select(selectUserOtherSubscriptions);
    pendingOrders$ = this.#store.select(selectUserOpenOrders);

    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);
    selectPlanPath = APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN();
    marketplacePath = APP_ROUTES.MARKETPLACE();
    productPath = APP_ROUTES.MARKETPLACE_PRODUCT;

    constructor() {
        this.#navigationService.saveCurrentRoute();
    }

    onSubmitBeginCancelSubscription(order: Order) {
        this.#modalService.open(BeginCancelSubscriptionModalComponent, {
            data: {
                order: order,
            },
        });
    }

    getProduct(orderItem: OrderItem): Product {
        return {
            ...orderItem.product,
            isActive: true,
            isFeaturedProduct: false,
            price: orderItem.unitPrice,
        };
    }

    cancelSubscription(id: string) {
        this.#store.dispatch(
            cancelSubscription({
                id: id,
            })
        );
    }

    completeOrder(id: string) {
        this.#store.dispatch(
            completeOrder({
                id: id,
            })
        );
    }

    cancelOrder(id: string) {
        this.#store.dispatch(
            cancelOrder({
                id: id,
            })
        );
    }
}
