import {Component, OnInit, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SearchBarComponent} from '../../components/search-bar/search-bar.component';
import {TranslocoModule} from '@ngneat/transloco';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {selectUserBaseSubscription} from "../../store/user/user.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.state";
import {MarketplacePageStore} from "./marketplace-page.store";
import {provideComponentStore} from "@ngrx/component-store";
import {APP_ROUTES} from "../../app.routes.definition";
import CarouselComponent from "../../components/carousel/carousel.component";
import {TierCardComponent} from "../../components/tier-card/tier-card.component";
import LoaderComponent from "../../components/loader/loader.component";
import {NavigationService} from "../../services/navigation.service";
import { ProductCardComponent } from '../../components/product-card/product-card.component';

@Component({
    selector: 'marketplace-page',
    standalone: true,
    imports: [FormsModule, RouterModule, ProductCardComponent, SearchBarComponent, TranslocoModule, NgIf, AsyncPipe, CarouselComponent, TierCardComponent, LoaderComponent, NgClass],
    templateUrl: './marketplace-page.component.html',
    styleUrl: './marketplace-page.component.css',
    providers: [provideComponentStore(MarketplacePageStore)]
})
export default class MarketplacePageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #componentStore = inject(MarketplacePageStore);
    #navigationService = inject(NavigationService);

    userBasePlan$ = this.#store.select(selectUserBaseSubscription);
    products$ = this.#componentStore.data$;
    isLoading$ = this.#componentStore.isLoading$;

    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();
    selectPlanPath = APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN();

    currentProductSlide = 0;

    constructor() {
        this.#navigationService.saveCurrentRoute(true);
    }

    ngOnInit(): void {
        this.#componentStore.fetchProducts();
    }
}
