import { AbstractControl, ValidatorFn } from '@angular/forms';
import CodiceFiscale from 'codice-fiscale-js';

export function fiscalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }

        const fiscalCode = control.value;
        try {
            new CodiceFiscale(fiscalCode);
            return null;
        } catch (error) {
            return { invalidFiscalCode: error };
        }
    };
}
