import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";
import {PermissionStatus, PermissionType} from "../../models/permission.models";


/**
 * This service allows interaction with Agora chat native APIs.
 *
 * Official API: https://pub.dev/packages/agora_chat_sdk
 */
@Injectable({
    providedIn: 'root'
})
export class AgoraChatNativeService {
    #nativeAPIService = inject(NativeAPIService);

    constructor() {
    }

    /**
     * Checks if the native Permission Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }

    loginWithAgoraToken(options: {userId: string, agoraToken: string}) {
        return this.#nativeAPIService.callHandler<void>('AgoraChatSdk.loginWithAgoraToken', options);
    }

    logout(options: {unbindDeviceToken: boolean} = {unbindDeviceToken: true}) {
        return this.#nativeAPIService.callHandler<void>('AgoraChatSdk.logout', options);
    }
}
