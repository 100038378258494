import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import {NgIf} from "@angular/common";
import { NoWhitespaceValidator } from '../../shared/validators/no-whitespace.validator';

@Component({
    selector: 'message-form',
    standalone: true,
    imports: [ReactiveFormsModule, TranslocoModule, NgIf],
    templateUrl: './message-form.component.html',
})
export class MessageFormComponent {
    @Input() disabled = true;

    @Output() newMessage = new EventEmitter<string>();

    messageForm!: FormGroup;
    messageMaxLength = 2000;

    constructor() {
        this.initializeForm();
    }

    onMessageFormSubmit() {
        if (this.messageForm.invalid || this.disabled) return;
        this.emitNewMessage();
    }

    private initializeForm() {
        this.messageForm = new FormGroup({
            message: new FormControl('', [Validators.required, Validators.maxLength(this.messageMaxLength), NoWhitespaceValidator()]),
        });
    }

    private emitNewMessage() {
        this.newMessage.emit(this.messageForm.value.message);
        this.messageForm.reset();
    }
}
