import { Component, inject } from '@angular/core';
import { ModalService } from '../../../../../../../../services/modal.service';
import { NgFor } from '@angular/common';
import { FileSaverService } from 'ngx-filesaver';
import { NativeAPIService } from '../../../../../../../../services/native/native-api.service';
import {
    ShareNativeService,
    ShareResultStatus,
} from '../../../../../../../../services/native/share-native.service';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../../../../../../../app.routes.definition';
import { LogService } from '../../../../../../../../services/log.service';

@Component({
    selector: 'app-two-factor-activated-modal-content',
    standalone: true,
    imports: [NgFor],
    templateUrl: './two-factor-activated-modal-content.component.html',
    styleUrl: './two-factor-activated-modal-content.component.css',
})
export class TwoFactorActivatedModalContentComponent {
    #modalService = inject(ModalService);
    #loggerService = inject(LogService);
    #nativeAPIService = inject(NativeAPIService);
    #shareNativeService = inject(ShareNativeService);
    #fileSaver = inject(FileSaverService);
    #router = inject(Router);

    twoFactorSetupPath = APP_ROUTES.SETTINGS_TWO_FACTOR_SETUP(false);

    recoveryCodes: string[] = [];

    constructor() {}

    async onSubmitDownloadTxt(recoveryCodes: string[]) {
        const filename = 'beourbest_recovery_codes.txt';
        const data = recoveryCodes.join('\n');
        const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
        if (this.#nativeAPIService.isAvailable()) {
            const file = new File([blob], filename, {
                type: 'text/plain',
            });
            const shareData: ShareData = {
                title: filename,
                files: [file],
            };
            if (navigator.share != null && navigator.canShare(shareData)) {
                await navigator.share(shareData);
            } else {
                const blobData = Array.from(
                    new Uint8Array(await blob.arrayBuffer())
                );
                const result = await this.#shareNativeService.shareXFiles({
                    files: [blobData],
                    subject: filename,
                });
                if (result.status !== ShareResultStatus.success) {
                    this.#loggerService.log('cannot download recovery codes');
                }
            }
        } else {
            this.#fileSaver.save(blob, filename);
        }
    }

    onClose() {
        this.#modalService.close();
    }

    ngOnDestroy() {
        this.#router.navigate([this.twoFactorSetupPath]);
    }
}
