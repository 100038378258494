<app-registration-dash-icon>
    <svg class="w-14 h-auto fill-primary">
        <use href="../../../assets/svg/svGP.svg#registration-pencil"></use>
    </svg>
</app-registration-dash-icon>

<section class="mt-10 relative">
    <div>
        <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl">
            <h1 class="text-primary font-semibold text-3xl">Registrati</h1>
            <p class="mt-3">Inserisci i tuoi dati per completare la registrazione.</p>
            <form
                [formGroup]="registrationWithProviderForm"
                (ngSubmit)="onRegistrationFormSubmit()"
            >
                <div class="flex flex-col mt-3.5">
                    <div class="relative mt-2.5">
                        <input
                            id="firstNameLabel"
                            class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                            type="text"
                            formControlName="firstName"
                            placeholder=" "
                        />
                        <label
                            for="firstNameLabel"
                            class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >{{ "components.registration-form.firstNameLabel"| transloco }}</label>
                    </div>
                    <!-- Gestione errore campo form firstname da stilizzare -->
                    @if (registrationWithProviderForm.controls['firstName'].invalid && registrationWithProviderForm.controls['firstName'].touched) {
                        <span class="text-danger text-sm mt-2">Per favore inserisci un nome valido.</span>
                    }
                    <!-- Fine gestion errore campo form firstname-->
                    <div class="relative mt-2.5">
                        <input
                            id="lastNameLabel"
                            class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                            type="text"
                            formControlName="lastName"
                            placeholder=" "
                        />
                        <label
                            for="lastNameLabel"
                            class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >{{ "components.registration-form.lastNameLabel"| transloco }}</label>
                    </div>
                    <!-- Gestione errore campo form lastname da stilizzare -->
                    @if (registrationWithProviderForm.controls['lastName'].invalid && registrationWithProviderForm.controls['lastName'].touched) {
                        <span class="text-danger text-sm mt-2">Per favore inserisci un cognome valido.</span>
                    }
                    <!-- Fine gestion errore campo form lastname-->
                    @if (isPrivateEmail) {
                        <p>
                            L'email descritta è una email generata dal sistema di
                            sicurezza Apple, verranno comunque inoltrate le tue
                            notifiche all'account Apple associato
                        </p>
                    }
                    <div class="relative mt-2.5">
                        <input
                            id="emailLabel"
                            class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                            type="email"
                            formControlName="email"
                            placeholder=" "
                        />
                        <label
                            for="emailLabel"
                            class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >{{ "components.registration-form.emailLabel"| transloco }}</label>
                    </div>
                    <!-- Gestione errore campo form email da stilizzare -->
                    @if (registrationWithProviderForm.controls['email'].invalid && registrationWithProviderForm.controls['email'].touched) {
                        <span class="text-danger text-sm mt-2">Per favore inserisci una email valida.</span>
                    }
                    <label
                        for="birthDate"
                        class="px-4 text-9ea5b0 text-xs mt-5"
                    >{{ "components.registration-form.birthDateLabel"| transloco }}</label>
                    <input
                        id="birthDate"
                        [class]="targetPlatform === TargetPlatform.iOS ? 'ios-device' : 'not-ios-device'"
                        class="py-5 px-4 bg-transparent border border-light-grey rounded-lg mt-1 w-full"
                        type="date"
                        formControlName="birthDate"
                        placeholder=" "
                    />
                    <!-- Gestione errore campo form birthDate da stilizzare -->
                    @if (registrationWithProviderForm.controls['birthDate'].invalid && registrationWithProviderForm.controls['birthDate'].touched) {
                        <span class="text-danger text-sm mt-2">Per favore inserisci una data di nascita valida.</span>
                    }
                    <!-- Fine gestion errore campo form birthdate-->
                    <div class="ngx-tel mt-5 w-100">
                        <label
                            for="mobilePhone"
                            class="px-4 text-9ea5b0 text-xs"
                        >{{ "components.registration-form.mobilePhoneLabel"| transloco }}</label>
                        <ngx-intl-tel-input
                            [cssClass]="'form-control'"
                            [customPlaceholder]="'components.registration-form.mobilePhoneLabel' | transloco"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryPlaceholder]="'components.registration-form.searchCountry' | transloco"
                            [selectFirstCountry]="true"
                            [phoneValidation]="true"
                            [separateDialCode]="true"
                            [required]="true"
                            id="mobilePhone"
                            formControlName="mobilePhone"
                        ></ngx-intl-tel-input>
                    </div>
                    <!-- Gestione errore campo form mobilePhone da stilizzare -->
                    @if (registrationWithProviderForm.controls['mobilePhone'].invalid && registrationWithProviderForm.controls['mobilePhone'].touched) {
                        <span class="text-danger text-sm mt-2">Inserire un numero di telefono valido per il prefisso selezionato.</span>
                    }
                    <!-- Fine gestione errore campo form mobilePhone -->
                    <!--  --- --- --- --- ---->

                    <div>
                        <div class="flex items-start justify-start mt-5">
                            <input
                                type="checkbox"
                                class="me-2"
                                formControlName="policies"
                                id="policies"
                            />
                            <span class="text-sm">
                                <label
                                    for="policies">* Dichiaro di aver preso visione e di accettare la </label>
                                <a class="text-primary cursor-pointer"
                                   [href]="iubenda.privacyPolicy" target="_blank">privacy policy</a> e i
                                <a class="text-primary cursor-pointer"
                                   [href]="iubenda.termsAndConditions" target="_blank">termini e le condizioni</a>.
                            </span>
                        </div>
                    </div>

                    <div>
                        <div class="flex items-center justify-start mt-5">
                            <input
                                type="checkbox"
                                class="me-2"
                                formControlName="healthDataConsent"
                                id="healthDataConsent"
                            />
                            <span class="text-sm">
                                    <label for="healthDataConsent">* Acconsento al trattamento dei miei dati personali relativi alla mia salute che deciderò di comunicare durante l’utilizzo di Be Our Best.</label>
                                </span>
                        </div>
                    </div>

                    <div>
                        <div class="flex items-center justify-start mt-5">
                            <input
                                type="checkbox"
                                class="me-2"
                                formControlName="marketingConsent"
                                id="marketingConsent"
                            />
                            <span class="text-sm">
                                    <label for="marketingConsent">Acconsento al trattamento dei miei dati personali per ricevere e-mail aventi contenuto promozionale in relazione a nuovi prodotti e servizi promossi da Be Our Best.</label>
                                </span>
                        </div>
                    </div>

                    <button
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                        type="submit"
                        [disabled]="registrationWithProviderForm.invalid || isLoading"
                    >
                        <div class="flex items-center">
                            @if (isLoading) {
                                <span class="button-loader"></span>
                            } @else {
                                <span
                                    class="text-white text-sm font-bold uppercase"
                                >{{ "components.registration-form.registerLabel"| transloco }}</span>
                            }
                        </div>
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>
