<app-loader *ngIf="isLoading$ | async"></app-loader>

<section class="custom-bg-white py-7 px-6 relative h-full pb-32">
    <div class="flex items-center justify-between">
        <h1 class="font-semibold text-lg">
            {{ "pages.monitor-page.pages.view-internal-health-data-page." + typeName | transloco }}
        </h1>
    </div>
    <br/>
    @if (((data$ | async)?.length ?? 0) > 0) {
        <div class="flex flex-col justify-between h-full pb-20">
            <ul>
                <li *ngFor="let data of (data$ | async)" class="flex flex-row justify-between items-center py-5 border-b border-light-grey">
                    <span>{{ data.date | date: 'dd/MM/yyyy HH:mm' }} - <strong>{{ data.value }} {{ HealthDataUnitToString[data.type.unit] }}</strong></span>
                    <div class="flex flex-row gap-2">
                        <button class="bg-transparent rounded-lg border border-primary p-2.5"
                                [routerLink]="APP_ROUTES.MONITOR_EDIT_INTERNAL_HEALTH_DATA(false, typeName, data.id)"
                                title="Modifica">
                            <svg class="fill-primary size-3.5">
                                <use href="../../../assets/svg/svGP.svg#edit"></use>
                            </svg>
                        </button>
                        <button class="bg-danger rounded-lg border-0 p-2.5" title="Elimina"
                                [disabled]="isDeleting$ | async"
                                (click)="deleteValue(data)">
                            <svg class="fill-white size-3.5">
                                <use href="../../../assets/svg/svGP.svg#close"></use>
                            </svg>
                        </button>
                    </div>
                </li>
            </ul>
            <button class="w-full bg-primary rounded-lg border-0 py-5 px-4"
                    *ngIf="canLoadMore$ | async" [disabled]="(isLoading$ | async) || (isLoadMoreLoading$ | async)" (click)="loadMore()">
                <div class="flex items-center justify-center">
                    @if (isLoadMoreLoading$ | async) {
                        <span class="button-loader"></span>
                    } @else {
                        <span class="text-white text-sm font-bold uppercase">Carica altri dati</span>
                    }
                </div>
            </button>
        </div>
    } @else {
        <p>Non ci sono dati da visualizzare 🙁.</p>
    }
</section>
