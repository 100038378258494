import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../environments/environment";
import {Voucher} from "../models/voucher.models";

@Injectable({
    providedIn: 'root',
})
export class VoucherService {
    #http = inject(HttpClient);

    #apiUrl = `${environment.apiUrl}/vouchers`;

    constructor() {}

    getOne(discountCode: string, productSKU: string) {
        return this.#http.get<Voucher>(`${this.#apiUrl}/${discountCode}?productSKU=${productSKU}`);
    }
}
