import { Component, Input } from '@angular/core';
import {RouterLink} from "@angular/router";
import {TitleCasePipe} from "@angular/common";
import {HealthDataPeriod} from "../../models/health.models";
import {TranslocoPipe} from "@ngneat/transloco";

@Component({
    selector: 'health-statistic-card',
    standalone: true,
    imports: [
        RouterLink,
        TitleCasePipe,
        TranslocoPipe
    ],
    templateUrl: './health-statistic-card.component.html',
})
export class HealthStatisticCardComponent {
    @Input({required: true}) title!: string;
    @Input({required: true}) iconPath!: string;
    @Input({required: true}) period!: HealthDataPeriod;
    @Input() isSingleValue: boolean = false;
    @Input() healthTypePath?: string | null;
    @Input() value?: string | null;
    @Input() unitSymbolLabel?: string | null;

    HealthDataPeriod = HealthDataPeriod;
}
