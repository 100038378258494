<section class="custom-bg-white py-7 px-6 relative h-full pb-28">
    @if ((userIsLoading$ | async) || (tryTerraIsGettingUserInfo$ | async)) {
        <app-loader></app-loader>
    } @else {
        <div class="flex items-center justify-between">
            <h1 class="font-semibold text-lg">
                I tuoi dispositivi
            </h1>
        </div>
        <ul>
            <li *ngFor="let provider of providers">
                <div class="flex items-center justify-between py-6 px-7 shadow rounded-lg mt-5">
                    <div class="flex items-center justify-start">
                        <img class="size-10 p-1 border border-light-grey rounded"
                             src="../../../../../assets/img/providers/{{provider.toLowerCase()}}.png"
                             [alt]="HealthProviderName[provider]">
                        <div class="flex items-start justify-center flex-col ms-5">
                            <span class="font-semibold capitalize">{{ HealthProviderName[provider] }}</span>
                            @if (userProviders.includes(provider)) {
                                <span class="text-xs py-1 px-2 bg-[#56c0804a] rounded mt-1">Connesso</span>
                            }
                        </div>
                    </div>

                    <div>
                        <button (click)="openModalConnect(provider, !userProviders.includes(provider))"
                                class="flex items-center py-2 px-3 disabled:bg-black-60 rounded-lg cursor-pointer"
                                [ngClass]="{'bg-danger': userProviders.includes(provider), 'bg-primary': !userProviders.includes(provider)}">
                            <div class="flex items-center">
                                <svg class="w-4 h-4 fill-white appointment-join__icon">
                                    @if (userProviders.includes(provider)) {
                                        <use href="../../../../../assets/svg/svGP.svg#not-watch"></use>
                                    } @else {
                                        <use href="../../../../../assets/svg/svGP.svg#watch"></use>
                                    }
                                </svg>
                            </div>
                            <div class="ms-2">
                                <span class="text-xs text-white appointment-join__text font-bold">
                                    {{ userProviders.includes(provider) ? 'Disconetti' : 'Connetti' }}
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    }
</section>
