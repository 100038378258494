<form class="mx-4 h-full" [formGroup]="form">
    <div class="flex flex-col justify-between h-full">
        <div>
            @if (surveyPages.length > 1) {
                <div class="wizard-container my-12">
                    <div
                        class="flex items-center gap-x-2 rounded-md overflow-hidden"
                    >
                        @for (page of surveyPages; track $index) {
                            <div
                                class="wizard-item bg-e9e9e9 h-3"
                                [ngClass]="{
                            'active': currentPage === $index,
                            'basis-6/12': isLessThanThree,
                            'basis-3/12': !isLessThanThree
                          }"
                            ></div>
                        }
                    </div>
                </div>
            }
            <ng-container
                *ngFor="let page of surveyPages; let pageIndex = index; let first = first; let last = last"
            >
                <div *ngIf="pageIndex === currentPage"
                     [class]="'page-' + pageIndex + ' page-type-' + page.type.toLowerCase() + ' ' + (first ? 'first-page' : (last ? 'last-page' : ''))"
                     [ngClass]="{'no-last-page-image': last && !lastPageHasImage}"
                >
                    <div *ngIf="page.type === 'Text'">
                        <!-- ultimo div -->
                        <div [innerHTML]="page.description"></div>
                        <!-- ultimo div -->
                    </div>
                    <div *ngIf="page.type === 'Questions'">
                        <div
                            *ngFor="
                                let question of page.surveyQuestions;
                                let questionIndex = index
                            "
                        >
                            <div *ngIf="!form.get(question.id)?.disabled">
                                <h3>{{ question.questionName }}<span *ngIf="question.isRequired"> *</span></h3>
                                <div
                                    *ngIf="
                                        question.questionType ===
                                        'MultiPicklist'
                                    "
                                    [formGroupName]="question.id"
                                >
                                    <label
                                        *ngFor="
                                            let choice of question.surveyQuestionChoices;
                                            let choiceIndex = index
                                        "
                                    >
                                        <input
                                            type="checkbox"
                                            [formControlName]="choice.name"
                                        />
                                        {{ choice.name }}
                                    </label>
                                </div>
                                <div
                                    *ngIf="question.questionType === 'Picklist'"
                                >
                                    <label
                                        *ngFor="
                                            let choice of question.surveyQuestionChoices;
                                            let choiceIndex = index
                                        "
                                    >
                                        <input
                                            type="radio"
                                            [formControlName]="question.id"
                                            [value]="choice.name"
                                        />
                                        {{ choice.name }}
                                    </label>
                                </div>
                                <div
                                    *ngIf="question.questionType === 'FreeText'"
                                    class="py-1 px-0.5"
                                >
                                    <input
                                        type="text"
                                        class="border"
                                        [formControlName]="question.id"
                                    />
                                </div>
                                <div
                                    *ngIf="question.questionType === 'Date'"
                                    class="py-1 px-0.5"
                                >
                                    <input
                                        type="date"
                                        class="border"
                                        [formControlName]="question.id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="flex items-center w-full pb-6">
            @if (canFirstPageGoBack || (currentPage > 0 && currentPage < surveyPages.length - 1)) {
                <button
                    class="py-5 px-4 border-0 bg-body-bg rounded-lg flex items-center justify-center w-full mt-7 me-4"
                    (click)="goToPreviousPage()"
                    [disabled]="isLoading"
                >
                    <div class="flex items-center">
                        <svg class="w-4 h-4 fill-primary me-3">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-left"
                            ></use>
                        </svg>
                        <span class="text-primary text-sm font-bold uppercase">
                            {{ currentPage === 0 && firstPageGoBackLabel != null && firstPageGoBackLabel !== '' ? firstPageGoBackLabel : 'Indietro' }}
                        </span>
                    </div>
                </button>
            }
            @if (currentPage < surveyPages.length - 1) {
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full mt-7"
                    (click)="goToNextPage()"
                    [disabled]="!currentPageValid || isLoading"
                >
                    <div class="flex items-center">
                    <span class="text-white text-sm font-bold uppercase"
                    >Avanti</span
                    >
                        <svg class="w-4 h-4 fill-white ms-3">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-right"
                            ></use>
                        </svg>
                    </div>
                </button>
            }
            @if (currentPage === surveyPages.length - 1) {
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full mt-7"
                    (click)="onSubmit()"
                    [disabled]="form.invalid || isLoading"
                >
                    <div class="flex items-center">
                        <svg class="w-4 h-4 fill-white me-3">
                            <use
                                href="../../../assets/svg/svGP.svg#check-circle"
                            ></use>
                        </svg>
                        @if (isLoading) {
                            <span class="button-loader"></span>
                        } @else {
                            <span class="text-white text-sm font-bold uppercase">{{
                                    completeSurveyButtonLabel ?? "Completa questionario"
                                }}</span>
                        }
                    </div>
                </button>
            }
        </div>
    </div>
</form>
