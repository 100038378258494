import { Component, inject, signal } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UserService } from '../../../../../../services/user.service';
import { Router, RouterModule } from '@angular/router';
import { first } from 'rxjs';
import { VerifyEmailForm } from '../../../../../../models/formgroups/verify-email-form.model';
import { VerifyOtpForm } from '../../../../../../models/formgroups/verify-otp-form.model';
import { NavigationService } from '../../../../../../services/navigation.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../../../../components/error-modal/error-modal.component';
import { LogService } from '../../../../../../services/log.service';
import { ModalService } from '../../../../../../services/modal.service';
import { GenericModalComponent } from '../../../../../../components/generic-modal/generic-modal.component';
import { APP_ROUTES } from '../../../../../../app.routes.definition';
import { InfoModalComponent, InfoModalData } from '../../../../../../components/info-modal/info-modal.component';

@Component({
    selector: 'change-email-page',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        RouterModule,
        GenericModalComponent,
    ],
    templateUrl: './change-email-page.component.html',
})
export default class ChangeEmailPageComponent {
    #fb = inject(FormBuilder);
    #router = inject(Router);
    #logService = inject(LogService);
    #modalService = inject(ModalService);
    #userService = inject(UserService);
    #navigationService = inject(NavigationService);

    #numericPattern: string = '[0-9]*';
    settingsPath = APP_ROUTES.SETTINGS(false);
    showEmailValidationForm: boolean = false;
    showOptValidationForm: boolean = false;
    verifyEmailForm!: FormGroup<VerifyEmailForm>;
    verifyOtpForm!: FormGroup<VerifyOtpForm>;
    isLoading: boolean = false;

    constructor() {
        this.showEmailForm();
        this.initializeForm();
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }

    initializeForm() {
        this.verifyEmailForm = this.#fb.nonNullable.group({
            email: ['', [Validators.required, Validators.email]],
        });
        this.verifyOtpForm = this.#fb.nonNullable.group({
            otp: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.#numericPattern),
                    Validators.minLength(6),
                    Validators.maxLength(6),
                ],
            ],
        });
    }

    showEmailForm() {
        this.showEmailValidationForm = true;
        this.showOptValidationForm = false;
    }

    showOptForm() {
        this.showEmailValidationForm = false;
        this.showOptValidationForm = true;
    }

    onSubmitVerifyEmail() {
        if (this.verifyEmailForm.invalid || this.isLoading) return;
        const { email } = this.verifyEmailForm.value;

        this.isLoading = true;

        this.#userService
            .verifyEmailChange(email!.toLowerCase())
            .pipe(first())
            .subscribe({
                error: (error) => {
                    this.isLoading = false;
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                            message: 'Email non valida',
                        },
                    });
                },
                complete: () => {
                    this.isLoading = false;
                    this.#modalService.open<
                        InfoModalData,
                        InfoModalComponent
                    >(InfoModalComponent, {
                        data: {
                            title: 'Codice di attivazione inviato',
                            message: 'Il codice di attivazione è stato inviato all\'email appena inserita',
                        },
                    });
                    this.showOptForm();
                },
            });
    }

    onSubmitVerifyOtp() {
        if (this.verifyOtpForm.invalid || this.isLoading) return;
        const { otp } = this.verifyOtpForm.value;

        this.isLoading = true;

        this.#userService
            .verifyEmailChangeWithCode(otp!)
            .pipe(first())
            .subscribe({
                error: (error) => {
                    this.isLoading = false;
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                            message: 'Codice OTP non valido',
                        },
                    });
                },
                complete: () => {
                    this.isLoading = false;
                    this.#modalService.open<
                        InfoModalData,
                        InfoModalComponent
                    >(InfoModalComponent, {
                        data: {
                            title: 'Email aggiornata con successo',
                            message: 'Cambio email avvenuto con successo. Otp correttamente verificato!',
                        },
                    });
                },
            });
    }

    onSubmitResendOtp() {
        if (this.isLoading) return;

        this.isLoading = true;

        this.#userService
            .resendEmailChangeOtp()
            .pipe(first())
            .subscribe({
                error: (error) => {
                    this.isLoading = false;
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                        },
                    });
                },
                complete: () => {
                    this.isLoading = false;
                    this.#modalService.open<
                        InfoModalData,
                        InfoModalComponent
                    >(InfoModalComponent, {
                        data: {
                            title: 'Nuovo codice OTP inviato',
                            message: 'Il codice di attivazione OTP è stato inviato nuovamente',
                        },
                    });
                },
            });
    }

    goBack() {
        this.#navigationService.navigateBack();
    }
}
