export enum ProductFeature {
    CHAT = 'CHAT',
    VIDEOCALL = 'VIDEOCALL',
    // ADVANCED_CHARTS = 'ADVANCED_CHARTS',
}

export interface Product {
    id: string;
    name: string;
    description?: string | null;
    productCode: string;
    price: number;
    subscriptionRecurringPeriod?: 'day' | 'month' | 'year' | null;
    subscriptionRecurringInterval?: number | null;
    isActive: boolean;
    imageUrl?: string | null;
    family: 'Subscription' | 'One shot purchase';
    type: 'Base' | 'Add-on';
    productFeatures?: ProductFeature[] | null;
    isFeaturedProduct: boolean;
    tier?: string | null;
    sortOrder?: number | null;
}
