<health-data-page
    [chartLabel]="chartLabel"
    [chartConfiguration]="chartConfiguration"
    [healthDataLabel]="healthDataLabel"
    [healthDataType]="healthDataType"
    [healthDataUnitSymbolLabel]="healthDataUnitSymbolLabel"
    [hasTwoDecimals]="hasTwoDecimals"
    [aggregateOperation]="HealthDataAggregateOperation.SUM"
    [payloadTypes]="payloadTypes"
/>
