<app-loader *ngIf="initialSurvey == null"></app-loader>

<div class="survey-page bg-body-bg h-screen">
    @if (initialSurvey) {
        <survey
            [survey]="initialSurvey"
            [canFirstPageGoBack]="true"
            [isLoading]="isSendingSurvey"
            (submitSurvey)="onSurveySubmit($event)"
            (nextPage)="onSurveyNextPage($event)"
            (prevPage)="onSurveyPrevPage($event)"
            (cancelSurvey)="onCancelSurvey()"
        />
    }
</div>
