import {Component, inject, OnInit} from '@angular/core';
import {
    FormBuilder, FormGroup,
    ReactiveFormsModule, Validators,
} from '@angular/forms';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {environment} from '../../../environments/environment';
import {login, loginProvider, setLoading} from '../../store/user/user.actions';
import {TranslocoModule} from '@ngneat/transloco';
import {
    RegistrationDashIconComponent
} from '../../components/registration-dash-icon/registration-dash-icon.component';
import {APP_ROUTES} from "../../app.routes.definition";
import {AsyncPipe} from "@angular/common";
import {NativeAPIService} from "../../services/native/native-api.service";
import {selectUserError, selectUserIsLoading} from "../../store/user/user.selectors";
import {LoginForm} from "../../models/formgroups/login-form.model";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-login-page',
    standalone: true,
    imports: [
        RegistrationDashIconComponent,
        TranslocoModule,
        ReactiveFormsModule,
        AsyncPipe,
        RouterLink,
    ],
    templateUrl: './login-page.component.html',
    styleUrl: './login-page.component.css',
})
export default class LoginPageComponent implements OnInit {
    #nativeAPIService = inject(NativeAPIService);
    #activatedRoute = inject(ActivatedRoute);

    testEmails: any[] = [];
    #fb = inject(FormBuilder);
    #store = inject(Store<AppState>);
    isLoading$ = this.#store.select(selectUserIsLoading);
    loginError$ = this.#store.select(selectUserError);

    accessTokenLabel = environment.accessTokenKey;
    apiUrl = environment.apiUrl;
    googleAuth = '/google-auth';
    appleAuth = '/apple-auth';
    errorMessage: string | null = null;
    loginForm!: FormGroup<LoginForm>;

    authPath = APP_ROUTES.AUTH();
    resetPasswordPath = APP_ROUTES.RESET_PASSWORD();

    constructor() {
        if (environment.loginTestEmailsEnabled) {
            this.testEmails = [
                {
                    index: 0,
                    mail: 'luca.disabatino@seidorgunpowder.com',
                    pass: 'testtest',
                },
                {
                    index: 1,
                    mail: 'giulia.rossi@gmail.com.bobdev.invalid',
                    pass: 'giulia',
                },
                {
                    index: 2,
                    mail: 'verci.claudio@libero.it.bobdev.invalid',
                    pass: 'verci',
                },
                {
                    index: 3,
                    mail: '',
                    pass: '',
                },
            ];
        }

        if (this.#nativeAPIService.isAvailable()) {
            this.googleAuth = '/google-native-app-auth';
            this.appleAuth = '/apple-native-app-auth';
        }

        this.initializeForm();

        this.loginError$.pipe(takeUntilDestroyed()).subscribe((value) => {
            if (value != null) {
                if (value.status >= 400 && value.status < 500) {
                    this.errorMessage =
                        'Credenziali non valide. Si prega di riprovare';
                } else if (value.status >= 500) {
                    // Use else if for the second condition
                    this.errorMessage =
                        'Ops, si è verificato un errore, contattare il supporto';
                }
            } else {
                this.errorMessage = '';
            }
        });
    }

    ngOnInit() {
        const accessToken =
            this.#activatedRoute.snapshot.queryParamMap.get('access_token');
        if (accessToken) {
            this.#store.dispatch(loginProvider({accessToken}));
        }
    }

    private initializeForm(): void {
        //TODO: cambiare qui per cambiare account
        // 0 luca di sabatino
        // 1 giulia rossi
        // 2 claudio verci
        // 3 input vuoto
        let testEmailsIndex = 1;

        this.loginForm = this.#fb.nonNullable.group({
            email: this.#fb.nonNullable.control(
                environment.loginTestEmailsEnabled ? this.testEmails[testEmailsIndex].mail : '',
                [Validators.required, Validators.email]
            ),
            password: this.#fb.nonNullable.control(
                environment.loginTestEmailsEnabled ? this.testEmails[testEmailsIndex].pass : '',
                [Validators.required]
            ),
        });
    }

    onLoginFormSubmit(): void {
        if (this.loginForm.invalid) return;
        const { email, password } = this.loginForm.value;

        this.#store.dispatch(login({ email: email!, password: password! }));
    }

    ngOnDestroy() {
        this.#store.dispatch(setLoading({ isLoading: false }));
    }
}
