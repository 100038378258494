import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { ModalService } from '../../../../../services/modal.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.state';
import { selectCreateOrderIsLoading } from '../../../../../store/create-order/create-order.selectors';
import { createOrder } from '../../../../../store/create-order/create-order.actions';
import { AsyncPipe, NgIf } from '@angular/common';
import { TierCardComponent } from '../../../../../components/tier-card/tier-card.component';
import { GenericModalComponent } from '../../../../../components/generic-modal/generic-modal.component';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MembershipFormModalContentComponent } from './membership-form-modal-content/membership-form-modal-content.component';
import {selectUserBillingInfoIsValid} from "../../../../../store/user/user.selectors";
import {APP_ROUTES} from "../../../../../app.routes.definition";
import {RouterLink} from "@angular/router";
import {environment} from "../../../../../../environments/environment";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import ErrorModalComponent, {ErrorModalData} from "../../../../../components/error-modal/error-modal.component";
import {Voucher} from "../../../../../models/voucher.models";
import {VoucherService} from "../../../../../services/voucher.service";
import {LogService} from "../../../../../services/log.service";

export interface TermsAndConditionsForm {
    termsAndConditions: FormControl<boolean>;
}

@Component({
    selector: 'app-buy-product-modal-content',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        TierCardComponent,
        GenericModalComponent,
        MembershipFormModalContentComponent,
        ReactiveFormsModule,
        RouterLink,
        NgxIntlTelInputModule
    ],
    templateUrl: './buy-product-modal-content.component.html',
    styleUrl: './buy-product-modal-content.component.css',
})
export default class BuyProductModalContentComponent {
    #store = inject(Store<AppState>);
    #modalService = inject(ModalService);
    #fb = inject(FormBuilder);
    #voucherService = inject(VoucherService);
    #logService = inject(LogService);

    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);
    isUserBillingInfoValid$ = this.#store.select(selectUserBillingInfoIsValid);

    product!: Product;
    termsAndConditionsForm!: FormGroup<TermsAndConditionsForm>;
    iubenda = environment.iubenda;

    billingInfoPath = APP_ROUTES.SETTINGS_BILLING_INFO();
    discountCodeForm: FormGroup<{
        discountCode: FormControl<string>
    }>;
    voucher?: Voucher | null;
    isApplyingDiscountCode = false;

    constructor() {
        this.discountCodeForm = this.#fb.group({
            discountCode: this.#fb.nonNullable.control('', [Validators.required])
        });

        this.termsAndConditionsForm = this.#fb.nonNullable.group({
            termsAndConditions: this.#fb.nonNullable.control(false, [
                Validators.requiredTrue,
            ]),
        });
    }

    applyDiscountCode() {
        if (this.isApplyingDiscountCode || this.discountCodeForm.invalid) {
            return;
        }
        this.isApplyingDiscountCode = true;
        this.voucher = null;
        const discountCode = this.discountCodeForm.controls.discountCode.value.trim();
        this.#voucherService.getOne(discountCode, this.product.productCode).subscribe({
            next: voucher => {
                this.voucher = voucher;
                this.#logService.log('Discount code applied', voucher);
                this.isApplyingDiscountCode = false;
            },
            error: error => {
                this.#logService.error('Failed to apply discount code', error);
                this.isApplyingDiscountCode = false;
                this.discountCodeForm.reset();
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                            message: 'Il codice sconto inserito non è valido oppure è scaduto.',
                        },
                    }
                );
            }
        });
    }

    removeDiscountCode() {
        this.voucher = null;
        this.discountCodeForm.reset();
    }

    createOrder() {
        if (this.termsAndConditionsForm.invalid || this.isApplyingDiscountCode) return;

        this.#store.dispatch(
            createOrder({
                request: {
                    productSKU: this.product.productCode,
                    discountCode: this.voucher?.code
                },
            })
        );
    }

    onClose() {
        this.#modalService.close();
    }
}
