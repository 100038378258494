import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from "@angular/common/http";

export const cancelSubscription = createAction('[CancelSubscription] Cancel Subscription',
    props<{ id: string }>()
);

export const cancelSubscriptionSuccess = createAction('[CancelSubscription] Cancel Subscription Success');

export const cancelSubscriptionFailure = createAction('[CancelSubscription] Cancel Subscription Failure',
    props<{ error: HttpErrorResponse }>()
);

export const resetCancelSubscription = createAction('[CancelSubscription] Reset Cancel Subscription');
