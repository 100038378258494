<div class="custom-bg-white py-7 px-6 relative h-full pb-28">
    @if (showEmailValidationForm) {
    <h2 class="font-semibold text-lg">Cambia email</h2>
    <p class="my-5">
        Il tuo indirizzo email non cambierà fino a quando non avrai verificato
        il nuovo indirizzo email con un OTP
    </p>
    <form [formGroup]="verifyEmailForm">
        <div class="flex flex-col py-3.5">
            <label for="email" class="text-595959 font-bold text-sm">
                {{
                    "pages.security-page.pages.change-email-page.emailLabel"
                        | transloco
                }}</label
            >
            <input
                type="text"
                class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm"
                id="email"
                placeholder="{{
                    'pages.security-page.pages.change-email-page.emailPlaceholderLabel'
                        | transloco
                }}"
                formControlName="email"
            />
            <!-- Gestione errore campo form email da stilizzare -->
            @if (verifyEmailForm.controls['email'].invalid &&
            verifyEmailForm.controls['email'].touched) {
            <span class="text-danger text-sm mt-2"
                >Per favore inserisci una email valida</span
            >
            }
            <!-- Fine gestion errore campo email -->
        </div>
        <button
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
            [disabled]="verifyEmailForm.invalid || isLoading"
            (click)="onSubmitVerifyEmail()"
        >
            <div class="flex items-center justify-center w-full">
                @if (isLoading) {
                <span class="button-loader"></span>
                } @else {
                <span class="text-white text-sm font-bold uppercase">{{
                    "pages.security-page.pages.change-email-page.verifyEmailLabel"
                        | transloco
                }}</span>
                }
            </div>
        </button>
        <button
            class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center mt-2 w-full"
            (click)="goBack()"
        >
            <div class="flex items-center justify-center w-full">
                <span class="text-primary text-sm font-bold"
                    >Annulla operazione</span
                >
            </div>
        </button>
    </form>
    } @else {
    <h2 class="font-semibold text-lg">Inserisci il codice OTP</h2>
    <p class="my-5">Inserisci il codice OTP ricevuto via email</p>
    <form [formGroup]="verifyOtpForm">
        <div class="flex flex-col py-3.5">
            <label for="otp" class="text-595959 font-bold text-sm">
                {{
                    "pages.security-page.pages.change-email-page.otpLabel"
                        | transloco
                }}</label
            >
            <input
                type="text"
                class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm"
                id="otp"
                placeholder="{{
                    'pages.security-page.pages.change-email-page.otpLabel'
                        | transloco
                }}"
                formControlName="otp"
                autocomplete="one-time-code"
            />
            <!-- Gestione errore campo form email da stilizzare -->
            @if (verifyOtpForm.controls['otp'].invalid &&
            verifyOtpForm.controls['otp'].touched) {
            <span class="text-danger text-sm mt-2"
                >Per favore inserisci un otp valido formato da 6 caratteri</span
            >
            }
            <!-- Fine gestion errore campo email -->
        </div>
        <button
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
            [disabled]="verifyOtpForm.invalid || isLoading"
            (click)="onSubmitVerifyOtp()"
        >
            <div class="flex items-center justify-center w-full">
                @if (isLoading) {
                <span class="button-loader"></span>
                } @else {
                <span class="text-white text-sm font-bold uppercase">{{
                    "pages.security-page.pages.change-email-page.otpLabel"
                        | transloco
                }}</span>
                }
            </div>
        </button>
        <button
            class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center mt-2 w-full"
            [disabled]="isLoading"
            (click)="onSubmitResendOtp()"
        >
            <div class="flex items-center justify-center w-full">
                <span class="text-primary text-sm font-bold uppercase">{{
                    "pages.security-page.pages.change-email-page.resendOtpLabel"
                        | transloco
                }}</span>
            </div>
        </button>
        <br /><br />
    </form>
    }
</div>
