import {createAction, props} from '@ngrx/store';
import {User, UserUpdateBillingInfoData, UserUpdateInfoData} from '../../models/users.models';
import {HttpErrorResponse} from "@angular/common/http";
import {AgoraConversation, VideoCallTracks} from "../../models/agora.models";
import {TryTerraUser} from "../../models/try-terra.models";

export const login = createAction('[User] Login', props<{ email: string; password: string }>());

export const setLoading = createAction('[User] Set Loading', props<{ isLoading: boolean }>());

export const loginProvider = createAction('[User] Login Provider', props<{ accessToken: string }>());
export const loginOtp = createAction('[User] Login OTP', props<{ otp: string }>());
export const loginRecoveryCode = createAction('[User] Login Recovery Code', props<{ recoveryCode: string }>());
export const loginSuccess = createAction('[User] Login Success', props<{ data: User }>());
export const loginFailure = createAction('[User] Login Failure', props<{ error: HttpErrorResponse }>());
export const loginOtpFailure = createAction('[User] Login OTP Failure', props<{ error: HttpErrorResponse }>());

export const loginAgoraChat = createAction('[User] Login Agora Chat', props<{ userId: string; }>());
export const loginAgoraChatSuccess = createAction('[User] Login Agora Chat Success');
export const loginAgoraChatFailure = createAction('[User] Login Agora Chat Failure', props<{ error: Error }>());
export const logoutAgoraChat = createAction('[User] Logout Agora Chat');

export const logout = createAction('[User] Logout');

export const fetchUser = createAction('[User] Fetch User');

export const fetchUserSuccess = createAction(
    '[User] Fetch User Success',
    props<{ data: User }>()
);

export const fetchUserFailure = createAction(
    '[User] Fetch User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateUser = createAction(
    '[User] Update User',
    props<{ data: UserUpdateInfoData }>()
);

export const updateUserSuccess = createAction(
    '[User] Update User Success',
    props<{ data: UserUpdateInfoData }>()
);

export const updateUserFailure = createAction(
    '[User] Update User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateUserBillingInfo = createAction(
    '[User] Update User Billing Info',
    props<{ data: UserUpdateBillingInfoData }>()
);

export const updateUserBillingInfoSuccess = createAction(
    '[User] Update User Billing Info Success',
    props<{ data: UserUpdateBillingInfoData }>()
);

export const updateUserBillingInfoFailure = createAction(
    '[User] Update User Billing Info Failure',
    props<{ error: HttpErrorResponse }>()
);

export const updateUserAvatar = createAction(
    '[User] Update User Avatar',
    props<{ avatar: Blob }>()
);

export const updateUserAvatarSuccess = createAction(
    '[User] Update User Avatar Success'
);

export const updateUserAvatarFailure = createAction(
    '[User] Update User Avatar Failure',
    props<{ error: HttpErrorResponse }>()
);

export const deleteUser = createAction(
    '[User] Delete User',
    props<{ password: string | null }>()
);

export const deleteUserSuccess = createAction(
    '[User] Delete User Success'
);

export const deleteUserFailure = createAction(
    '[User] Delete User Failure',
    props<{ error: HttpErrorResponse }>()
);

export const cancelUserSubscription = createAction(
    '[User] Cancel User Subscription',
    props<{ id: string }>()
);

export const cancelUserSubscriptionSuccess = createAction(
    '[User] Cancel User Subscription Success'
);

export const cancelUserSubscriptionFailure = createAction(
    '[User] Cancel User Subscription Failure',
    props<{ error: HttpErrorResponse }>()
);

export const fetchTryTerraUserInfo = createAction('[User] Fetch TryTerra UserInfo', props<{
    initializeNative: boolean
}>());
export const fetchTryTerraUserInfoSuccess = createAction('[User] Fetch TryTerra UserInfo Success', props<{
    userInfo: TryTerraUser[], initializeNative: boolean
}>());
export const fetchTryTerraUserInfoFailure = createAction('[User] Fetch TryTerra UserInfo Failure', props<{
    error: Error
}>());

export const initializeNativeTryTerra = createAction('[User] Initialize Native TryTerra', props<{
    triggerAllDataWebhook: boolean, daysBeforeNow?: number | null
}>());
export const initializeNativeTryTerraSuccess = createAction('[User] Initialize Native TryTerra Success', props<{
    daysBeforeNow?: number | null
}>());
export const initializeNativeTryTerraFailure = createAction('[User] Initialize Native TryTerra Failure', props<{ error: Error }>());

export const triggerNativeTryTerraWebhook = createAction('[User] Trigger Native TryTerra Webhook', props<{
    daysBeforeNow: number
}>());
export const triggerNativeTryTerraWebhookSuccess = createAction('[User] Trigger Native TryTerra Webhook Success');
export const triggerNativeTryTerraWebhookFailure = createAction('[User] Trigger Native TryTerra Webhook Failure', props<{ error: Error }>());

export const updateAgoraConversationList = createAction('[User] Update Agora Conversation List');
export const updateAgoraConversationListSuccess = createAction('[User]  Update Agora Conversation List Data Success', props<{
    conversationList: AgoraConversation[]
}>());
export const updateAgoraConversationListFailure = createAction('[User]  Update Agora Conversation List Data Failure', props<{
    error: Error
}>());

export const updateAgoraVideoCallIsMinimized = createAction('[User] Update Agora Video Call Is Minimized', props<{
    isMinimized: boolean
}>());

export const toggleAgoraVideoCallIsMinimized = createAction('[User] Update Agora Video Call Toggle Minimized');

export const joinAgoraVideoCall = createAction('[User] Join Agora Video Call', props<{
    meetingId: string;
}>());

export const joinAgoraVideoCallSuccess = createAction('[User] Join Agora Video Call Success');

export const joinAgoraVideoCallFailure = createAction('[User] Join Agora Video Call Failure', props<{
    error: Error;
}>());

export const leaveAgoraVideoCall = createAction('[User] Leave Agora Video Call', props<{
    meetingId?: string | null;
    elapsedCallTime?: number | null;
    callTimeDisplay?: string | null;
}>());
