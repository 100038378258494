import {Injectable} from '@angular/core';

declare global {
    interface Window {
        flutter_inappwebview?: {
            callHandler?: <T>(handlerName: string, ...args: any[]) => Promise<T>
        }
    }
}

export enum TargetPlatform {
    /// Android: <https://www.android.com/>
    android = 'android',

    /// Fuchsia: <https://fuchsia.dev/fuchsia-src/concepts>
    fuchsia = 'fuchsia',

    /// iOS: <https://www.apple.com/ios/>
    iOS = 'iOS',

    /// Linux: <https://www.linux.org>
    linux = 'linux',

    /// macOS: <https://www.apple.com/macos>
    macOS = 'macOS',

    /// Windows: <https://www.windows.com>
    windows = 'windows',
}

/**
 * This service allows interaction with device native APIs, such as Android and iOS.
 */
@Injectable({
    providedIn: 'root'
})
export class NativeAPIService {

    constructor() {
    }

    /**
     * Checks if access to native APIs is available.
     */
    isAvailable() {
        return window.flutter_inappwebview?.callHandler != null;
    }

    /**
     * The {@link TargetPlatform} that matches the platform on which the framework is currently executing.
     */
    defaultTargetPlatform() {
        return this.callHandler<TargetPlatform>('defaultTargetPlatform');
    }

    /**
     * Calls the native API.
     */
    callHandler<T>(handlerName: string, ...args: any[]): Promise<T> {
        return window.flutter_inappwebview!.callHandler!<T>(handlerName, ...args);
    }
}
