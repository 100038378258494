<div class="flex flex-col h-full">
    <div class="relative px-6 pt-0.5 pb-32">
        <div class="flex items-center justify-between w-full mt-5">
            <h3 class="font-semibold text-lg">Categorie</h3>
        </div>

        <div class="grid grid-rows-2 grid-flow-col gap-2 mt-5 relative">
            <a
                [routerLink]="heartPath"
                class="bg-data-red flex flex-col p-3 rounded-lg"
            >
                <div>
                    <svg class="size-8 fill-icon-data-red">
                        <use href="../assets/svg/svGP.svg#heart-full"></use>
                    </svg>
                </div>
                <div class="mt-2">
                    <span class="text-white font-bold text-sm">Cuore</span>
                </div>
            </a>
            <a
                [routerLink]="activityPath"
                class="bg-data-green flex flex-col p-3 rounded-lg"
            >
                <div>
                    <svg class="size-8 fill-icon-data-green">
                        <use href="../assets/svg/svGP.svg#activity"></use>
                    </svg>
                </div>
                <div class="mt-2">
                    <span class="text-white font-bold text-sm">Attività</span>
                </div>
            </a>
            <a
                [routerLink]="bodyPath"
                class="bg-data-yellow flex flex-col p-3 rounded-lg"
            >
                <div>
                    <svg class="size-8 fill-icon-data-yellow">
                        <use href="../assets/svg/svGP.svg#yoga"></use>
                    </svg>
                </div>
                <div class="mt-2">
                    <span class="text-white font-bold text-sm">Corpo</span>
                </div>
            </a>
            <a
                [routerLink]="sleepPath"
                class="bg-data-purple flex flex-col p-3 rounded-lg"
            >
                <div>
                    <svg class="size-8 fill-icon-data-purple">
                        <use href="../assets/svg/svGP.svg#bed"></use>
                    </svg>
                </div>
                <div class="mt-2">
                    <span class="text-white font-bold text-sm">Sonno</span>
                </div>
            </a>
        </div>
        <div>
            <div
                class="border-2 border-white bg-white rounded-lg mt-5 card-shadow-bottom py-2.5 px-3"
            >
                <h3 class="font-bold text-lg">Come ti senti oggi? 😁</h3>
                <div class="flex items-center justify-center mt-5">
                    <button
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                        [routerLink]="feelingPath"
                        [disabled]="hasTodayFeelingSurvey"
                    >
                        <div>
                            <svg class="size-6 fill-white me-2">
                                <use
                                    href="../../../assets/svg/svGP.svg#pencil"
                                ></use>
                            </svg>
                        </div>
                        <div>
                            <span class="text-white text-sm font-bold uppercase">Compila il questionario</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        @if (!(userIsLoading$ | async) && !(userTryTerraIsNativeInitializing$ | async) &&
        !(userTryTerraIsLoading$ | async) && !(userTryTerraHasProviders$ | async)) {
            <no-wearable-connected-card></no-wearable-connected-card>
        }
        <div class="flex items-center justify-between w-full my-5">
            <h3 class="font-semibold text-lg">Le tue ultime 24h</h3>
        </div>

        <health-statistic-heart-rate-card/>

        <health-statistic-steps-card/>

        <health-statistic-active-energy-burned-card/>
    </div>
</div>
