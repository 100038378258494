import {dataType} from "terra-api/lib/esm/API/Data";
import {ChartType} from "chart.js";
import {ActivityType} from "terra-api/lib/cjs/models/enums/ActivityType";

/**
 * List of all available data types.
 */
export enum HealthDataType {
    OXYGEN_SATURATION = 'OXYGEN_SATURATION',
    VO2_MAX = 'VO2_MAX',
    HYDRATION_LEVEL = 'HYDRATION_LEVEL',
    HYDRATION_MEASUREMENT = 'HYDRATION_MEASUREMENT',
    BLOOD_PRESSURE_DIASTOLIC = 'BLOOD_PRESSURE_DIASTOLIC',
    BLOOD_PRESSURE_SYSTOLIC = 'BLOOD_PRESSURE_SYSTOLIC',
    BODY_TEMPERATURE = 'BODY_TEMPERATURE',
    AMBIENT_TEMPERATURE = 'AMBIENT_TEMPERATURE',
    SKIN_TEMPERATURE = 'SKIN_TEMPERATURE',
    BODY_MASS_INDEX = 'BODY_MASS_INDEX',
    BASAL_METABOLIC_RATE = 'BASAL_METABOLIC_RATE',
    RESTING_METABOLIC_RATE = 'RESTING_METABOLIC_RATE',
    ESTIMATED_FITNESS_AGE = 'ESTIMATED_FITNESS_AGE',
    SKIN_FOLD = 'SKIN_FOLD',
    BODY_FAT_PERCENTAGE = 'BODY_FAT_PERCENTAGE',
    WEIGHT = 'WEIGHT',
    HEIGHT = 'HEIGHT',
    BONE_MASS = 'BONE_MASS',
    MUSCLE_MASS = 'MUSCLE_MASS',
    LEAN_MASS = 'LEAN_MASS',
    BODY_WATER = 'BODY_WATER',
    INSULIN_UNITS = 'INSULIN_UNITS',
    INSULIN_TYPE = 'INSULIN_TYPE',
    URINE_COLOR = 'URINE_COLOR',
    HEART_RATE = 'HEART_RATE',
    HEART_RATE_VARIABILITY_SDNN = 'HEART_RATE_VARIABILITY_SDNN',
    HEART_RATE_VARIABILITY_RMSSD = 'HEART_RATE_VARIABILITY_RMSSD',
    RESTING_HEART_RATE = 'RESTING_HEART_RATE',
    HEART_RATE_PULSE_WAVE_VELOCITY = 'HEART_RATE_PULSE_WAVE_VELOCITY',
    BLOOD_GLUCOSE = 'BLOOD_GLUCOSE',
    STRESS_LEVEL = 'STRESS_LEVEL',
    STRESS_DURATION = 'STRESS_DURATION',
    REST_STRESS_DURATION = 'REST_STRESS_DURATION',
    ACTIVITY_STRESS_DURATION = 'ACTIVITY_STRESS_DURATION',
    LOW_STRESS_DURATION = 'LOW_STRESS_DURATION',
    MEDIUM_STRESS_DURATION = 'MEDIUM_STRESS_DURATION',
    HIGH_STRESS_DURATION = 'HIGH_STRESS_DURATION',
    STRESS_RATING = 'STRESS_RATING',
    STEPS = 'STEPS',
    FLOORS_CLIMBED = 'FLOORS_CLIMBED',
    DISTANCE_METERS = 'DISTANCE_METERS',
    ELEVATION = 'ELEVATION',
    MET_LEVEL = 'MET_LEVEL',
    NET_INTAKE_CALORIES = 'NET_INTAKE_CALORIES',
    BMR_CALORIES = 'BMR_CALORIES',
    TOTAL_BURNED_CALORIES = 'TOTAL_BURNED_CALORIES',
    NET_ACTIVITY_CALORIES = 'NET_ACTIVITY_CALORIES',
    CALORIES = 'CALORIES',
    ACTIVITY_LEVEL = 'ACTIVITY_LEVEL',
    ACTIVITY_SECONDS = 'ACTIVITY_SECONDS',
    INACTIVITY_SECONDS = 'INACTIVITY_SECONDS',
    REST_SECONDS = 'REST_SECONDS',
    STANDING_SECONDS = 'STANDING_SECONDS',
    STANDING_HOURS_COUNT = 'STANDING_HOURS_COUNT',
    ACTIVITY_LOW_INTENSITY_SECONDS = 'ACTIVITY_LOW_INTENSITY_SECONDS',
    ACTIVITY_MODERATE_INTENSITY_SECONDS = 'ACTIVITY_MODERATE_INTENSITY_SECONDS',
    ACTIVITY_VIGOROUS_INTENSITY_SECONDS = 'ACTIVITY_VIGOROUS_INTENSITY_SECONDS',
    WATER = 'WATER',
    SLEEP_IN_BED = 'SLEEP_IN_BED',
    UNMEASURABLE_SLEEP = 'UNMEASURABLE_SLEEP',
    SLEEP_EFFICIENCY = 'SLEEP_EFFICIENCY',
    HYPNOGRAM_LEVEL = 'HYPNOGRAM_LEVEL',
    SLEEP_AWAKE = 'SLEEP_AWAKE',
    SLEEP_AWAKE_DURATION_SHORT_INTERRUPTION = 'SLEEP_AWAKE_DURATION_SHORT_INTERRUPTION',
    SLEEP_AWAKE_DURATION_LONG_INTERRUPTION = 'SLEEP_AWAKE_DURATION_LONG_INTERRUPTION',
    SLEEP_AWAKE_NUM_WAKEUP_EVENTS = 'SLEEP_AWAKE_NUM_WAKEUP_EVENTS',
    SLEEP_AWAKE_WAKE_UP_LATENCY = 'SLEEP_AWAKE_WAKE_UP_LATENCY',
    SLEEP_AWAKE_NUM_OUT_OF_BED_EVENTS = 'SLEEP_AWAKE_NUM_OUT_OF_BED_EVENTS',
    SLEEP_AWAKE_LATENCY = 'SLEEP_AWAKE_LATENCY',
    SLEEP_ASLEEP = 'SLEEP_ASLEEP',
    SLEEP_LIGHT = 'SLEEP_LIGHT',
    SLEEP_DEEP = 'SLEEP_DEEP',
    SLEEP_REM = 'SLEEP_REM',
    SLEEP_ASLEEP_NUM_REM_EVENTS = 'SLEEP_ASLEEP_NUM_REM_EVENTS',
    SLEEP_TEMPERATURE_DELTA = 'SLEEP_TEMPERATURE_DELTA',
    SLEEP_READINESS = 'SLEEP_READINESS',
    SLEEP_RECOVERY_LEVEL = 'SLEEP_RECOVERY_LEVEL',
    RESPIRATORY_RATE = 'RESPIRATORY_RATE',
    NUM_SNORING_EVENTS = 'NUM_SNORING_EVENTS',
    TOTAL_SNORING_DURATION = 'TOTAL_SNORING_DURATION',
    SNORING = 'SNORING',
}

/**
 * List of all {@link HealthDataUnit}s.
 */
export enum HealthDataUnit {
    // Mass units
    GRAM = 'GRAM',
    KILOGRAM = 'KILOGRAM',
    OUNCE = 'OUNCE',
    POUND = 'POUND',
    STONE = 'STONE',
    // MOLE_UNIT_WITH_MOLAR_MASS = 'MOLE_UNIT_WITH_MOLAR_MASS', // requires molar mass input - not supported yet
    // MOLE_UNIT_WITH_PREFIX_MOLAR_MASS = 'MOLE_UNIT_WITH_PREFIX_MOLAR_MASS', // requires molar mass & prefix input - not supported yet

    // Length units
    METER = 'METER',
    CENTIMETER = 'CENTIMETER',
    MILLIMETER = 'MILLIMETER',
    INCH = 'INCH',
    FOOT = 'FOOT',
    YARD = 'YARD',
    MILE = 'MILE',

    // Volume units
    LITER = 'LITER',
    MILLILITER = 'MILLILITER',
    FLUID_OUNCE_US = 'FLUID_OUNCE_US',
    FLUID_OUNCE_IMPERIAL = 'FLUID_OUNCE_IMPERIAL',
    CUP_US = 'CUP_US',
    CUP_IMPERIAL = 'CUP_IMPERIAL',
    PINT_US = 'PINT_US',
    PINT_IMPERIAL = 'PINT_IMPERIAL',

    // Pressure units
    PASCAL = 'PASCAL',
    MILLIMETER_OF_MERCURY = 'MILLIMETER_OF_MERCURY',
    INCHES_OF_MERCURY = 'INCHES_OF_MERCURY',
    CENTIMETER_OF_WATER = 'CENTIMETER_OF_WATER',
    ATMOSPHERE = 'ATMOSPHERE',
    DECIBEL_A_WEIGHTED_SOUND_PRESSURE_LEVEL = 'DECIBEL_A_WEIGHTED_SOUND_PRESSURE_LEVEL',

    // Time units
    SECOND = 'SECOND',
    MILLISECOND = 'MILLISECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',

    // Energy units
    JOULE = 'JOULE',
    KILOCALORIE = 'KILOCALORIE',
    LARGE_CALORIE = 'LARGE_CALORIE',
    SMALL_CALORIE = 'SMALL_CALORIE',

    // Temperature units
    DEGREE_CELSIUS = 'DEGREE_CELSIUS',
    DEGREE_FAHRENHEIT = 'DEGREE_FAHRENHEIT',
    KELVIN = 'KELVIN',

    // Hearing units
    DECIBEL_HEARING_LEVEL = 'DECIBEL_HEARING_LEVEL',

    // Frequency units
    HERTZ = 'HERTZ',

    // Electrical conductance units
    SIEMEN = 'SIEMEN',

    // Potential units
    VOLT = 'VOLT',

    // Pharmacology units
    INTERNATIONAL_UNIT = 'INTERNATIONAL_UNIT',

    // Scalar units
    COUNT = 'COUNT',
    PERCENT = 'PERCENT',

    // Other units
    BEATS_PER_MINUTE = 'BEATS_PER_MINUTE',
    METERS_PER_SECOND = 'METERS_PER_SECOND',
    RESPIRATIONS_PER_MINUTE = 'RESPIRATIONS_PER_MINUTE',
    MILLIGRAM_PER_DECILITER = 'MILLIGRAM_PER_DECILITER',
    UNKNOWN_UNIT = 'UNKNOWN_UNIT',
    NO_UNIT = 'NO_UNIT',
}

/**
 * Maps a {@link HealthDataUnit} to a string.
 */

export const HealthDataUnitToString: {
    [key in HealthDataUnit]: string;
} = {
    [HealthDataUnit.GRAM]: 'g',
    [HealthDataUnit.KILOGRAM]: 'kg',
    [HealthDataUnit.OUNCE]: 'oz',
    [HealthDataUnit.POUND]: 'lb',
    [HealthDataUnit.STONE]: 'st',
    [HealthDataUnit.METER]: 'm',
    [HealthDataUnit.INCH]: 'in',
    [HealthDataUnit.FOOT]: 'ft',
    [HealthDataUnit.YARD]: 'yd',
    [HealthDataUnit.MILE]: 'mi',
    [HealthDataUnit.LITER]: 'L',
    [HealthDataUnit.MILLILITER]: 'mL',
    [HealthDataUnit.FLUID_OUNCE_US]: 'fl oz',
    [HealthDataUnit.FLUID_OUNCE_IMPERIAL]: 'fl oz',
    [HealthDataUnit.CUP_US]: 'cup',
    [HealthDataUnit.CUP_IMPERIAL]: 'cup',
    [HealthDataUnit.PINT_US]: 'pt',
    [HealthDataUnit.PINT_IMPERIAL]: 'pt',
    [HealthDataUnit.PASCAL]: 'Pa',
    [HealthDataUnit.MILLIMETER_OF_MERCURY]: 'mmHg',
    [HealthDataUnit.INCHES_OF_MERCURY]: 'inHg',
    [HealthDataUnit.CENTIMETER_OF_WATER]: 'cmH2O',
    [HealthDataUnit.ATMOSPHERE]: 'atm',
    [HealthDataUnit.DECIBEL_A_WEIGHTED_SOUND_PRESSURE_LEVEL]: 'dB',
    [HealthDataUnit.SECOND]: 's',
    [HealthDataUnit.MILLISECOND]: 'ms',
    [HealthDataUnit.MINUTE]: 'min',
    [HealthDataUnit.HOUR]: 'h',
    [HealthDataUnit.DAY]: 'd',
    [HealthDataUnit.JOULE]: 'J',
    [HealthDataUnit.KILOCALORIE]: 'kcal',
    [HealthDataUnit.LARGE_CALORIE]: 'Cal',
    [HealthDataUnit.SMALL_CALORIE]: 'cal',
    [HealthDataUnit.DEGREE_CELSIUS]: '°C',
    [HealthDataUnit.DEGREE_FAHRENHEIT]: '°F',
    [HealthDataUnit.KELVIN]: 'K',
    [HealthDataUnit.DECIBEL_HEARING_LEVEL]: 'dB HL',
    [HealthDataUnit.HERTZ]: 'Hz',
    [HealthDataUnit.SIEMEN]: 'S',
    [HealthDataUnit.VOLT]: 'V',
    [HealthDataUnit.INTERNATIONAL_UNIT]: 'IU',
    [HealthDataUnit.COUNT]: 'count',
    [HealthDataUnit.PERCENT]: '%',
    [HealthDataUnit.BEATS_PER_MINUTE]: 'bpm',
    [HealthDataUnit.RESPIRATIONS_PER_MINUTE]: 'rpm',
    [HealthDataUnit.MILLIGRAM_PER_DECILITER]: 'mg/dL',
    [HealthDataUnit.UNKNOWN_UNIT]: '',
    [HealthDataUnit.NO_UNIT]: '',
    [HealthDataUnit.CENTIMETER]: 'cm',
    [HealthDataUnit.MILLIMETER]: 'mm',
    [HealthDataUnit.METERS_PER_SECOND]: 'm/s'
};

/**
 * Maps a {@link HealthDataType} to a {@link HealthDataUnit}.
 */
export const HealthDataTypeToUnit: {
    [key in HealthDataType]: HealthDataUnit;
} = {
    [HealthDataType.OXYGEN_SATURATION]: HealthDataUnit.PERCENT,
    [HealthDataType.VO2_MAX]: HealthDataUnit.PERCENT,
    [HealthDataType.HYDRATION_LEVEL]: HealthDataUnit.NO_UNIT,
    [HealthDataType.HYDRATION_MEASUREMENT]: HealthDataUnit.KILOGRAM,
    [HealthDataType.BLOOD_PRESSURE_DIASTOLIC]: HealthDataUnit.MILLIMETER_OF_MERCURY,
    [HealthDataType.BLOOD_PRESSURE_SYSTOLIC]: HealthDataUnit.MILLIMETER_OF_MERCURY,
    [HealthDataType.BODY_TEMPERATURE]: HealthDataUnit.DEGREE_CELSIUS,
    [HealthDataType.AMBIENT_TEMPERATURE]: HealthDataUnit.DEGREE_CELSIUS,
    [HealthDataType.SKIN_TEMPERATURE]: HealthDataUnit.DEGREE_CELSIUS,
    [HealthDataType.BODY_MASS_INDEX]: HealthDataUnit.NO_UNIT,
    [HealthDataType.BASAL_METABOLIC_RATE]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.RESTING_METABOLIC_RATE]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.ESTIMATED_FITNESS_AGE]: HealthDataUnit.NO_UNIT,
    [HealthDataType.SKIN_FOLD]: HealthDataUnit.MILLIMETER,
    [HealthDataType.BODY_FAT_PERCENTAGE]: HealthDataUnit.PERCENT,
    [HealthDataType.WEIGHT]: HealthDataUnit.KILOGRAM,
    [HealthDataType.HEIGHT]: HealthDataUnit.CENTIMETER,
    [HealthDataType.BONE_MASS]: HealthDataUnit.GRAM,
    [HealthDataType.MUSCLE_MASS]: HealthDataUnit.GRAM,
    [HealthDataType.LEAN_MASS]: HealthDataUnit.GRAM,
    [HealthDataType.BODY_WATER]: HealthDataUnit.PERCENT,
    [HealthDataType.INSULIN_UNITS]: HealthDataUnit.NO_UNIT,
    [HealthDataType.INSULIN_TYPE]: HealthDataUnit.NO_UNIT,
    [HealthDataType.URINE_COLOR]: HealthDataUnit.NO_UNIT,
    [HealthDataType.HEART_RATE]: HealthDataUnit.BEATS_PER_MINUTE,
    [HealthDataType.HEART_RATE_VARIABILITY_SDNN]: HealthDataUnit.BEATS_PER_MINUTE,
    [HealthDataType.HEART_RATE_VARIABILITY_RMSSD]: HealthDataUnit.BEATS_PER_MINUTE,
    [HealthDataType.RESTING_HEART_RATE]: HealthDataUnit.BEATS_PER_MINUTE,
    [HealthDataType.HEART_RATE_PULSE_WAVE_VELOCITY]: HealthDataUnit.METERS_PER_SECOND,
    [HealthDataType.BLOOD_GLUCOSE]: HealthDataUnit.MILLIGRAM_PER_DECILITER,
    [HealthDataType.STRESS_LEVEL]: HealthDataUnit.NO_UNIT,
    [HealthDataType.STRESS_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.REST_STRESS_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.ACTIVITY_STRESS_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.LOW_STRESS_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.MEDIUM_STRESS_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.HIGH_STRESS_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.STRESS_RATING]: HealthDataUnit.NO_UNIT,
    [HealthDataType.STEPS]: HealthDataUnit.COUNT,
    [HealthDataType.FLOORS_CLIMBED]: HealthDataUnit.COUNT,
    [HealthDataType.DISTANCE_METERS]: HealthDataUnit.METER,
    [HealthDataType.ELEVATION]: HealthDataUnit.METER,
    [HealthDataType.MET_LEVEL]: HealthDataUnit.NO_UNIT,
    [HealthDataType.NET_INTAKE_CALORIES]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.BMR_CALORIES]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.TOTAL_BURNED_CALORIES]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.NET_ACTIVITY_CALORIES]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.CALORIES]: HealthDataUnit.KILOCALORIE,
    [HealthDataType.ACTIVITY_LEVEL]: HealthDataUnit.NO_UNIT,
    [HealthDataType.ACTIVITY_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.INACTIVITY_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.REST_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.STANDING_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.STANDING_HOURS_COUNT]: HealthDataUnit.COUNT,
    [HealthDataType.ACTIVITY_LOW_INTENSITY_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.ACTIVITY_MODERATE_INTENSITY_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.ACTIVITY_VIGOROUS_INTENSITY_SECONDS]: HealthDataUnit.SECOND,
    [HealthDataType.WATER]: HealthDataUnit.MILLILITER,
    [HealthDataType.SLEEP_IN_BED]: HealthDataUnit.SECOND,
    [HealthDataType.UNMEASURABLE_SLEEP]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_EFFICIENCY]: HealthDataUnit.NO_UNIT,
    [HealthDataType.HYPNOGRAM_LEVEL]: HealthDataUnit.NO_UNIT,
    [HealthDataType.SLEEP_AWAKE]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_AWAKE_DURATION_SHORT_INTERRUPTION]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_AWAKE_DURATION_LONG_INTERRUPTION]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_AWAKE_NUM_WAKEUP_EVENTS]: HealthDataUnit.COUNT,
    [HealthDataType.SLEEP_AWAKE_WAKE_UP_LATENCY]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_AWAKE_NUM_OUT_OF_BED_EVENTS]: HealthDataUnit.COUNT,
    [HealthDataType.SLEEP_AWAKE_LATENCY]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_ASLEEP]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_LIGHT]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_DEEP]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_REM]: HealthDataUnit.SECOND,
    [HealthDataType.SLEEP_ASLEEP_NUM_REM_EVENTS]: HealthDataUnit.COUNT,
    [HealthDataType.SLEEP_TEMPERATURE_DELTA]: HealthDataUnit.NO_UNIT,
    [HealthDataType.SLEEP_READINESS]: HealthDataUnit.NO_UNIT,
    [HealthDataType.SLEEP_RECOVERY_LEVEL]: HealthDataUnit.NO_UNIT,
    [HealthDataType.RESPIRATORY_RATE]: HealthDataUnit.RESPIRATIONS_PER_MINUTE,
    [HealthDataType.NUM_SNORING_EVENTS]: HealthDataUnit.COUNT,
    [HealthDataType.TOTAL_SNORING_DURATION]: HealthDataUnit.SECOND,
    [HealthDataType.SNORING]: HealthDataUnit.SECOND,
};

export interface HealthDataTypeDTO {
    id: number;
    createdDate?: string | null;
    lastModifiedDate?: string | null;
    name: HealthDataType;
    unit: HealthDataUnit;
}

export interface HealthDataValue {
    date: string;
    value: number;
    type: {
        id: number;
        name: HealthDataType;
        unit: HealthDataUnit;
    };
}



export interface InternalHealthDataValue {
    id: string;
    date: string;
    value: number;
    type: {
        id: number;
        name: HealthDataType;
        unit: HealthDataUnit;
    };
}

export enum HealthDataPeriod {
    lastValue = 'lastValue',
    last24Hours = 'last24Hours',
    dayLastValue = 'dayLastValue',
    day = 'day',
    week = 'week',
    month = 'month',
}

export enum HealthChartPeriod {
    day = 'day',
    week = 'week',
    month = 'month',
}

export type HealthChartConfiguration = {
    [key in HealthChartPeriod]: ChartType;
};

export enum HealthDataAggregateOperation {
    SUM = 'SUM',
    AVG = 'AVG'
}

export interface HealthDataFiltersDTO {
    period: HealthDataPeriod;
    dateFrom: string;
    timeZone?: string | null;
    aggregateOperation?: HealthDataAggregateOperation | null;
    payloadTypes: dataType[];
    useMetadataEndTime?: boolean | null;
}

export interface ActivityAggregation {
    date: string;
    summaryId: string;
    activityType?: ActivityType | null;
    averageHeartRate: number;
    totalDurationSeconds: number;
}

export interface ActivitySummary {
    summaryId: string;
    startTime: string;
    endTime: string;
    activityType?: ActivityType | null;
}
