<h1>Nuovi codici di recupero generati!</h1>

<div class="pt-8">
    <button
        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full mt-2 text-center"
        (click)="onSubmitDownloadTxt(recoveryCodes)"
    >
        <div class="flex items-center justify-center w-full">
            <span class="text-white text-sm font-bold">Scarica codici di recupero</span>
        </div>
    </button>
</div>
