<section class="custom-bg-white py-7 px-6 relative h-full pb-32">
    <div class="flex items-center justify-between">
        <h1 class="font-semibold text-lg">
            {{
                "pages.settings-page.pages.billing-info-page.yourDataLabel"
                    | transloco
            }}
        </h1>
        <button (click)="toggleReadOnly()">
            @if (isReadOnly) {
                <div class="flex items-center">
                    <div>
                        <svg class="size-4 me-2">
                            <use href="../../../assets/svg/svGP.svg#edit"></use>
                        </svg>
                    </div>
                    <div>
                        <span>Modifica</span>
                    </div>
                </div>
            } @else {
                <div class="flex items-center bg-light-danger py-2 px-3 rounded-lg">
                    <div>
                        <span class="text-danger text-sm font-bold">Annulla</span>
                    </div>
                </div>
            }
        </button>
    </div>
    <br/>
    <form [formGroup]="billingInfoDataForm" (ngSubmit)="onInfoFormSubmit()">
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="fiscalCode">Codice Fiscale</label>
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="fiscalCode"
                placeholder="Codice Fiscale"
                formControlName="fiscalCode"
                style="text-transform: capitalize"
                [readOnly]="isReadOnly"
                tabindex="0"
            />
            <!-- Gestione errore campo form fiscalCode da stilizzare -->
            @if (billingInfoDataForm.controls.fiscalCode.invalid && billingInfoDataForm.controls.fiscalCode.touched && !isReadOnly) {
                <span class="text-danger text-sm mt-2">Per favore inserisci un codice fiscale valido.</span>
            }
            <!-- Fine gestion errore campo form fiscalCode-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm"
                   for="billingStreet">{{ "pages.settings-page.pages.billing-info-page.billingStreetLabel"| transloco }}
            </label>
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="billingStreet"
                placeholder="{{'pages.settings-page.pages.billing-info-page.billingStreetLabel'| transloco}}"
                formControlName="billingStreet"
                style="text-transform: capitalize"
                [readOnly]="isReadOnly"
                tabindex="0"
            />
            <!-- Gestione errore campo form firstname da stilizzare -->
            @if (billingInfoDataForm.controls.billingStreet.invalid && billingInfoDataForm.controls.billingStreet.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci una via valida.</span>
            }
            <!-- Fine gestion errore campo form firstname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="billingProvince">
                {{ "pages.settings-page.pages.billing-info-page.billingProvinceLabel"| transloco }}
            </label>
            <select id="billingProvince"
                    tabindex="0"
                    formControlName="billingProvince"
                    class="form-control text-sm mt-2.5">
                @if (billingInfoDataForm.controls.billingCountry.value === 'Italia') {
                    <option [ngValue]="''" class="hidden">
                        Seleziona una provincia
                    </option>
                    @if (provincesItaly$ | async; as provinces) {
                        <option *ngFor="let province of provinces.picklistValues" [ngValue]="province">
                            {{ province }}
                        </option>
                    }
                } @else {
                    <option [ngValue]="''">
                        Nessuna provincia
                    </option>
                }
            </select>
            <!-- Gestione errore campo form lastname da stilizzare -->
            @if (billingInfoDataForm.controls.billingProvince.invalid && billingInfoDataForm.controls.billingProvince.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci una provincia valida.</span>
            }
            <!-- Fine gestion errore campo form lastname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="billingCity">
                {{ "pages.settings-page.pages.billing-info-page.billingCityLabel"| transloco }}
            </label>
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="billingCity"
                placeholder="{{'pages.settings-page.pages.billing-info-page.billingCityLabel'| transloco}}"
                formControlName="billingCity"
                style="text-transform: capitalize"
                [readOnly]="isReadOnly"
                tabindex="0"
            />
            <!-- Gestione errore campo form lastname da stilizzare -->
            @if (billingInfoDataForm.controls.billingCity.invalid && billingInfoDataForm.controls.billingCity.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci una città valida.</span>
            }
            <!-- Fine gestion errore campo form lastname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="billingPostalCode">
                {{ "pages.settings-page.pages.billing-info-page.billingPostalCodeLabel"| transloco }}
            </label>
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="billingPostalCode"
                placeholder="{{'pages.settings-page.pages.billing-info-page.billingPostalCodeLabel'| transloco}}"
                formControlName="billingPostalCode"
                style="text-transform: capitalize"
                [readOnly]="isReadOnly"
                tabindex="0"
            />
            <!-- Gestione errore campo form lastname da stilizzare -->
            @if (billingInfoDataForm.controls.billingPostalCode.invalid && billingInfoDataForm.controls.billingPostalCode.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci un CAP valido.</span>
            }
            <!-- Fine gestion errore campo form lastname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="billingCountry">
                {{ "pages.settings-page.pages.billing-info-page.billingCountryLabel"| transloco }}
            </label>
            <select id="billingCountry"
                    tabindex="0"
                    formControlName="billingCountry"
                    class="form-control text-sm mt-2.5">
                <option *ngFor="let country of supportedBillingCountry" [ngValue]="country">
                    {{ country }}
                </option>
            </select>
            <!-- Gestione errore campo form lastname da stilizzare -->
            @if (billingInfoDataForm.controls.billingCountry.invalid && billingInfoDataForm.controls.billingCountry.touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci una nazione valida.</span>
            }
            <!-- Fine gestion errore campo form lastname-->
        </div>
        @if (isReadOnly) {
            <hr class="border-light-grey"/>
        }
        @if (!isReadOnly) {
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                [disabled]="billingInfoDataForm.invalid || (userIsLoading$ | async)"
                type="submit"
            >
                <div class="flex items-center justify-center w-full">
                    @if (userIsLoading$ | async) {
                        <span class="button-loader"></span>
                    } @else {
                        <span
                            class="text-white text-sm font-bold uppercase">{{ "pages.settings-page.pages.billing-info-page.updateUserLabel"| transloco }}</span>
                    }
                </div>
            </button>
            <button
                class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center mt-2 w-full"
                (click)="toggleReadOnly()"
            >
                <div class="flex items-center justify-center w-full">
                    <span class="text-primary text-sm font-bold">Annulla modifiche</span>
                </div>
            </button>
        }
    </form>
</section>
