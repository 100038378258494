@if (isVisible()) {
    <div
        class="fixed inset-0 p-8 bg-white w-full h-full z-50 overflow-y-scroll">
        <div class="flex items-center justify-center w-full">
            <svg class="w-14 h-14 fill-primary">
                <use href="../../../assets/svg/svGP.svg#registration-pencil"></use>
            </svg>
        </div>
        <div class="w-full">
            <ng-content></ng-content>
        </div>
        <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                (click)="toggleModal()">
            <span class="text-white text-sm font-bold uppercase">{{ buttonLabel }}</span>
        </button>
    </div>
}
