import { Route } from "@angular/router";
import { UserHasProductFeatureGuard } from "../../../../guards/auth.guard";
import { ProductFeature } from "../../../../models/product.model";
import {APP_ROUTES} from "../../../../app.routes.definition";
import BodyCategoryPageComponent from "./body-category-page.component";
import WeightPageComponent from "./pages/weight-page/weight-page.component";
import HeightPageComponent from "./pages/height-page/height-page.component";
import RespiratoryRatePageComponent from "./pages/respiratory-rate-page/respiratory-rate-page.component";
import BodyTemperaturePageComponent from "./pages/body-temperature-page/body-temperature-page.component";
import BloodGlucosePageComponent from "./pages/blood-glucose-page/blood-glucose-page.component";
import BloodPressureSystolicPageComponent
    from "./pages/blood-pressure-systolic-page/blood-pressure-systolic-page.component";
import BloodPressureDiastolicPageComponent
    from "./pages/blood-pressure-diastolic-page/blood-pressure-diastolic-page.component";
import BmiPageComponent from "./pages/bmi-page/bmi-page.component";
import BodyFatPageComponent from "./pages/body-fat-page/body-fat-page.component";

export const BODY_CATEGORY_ROUTES: Route[] = [
    {
        path: '',
        data: {
            customSectionClass: 'bg-section-data-yellow'
        },
        component: BodyCategoryPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_WEIGHT(true),
        data: { title: 'Weight' },
        component: WeightPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_HEIGHT(true),
        data: { title: 'Height' },
        component: HeightPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_RESPIRATORY_RATE(true),
        data: { title: 'Respiratory Rate' },
        component: RespiratoryRatePageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_TEMPERATURE(true),
        data: { title: 'Body Temperature' },
        component: BodyTemperaturePageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BLOOD_GLUCOSE(true),
        data: { title: 'Blood Glucose' },
        component: BloodGlucosePageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BLOOD_PRESSURE_SYSTOLIC(true),
        data: { title: 'Blood Pressure Systolic' },
        component: BloodPressureSystolicPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BLOOD_PRESSURE_DIASTOLIC(true),
        data: { title: 'Blood Pressure Diastolic' },
        component: BloodPressureDiastolicPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_MASS_INDEX(true),
        data: { title: 'Body Mass Index' },
        component: BmiPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_FAT(true),
        data: { title: 'Body Fat Percentage' },
        component: BodyFatPageComponent,
    }
]
