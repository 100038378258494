import { Component, Input, inject } from '@angular/core';
import { SurveyComponent } from '../../components/survey/survey.component';
import { Survey } from '../../models/survey.models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router, RouterModule } from '@angular/router';
import { APP_ROUTES } from '../../app.routes.definition';
import { LogService } from '../../services/log.service';
import { ModalService } from '../../services/modal.service';
import ErrorModalComponent from '../../components/error-modal/error-modal.component';

@Component({
    selector: 'app-rating-page',
    standalone: true,
    imports: [SurveyComponent, RouterModule],
    templateUrl: './rating-page.component.html',
})
export default class RatingPageComponent {
    @Input() id: string = '';

    #http = inject(HttpClient);
    #router = inject(Router);
    #logger = inject(LogService);
    #modalService = inject(ModalService);

    apiUrl = environment.apiUrl + '/surveys/coaching-session-feedback';
    postUrl = environment.apiUrl + '/coaching-sessions/';

    surveyData!: Survey;
    data: string;
    homePath = APP_ROUTES.HOME();

    constructor() {
        const navigation = this.#router.getCurrentNavigation();
        this.data = navigation?.extras.state?.['data'];
        this.#logger.log('DATA: ', this.data);

        if (!this.data) {
            this.#router.navigate([APP_ROUTES.HOME()]);
        }
    }

    submitFeedback(event: Survey) {
        this.postUrl = `${this.postUrl}${this.data}/feedback`;

        this.#http.post(this.postUrl, event).subscribe({
            next: (response) => {},
            error: (error) => {
                this.#modalService.open(ErrorModalComponent, {
                    data: {
                        title: 'Error',
                        message:
                            'Non è stato possibile inviare il feedback. Riprova più tardi.',
                        callback: () => {
                            this.#router.navigate([APP_ROUTES.HOME()]);
                        },
                    },
                });
            },
            complete: () => {
                this.#router.navigate([APP_ROUTES.HOME()]);
            },
        });
    }

    ngOnInit() {
        this.#http.get<Survey>(this.apiUrl).subscribe({
            next: (response) => {
                this.surveyData = response;
                this.#logger.log('Survey Data: ', this.surveyData);
            },
            error: (error) => {
                this.#router.navigate([APP_ROUTES.HOME()]);
            },
            complete: () => {},
        });
    }

    ngOnDestroy() {}
}
