import {Component, inject, OnInit} from '@angular/core';
import {NavigationService} from '../../../../../../../services/navigation.service';
import {RouterModule} from '@angular/router';
import {Store} from '@ngrx/store';
import {selectUserData} from '../../../../../../../store/user/user.selectors';
import {AsyncPipe, NgIf} from '@angular/common';
import {TranslocoModule} from '@ngneat/transloco';
import {TwoFactorAddedComponent} from './components/two-factor-added/two-factor-added.component';
import {InitTwoFactorProcessComponent} from './components/init-two-factor-process/init-two-factor-process.component';
import ErrorModalComponent, {ErrorModalData} from '../../../../../../../components/error-modal/error-modal.component';
import {LogService} from '../../../../../../../services/log.service';
import {ModalService} from '../../../../../../../services/modal.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-begin-two-factor-process-page',
    standalone: true,
    imports: [RouterModule, NgIf, TranslocoModule, AsyncPipe, TwoFactorAddedComponent, InitTwoFactorProcessComponent],
    templateUrl: './two-factor-setup-page.component.html',
})
export default class TwoFactorSetupPageComponent implements OnInit {
    #navigationService = inject(NavigationService);
    #store = inject(Store);
    #logService = inject(LogService);
    #modalService = inject(ModalService);

    user$ = this.#store.select(selectUserData);

    hasTwoFactorAuthentication?: boolean;

    constructor() {
        this.retrieveUser2faAuthentication();
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
    }

    private retrieveUser2faAuthentication() {
        this.user$.pipe(takeUntilDestroyed()).subscribe({
            next: (user) => {
                if (user) {
                    this.hasTwoFactorAuthentication = user.isTwoFactorAuthenticationEnabled;
                }
            },
            error: (error) => {
                this.#logService.error(error);
                this.#modalService.open<
                    ErrorModalData,
                    ErrorModalComponent
                >(ErrorModalComponent, {
                    data: {
                        error: error,
                    },
                });
            },
        });
    }
}
