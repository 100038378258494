<div class="container max-w-3xl mx-auto flex flex-col items-center justify-start w-full mt-5 bg-white rounded-3xl">
    <img class="size-20 p-1 border border-light-grey rounded"
         src="assets/img/providers/{{provider.toLowerCase()}}.png"
         [alt]="HealthProviderName[provider]">
    <h1 class="font-semibold text-lg mt-7">
        Stai
        per {{ isConnecting ? 'connettere' : 'disconnettere' }}&nbsp;<span>{{ HealthProviderName[provider] }}</span>
    </h1>
    <div class="container">
        <div class="flex items-center justify-around w-full mt-7">
            <div class="basis-6/12 flex items-center justify-center">
                <button class="py-4 px-5 disabled:bg-black-60 rounded-lg cursor-pointer w-full mx-2 bg-white border-2 border-primary"
                        (click)="onClose()">
                    <div>
                        <span class="text-xs appointment-join__text font-bold text-primary">
                            Annulla
                        </span>
                    </div>
                </button>
            </div>
            <div class="basis-6/12 flex items-center justify-center">
                @if ([TryTerraSupportedHealthProvider.HEALTH_CONNECT, TryTerraSupportedHealthProvider.APPLE].includes(provider) || !isConnecting) {
                    <button class="py-4 px-5 disabled:bg-black-60 rounded-lg cursor-pointer w-full mx-2"
                            [disabled]="isRequestingAuthLink || isRequestingDeauth || (tryTerraIsNativeInitializing$ | async)"
                            (click)="isConnecting ? authWithNativeProvider() : deauthProvider()"
                            [ngClass]="{ 'bg-primary': isConnecting, 'bg-danger': !isConnecting }">
                        <div>
                            @if (isRequestingAuthLink || isRequestingDeauth || (tryTerraIsNativeInitializing$ | async)) {
                                <span class="button-loader"></span>
                            } @else {
                                <span class="text-xs text-white appointment-join__text font-bold">
                                    {{ isConnecting ? 'Connetti' : 'Disconnetti' }}
                                </span>
                            }
                        </div>
                    </button>
                } @else {
                    <a class="bg-primary text-center py-4 px-5 disabled:bg-black-60 rounded-lg cursor-pointer w-full mx-2"
                       [target]="isMobileNative ? '_blank' : '_self'"
                       (click)="authLinkClick($event)"
                       [href]="authLink">
                        <div>
                           @if (isRequestingAuthLink || isRequestingDeauth) {
                               <span class="button-loader"></span>
                           } @else {
                               <span class="text-xs text-white appointment-join__text font-bold">Connetti</span>
                           }
                       </div>
                    </a>
                }
            </div>
        </div>
    </div>
</div>
