<div class="flex flex-col justify-between h-full">
    <div class="flex flex-col items-center justify-between px-6 h-full">
        <div class="flex flex-col items-center justify-center">
            <div class="text-center">
                <h1 class="font-bold text-center text-2xl">
                    Seleziona l'abbonamento
                </h1>
            </div>
            @for (product of baseProducts; track product) {
                <div class="bg-white border border-light-grey mt-8 rounded-lg w-full">
                    <app-tier-card [product]="product"></app-tier-card>
                    <div class="py-7 px-6">
                        <div>
                            <button
                                [disabled]="isVerifyingInitialSurvey || (isCreatingOrder$ | async) || (userBasePlan$ | async)?.orderItems?.[0]?.product?.productCode === product.productCode"
                                (click)="selectProduct(product)"
                                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                            >
                                <div>
                                    @if (isVerifyingInitialSurvey) {
                                        <span class="button-loader"></span>
                                    } @else {
                                        <span class="text-white text-sm font-bold uppercase">Seleziona</span>
                                    }
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
