import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from '../../../../services/navigation.service';
import { HealthStatisticWeightCardComponent } from '../../../../components/health-statistic-weight-card/health-statistic-weight-card.component';
import { HealthStatisticHeightCardComponent } from '../../../../components/health-statistic-height-card/health-statistic-height-card.component';
import {NavbarCategoriesComponent} from "../../components/navbar-categories/navbar-categories.component";
import { HealthStatisticRespiratoryRateCardComponent } from '../../../../components/health-statistic-respiratory-rate-card/health-statistic-respiratory-rate-card.component';
import {
    HealthStatisticBodyTemperatureCardComponent
} from "../../../../components/health-statistic-body-temperature-card/health-statistic-body-temperature-card.component";
import {
    HealthStatisticBloodGlucoseCardComponent
} from "../../../../components/health-statistic-blood-glucose-card/health-statistic-blood-glucose-card.component";
import {
    HealthStatisticBloodPressureSystolicCardComponent
} from "../../../../components/health-statistic-blood-pressure-systolic-card/health-statistic-blood-pressure-systolic-card.component";
import {
    HealthStatisticBloodPressureDiastolicCardComponent
} from "../../../../components/health-statistic-blood-pressure-diastolic-card/health-statistic-blood-pressure-diastolic-card.component";
import {
    HealthStatisticBmiCardComponent
} from "../../../../components/health-statistic-bmi-card/health-statistic-bmi-card.component";
import {
    HealthStatisticBodyFatCardComponent
} from "../../../../components/health-statistic-body-fat-card/health-statistic-body-fat-card.component";

@Component({
    selector: 'app-body-category-page',
    standalone: true,
    imports: [
        HealthStatisticWeightCardComponent,
        HealthStatisticHeightCardComponent,
        HealthStatisticRespiratoryRateCardComponent,
        RouterModule,
        NavbarCategoriesComponent,
        HealthStatisticBodyTemperatureCardComponent,
        HealthStatisticBloodGlucoseCardComponent,
        HealthStatisticBloodPressureSystolicCardComponent,
        HealthStatisticBloodPressureDiastolicCardComponent,
        HealthStatisticBmiCardComponent,
        HealthStatisticBodyFatCardComponent,
    ],
    templateUrl: './body-category-page.component.html',
    styleUrl: './body-category-page.component.css',
})
export default class BodyCategoryPageComponent {
    #navigationService = inject(NavigationService);

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
