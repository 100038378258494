import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SurveyComponent } from '../../components/survey/survey.component';
import { ModalService } from '../../services/modal.service';
import { environment } from '../../../environments/environment';
import { Survey } from '../../models/survey.models';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../services/log.service';

@Component({
    selector: 'app-rating-modal',
    standalone: true,
    imports: [FormsModule, CommonModule, SurveyComponent],
    templateUrl: './rating-modal.component.html',
    styleUrl: './rating-modal.component.css',
})
export class RatingModalComponent {
    #modalService = inject(ModalService);
    #loggerService = inject(LogService);
    #http = inject(HttpClient);

    @Input() userImage: string = '';
    @Input() coachImage: string = '';
    @Input() time: string = '';
    @Input() id: string = '';

    apiUrl = environment.apiUrl + '/surveys/coaching-session-feedback';
    postUrl = environment.apiUrl + '/coaching-sessions/';

    surveyData!: Survey;

    ngOnInit() {
        this.#http.get<Survey>(this.apiUrl).subscribe({
            next: (response) => {
                this.#loggerService.log('Survey data:', response);
                this.surveyData = response;
            },
            error: (error) => {
                this.#loggerService.error('Error getting survey data:', error);
            },
            complete: () => {
                this.#loggerService.log('Survey data loaded');
            },
        });
    }

    submitFeedback(event: Survey) {
        this.postUrl = `${this.postUrl}${this.id}/feedback`;

        this.#http.post(this.postUrl, event).subscribe({
            next: (response) => {
                this.#loggerService.log('Feedback submitted:', response);
                this.#modalService.closeAll();
            },
            error: (error) => {
                this.#loggerService.error('Error submitting feedback:', error);
            },
            complete: () => {
                this.#modalService.closeAll();
            },
        });
    }
}
