<div class="basis-11/12">
    <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl">
        <h1 class="text-primary font-semibold text-3xl">MODULO DI ADESIONE</h1>
        <div class="mt-6">
            <p class="mt-1">
                La <strong> Be Our Best S.r.l.</strong> <sup>1</sup> ti
                accompagna nel percorso di miglioramento dello stile di vita e
                del benessere psicofisico tramite il servizio di health coaching
                in videoconferenza integrato con il monitoraggio a distanza dei
                tuoi parametri vitali, peso corporeo, abitudini alimentari, del
                movimento e del riposo . Grazie all’app
                <strong>Be Our Best</strong> facilmente accessibile dal tuo
                smartphone o da web la
                <strong>Be Our Best S.r.l.</strong> consente di entrare in
                contatto con la propria rete di <i>health coach</i> e di
                acquisire le informazioni registrate dai tuoi dispositivi
                elettronici intelligenti.
            </p>
        </div>
        <div class="mt-4">
            <h2 class="font-bold text-lg text-text-color text-center">
                Condizioni generali
            </h2>
            <p class="mt-1">
                L’Abbonamento è soggetto alle “Condizioni generali di contratto”
                (documento disponibile al <a [href]="iubenda.termsAndConditions"
                                             class="text-primary underline cursor-pointer" target="_blank">seguente
                link</a>) ed alla
                "Informativa privacy" (documento disponibile al <a [href]="iubenda.privacyPolicy"
                                                                   class="text-primary underline cursor-pointer"
                                                                   target="_blank">seguente
                link</a>),
                entrambi accettate al momento della creazione dell’Account
                Utente.
            </p>
        </div>
        <div class="mt-6">
            <h2 class="font-bold text-lg text-text-color text-center">
                Costo dell'abbonamento
            </h2>
            <p class="mt-1">
                L’abbonamento ai servizi della <strong>Be Our Best
                S.r.l</strong> decorre dal momento dell’adesione ed il suo
                costo viene esposto all'interno della pagina di conclusione
                dell'acquisto.
            </p>
        </div>
        <div class="mt-6">
            <h2 class="font-bold text-lg text-text-color text-center">
                Diritto di recesso
            </h2>
            <p class="mt-1">
                Hai diritto di recedere in base a quanto previsto dalla
                “Condizioni generali di contratto” applicabili all’Abbonamento.
                Non sono ammesse modalità o ragioni di recesso diverse da quelle
                indicate dalle predette “Condizioni generali di contratto”.
            </p>
        </div>
        <div class="mt-6">
            <h2 class="font-bold text-lg text-text-color text-center">
                Avvertenze
            </h2>
            <p class="mt-1">
                I servizi non sono di tipo sanitario e/o medico. Si consiglia si
                consultare la sezione dedicata all'interno delle "Condizioni generali di
                contratto".
            </p>
        </div>
        <div class="mt-10 border-t-2">
            <p class="mt-1">
                <sup>1</sup> Società di diritto italiano, con sede legale in San
                Salvo, via Gargheta n. 95; CF/P.IVA n.02799780693.
            </p>
        </div>
    </div>
</div>
