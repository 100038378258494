<div>
    <div class="flex flex-col">
        <div class="mt-5 text-center">
            <h1 class="font-bold text-xl">
                {{ !unrecoverable  ? 'È disponibile una nuova versione dell\'App!' : 'È necessario aggiornare la versione dell\'App!'}}
            </h1>
        </div>

        <div class="mt-5">
            <button (click)="updateAppVersion()" class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full">
                <div>
                    <span class="text-white text-sm font-bold uppercase">Aggiorna</span>
                </div>
            </button>
            <button (click)="close()" class="py-5 px-4 border-0 rounded-lg flex items-center justify-center w-full">
                <div>
                    <span class="text-sm font-bold uppercase">Annulla</span>
                </div>
            </button>
        </div>
    </div>
</div>
