import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LogService} from "../../../../services/log.service";
import {AppState} from "../../../../store/app.state";
import {Store} from "@ngrx/store";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {ActivatedRoute, Router} from "@angular/router";
import {AsyncPipe} from "@angular/common";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import dayjs from "dayjs";
import {selectCreateInternalHealthDataValueIsLoading} from "../../../../store/internal-health-data/internal-health-data.selectors";
import {createInternalHealthDataValue} from "../../../../store/internal-health-data/internal-health-data.actions";
import {AddHealthDataRequest} from "../../../../models/add-health-data.models";
import {NavigationService} from "../../../../services/navigation.service";
import {HealthDataType, HealthDataTypeToUnit, HealthDataUnitToString} from "../../../../models/health.models";
import {TranslocoPipe} from "@ngneat/transloco";

export interface AddHealthDataForm {
    dateFrom: FormControl<string>;
    dateTo: FormControl<string | null>;
    value: FormControl<number | null>;
}

@Component({
  selector: 'create-internal-health-data-page',
  standalone: true,
    imports: [
        AsyncPipe,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        TranslocoPipe,
    ],
  templateUrl: './create-internal-health-data-page.component.html',
  styleUrl: './create-internal-health-data-page.component.css'
})
export class CreateInternalHealthDataPageComponent implements OnInit {
    #router = inject(Router);
    #activatedRoute = inject(ActivatedRoute);
    #store = inject(Store<AppState>);
    #fb = inject(FormBuilder);
    #navigationService = inject(NavigationService);
    #logService = inject(LogService);

    isLoading$ = this.#store.select(selectCreateInternalHealthDataValueIsLoading);

    HealthDataTypeToUnit = HealthDataTypeToUnit;
    HealthDataUnitToString = HealthDataUnitToString;

    form!: FormGroup<AddHealthDataForm>;
    typeName!: HealthDataType;

    constructor() {
        const typeName = this.#activatedRoute.snapshot.paramMap.get('type') as HealthDataType | null;

        if (!typeName) {
            this.#navigationService.navigateBack();
            return;
        }
        this.typeName = typeName;

        this.form = this.#fb.group({
            dateFrom: this.#fb.nonNullable.control(dayjs().format('YYYY-MM-DDTHH:mm'), [Validators.required]),
            dateTo: this.#fb.control<string | null>(null),
            value: this.#fb.control<number | null>(null, [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    onFormSubmit() {
        if (this.form.invalid) {
            return;
        }

        this.#logService.log('Form submitted', this.form.value);

        const {dateFrom, dateTo, value} = this.form.value;
        const request: AddHealthDataRequest = {
            dateFrom: dayjs(dateFrom!).toISOString(),
            dateTo: dateTo ? dayjs(dateTo).toISOString() : null,
            value: value!,
            typeName: this.typeName,
        }
        this.#store.dispatch(createInternalHealthDataValue({request}));
    }
}
