<div class="flex flex-col h-full">
    <div class="custom-bg-white relative px-6 h-full pb-28">
        <div class="flex items-center justify-between my-5">
            <div>
                <h1 class="font-bold text-xl">Le tue chat</h1>
            </div>
        </div>

        <app-loader *ngIf="loading"></app-loader>

        @for (conversation of conversations; track $index) {
            <chat-conversation-card [data]="{
                conversation: conversation,
                lastMessage: lastMessages[conversation.id]
            }"></chat-conversation-card>
        }
    </div>
</div>
