<section class="flex flex-col h-screen">
    <div class="basis-11/12">
        <div
            class="bg-box-gradient w-full h-full pt-6 px-6 pb-40 rounded-t-4xl"
        >
            <h1 class="text-primary font-semibold text-lg mb-5">
                Abbonamento Attivo
            </h1>
            @if (userBaseSubscription$ | async; as userBaseSubscription) {
            <app-tier-card
                [product]="getProduct(userBaseSubscription.orderItems[0])"
                [showActionButton]="true"
                [actionButtonLabel]="
                    userBaseSubscription.activatedStatusReason === 'Active'
                        ? 'Annulla abbonamento'
                        : 'Abbonamento annullato'
                "
                [disabledActionButton]="
                    userBaseSubscription.activatedStatusReason !== 'Active'
                "
                (actionButtonClick)="
                    onSubmitBeginCancelSubscription(userBaseSubscription)
                "
            />
            } @else {
            <p class="text-lg mt-7">Non c'è un abbonamento attivo!</p>
            <div class="my-7">
                <img
                    src="../../../../../assets/img/illustrations/illustration-nosubscriptions.png"
                    class="object-contain"
                    alt=""
                />
            </div>
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                [routerLink]="selectPlanPath"
            >
                <div>
                    <svg class="size-6 fill-white me-2">
                        <use
                            href="../../../../../assets/svg/svGP.svg#shopping-bag"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span class="text-white text-sm font-bold uppercase"
                        >Acquista abbonamento
                    </span>
                </div>
            </button>
            }

            <hr class="dashed my-7 -mx-6" />

            @if (userOtherSubscriptions$ | async; as userOtherSubscriptions) {
            @if (userOtherSubscriptions.length > 0) {
            <div>
                <h2 class="font-semibold text-lg">Abbonamenti attivi</h2>
                @for (order of userOtherSubscriptions; track order) {
                <div
                    class="border-2 border-white bg-white rounded-lg mt-4 card-shadow p-0.5"
                >
                    <div class="flex">
                        <div
                            class="basis-4/12 flex items-center justify-center"
                            *ngIf="order.orderItems[0].product.imageUrl"
                        >
                            <img
                                class="w-full h-full max-h-40 object-cover rounded"
                                src="{{ order.orderItems[0].product.imageUrl }}"
                                alt=""
                            />
                        </div>
                        <div class="py-2.5 px-3 bg-white"
                             [ngClass]="{
                                'basis-8/12': order.orderItems[0].product.imageUrl != null,
                                'w-full': order.orderItems[0].product.imageUrl == null
                            }"
                        >
                            <div class="mt-4">
                                <h3 class="font-bold text-sm">
                                    {{ order.orderItems[0].product.name }}
                                </h3>
                                <p
                                    class="font-light text-text-color text-sm mt-1.5"
                                >
                                    @switch (order.orderItems[0].product.family)
                                    { @case('Subscription') { Abbonamento }
                                    @case('One shot purchase') { Acquisto
                                    singolo } }
                                </p>
                                <div
                                    class="flex items-center justify-between w-full mt-6"
                                >
                                    <div>
                                        <span class="font-bold text-sm">
                                            @switch
                                            (order.orderItems[0].product.family)
                                            { @case ('Subscription') {
                                            {{ order.orderItems[0].unitPrice }}€
                                            /
                                            {{
                                                order.orderItems[0].product
                                                    .subscriptionRecurringPeriod
                                            }}
                                            } @case ('One shot purchase') {
                                            {{ order.orderItems[0].unitPrice }}€
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="
                                            order.orderItems[0].product
                                                .family === 'Subscription'
                                        "
                                        class="flex align-items-center justify-end"
                                    >
                                        <button
                                            class="py-2 px-2.5 bg-danger rounded-lg"
                                            [disabled]="
                                                order.activatedStatusReason !==
                                                'Active'
                                            "
                                            (click)="
                                                cancelSubscription(
                                                    order.subscriptions[0].id
                                                )
                                            "
                                        >
                                            <span
                                                class="text-white font-bold text-sm"
                                                >Rimuovi</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            } @else {

            <p class="text-lg mt-7">Non ci sono altri abbonamenti attivi, vai al marketplace!</p>
            <div class="my-7">
                <img
                    src="../../../../../assets/img/illustrations/illustration-nosubscriptions.png"
                    class="object-contain"
                    alt=""
                />
            </div>
            <button
                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                [routerLink]="marketplacePath"
            >
                <div>
                    <svg class="size-6 fill-white me-2">
                        <use
                            href="../../../../../assets/svg/svGP.svg#shopping-bag"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span class="text-white text-sm font-bold uppercase"
                        >Vai al marketplace
                    </span>
                </div>
            </button>

            } } @if (pendingOrders$ | async; as pendingOrders) { @if
            (pendingOrders.length > 0) {
            <div class="mt-7">
                <h2 class="font-semibold text-lg">Ordini in attesa</h2>
                @for (order of pendingOrders; track order) {
                <div
                    class="border-2 border-white bg-white rounded-lg mt-4 card-shadow p-0.5"
                >
                    <div class="flex">
                        <div
                            class="basis-4/12 flex items-center justify-center"
                        >
                            <img
                                class="w-full h-full max-h-40 object-cover rounded"
                                src="{{ order.orderItems[0].product.imageUrl }}"
                                alt=""
                            />
                        </div>
                        <div class="basis-8/12 py-2.5 px-3 bg-white">
                            <div class="mt-4">
                                <h3 class="font-bold text-sm">
                                    {{ order.orderItems[0].product.name }}
                                </h3>
                                <p
                                    class="font-light text-text-color text-sm mt-1.5"
                                >
                                    @switch (order.orderItems[0].product.family)
                                    { @case('Subscription') { Abbonamento }
                                    @case('One shot purchase') { Acquisto
                                    singolo } }
                                </p>
                                <div
                                    class="flex items-center justify-between w-full mt-6"
                                >
                                    <div>
                                        <span class="font-bold text-sm">
                                            @switch
                                            (order.orderItems[0].product.family)
                                            { @case ('Subscription') {
                                            {{ order.orderItems[0].unitPrice }}€
                                            /
                                            {{
                                                order.orderItems[0].product
                                                    .subscriptionRecurringPeriod
                                            }}
                                            } @case ('One shot purchase') {
                                            {{ order.orderItems[0].unitPrice }}€
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        class="flex align-items-center justify-end"
                                    >
                                        <button
                                            class="py-2 px-2.5 bg-danger rounded-lg"
                                            (click)="cancelOrder(order.id)"
                                        >
                                            <span
                                                class="text-white font-bold text-sm"
                                                >Rimuovi</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            } }
        </div>
    </div>
</section>
