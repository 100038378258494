import {Injectable, inject, NgZone} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, tap} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import * as InternalHealthDataActions from './internal-health-data.actions';
import {LogService} from "../../services/log.service";
import {Router} from "@angular/router";
import ErrorModalComponent, {ErrorModalData} from "../../components/error-modal/error-modal.component";
import {ModalService} from "../../services/modal.service";
import {HealthDataService} from "../../services/health-data.service";
import {InfoModalComponent, InfoModalData} from "../../components/info-modal/info-modal.component";

@Injectable()
export class InternalHealthDataEffects {
    #actions$ = inject(Actions);
    #router = inject(Router);
    #healthDataService = inject(HealthDataService);
    #zone = inject(NgZone);
    #modalService = inject(ModalService);
    #logService = inject(LogService);

    createInternalHealthDataValue$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(InternalHealthDataActions.createInternalHealthDataValue),
            mergeMap((action) =>
                this.#healthDataService.addNewInternalValue(action.request).pipe(
                    mergeMap((response) => {
                        return [InternalHealthDataActions.createInternalHealthDataValueSuccess({
                            data: response,
                        })];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(InternalHealthDataActions.createInternalHealthDataValueFailure({error}));
                    })
                )
            )
        )
    );

    createInternalHealthDataValueSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.createInternalHealthDataValueSuccess),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                    data: {
                        title: 'Inserimento riuscito 🥳',
                        message: 'I nuovi dati sono stati salvati con successo.',
                    }
                });
            });
        })
    ), {dispatch: false});

    createInternalHealthDataValueFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.createInternalHealthDataValueFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante l\'inserimento dei dati.'
                    }
                });
            });
        })
    ), {dispatch: false});

    getInternalHealthDataValues = createEffect(() =>
        this.#actions$.pipe(
            ofType(InternalHealthDataActions.getInternalHealthDataValues),
            mergeMap((action) =>
                this.#healthDataService.getInternalValues(action.typeName, action.options).pipe(
                    mergeMap((response) => {
                        return [InternalHealthDataActions.getInternalHealthDataValuesSuccess({
                            data: response,
                            options: action.options
                        })];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(InternalHealthDataActions.getInternalHealthDataValuesFailure({error}));
                    })
                )
            )
        )
    );

    getInternalHealthDataValuesFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.getInternalHealthDataValuesFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante il recupero dei dati.'
                    }
                });
            });
        })
    ), {dispatch: false});

    loadMoreInternalHealthDataValues = createEffect(() =>
        this.#actions$.pipe(
            ofType(InternalHealthDataActions.loadMoreInternalHealthDataValues),
            mergeMap((action) =>
                [InternalHealthDataActions.getInternalHealthDataValues({
                    typeName: action.typeName,
                    options: action.options
                })]
            )
        )
    );

    getInternalHealthDataValue = createEffect(() =>
        this.#actions$.pipe(
            ofType(InternalHealthDataActions.getInternalHealthDataValue),
            mergeMap((action) =>
                this.#healthDataService.getInternalValue(action.id, action.typeName).pipe(
                    mergeMap((response) => {
                        return [InternalHealthDataActions.getInternalHealthDataValueSuccess({
                            data: response,
                        })];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(InternalHealthDataActions.getInternalHealthDataValueFailure({error}));
                    })
                )
            )
        )
    );

    getInternalHealthDataValueFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.getInternalHealthDataValueFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante il recupero dei dati.'
                    }
                });
            });
        })
    ), {dispatch: false});

    updateInternalHealthDataValue$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(InternalHealthDataActions.updateInternalHealthDataValue),
            mergeMap((action) =>
                this.#healthDataService.updateInternalValue(action.id, action.request).pipe(
                    mergeMap((response) => {
                        return [InternalHealthDataActions.updateInternalHealthDataValueSuccess()];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(InternalHealthDataActions.updateInternalHealthDataValueFailure({error}));
                    })
                )
            )
        )
    );

    updateInternalHealthDataValueSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.updateInternalHealthDataValueSuccess),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                    data: {
                        title: 'Aggiornamento riuscito 🥳',
                        message: 'I dati sono stati aggiornati con successo.',
                    }
                });
            });
        })
    ), {dispatch: false});

    updateInternalHealthDataValueFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.updateInternalHealthDataValueFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante l\'aggiornamento dei dati.'
                    }
                });
            });
        })
    ), {dispatch: false});

    deleteInternalHealthDataValue$ = createEffect(() =>
        this.#actions$.pipe(
            ofType(InternalHealthDataActions.deleteInternalHealthDataValue),
            mergeMap((action) =>
                this.#healthDataService.deleteInternalValue(action.data.id).pipe(
                    mergeMap((response) => {
                        return [InternalHealthDataActions.deleteInternalHealthDataValueSuccess({data: action.data})];
                    }),
                    catchError((error) => {
                        this.#logService.error(error);
                        return of(InternalHealthDataActions.deleteInternalHealthDataValueFailure({error}));
                    })
                )
            )
        )
    );

    deleteInternalHealthDataValueSuccess$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.deleteInternalHealthDataValueSuccess),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<InfoModalData, InfoModalComponent>(InfoModalComponent, {
                    data: {
                        title: 'Cancellazione riuscita 🥳',
                        message: 'I dati sono stati cancellati con successo.',
                    }
                });
            });
        })
    ), {dispatch: false});

    deleteInternalHealthDataValueFailure$ = createEffect(() => this.#actions$.pipe(
        ofType(InternalHealthDataActions.deleteInternalHealthDataValueFailure),
        tap(payload => {
            this.#zone.run(() => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(ErrorModalComponent, {
                    data: {
                        error: payload.error,
                        message: 'Errore durante l\'eliminazione dei dati.'
                    }
                });
            });
        })
    ), {dispatch: false});
}
