import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customDate',
    standalone: true,
})
export class CustomDatePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        const today = new Date();
        const valueDate = new Date(value);

        if (
            valueDate.getDate() === today.getDate() &&
            valueDate.getMonth() === today.getMonth() &&
            valueDate.getFullYear() === today.getFullYear()
        ) {
            return 'Oggi';
        } else {
            return new Intl.DateTimeFormat('it-IT').format(valueDate);
        }
    }
}
