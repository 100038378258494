import {Component, inject, OnInit} from '@angular/core';
import {RegistrationDashIconComponent} from '../../components/registration-dash-icon/registration-dash-icon.component';
import {SurveyComponent} from '../../components/survey/survey.component';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {Survey} from '../../models/survey.models';
import {LogService} from '../../services/log.service';
import {SurveyService} from '../../services/survey.service';
import {first} from 'rxjs';
import {NavigationService} from '../../services/navigation.service';
import {APP_ROUTES} from '../../app.routes.definition';
import ErrorModalComponent, {ErrorModalData} from '../../components/error-modal/error-modal.component';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'life-wheel-survey-page',
    standalone: true,
    imports: [RegistrationDashIconComponent, SurveyComponent],
    templateUrl: './life-wheel-survey-page.component.html',
})
export default class LifeWheelSurveyPageComponent implements OnInit {
    #surveyService = inject(SurveyService);
    #modalService = inject(ModalService);
    #loggerService = inject(LogService);
    #router = inject(Router);
    lifeWheelSurvey?: Survey;
    startSurvey = false;

    ngOnInit() {
        this.#surveyService
            .getLifeWheelSurvey()
            .pipe(first())
            .subscribe({
                next: (survey) => {
                    this.lifeWheelSurvey = survey;
                },
                error: (error) => {
                    this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                        ErrorModalComponent,
                        {
                            data: {
                                error: error,
                            },
                        }
                    );
                },
            });
    }

    onSurveySubmit(survey: Survey) {
        this.#surveyService.postSurvey(survey).subscribe({
            next: () => {
                this.#loggerService.log('Survey submitted');
            },
            error: (error) => {
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                        },
                    }
                );
            },
            complete: () => {
                this.#router.navigate([APP_ROUTES.HOME()]);
            },
        });
    }
}
