import {LogLevel} from "../app/models/log-level.model";
import {AppEnvironment, EnvironmentName} from "../app/models/app-environment.model";

export const environment: AppEnvironment = {
    name: EnvironmentName.dev,
    sentryEnabled: true,
    useAccessTokenAsCookie: false, // cross-site cookie not working on Safari and mobile, so use localStorage. To enable this, frontend and backend should have same base domain name.
    loginTestEmailsEnabled: true,
    logLevel: LogLevel.debug,
    apiUrl: 'https://bob-app-dev-5c4f925568c8.herokuapp.com/api/v1',
    accessTokenKey: 'access_token',
    otpAccessTokenKey: 'otp_access_token',
    authenticatedKey: 'authenticated',
    agoraAppKey: '711097290#1277672',
    agoraAppId: '2e395a764aec43c2afec7a71fb62ab91',
    googleAnalyticsTrackingId: null,
    sfmc: {
        appId: '4c3612a7-af8b-49e2-8799-43d1f2f027ec',
        accessToken: 'My3M29Y8fHQpvosNzVwYEVpc',
        mid: '536005473',
        sfmcURL: 'https://mczdb4k4r6x-g1scvwf-wm2c8pj0.device.marketingcloudapis.com/',
        senderId: '877574189035',
        delayRegistration: true,
    },
    tryTerra: {
        devId: 'beourbestsrl-testing-sdVe17CNwW',
    },
    httpInterceptor: {
        retryCount: 3,
        retryDelay: 1000,
    },
    storeUrl: {
        apple: 'https://apps.apple.com/app/id6497793093',
        googlePlay: 'https://play.google.com/store/apps/details?id=eu.beourbest.bob_mobile_app'
    },
    iubenda: {
        termsAndConditions: 'https://www.iubenda.com/termini-e-condizioni/25953371',
        privacyPolicy: 'https://www.iubenda.com/privacy-policy/25953371',
        cookiePolicy: 'https://www.iubenda.com/privacy-policy/25953371/cookie-policy'
    },
};
