<div>
    <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl pb-28">
        <!-- permessi Broswer-->
        @if (!nativeAPIAvailability) {
        <h1 class="text-primary font-semibold text-3xl">
            Permessi Video e Microfono del Browser
        </h1>
        <div class="mt-6">
            <h3 class="font-bold text-lg text-text-color">Google Chrome</h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    <strong>Clicca sull'icona del lucchetto</strong> o
                    dell'informazione (i) accanto alla barra degli indirizzi.
                </li>
                <li>
                    Dal menu che appare, seleziona
                    <strong>Impostazioni sito</strong>.
                </li>
                <li>
                    Nella sezione <strong>Permessi</strong>, cerca
                    <strong>Microfono</strong> e <strong>Fotocamera</strong>.
                </li>
                <li>
                    <strong>Imposta su "Consenti"</strong> entrambi i permessi.
                </li>
                <li>Ricarica la pagina per applicare le nuove impostazioni.</li>
            </ol>

            <img
                src="../../../../../../../assets/img/consent-video-and-mic/video-and-mic-1.png"
                alt=""
                class="mt-6"
            />
            <img
                src="../../../../../../../assets/img/consent-video-and-mic/video-and-mic-2.png"
                alt=""
                class="mt-6"
            />
        </div>
        <div class="mt-6">
            <h3 class="font-bold text-lg text-text-color">Mozilla Firefox</h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    <strong>Clicca sull'icona dello scudo</strong> accanto alla
                    barra degli indirizzi.
                </li>
                <li>
                    Seleziona <strong>Opzioni Protezione</strong> e poi
                    <strong>Gestisci Permessi</strong>.
                </li>
                <li>
                    Trova l'URL del sito e cambia i permessi di
                    <strong>Microfono</strong> e <strong>Fotocamera</strong> su
                    <strong>Consenti</strong>.
                </li>
                <li>Ricarica la pagina per applicare le nuove impostazioni.</li>
            </ol>
        </div>

        <div class="mt-6">
            <h3 class="font-bold text-lg text-text-color">Microsoft Edge</h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    <strong>Clicca sull'icona del lucchetto</strong> o
                    dell'informazione (i) accanto alla barra degli indirizzi.
                </li>
                <li>Seleziona <strong>Permessi del sito</strong>.</li>
                <li>
                    Trova <strong>Microfono</strong> e
                    <strong>Fotocamera</strong> nella lista dei permessi e
                    imposta entrambi su <strong>Consenti</strong>.
                </li>
                <li>Ricarica la pagina per applicare le nuove impostazioni.</li>
            </ol>
        </div>

        <div class="mt-6">
            <h3 class="font-bold text-lg text-text-color">Safari (macOS)</h3>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    <strong>Clicca su Safari</strong> nella barra dei menu in
                    alto, quindi seleziona <strong>Preferenze</strong>.
                </li>
                <li>
                    Vai alla scheda <strong>Siti web</strong> e seleziona
                    <strong>Microfono</strong> e <strong>Fotocamera</strong> dal
                    menu laterale.
                </li>
                <li>
                    Trova l'URL del sito nell'elenco e impostalo su
                    <strong>Consenti</strong>.
                </li>
                <li>
                    Chiudi le Preferenze e ricarica la pagina per applicare le
                    nuove impostazioni.
                </li>
            </ol>
        </div>

        <div class="mt-6">
            <h3 class="font-bold text-lg text-text-color">Note Aggiuntive</h3>
            <ul class="mt-6">
                <li>
                    Dopo aver modificato le impostazioni dei permessi, potrebbe
                    essere necessario ricaricare la pagina o riavviare il
                    browser affinché le modifiche abbiano effetto.
                </li>
                <li>
                    Se continui ad avere problemi, prova a cancellare la cache
                    del browser e i cookie, quindi ripeti i passaggi sopra
                    descritti.
                </li>
            </ul>
        </div>
        } @else { @if (targetPlatform === TargetPlatform.android) {
        <!-- Permessi Android -->
        <div class="mt-6">
            <h1 class="text-primary font-semibold text-3xl">
                Permessi Video e Microfono Android
            </h1>
            <div class class="mt-6">
                <ol class="mt-6 list-decimal ml-5">
                    <li>Sul tuo dispositivo, apri l'app Impostazioni.</li>
                    <li>
                        Tocca
                        <strong>App</strong>.
                    </li>
                    <li>
                        Tocca l'app che vuoi modificare. Se non riesci a
                        trovarla, tocca
                        <strong>Mostra tutte le app</strong>. Dopodiché scegli
                        la tua app.
                    </li>
                    <li>
                        Tocca <strong>Autorizzazioni.</strong>
                        <ul class="list-disc ml-5">
                            <li>
                                Se hai concesso o negato autorizzazioni per
                                l'app, le troverai qui.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Per modificare un'impostazione di autorizzazione,
                        toccala, quindi scegli <strong>Consenti</strong> o
                        <strong>Non consentire.</strong>
                    </li>
                </ol>
                <p class="mt-2">
                    Per le autorizzazioni relative a fotocamera e microfono,
                    puoi scegliere:
                </p>
                <ul class="mt-2 list-disc ml-5">
                    <li>
                        <strong>Consenti solo mentre l'app è in uso:</strong>
                        l'app può usare l'autorizzazione soltanto mentre è in
                        uso.
                    </li>
                    <li>
                        <strong>Chiedi ogni volta: </strong> ogni volta che la
                        apri, l'app ti chiederà di usare l'autorizzazione e
                        potrà usarla finché l'operazione con l'app non sarà
                        completata.
                    </li>
                    <li>
                        <strong>Non consentire: </strong> l'app non può
                        utilizzare l'impostazione, neanche mentre è in uso.
                    </li>
                </ul>
                <p class="mt-2">
                    <strong>Importante:</strong> alcuni di questi passaggi
                    funzionano soltanto su Android 11 e versioni successive.
                </p>
            </div>
        </div>
        } @else if (targetPlatform === TargetPlatform.iOS) {
        <!-- Permessi iOS -->
        <div class="mt-6">
            <h1 class="text-primary font-semibold text-3xl">
                Permessi Video e Microfono iOS
            </h1>
            <ol class="mt-6 list-decimal ml-5">
                <li>
                    Vai in
                    <strong>Impostazioni</strong>.
                </li>
                <li>Tocca <strong>Privacy e sicurezza</strong>.</li>
                <li>
                    Tocca una funzionalità hardware come
                    <strong>Microfono</strong> o <strong>Fotocamera</strong>.
                </li>
            </ol>
            <p class="mt-2">
                L’elenco mostra le app che hanno richiesto l’accesso. Puoi
                attivare o disattivare l’accesso per qualsiasi app nell’elenco.
            </p>
            <p class="mt-2">
                <strong>Importante:</strong> quando un’app utilizza la
                fotocamera (compreso quando fotocamera e microfono vengono usati
                insieme) viene visualizzato un indicatore verde. Un indicatore
                arancione viene visualizzato nella parte superiore della
                schermata quando un’app utilizza il microfono senza la
                fotocamera. Inoltre, viene visualizzato un messaggio nella parte
                superiore del Centro di Controllo che indica se un’app è stata
                utilizzata di recente. Alcuni di questi passaggi funzionano
                soltanto su iOS versione 16 e versioni successive.
            </p>
            <img
                src="../../../../../../../assets/img/consent-video-and-mic/video-and-mic-iOS.png"
                alt=""
                class="mt-2 w-1/4"
            />
        </div>
        } }
        <!-- Fine permessi-->
    </div>
</div>
