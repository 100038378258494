<nav class="mt-10 overflow-gradient relative">
    <ul
        class="flex items-center overflow-x-auto pe-5 scroll-smooth ease-in-out py-1 scrollbar-hidden"
        #list
    >
        <li
            [routerLink]="heartPath"
            [routerLinkActive]="'bg-data-red active'"
            class="py-2 px-5 w-33vw md:w-1/4 shrink-0 grow-1 rounded-full shadow me-2.5 flex items-center justify-center"
        >
            <a class="flex items-center" [routerLink]="heartPath">
                <div class="me-2">
                    <svg class="size-5 fill-icon-data-red">
                        <use
                            href="../../../../../assets/svg/svGP.svg#heart-full"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span [routerLinkActive]="'font-bold text-white'"
                        >Cuore</span
                    >
                </div>
            </a>
        </li>
        <li
            [routerLink]="bodyPath"
            [routerLinkActive]="'bg-data-yellow active'"
            class="py-2 px-5 w-33vw md:w-1/4 shrink-0 grow-1 rounded-full shadow me-2.5 flex items-center justify-center"
        >
            <a class="flex items-center" [routerLink]="bodyPath">
                <div class="me-2">
                    <svg class="size-5 fill-icon-data-yellow">
                        <use
                            href="../../../../../assets/svg/svGP.svg#yoga"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span [routerLinkActive]="'font-bold text-white'"
                        >Corpo</span
                    >
                </div>
            </a>
        </li>
        <li
            [routerLink]="activityPath"
            [routerLinkActive]="'bg-data-green active'"
            class="py-2 px-5 w-33vw md:w-1/4 shrink-0 grow-1 rounded-full shadow me-2.5 flex items-center justify-center"
        >
            <a class="flex items-center" [routerLink]="activityPath">
                <div class="me-2">
                    <svg class="size-5 fill-icon-data-green">
                        <use
                            href="../../../../../assets/svg/svGP.svg#activity"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span [routerLinkActive]="'font-bold text-white'"
                        >Attività</span
                    >
                </div>
            </a>
        </li>
        <li
            [routerLink]="sleepPath"
            [routerLinkActive]="'bg-data-purple active'"
            class="py-2 px-5 w-33vw md:w-1/4 shrink-0 grow-1 rounded-full shadow me-2.5 flex items-center justify-center"
        >
            <a class="flex items-center" [routerLink]="sleepPath">
                <div class="me-2">
                    <svg class="size-5 fill-icon-data-purple">
                        <use
                            href="../../../../../assets/svg/svGP.svg#bed"
                        ></use>
                    </svg>
                </div>
                <div>
                    <span [routerLinkActive]="'font-bold text-white'"
                        >Sonno</span
                    >
                </div>
            </a>
        </li>
    </ul>
</nav>
