import { Component, inject } from '@angular/core';
import { ModalService } from '../../../../../services/modal.service';
import { APP_ROUTES } from '../../../../../app.routes.definition';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-success-request-sent-modal-content',
    standalone: true,
    imports: [RouterModule],
    templateUrl: './success-request-sent-modal-content.component.html',
    styleUrl: './success-request-sent-modal-content.component.css',
})
export class SuccessRequestSentModalContentComponent {
    #modalService = inject(ModalService);
    #router = inject(Router);

    settingsPath = APP_ROUTES.SETTINGS();

    goToSettings() {
        this.#router.navigate([this.settingsPath]);
        this.onClose();
    }

    onClose() {
        this.#modalService.close();
    }
}
