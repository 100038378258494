import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AgoraService} from '../../services/agora.service';
import {FreeChatService} from '../../services/free-chat.service';
import {ChatConversation, PayloadChat} from '../../models/chat.models';
import {NavigationService} from '../../services/navigation.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {
    selectUserAgoraChatIsLogged,
    selectUserAgoraChatState,
    selectUserData,
    selectUserHasFeature, selectUserState
} from '../../store/user/user.selectors';
import {ProductFeature} from '../../models/product.model';
import {defaultIfEmpty, first, firstValueFrom, forkJoin, from, Observable} from 'rxjs';
import {AsyncPipe, CommonModule, DatePipe, NgIf} from '@angular/common';
import {APP_ROUTES} from '../../app.routes.definition';
import LoaderComponent from '../../components/loader/loader.component';
import {LogService} from '../../services/log.service';
import {AgoraChat} from "agora-chat";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {loginAgoraChat, updateAgoraConversationList} from "../../store/user/user.actions";
import {User} from "../../models/users.models";

@Component({
    selector: 'messaging-page',
    standalone: true,
    imports: [CommonModule, AsyncPipe, DatePipe, LoaderComponent],
    templateUrl: './messaging-page.component.html',
    styleUrl: './messaging-page.component.css',
})
export default class MessagingPageComponent implements OnInit {
    apiUrl = environment.apiUrl + '/free-chat';
    #http = inject(HttpClient);
    #router = inject(Router);
    #agoraService = inject(AgoraService);
    #freeChatService = inject(FreeChatService);
    #navigationService = inject(NavigationService);
    #store = inject(Store<AppState>);
    #logService = inject(LogService);
    destroyRef = inject(DestroyRef);
    defaultCoachImg = '/assets/img/pic/profile.png';

    hasFeatureChatEnabled$ = this.#store.select(
        selectUserHasFeature(ProductFeature.CHAT)
    );

    loading = true;
    conversations: ChatConversation[] = [];
    agoraLastMessages: {
        [key: string]: {
            createdDate: number;
            text: string;
        }
    } = {};

    constructor() {
        this.getConversations();
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute(true);
    }

    openChatWithUser(user: ChatConversation): void {
        let payloadForChat: PayloadChat = {
            userId: user.contactId,
            userName: user.coachName,
            userAvatar: user.coachImageURL,
        };
        this.#router.navigate(
            [APP_ROUTES.MESSAGING_ID(false, user.contactId)],
            {state: {data: payloadForChat}}
        );
    }

    getConversations() {
        this.#store.select(selectUserState)
            .pipe(takeUntilDestroyed())
            .pipe(first(userState => userState.data != null))
            .subscribe({
                next: userState => {
                    const user = userState.data as User;
                    this.#freeChatService.getConversations().subscribe({
                        next: (response) => {
                            this.conversations = response;

                            if (user.enabledFeatures.includes(ProductFeature.CHAT)) {
                                if (!userState.agora.chat.isLogged && !userState.agora.chat.isLogging) {
                                    this.#store.dispatch(loginAgoraChat({userId: user.sfAccountId}));
                                }

                                this.#store.select(selectUserAgoraChatState)
                                    .pipe(takeUntilDestroyed(this.destroyRef))
                                    // @ts-ignore
                                    .pipe(first(agoraChatState => !agoraChatState?.isLogging && (agoraChatState?.isLogged || agoraChatState?.loginError != null)))
                                    .subscribe({
                                        next: (agoraChatState) => {
                                            if (!agoraChatState.isLogged) {
                                                this.loading = false;
                                                this.#logService.error('Agora Chat not connected', agoraChatState.loginError);
                                                return;
                                            }
                                            const agoraObservables: Observable<AgoraChat.HistoryMessages | undefined>[] = [];
                                            for (const conversation of this.conversations) {
                                                agoraObservables.push(from(this.#agoraService.getHistoryMessages({
                                                    chatType: 'singleChat',
                                                    searchDirection: 'up',
                                                    targetId: conversation.contactId,
                                                    pageSize: 1
                                                })));
                                            }
                                            forkJoin(agoraObservables).pipe(defaultIfEmpty([])).subscribe({
                                                next: values => {
                                                    for (let i = 0; i < values.length; i++) {
                                                        const historyMessage = values[i];
                                                        const conversation = this.conversations[i];
                                                        if (conversation && historyMessage && historyMessage.messages.length > 0) {
                                                            const messages = historyMessage.messages as AgoraChat.TextMsgBody[];
                                                            this.agoraLastMessages[conversation.id] = {
                                                                createdDate: messages[0].time,
                                                                text: messages[0].msg,
                                                            }
                                                        }
                                                    }
                                                    this.loading = false;
                                                },
                                                error: err => {
                                                    this.#logService.error(err);
                                                    this.loading = false;
                                                }
                                            });
                                        },
                                        error: err => {
                                            this.#logService.error(err);
                                            this.loading = false;
                                        }
                                    })
                            } else {
                                this.loading = false;
                            }
                        },
                        error: (error) => {
                            this.#logService.error(
                                'Error fetching conversations',
                                error
                            );
                            this.loading = false;
                        },
                    });
                },
                error: err => {
                    this.#logService.error(err);
                    this.loading = false;
                }
            })
    }

    openFreeConversation(conversation: ChatConversation): void {
        let payloadForChat: PayloadChat = {
            userId: conversation.contactId,
            userName: conversation.coachName,
            userAvatar: conversation.coachImageURL,
        };
        this.#router.navigate(
            [APP_ROUTES.FREE_MESSAGING(false, conversation.contactId)],
            {state: {data: payloadForChat}}
        );
    }

    openConversation(conversation: ChatConversation) {
        firstValueFrom(this.hasFeatureChatEnabled$).then((value) => {
            if (value) {
                this.openChatWithUser(conversation);
            } else {
                this.openFreeConversation(conversation);
            }
        });
    }
}
