import {Component, inject, OnInit} from '@angular/core';
import {first} from 'rxjs';
import {
    selectUserData, selectUserTryTerraInitializationError, selectUserTryTerraIsInitializing,
} from '../../../../store/user/user.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {initializeTryTerra} from "../../../../store/user/user.actions";
import {AsyncPipe, NgIf} from "@angular/common";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Router} from "@angular/router";
import {APP_ROUTES} from "../../../../app.routes.definition";

@Component({
    selector: 'app-import-page',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf
    ],
    templateUrl: './import-page.component.html',
    styleUrl: './import-page.component.css',
})
export default class ImportPageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #router = inject(Router);

    user$ = this.#store.select(selectUserData);
    isImportingHealthData$ = this.#store.select(
        selectUserTryTerraIsInitializing
    );
    importHealthDataError$ = this.#store.select(
        selectUserTryTerraInitializationError
    );

    constructor() {
        this.user$.pipe(takeUntilDestroyed()).subscribe(user => {
            if (user != null && user.hasLoggedMobile) {
                this.#router.navigate([APP_ROUTES.HOME()]);
            }
        });
    }

    ngOnInit() {
        this.requestImportHealthData();
    }

    requestImportHealthData() {
        this.user$.pipe(first((value) => value != null)).subscribe({
            next: (user) => {
                if (!user) {
                    return;
                }
                this.#store.dispatch(
                    initializeTryTerra({triggerAllDataWebhook: true})
                );
            },
        });
    }
}
