import {inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HealthDataFiltersDTO, HealthDataType, HealthDataTypeDTO, HealthDataValue,} from '../models/health.models';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TerraDataResponse} from "terra-api/lib/cjs/API/Data";
import dayjs from "dayjs";
import {
    HealthDataType as TempHealthDataType,
    HealthNativeService,
    NumericHealthValue
} from "./native/health-native.service";
import {Body, Daily, Sleep} from "terra-api";
import {UploadType} from "terra-api/lib/cjs/models/enums/UploadType";
import {HeartRateDataSample} from "terra-api/lib/cjs/models/samples/HeartRateDataSample";
import {User} from "../models/users.models";
import {StepSample} from "terra-api/lib/cjs/models/samples/StepSample";
import {SleepUploadType} from "terra-api/lib/cjs/models/enums/SleepUploadType";
import { concatLatestFrom } from '@ngrx/operators';
import { CalorieSample } from 'terra-api/lib/cjs/models/samples/CalorieSample';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root',
})
export class HealthDataService {
    #http = inject(HttpClient);
    #healthNativeService = inject(HealthNativeService);
    #logService = inject(LogService);

    #apiUrl = environment.apiUrl;

    #healthDataValuesUrl = '/health-data-values';
    timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    getAllServerHealthDataTypes() {
        return this.#http.get<HealthDataTypeDTO[]>(
            `${this.#apiUrl}/health-data-types?size=999`
        );
    }

    fetchHealthUserData(
        healthType: HealthDataType,
        filters: HealthDataFiltersDTO
    ): Observable<HealthDataValue[]> {
        filters.timeZone = filters.timeZone ?? this.timeZone;
        const params = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null));
        const searchParams = new URLSearchParams(params);
        return this.#http.get<HealthDataValue[]>(
            `${this.#apiUrl}${this.#healthDataValuesUrl}/${healthType}?${searchParams}`
        );
    }

    // TODO: remove when Try Terra fixes health connect for Android
    async getAndSendTempAndroidHealthData(user: User) {
        // send always data of today
        const startDate = dayjs();
        await Promise.all([
            this.sendDailyTempAndroidHealthData(startDate, user),
            this.sendBodyTempAndroidHealthData(startDate, user),
            this.sendSleepTempAndroidHealthData(startDate, user)
        ]);

        // check and send old data before today
        const lastSendDate = localStorage.getItem('_tempAndroidHealthDataLastSendDate');
        if (lastSendDate == null) {
            localStorage.setItem('_tempAndroidHealthDataLastSendDate', dayjs().toISOString());
        }
        if (lastSendDate == null || !dayjs(lastSendDate).isToday()) {
            for (let i = 1; i < 7; i++) {
                const date = startDate.subtract(i, 'day');
                await Promise.all([
                    this.sendDailyTempAndroidHealthData(date, user),
                    this.sendBodyTempAndroidHealthData(date, user),
                    this.sendSleepTempAndroidHealthData(date, user)
                ]);
            }
            localStorage.setItem('_tempAndroidHealthDataLastSendDate', dayjs().toISOString());
        }
    }

    // TODO: remove when Try Terra fixes health connect for Android
    private async sendDailyTempAndroidHealthData(startDate: dayjs.Dayjs, user: User) {
        return this.#healthNativeService.getHealthIntervalDataFromTypes({
            interval: 300, types: [TempHealthDataType.HEART_RATE, TempHealthDataType.RESTING_HEART_RATE, TempHealthDataType.STEPS, TempHealthDataType.ACTIVE_ENERGY_BURNED],
            startDate: startDate.startOf('day').toDate(),
            endDate: startDate.isToday() ? new Date() : startDate.endOf('day').toDate(),
        }).then(value => {
            const validValues = value.filter(v => (v.value as NumericHealthValue).numeric_value > 0);
            const heartRateSamples = validValues.filter(v => v.type === 'HEART_RATE');
            const restingHeartRateSamples = validValues.filter(v => v.type === 'RESTING_HEART_RATE');
            const stepsSamples = validValues.filter(v => v.type === 'STEPS');
            for (let i = 0; i < stepsSamples.length; i++) {
                if (i > 0) {
                    const prevSample = stepsSamples[i - 1];
                    const prevSampleValue = (prevSample.value as NumericHealthValue).numeric_value;

                    const currSample = stepsSamples[i];
                    const currSampleValue = (currSample.value as NumericHealthValue).numeric_value;

                    (currSample.value as NumericHealthValue).numeric_value = currSampleValue + prevSampleValue;
                }
            }
            const caloriesSamples = validValues.filter(v => v.type === 'ACTIVE_ENERGY_BURNED');
            for (let i = 0; i < caloriesSamples.length; i++) {
                if (i > 0) {
                    const prevSample = caloriesSamples[i - 1];
                    const prevSampleValue = (prevSample.value as NumericHealthValue).numeric_value;

                    const currSample = caloriesSamples[i];
                    const currSampleValue = (currSample.value as NumericHealthValue).numeric_value;

                    (currSample.value as NumericHealthValue).numeric_value = currSampleValue + prevSampleValue;
                }
            }
            this.sendTempAndroidHealthData<Daily>({
                status: '',
                type: 'daily',
                user: {
                    reference_id: user.id.toString(),
                    user_id: '',
                    last_webhook_update: '',
                    scopes: '',
                    provider: ''
                },
                data: [
                    // @ts-ignore
                    {
                        metadata: {
                            start_time: startDate.startOf('day').toISOString(),
                            end_time: startDate.add(1, 'day').startOf('day').toISOString(),
                            upload_type: UploadType.UNKNOWN
                        },
                        // @ts-ignore
                        heart_rate_data: {
                            detailed: {
                                hr_samples: heartRateSamples.map(v => ({
                                    timestamp: dayjs(v.date_from).toISOString(),
                                    bpm: (v.value as NumericHealthValue).numeric_value
                                } as HeartRateDataSample)),
                                hrv_samples_sdnn: [],
                                hrv_samples_rmssd: []
                            },
                            // @ts-ignore
                            summary: {
                                resting_hr_bpm: restingHeartRateSamples.length > 0 ? restingHeartRateSamples
                                    .reduce((a, data) => a + (data.value as NumericHealthValue).numeric_value, 0) / restingHeartRateSamples.length : null,
                            }
                        },
                        distance_data: {
                            // @ts-ignore
                            detailed: {
                                step_samples: stepsSamples.map(v => ({
                                    steps: (v.value as NumericHealthValue).numeric_value,
                                    timestamp: dayjs(v.date_from).toISOString(),
                                } as StepSample)),
                            }
                        },
                        // @ts-ignore
                        calories_data: {
                            calorie_samples: caloriesSamples.map(v => ({
                                calories: (v.value as NumericHealthValue).numeric_value,
                                timestamp: dayjs(v.date_from).toISOString(),
                            } as CalorieSample)),
                        }
                    }
                ]
            }).subscribe({
                next: value1 => {
                    this.#logService.log('daily data saved', JSON.stringify(value1));
                },
                error: err => {
                    this.#logService.error(err);
                }
            })
        });
    }

    // TODO: remove when Try Terra fixes health connect for Android
    private async sendBodyTempAndroidHealthData(startDate: dayjs.Dayjs, user: User) {
        return this.#healthNativeService.getHealthIntervalDataFromTypes({
            interval: 300, types: [TempHealthDataType.HEART_RATE, TempHealthDataType.RESTING_HEART_RATE, TempHealthDataType.HEIGHT, TempHealthDataType.WEIGHT],
            startDate: startDate.startOf('day').toDate(),
            endDate: startDate.isToday() ? new Date() : startDate.endOf('day').toDate(),
        }).then(value => {
            const validValues = value.filter(v => (v.value as NumericHealthValue).numeric_value > 0);
            const heartRateSamples = validValues.filter(v => v.type === 'HEART_RATE');
            const restingHeartRateSamples = validValues.filter(v => v.type === 'RESTING_HEART_RATE');
            const heightSamples = validValues.filter(v => v.type === 'HEIGHT');
            const weightSamples = validValues.filter(v => v.type === 'WEIGHT');
            this.sendTempAndroidHealthData<Body>({
                status: '',
                type: 'body',
                user: {
                    reference_id: user.id.toString(),
                    user_id: '',
                    last_webhook_update: '',
                    scopes: '',
                    provider: ''
                },
                data: [
                    // @ts-ignore
                    {
                        metadata: {
                            start_time: startDate.startOf('day').toISOString(),
                            end_time: startDate.add(1, 'day').startOf('day').toISOString(),
                        },
                        heart_data: {
                            // @ts-ignore
                            heart_rate_data: {
                                detailed: {
                                    hr_samples: heartRateSamples.map(v => ({
                                        timestamp: dayjs(v.date_from).toISOString(),
                                        bpm: (v.value as NumericHealthValue).numeric_value
                                    } as HeartRateDataSample)),
                                    hrv_samples_sdnn: [],
                                    hrv_samples_rmssd: []
                                },
                                // @ts-ignore
                                summary: {
                                    resting_hr_bpm: restingHeartRateSamples.length > 0 ? restingHeartRateSamples
                                        .reduce((a, data) => a + (data.value as NumericHealthValue).numeric_value, 0) / restingHeartRateSamples.length : null,
                                }
                            },
                        },
                        measurements_data: {
                            // @ts-ignore
                            measurements: [{
                                measurement_time: startDate.startOf('day').toISOString(),
                                height_cm: heightSamples.length > 0 ? (heightSamples[heightSamples.length - 1].value as NumericHealthValue).numeric_value * 100 : null,
                                weight_kg: weightSamples.length > 0 ? (weightSamples[weightSamples.length - 1].value as NumericHealthValue).numeric_value : null,
                            }]
                        }
                    }
                ]
            }).subscribe({
                next: value1 => {
                    this.#logService.log('body data saved', JSON.stringify(value1));
                },
                error: err => {
                    this.#logService.error(err);
                }
            })
        });
    }

    // TODO: remove when Try Terra fixes health connect for Android
    private async sendSleepTempAndroidHealthData(startDate: dayjs.Dayjs, user: User) {
        return Promise.all([
            this.#healthNativeService.getHealthIntervalDataFromTypes({
                interval: 300, types: [TempHealthDataType.HEART_RATE],
                startDate: startDate.startOf('day').subtract(2, 'hours').toDate(),
                endDate: startDate.isToday() ? new Date() : startDate.endOf('day').toDate(),
            }),
            this.#healthNativeService.getHealthIntervalDataFromTypes({
                interval: 86400, types: [TempHealthDataType.RESTING_HEART_RATE, TempHealthDataType.SLEEP_SESSION],
                startDate: startDate.startOf('day').subtract(2, 'hours').toDate(),
                endDate: startDate.isToday() ? new Date() : startDate.endOf('day').toDate(),
            })
        ]).then(([heartRateValues, sleepValues]) => {
            const value = [...heartRateValues, ...sleepValues];
            const validValues = value.filter(v => (v.value as NumericHealthValue).numeric_value > 0);
            const heartRateSamples = validValues.filter(v => v.type === 'HEART_RATE');
            const restingHeartRateSamples = validValues.filter(v => v.type === 'RESTING_HEART_RATE');
            const sleepSessions = validValues.filter(v => v.type === 'SLEEP_SESSION');
            this.sendTempAndroidHealthData<Sleep>({
                status: '',
                type: 'sleep',
                user: {
                    reference_id: user.id.toString(),
                    user_id: '',
                    last_webhook_update: '',
                    scopes: '',
                    provider: ''
                },
                data: [
                    // @ts-ignore
                    {
                        metadata: {
                            start_time: startDate.startOf('day').subtract(2, 'hours').toISOString(),
                            end_time: startDate.add(1, 'day').startOf('day').subtract(2, 'hours').toISOString(),
                            upload_type: SleepUploadType.UNKNOWN
                        },
                        // @ts-ignore
                        heart_rate_data: {
                            detailed: {
                                hr_samples: heartRateSamples.map(v => ({
                                    timestamp: dayjs(v.date_from).toISOString(),
                                    bpm: (v.value as NumericHealthValue).numeric_value
                                } as HeartRateDataSample)),
                                hrv_samples_sdnn: [],
                                hrv_samples_rmssd: []
                            },
                            // @ts-ignore
                            summary: {
                                resting_hr_bpm: restingHeartRateSamples.length > 0 ? restingHeartRateSamples
                                    .reduce((a, data) => a + (data.value as NumericHealthValue).numeric_value, 0) / restingHeartRateSamples.length : null,
                            }
                        },
                        sleep_durations_data: {
                            // @ts-ignore
                            other: {
                                duration_in_bed_seconds: sleepSessions.length > 0 ? (sleepSessions[sleepSessions.length - 1].value as NumericHealthValue).numeric_value * 60 : null
                            }
                        }
                    }
                ]
            }).subscribe({
                next: value1 => {
                    this.#logService.log('sleep data saved', JSON.stringify(value1));
                },
                error: err => {
                    this.#logService.error(err);
                }
            });
        });
    }

    private sendTempAndroidHealthData<T>(
        data: TerraDataResponse<T>
    ) {
        return this.#http.post(
            `${this.#apiUrl}/try-terra/temp-webhook-android`,
            data
        );
    }
}
