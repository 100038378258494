import {Component, inject, OnInit} from '@angular/core';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import { NavigationService } from '../../../../../../services/navigation.service';
import {HealthDataType} from "../../../../../../models/health.models";
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-respiratory-rate-page',
    standalone: true,
    imports: [
        HealthDataPageComponent
    ],
    templateUrl: './respiratory-rate-page.component.html',
    styleUrl: './respiratory-rate-page.component.css',
})
export default class RespiratoryRatePageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Frequenza respiratoria';
    chartLabel = 'Frequenza respiratoria';
    respiratoryRateHealthDataType = HealthDataType.RESPIRATORY_RATE;
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['sleep'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
