<div class="border-2 border-white bg-white rounded-lg card-shadow p-0.5">
    <div class="flex">
        <div class="basis-4/12 flex items-center justify-center" *ngIf="product.imageUrl">
            <img class="w-full h-full max-h-40 object-cover rounded" [src]="product.imageUrl" alt="" />
        </div>
        <div class="py-2.5 px-3" [ngClass]="{
                'basis-8/12': product.imageUrl != null,
                'w-full': product.imageUrl == null
            }">
            <div class="mt-4">
                <h3 class="font-bold text-sm">
                    {{ product.name }}
                </h3>
                <p class="font-light text-text-color text-sm mt-1.5">
                    @switch (product.family) {
                    @case ('One shot purchase') {
                    {{
                    "components.product-card.oneShotPurchaseLabel"
                    | transloco
                    }}
                    }
                    @case ('Subscription') {
                    {{
                    "components.product-card.subscriptionLabel" | transloco
                    }}
                    }
                    }
                </p>
                <div class="flex items-center justify-between w-full mt-6">
                    <div>
                        <span class="font-bold text-sm">
                            @switch (product.family) {
                            @case ('Subscription') {
                            {{ product.price }}€ /
                            {{
                            "components.product-card." + (product.subscriptionRecurringInterval == null || product.subscriptionRecurringInterval === 1 ?
                            product.subscriptionRecurringPeriod :
                            ('' + product.subscriptionRecurringInterval + product.subscriptionRecurringPeriod)) + "Label" | transloco
                            }}
                            }
                            @case ('One shot purchase') {
                            {{ product.price }}€
                            }
                            }
                        </span>
                    </div>
                    <div class="flex align-items-center justify-end">
                        <button [routerLink]="
                                productPath(false, product.productCode)
                            " class="py-2 px-2.5 bg-transparent border border-primary rounded-lg">
                            <svg class="size-3 fill-primary">
                                <use href="../../../assets/svg/svGP.svg#chevron-right"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>