<section class="custom-bg-white py-7 px-6 relative h-full pb-32">
    <div class="flex items-center justify-between">
        <h1 class="font-semibold text-lg">Modulo di supporto</h1>
    </div>
    <br />
    <form [formGroup]="supportForm">
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="subject"
                >Oggetto</label
            >
            <input
                type="text"
                class="form-control text-sm mt-2.5"
                id="subject"
                formControlName="subject"
                placeholder="Oggetto"
            />
        </div>
        <hr class="border-light-grey" />
        @if (supportForm.controls.subject.invalid &&
        supportForm.controls.subject.touched) {
        <span class="text-danger text-sm"
            >Per favore inserisci un oggetto valido</span
        >
        }
        <div class="flex flex-col py-3.5">
            <label class="text-595959 font-bold text-sm" for="description"
                >Descrizione</label
            >
            <textarea
                class="form-control text-sm mt-2.5"
                id="description"
                formControlName="description"
                placeholder="Descrizione"
                rows="5"
            ></textarea>
        </div>
        <hr class="border-light-grey" />
        @if (supportForm.controls.description.invalid &&
        supportForm.controls.description.touched) {
        <span class="text-danger text-sm"
            >Per favore inserisci una descrizione valida</span
        >
        }
        <button
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
            [disabled]="supportForm.invalid || isLoading"
            (click)="onSupportFormSubmit()"
        >
            <div class="flex items-center justify-center w-full">
                @if (isLoading) {
                <button class="button-loader"></button>
                } @else {
                <span class="text-white text-sm font-bold uppercase"
                    >Invia</span
                >
                }
            </div>
        </button>
    </form>
</section>
