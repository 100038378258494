<div class="survey-page bg-body-bg h-screen">
    @if (!startSurvey) {
        <div class="flex flex-col h-screen justify-between">
            <div class="basis-3/12">
                <div class="px-3">
                    <button
                        class="p-2 border border-white rounded-full mt-3"
                        (click)="goBack()"
                    >
                        <svg class="w-4 h-4 fill-primary">
                            <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
                        </svg>
                    </button>
                </div>
                <app-registration-dash-icon>
                    <svg class="w-14 h-auto fill-primary">
                        <use href="../assets/svg/svGP.svg#paste-clipboard"></use>
                    </svg>
                </app-registration-dash-icon>
                <div class="text-center px-5 mt-8">
                    <h1 class="text-primary font-semibold text-3xl">
                        Benvenuto in Be Our Best, il tuo percorso verso il benessere.
                    </h1>
                    <strong class="text-xs mt-1">Trattamento dei dati personali</strong>
                    <span class="text-xs mt-1 inline-block">
                        I dati personali inseriti al momento della compilazione del questionario saranno trattati in forma anonima.
                        Successivamente, qualora decidessi di fornire un consenso specifico al trattamento dei dati relativi alla tua salute,
                        l’App Be Our Best elaborerà ed analizzerà i dati - che verranno comunicati all’Health Coach assegnato - connettendosi con i dispositivi digitali intelligenti utilizzati.
                    </span>
                </div>
            </div>
            <div class="basis-9/12 overflow-hidden mt-3">
                <div class="h-full flex flex-col justify-end">
                    <img
                        src="../assets/img/illustrations/illustration-survey.png"
                        class=""
                        alt=""
                    />
                    <div class="p-10">
                        <button
                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                            (click)="startSurveyEvent()"
                        >
                            <div class="flex items-center">
                                <span class="text-white text-sm font-bold uppercase">Inizia il questionario</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
    @if (initialSurvey && startSurvey) {
        <survey
            [survey]="initialSurvey"
            [completeSurveyButtonLabel]="'Completa'"
            [canFirstPageGoBack]="true"
            [firstPageGoBackLabel]="'Annulla'"
            (submitSurvey)="onSurveySubmit($event)"
            (nextPage)="onSurveyNextPage($event)"
            (prevPage)="onSurveyPrevPage($event)"
        />
    }
</div>
