import {Component, inject} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {ModalService} from "../../../../../../../../services/modal.service";
import {QRCodeModule} from "angularx-qrcode";

@Component({
    selector: 'app-qr-code-modal-content',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        QRCodeModule
    ],
    templateUrl: './qr-code-modal-content.component.html',
    styleUrl: './qr-code-modal-content.component.css'
})
export default class QrCodeModalContentComponent {
    #modalService = inject(ModalService);

    otpAuthUrl!: string;

    constructor() {
    }

    onClose() {
        this.#modalService.close();
    }
}
