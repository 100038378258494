import {Observable} from 'rxjs';
import {
    AppointmentRecord, SalesforcePicklistValuesResponse,
    SalesforceResponse,
    UserRecord,
} from '../../models/salesforce.models';

export class SalesforceService {
    getPicklistValues<T>(objApiName: string, picklistFieldApiName: string): Observable<SalesforcePicklistValuesResponse<T>> {
        throw new Error('Method not implemented.');
    }
}
