@if (isLoading) {
    <app-loader></app-loader>
} @else {
    <section class="bg-body-bg h-screen flex flex-col justify-between">
        <div class="basis-3/12">
            <app-registration-dash-icon>
                <svg class="w-14 h-auto fill-primary">
                    <use href="../../../assets/svg/svGP.svg#mail"></use>
                </svg>
            </app-registration-dash-icon>
            <div class="mt-10">
                <div class="text-center px-5">
                    <h1 class="text-icon-data-red font-semibold text-3xl">
                        {{ accountActivationErrorLabel }}
                    </h1>
                </div>
            </div>
        </div>
        <div class="basis-9/12 overflow-hidden mt-3">
            <div class="h-full flex flex-col justify-end">
                <div class="flex justify-center">
                    <img
                        src="../../../assets/img/illustrations/illustration-email.png"
                        class="max-w-lg w-full"
                        alt=""
                    />
                </div>
                <div class="p-10">
                    <button [routerLink]="authPath"
                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-14 w-full"
                    >
                        <div class="flex items-center justify-center">
                            <span class="text-white text-sm font-bold uppercase">Accedi</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </section>
}
