import {Component, OnInit, inject} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {selectUserData, selectUserIsLoading} from '../../../../store/user/user.selectors';
import {SupportedBillingCountry, User, UserUpdateBillingInfoData} from '../../../../models/users.models';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {updateUserBillingInfo} from '../../../../store/user/user.actions';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {NavigationService} from '../../../../services/navigation.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AsyncPipe, DatePipe, NgForOf} from '@angular/common';
import {NoWhitespaceValidator} from '../../../../shared/validators/no-whitespace.validator';
import {BillingInfoDataForm} from "../../../../models/formgroups/billing-info-data-form.model";
import {SalesforceService} from "../../../../services/salesforce/salesforce.service.interface";
import {fiscalCodeValidator} from '../../../../shared/validators/fiscal-code.validator';
import {first} from "rxjs";

@Component({
    selector: 'billing-info-page',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TranslocoModule,
        RouterModule,
        NgxIntlTelInputModule,
        AsyncPipe,
        DatePipe,
        NgForOf,
    ],
    templateUrl: './billing-info-page.component.html',
})
export default class BillingInfoPageComponent implements OnInit {
    #fb = inject(FormBuilder);
    #store = inject(Store);
    #navigationService = inject(NavigationService);
    #salesforceService = inject(SalesforceService);
    #activatedRoute = inject(ActivatedRoute);

    user$ = this.#store.select(selectUserData);
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    provincesItaly$ = this.#salesforceService.getPicklistValues<string>('Account', 'FIC_Billing_Province__c');

    user?: User | null;
    isReadOnly = true;
    shouldRedirectBackOnSave = false;
    billingInfoDataForm!: FormGroup<BillingInfoDataForm>;
    supportedBillingCountry = Object.values(SupportedBillingCountry);

    constructor() {
        this.isReadOnly = this.#activatedRoute.snapshot.queryParams['isReadOnly'] ?
            this.#activatedRoute.snapshot.queryParams['isReadOnly'] === 'true' : true;
        this.shouldRedirectBackOnSave = this.#activatedRoute.snapshot.queryParams['shouldRedirectBackOnSave'] ?
            this.#activatedRoute.snapshot.queryParams['shouldRedirectBackOnSave'] === 'true' : false;
        this.initializeForm();
        this.fetchUserData();
    }

    fetchUserData() {
        this.user$.pipe(takeUntilDestroyed()).subscribe({
            next: (user) => {
                if (user && this.user == null) {
                    this.populateForm(user);
                }
                if (user) {
                    this.user = user;
                }
            },
        });
    }

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    private initializeForm(): void {
        this.billingInfoDataForm = this.#fb.nonNullable.group({
            billingCity: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            billingCountry: this.#fb.nonNullable.control<SupportedBillingCountry>({
                value: SupportedBillingCountry.Italia,
                disabled: this.isReadOnly,
            }, [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            billingProvince: this.#fb.nonNullable.control({
                value: '',
                disabled: this.isReadOnly,
            }, [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            billingStreet: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            billingPostalCode: this.#fb.nonNullable.control('', [
                Validators.required,
                NoWhitespaceValidator(),
            ]),
            fiscalCode: this.#fb.nonNullable.control('', [
                Validators.required,
                Validators.minLength(9),
                NoWhitespaceValidator(),
                fiscalCodeValidator(),
            ]),
        });

        this.billingInfoDataForm.controls.billingCountry.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            if (value === 'Altro') {
                this.billingInfoDataForm.controls.billingProvince.setValue('');
                this.billingInfoDataForm.controls.billingProvince.clearValidators();
                this.billingInfoDataForm.controls.billingProvince.clearAsyncValidators();
                if (!this.isReadOnly) {
                    this.billingInfoDataForm.controls.billingProvince.disable();
                }
                this.billingInfoDataForm.controls.billingProvince.updateValueAndValidity();
            } else {
                this.billingInfoDataForm.controls.billingProvince.setValidators([
                    Validators.required,
                    NoWhitespaceValidator(),
                ]);
                if (!this.isReadOnly) {
                    this.billingInfoDataForm.controls.billingProvince.enable();
                }
                this.billingInfoDataForm.controls.billingProvince.updateValueAndValidity();
            }
        });
    }

    populateForm(user: User) {
        this.billingInfoDataForm.patchValue({
            billingCity: user.billingCity ?? '',
            billingCountry: user.billingCountry ?? SupportedBillingCountry.Italia,
            billingProvince: user.billingProvince ?? '',
            billingStreet: user.billingStreet ?? '',
            billingPostalCode: user.billingPostalCode ?? '',
            fiscalCode: user.fiscalCode,
        });
    }

    toggleReadOnly() {
        this.isReadOnly = !this.isReadOnly;
        if (this.isReadOnly) {
            this.disableForm();
            this.billingInfoDataForm.reset({
                billingCity: this.user?.billingCity ?? '',
                billingCountry: this.user?.billingCountry ?? SupportedBillingCountry.Italia,
                billingProvince: this.user?.billingProvince ?? '',
                billingStreet: this.user?.billingStreet ?? '',
                billingPostalCode: this.user?.billingPostalCode ?? '',
                fiscalCode: this.user?.fiscalCode,
            });
        } else {
            this.billingInfoDataForm.controls.billingProvince.enable();
            this.billingInfoDataForm.controls.billingCountry.enable();
        }
    }

    onInfoFormSubmit() {
        if (this.billingInfoDataForm.invalid || this.isReadOnly) return;

        const {
            billingCity,
            billingCountry,
            billingProvince,
            billingStreet,
            billingPostalCode,
            fiscalCode
        } = this.billingInfoDataForm.value;

        const newUserInfo: UserUpdateBillingInfoData = {
            billingCity: billingCity?.trim() ?? '',
            billingCountry: billingCountry ?? SupportedBillingCountry.Italia,
            billingProvince: billingProvince?.trim() ?? '',
            billingStreet: billingStreet?.trim() ?? '',
            billingPostalCode: billingPostalCode?.trim() ?? '',
            fiscalCode: fiscalCode?.toUpperCase() ?? '',
        };

        this.#store.dispatch(updateUserBillingInfo({data: newUserInfo}));
        this.userIsLoading$.pipe(first(isLoading => !isLoading)).subscribe(value => {
            this.disableForm();
            if (this.shouldRedirectBackOnSave) {
                window.history.back();
            }
        });
    }

    private disableForm() {
        this.isReadOnly = true;
        this.billingInfoDataForm.controls.billingProvince.disable();
        this.billingInfoDataForm.controls.billingCountry.disable();
    }
}
