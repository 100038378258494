import {Component, inject, OnInit} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import { SurveyService } from '../../services/survey.service';
import {UserService} from "../../services/user.service";
import {APP_ROUTES} from "../../app.routes.definition";

@Component({
    selector: 'how-do-you-feel-card',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './how-do-you-feel-card.component.html',
    styleUrl: './how-do-you-feel-card.component.css',
})
export class HowDoYouFeelCardComponent implements OnInit {
    #router = inject(Router);
    #userService = inject(UserService);

    hasTodayFeelingSurvey = true;

    feelingPath = APP_ROUTES.FEELING();

    constructor() {
    }

    ngOnInit() {
        this.#userService.hasTodayFeelingSurvey().subscribe(value => {
            this.hasTodayFeelingSurvey = value;
        });
    }
}
