import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/users.models';
import { environment } from '../../environments/environment';
import { TwoFactorInitResponse } from '../models/responses/two-factor-init-response.model';
import {
    AuthResponse,
    LoginResponse,
    TwoFactorAuthResponse,
} from '../models/responses/auth-response.model';
import { RegistrationData } from '../models/registration-data.model';
import { CoachingSession } from '../models/coaching-session.models';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class CoachingSessionService {
    #http = inject(HttpClient);
    #coachingSessions = '/coaching-sessions';
    #apiUrl = environment.apiUrl + this.#coachingSessions;

    // get all coaching sessions available for the current user
    getAllCoachingSessions() {
        return this.#http.get<CoachingSession[]>(this.#apiUrl);
    }

    // get all coaching sessions available for the current user
    getCoachingSession(id: string) {
        return this.#http.get<CoachingSession>(`${this.#apiUrl}/${id}`);
    }

    deleteSession(id: string) {
        return this.#http.patch(`${this.#apiUrl}/${id}/cancel`, null);
    }

    isCallTimeInvalid(appointmentDate: Date | string): boolean {
        const now = dayjs();
        return (
            now.isBefore(dayjs(appointmentDate).subtract(5, 'm')) ||
            now.isAfter(dayjs(appointmentDate).add(1, 'hour'))
        );
    }

    isRescheduleTimeValid(appointmentDate: Date | string): boolean {
        // 72 hours
        return dayjs().isBefore(dayjs(appointmentDate).subtract(3, 'day'));
    }
}
