import { Component, inject } from '@angular/core';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import dayjs from 'dayjs';
import { HealthChartComponent } from '../../../../../../components/health-chart/health-chart.component';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataAggregateOperation, HealthDataType,
    HealthDataUnit,
    HealthDataUnitToString,
    HealthDataValue,
} from '../../../../../../models/health.models';
import { HealthDataService } from '../../../../../../services/health-data.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { Store } from '@ngrx/store';
import { ProductFeature } from '../../../../../../models/product.model';
import { AppState } from '../../../../../../store/app.state';
import { selectUserHasFeature } from '../../../../../../store/user/user.selectors';
import { AsyncPipe, NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-active-energy-burned-page',
    standalone: true,
    imports: [HealthDataPageComponent, AsyncPipe, NgClass],
    templateUrl: './active-energy-burned-page.component.html',
    styleUrl: './active-energy-burned-page.component.css',
})
export default class ActiveEnergyBurnedPageComponent {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Calorie Bruciate';
    chartLabel = 'Calorie Bruciate';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bar',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar',
    }
    heartRateHealthDataType = HealthDataType.CALORIES;
    healthDataUnitSymbolLabel = 'kcal';
    hasTwoDecimals = true;
    payloadTypes: dataType[] = ['daily', 'body', 'activity'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    protected readonly HealthDataAggregateOperation = HealthDataAggregateOperation;
}
