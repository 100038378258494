<div class="flex flex-col">
    <div class="mt-5">
        <h1 class="font-bold text-xl">
            Stai per rimuovere il seguente pacchetto
        </h1>
    </div>
    <div class="mt-4">
        <p class="text-sm">Sei sicuro di voler procedere?</p>
    </div>
    <div class="mt-5 bg-light-linear p-4 rounded-lg">
        <app-tier-card
            [product]="getProduct(order.orderItems[0])"
            [showDescription]="false"
        ></app-tier-card>
    </div>
    <div class="mt-5">
        <button
            (click)="cancelSubscription()"
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-white text-sm font-bold uppercase"
                    >Rimuovi</span
                >
            </div>
        </button>
        <button
            (click)="onClose()"
            class="mt-5 py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-primary text-sm font-bold capitalize"
                    >Annulla</span
                >
            </div>
        </button>
    </div>
</div>

