import {Component, inject} from '@angular/core';
import {RouterLink} from '@angular/router';
import {RegistrationDashIconComponent} from '../../components/registration-dash-icon/registration-dash-icon.component';
import {SurveyComponent} from '../../components/survey/survey.component';
import {NavigationService} from '../../services/navigation.service';
import {APP_ROUTES} from '../../app.routes.definition';

@Component({
    selector: 'expired-initial-survey-page',
    standalone: true,
    imports: [RegistrationDashIconComponent, RouterLink],
    templateUrl: './expired-initial-survey-page.component.html',
    styleUrl: './expired-initial-survey-page.component.css',
})
export default class ExpiredInitialSurveyPageComponent {
    #navigationService = inject(NavigationService);

    surveyPath = APP_ROUTES.SURVEY();

    constructor() {
        this.#navigationService.saveCurrentRoute();
    }
}
