import {Component, OnInit, inject} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HealthDataService} from '../../services/health-data.service';
import dayjs from 'dayjs';
import {
    HealthDataPeriod, HealthDataType,
    HealthDataUnit,
    HealthDataUnitToString,
    HealthDataValue,
} from '../../models/health.models';
import {APP_ROUTES} from '../../app.routes.definition';
import {LogService} from '../../services/log.service';
import {ModalService} from '../../services/modal.service';
import ErrorModalComponent, {ErrorModalData} from '../error-modal/error-modal.component';
import {
    HealthStatisticCardComponent
} from "../health-statistic-card/health-statistic-card.component";
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'health-statistic-exercise-time-card',
    standalone: true,
    imports: [RouterModule, HealthStatisticCardComponent],
    templateUrl: './health-statistic-exercise-time-card.component.html',
    styleUrl: './health-statistic-exercise-time-card.component.css',
})
export class HealthStatisticExerciseTimeCardComponent implements OnInit {
    #healthDataService = inject(HealthDataService);
    #logService = inject(LogService);
    #modalService = inject(ModalService);


    title = 'Tempo in Movimento';
    healthTypePath = APP_ROUTES.MONITOR_ACTIVITY_EXERCISE_TIME(false);
    dataType = HealthDataType.ACTIVITY_SECONDS;
    unitSymbolLabel = 'ore';
    sumData = '';
    dateFrom = dayjs(new Date());
    period: HealthDataPeriod = HealthDataPeriod.day;
    payloadTypes: dataType[] = ['daily', 'body', 'activity'];

    ngOnInit(): void {
        this.#healthDataService
            .fetchHealthUserData(
                this.dataType,
                {
                    period: this.period,
                    dateFrom: this.dateFrom.format('YYYY-MM-DD'),
                    payloadTypes: this.payloadTypes
                }
            )
            .subscribe({
                next: (response: HealthDataValue[]) => {
                    if (response.length > 0) {
                        const values = response.map((entry) => entry.value);
                        const filteredValues = values.filter(
                            (value) => value === null
                        );

                        if (filteredValues.length > 0) {
                            this.sumData = '-';
                        } else {
                            this.sumData = values.reduce((a, b) => a + b, 0)
                                .toFixed(0);
                        }
                        const dataType = response.map(
                            (entry) => entry.type.unit
                        )[0];
                        this.unitSymbolLabel =
                            HealthDataUnitToString[HealthDataUnit[dataType]];
                    }
                },
                error: (error) => {
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                            message: 'Errore durante il recupero dei dati',
                        },
                    });
                },
            });
    }
}
