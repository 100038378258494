<div class="w-full py-12 relative">
    <div class="w-full">
        <img src="../../../assets/img/dumb-img/dashed-line.png" alt="" />
    </div>
    <div class="flex items-center justify-center absolute inset-0">
        <div class="bg-body-bg p-2.5 w-fit h-fit">
            <ng-content></ng-content>
        </div>
    </div>
</div>
