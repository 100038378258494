import { inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { APP_ROUTES } from "../app.routes.definition";
import { NavigationService } from "../services/navigation.service";

export const ExpiredInitialSurveyGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const router = inject(Router);
    const previousUrl = router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
    
    return previousUrl === APP_ROUTES.BUY_BASE_PLAN_SELECT_PLAN() ? true : router.createUrlTree([APP_ROUTES.HOME()]);
};