import { Component, inject } from '@angular/core';
import {environment} from "../../../../../../../environments/environment";


@Component({
    selector: 'membership-form-modal-content',
    standalone: true,
    imports: [],
    templateUrl: './membership-form-modal-content.component.html',
})
export class MembershipFormModalContentComponent {
    iubenda = environment.iubenda;
}
