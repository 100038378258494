import {
    Component,
    ElementRef,
    ViewChild,
    AfterViewInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, filter, fromEvent } from 'rxjs';
import { ModalService, ModalOptions } from '../../services/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    imports: [CommonModule],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('modal') modal!: ElementRef<HTMLDivElement>;
    @ViewChild('content') content!: ElementRef<HTMLDivElement>;
    @ViewChild('overlay') overlay!: ElementRef<HTMLDivElement>;
    options?: ModalOptions<any> | null;
    modalAnimationEnd!: Observable<Event>;
    overlayAnimationEnd!: Observable<Event>;
    modalLeaveAnimation = '';
    overlayLeaveAnimation = '';
    overlayClosed = false;
    modalClosed = false;
    layerLevel = 0;
    escapeKeySubscription!: Subscription;
    popstateSubscription!: Subscription;

    constructor(
        private modalService: ModalService,
        private element: ElementRef<HTMLElement>
    ) {}

    /**
     * Initialise variable and escape key on document.
     * Multiple modals might register multiple event listener, hence the 'layerLevel' variable and two times the condition check for the escape option.
     */
    ngOnInit() {
        this.modalService.layerLevel += 1;
        this.layerLevel = this.modalService.layerLevel;

        if (this.options?.actions?.escape === false) return;

        this.escapeKeySubscription = fromEvent<KeyboardEvent>(
            document,
            'keydown'
        )
            .pipe(filter((event) => event.key === 'Escape'))
            .subscribe(() => {
                if (this.options?.actions?.escape === false) return;

                if (this.layerLevel === this.modalService.layerLevel) {
                    this.modalService.close();
                }
            });

        document.body.style.overflowY = 'hidden';

        this.popstateSubscription = fromEvent(window, 'popstate').subscribe(
            () => {
                this.modalService.closeAll();
            }
        );
    }

    onClose() {
        if (this.options?.actions?.click === false) return;

        this.modalService.close();
    }

    ngAfterViewInit() {
        this.addOptionsAndAnimations();
    }

    ngOnDestroy() {
        document.body.style.overflowY = '';
        this.popstateSubscription.unsubscribe();
    }

    /**
     * Add options and animations
     * Apply user style and animations, listen to animation ends. Apply z-indexes on overlay and modal, with 1000 as incremental value.
     */
    addOptionsAndAnimations() {
        this.content.nativeElement.style.width =
            this.options?.size?.width || '';
        this.content.nativeElement.style.maxWidth =
            this.options?.size?.maxWidth || '';
        this.content.nativeElement.style.height =
            this.options?.size?.height || '';
        this.content.nativeElement.style.maxHeight =
            this.options?.size?.maxHeight || '';
        this.content.nativeElement.style.padding =
            this.options?.size?.padding || '';

        const overlayZIndex = 1000 * this.modalService.modalInstances.length;
        this.overlay.nativeElement.style.zIndex = `${overlayZIndex}`;
        this.content.nativeElement.style.zIndex = `${overlayZIndex + 1000}`;
        this.modal.nativeElement.style.zIndex = `${overlayZIndex + 1001}`;

        this.modalLeaveAnimation = this.options?.modal?.leave || '';
        this.overlayLeaveAnimation = this.options?.overlay?.leave || '';
        this.content.nativeElement.style.animation =
            this.options?.modal?.enter || '';
        this.content.nativeElement.style.top = this.options?.modal?.top ?? '';
        this.content.nativeElement.style.left = this.options?.modal?.left ?? '';

        this.overlay.nativeElement.style.animation =
            this.options?.overlay?.enter || '';
        this.overlay.nativeElement.style.backgroundColor =
            this.options?.overlay?.backgroundColor || '';

        this.modalAnimationEnd = fromEvent(
            this.content.nativeElement,
            'animationend'
        );
        this.overlayAnimationEnd = fromEvent(
            this.overlay.nativeElement,
            'animationend'
        );
    }

    removeElementIfNotAnimated(
        element: HTMLDivElement,
        animation: string,
        destroyCallback: () => void
    ) {
        if (!animation) {
            element.remove();

            if (element.classList.contains('ngx-modal')) {
                this.modalClosed = true;
            } else {
                this.overlayClosed = true;
            }

            destroyCallback();
        }
    }

    /**
     * Clean the DOM
     * Apply the leaving animations and clean the DOM. Three different use cases.
     * Last In First Out
     */
    close(destroyCallback: () => void): void {
        this.modalService.layerLevel -= 1;
        this.content.nativeElement.style.animation = this.modalLeaveAnimation;
        this.overlay.nativeElement.style.animation = this.overlayLeaveAnimation;
        this.escapeKeySubscription?.unsubscribe();

        // First: no animations on both elements
        if (!this.modalLeaveAnimation && !this.overlayLeaveAnimation) {
            this.element.nativeElement.remove();
            destroyCallback();
            return;
        }

        // Second: 1/2 animated, remove directly element if not animated
        this.removeElementIfNotAnimated(
            this.content.nativeElement,
            this.modalLeaveAnimation,
            destroyCallback
        );
        this.removeElementIfNotAnimated(
            this.overlay?.nativeElement,
            this.overlayLeaveAnimation,
            destroyCallback
        );

        // Third: Both animated with differents animation time, remove modal component as soon as last one ends
        this.modalAnimationEnd.subscribe(() => {
            this.content.nativeElement.remove();
            this.modalClosed = true;
            this.removeModalComponent(this.overlayClosed, destroyCallback);
        });
        this.overlayAnimationEnd.subscribe(() => {
            this.overlay.nativeElement.remove();
            this.overlayClosed = true;
            this.removeModalComponent(this.modalClosed, destroyCallback);
        });
    }

    removeModalComponent(
        modalOrOverlayClosed: boolean,
        destroyCallback: () => void
    ) {
        if (modalOrOverlayClosed) {
            this.element.nativeElement.remove();
            destroyCallback();
        }
    }
}
