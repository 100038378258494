import {Route} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {APP_ROUTES} from "../../app.routes.definition";
import MarketplacePageComponent from "./marketplace-page.component";
import ProductDetailsPageComponent from "./pages/product-details-page/product-details-page.component";

export const MARKETPLACES_ROUTES: Route[] = [
    {
        path: '',
        component: MarketplacePageComponent,
    },
    {
        path: APP_ROUTES.MARKETPLACE_PRODUCT(true, ':id'),
        data: {
            title: 'Product Details',
            customSectionClass: 'bg-body-bg',
            showInNavbar: false,
            showAvatar: false
        },
        component: ProductDetailsPageComponent,
    },
];
