<div class="relative h-26">
    <div
        class="top-0 right-0 left-0 p-6 z-10 max-w-screen-md m-0-auto"
        [ngClass]="{
            fixed: !showAvatar(),
            absolute: showAvatar()
        }"
    >
        <div
            class="flex items-center justify-between p-2.5 rounded-full relative"
            [ngClass]="{ 'gradient-blur': !showAvatar() }"
        >
            <div>
                @if (showAvatar()) {
                <div class="flex items-center">
                    <div>
                        <img
                            class="h-10 w-10 object-cover rounded-full"
                            [src]="
                                (user$ | async)?.avatar ??
                                '../../../assets/img/pic/profile.png'
                            "
                            alt=""
                        />
                    </div>
                    <div class="ms-3">
                        <strong>Ciao {{ (user$ | async)?.firstName }}!</strong>
                    </div>
                </div>
                } @else {
                <div class="flex items-center justify-start">
                    <button
                        class="p-2 bg-transparent rounded-full border border-primary"
                        (click)="goBack()"
                    >
                        <svg class="size-4 fill-primary">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-left"
                            ></use>
                        </svg>
                    </button>
                </div>
                }
            </div>
            @if (showTopBarLinks() && (user$ | async)) {
            <div class="p-2 flex flex-row gap-6">
                <a class="w-fit" [routerLink]="homePath">
                    <svg class="size-6 fill-primary">
                        <use href="../../../assets/svg/svGP.svg#Home"></use>
                    </svg>
                </a>
                <a class="w-fit" [routerLink]="settingsPath">
                    <svg class="size-6 fill-primary">
                        <use href="../../../assets/svg/svGP.svg#user"></use>
                    </svg>
                </a>
            </div>
            }
        </div>
    </div>
</div>
