import { Injectable, inject } from '@angular/core';
import { Route, Router } from '@angular/router';

const SKIP_CHAR = '*';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    #router = inject(Router);
    #history: string[] = [];

    getHistory(): string[] {
        return this.#history;
    }

    getNavigationRoutes(): Route[] {
        return this.#router.config
            .flatMap((route) => [route, ...(route.children || [])])
            .filter((route) => route.data?.['showInNavbar']);
    }

    saveCurrentRoute(cleanHistory?: boolean): void {
        if (cleanHistory) {
            this.#history = [];
        }

        if (
            this.#history.length > 0 &&
            this.#history[this.#history.length - 1] === this.#router.url
        ) {
            return;
        }

        this.#history.push(this.#router.url);
    }

    skipCurrentRoute(): void {
        this.#history.push(SKIP_CHAR);
    }

    navigateBack(): void {
        while (
            this.#history.length > 0 &&
            this.#history[this.#history.length - 1] === SKIP_CHAR
        ) {
            this.#history.pop();
        }

        if (this.#history.length > 1) {
            this.#history.pop();
            const lastRoute = this.#history.slice(-1)[0];
            this.#router.navigate([lastRoute]);
        } else {
            this.#router.navigate(['/']);
        }
    }
}
