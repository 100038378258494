import { Component, inject } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationService } from '../../../../services/navigation.service';
import { HealthStatisticSleepInBedCardComponent } from '../../../../components/health-statistic-sleep-in-bed-card/health-statistic-sleep-in-bed-card.component';
import {NavbarCategoriesComponent} from "../../components/navbar-categories/navbar-categories.component";

@Component({
    selector: 'app-sleep-category-page',
    standalone: true,
    imports: [HealthStatisticSleepInBedCardComponent, RouterModule, TranslocoModule, NavbarCategoriesComponent],
    templateUrl: './sleep-category-page.component.html',
    styleUrl: './sleep-category-page.component.css',
})
export default class SleepCategoryPageComponent {
    #navigationService = inject(NavigationService);

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
