import {inject, Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, interval} from 'rxjs';
import {LogService} from "./log.service";
import {ModalService} from "./modal.service";
import UpdateAppVersionModalComponent from "../components/update-app-version-modal/update-app-version-modal.component";

@Injectable({providedIn: 'root'})
export class CheckForUpdateService {
    #swUpdate = inject(SwUpdate);
    #logService = inject(LogService);
    #modalService = inject(ModalService);

    constructor() {
        this.#logService.log('Service worker enabled:', this.#swUpdate.isEnabled);
        if (this.#swUpdate.isEnabled) {
            this.listenForUpdate();
        }
    }

    async listenForUpdate(): Promise<void> {
        this.#swUpdate.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
        ).subscribe(evt => {
            this.#logService.log('CheckForUpdateService: A new version is available.');
            this.#logService.log('CheckForUpdateService Current Version: ' + evt.currentVersion.hash);
            this.#logService.log('CheckForUpdateService Latest Version: ' + evt.latestVersion.hash);
            if (!this.#modalService.isAlreadyOpened(UpdateAppVersionModalComponent)) {
                this.#modalService.open(UpdateAppVersionModalComponent);
            }
        });

        this.#swUpdate.unrecoverable.subscribe(event => {
            this.#logService.error(
                'An error occurred that we cannot recover from:\n' +
                event.reason
            );
            this.#modalService.open(UpdateAppVersionModalComponent, {
                data: {
                    unrecoverable: true
                }
            });
        });

        const updateFound = await this.#swUpdate.checkForUpdate();
        this.#logService.log('CheckForUpdateService: ' + (updateFound ? 'A new version is available.' : 'Already on the latest version.'));
        if (updateFound && !this.#modalService.isAlreadyOpened(UpdateAppVersionModalComponent)) {
            this.#modalService.open(UpdateAppVersionModalComponent);
        }

        // every 5 minutes
        interval(60 * 1000 * 5).subscribe(() => {
            this.#swUpdate.checkForUpdate().then(() => {
                this.#logService.log('checking for updates');
            });
        });
    }
}
