<section class="bg-body-bg h-screen flex flex-col justify-between">
    <div class="basis-10/12 flex items-center justify-center relative overflow-hidden">
        <img class="z-10 max-h-full" src="../../../assets/img/utility/importa-dati-salute.png" alt="">
    </div>
    <div class="basis-2/12">
        <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl">
            @if (!isCheckingNativePlatform) {
                @if (currentPlatform === TargetPlatform.android && !healthConnectAvailable) {
                    <h1 class="text-text-color font-semibold text-lg text-center">
                        Per poter continuare, è necessario installare l'App "Connessione Salute" dal Google Play
                        Store per poter poi importare i dati della tua salute.
                    </h1>
                    <div class="text-center mt-3">
                        <a (click)="enableHealthConnect()"
                           class="text-primary underline text-lg font-bold text-center cursor-pointer">
                            Abilita Health Connect
                        </a>
                    </div>
                    <div class="mt-7">
                        <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                                (click)="checkAndGoNext()">
                            <span class="text-white text-sm font-bold uppercase">Continua</span>
                        </button>
                    </div>
                } @else {
                    <h1 class="text-text-color font-semibold text-3xl text-center">
                        Per poter continuare, dobbiamo importare i dati della tua salute.
                    </h1>
                    <div class="mt-7">
                        <button class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                                [routerLink]="tutorialPath">
                            <span class="text-white text-sm font-bold uppercase">Continua</span>
                        </button>
                    </div>
                }
            }
        </div>
    </div>
</section>
