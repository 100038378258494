<section class="flex flex-col h-screen">
    <div class="flex items-center justify-start pb-6 px-3 basis-1/12">
        <div
            class="p-2 border border-white rounded-full mt-3"
            [routerLink]="authPath"
        >
            <svg class="w-4 h-4 fill-primary">
                <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
            </svg>
        </div>
    </div>
    <div class="basis-3/12 p-6 pt-0">
        <img
            src="../../../assets/img/login-images/accedi.jpg"
            class="rounded-4xl m-auto max-h-128"
            alt="accedi"
        />
    </div>
    <div class="bg-white w-full h-full py-5 px-4 rounded-t-4xl basis-8/12">
        <h1 class="text-primary font-semibold text-3xl">Accedi</h1>
        <span class="text-danger">{{ errorMessage }}</span>
        <p class="mt-3">
            Accedi alla tua area personale
        </p>
        <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
            <div class="flex flex-col mt-3.5">
                <div class="relative mt-2.5">
                    <input
                        id="emailLabel"
                        class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                        type="email"
                        placeholder=""
                        formControlName="email"
                        autocomplete="username"
                    />
                    <label
                        for="emailLabel"
                        class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >{{
                            "components.login-form.emailLabel" | transloco
                        }}</label
                    >
                </div>
                <!-- Gestione errore campo form email da stilizzare -->
                @if (loginForm.controls['email'].invalid &&
                loginForm.controls['email'].touched) {
                    <span class="text-danger text-sm"
                    >Per favore inserisci una email valida</span
                    >
                }
                <!-- Fine gestione errore campo email -->
                <div class="relative mt-2.5">
                    <input
                        id="passwordLabel"
                        class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                        type="password"
                        placeholder=""
                        formControlName="password"
                        autocomplete="current-password"
                    />
                    <label
                        for="passwordLabel"
                        class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >{{
                            "components.login-form.passwordLabel" | transloco
                        }}</label
                    >
                </div>
                <!-- Gestione errore campo form password da stilizzare -->
                @if (loginForm.controls['password'].invalid &&
                loginForm.controls['password'].touched) {
                    <span class="text-danger text-sm"
                    >Per favore inserisci una password</span
                    >
                }
                <!-- fine  gestione errore campo form password -->
                <div
                    class="py-5 px-3 border-0 bg-white rounded-lg flex items-center justify-start"
                >
                    <a
                        class="flex items-center"
                        [routerLink]="resetPasswordPath"
                    >
                        <span class="text-primary text-sm font-bold">{{
                                "components.login-form.forgotPasswordLabel"
                                    | transloco
                            }}</span>
                    </a>
                </div>
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7"
                    type="submit"
                    [disabled]="loginForm.invalid || (isLoading$ | async)"
                >
                    <div class="flex items-center">
                        @if (isLoading$ | async) {
                            <span class="button-loader"></span>
                        } @else {
                            <span class="text-white text-sm font-bold uppercase">{{
                                    "components.login-form.loginLabel" | transloco
                                }}</span>
                        }
                    </div>
                </button>
                <div class="my-7 text-center">
                    <span>Oppure</span>
                </div>
                <a
                    class="py-5 px-4 border border-light-grey rounded-lg flex items-center justify-center mb-2.5"
                    href="{{ apiUrl }}{{ googleAuth }}"
                >
                    <div>
                        <svg class="w-4 h-4 translate-y-px me-2">
                            <use
                                href="../../../assets/svg/svGP.svg#google-logo"
                            ></use>
                        </svg>
                    </div>
                    <div>
                            <span
                                class="font-titillium text-sm font-bold uppercase"
                            >{{
                                    "components.login-form.loginWithGoogleLabel"
                                        | transloco
                                }}</span
                            >
                    </div>
                </a>
                <a
                    class="py-5 px-4 border border-light-grey rounded-lg flex items-center justify-center mb-2.5"
                    href="{{ apiUrl }}{{ appleAuth }}"
                >
                    <div>
                        <svg class="w-4 h-4 translate-y-px me-2">
                            <use
                                href="../../../assets/svg/svGP.svg#apple-logo"
                            ></use>
                        </svg>
                    </div>
                    <div>
                            <span
                                class="font-titillium text-sm font-bold uppercase"
                            >Accedi con apple</span
                            >
                    </div>
                </a>
            </div>
        </form>
    </div>
</section>
