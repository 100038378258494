import {LogLevel} from "./log-level.model";

export enum EnvironmentName {
    dev = 'dev',
    uat = 'uat',
    prod = 'prod'
}

export interface AppEnvironment {
    name: EnvironmentName;
    sentryEnabled: boolean;
    useAccessTokenAsCookie: boolean;
    loginTestEmailsEnabled: boolean;
    logLevel: LogLevel;
    apiUrl: string;
    accessTokenKey: string;
    otpAccessTokenKey: string;
    authenticatedKey: string;
    agoraAppKey: string;
    agoraAppId: string;
    googleAnalyticsTrackingId: string | null;
    sfmc: {
        appId: string;
        accessToken: string;
        mid: string;
        sfmcURL: string;
        senderId: string;
        delayRegistration: boolean;
    };
    tryTerra: {
        devId: string;
    };
    httpInterceptor: {
        retryCount: number;
        retryDelay: number;
    };
    storeUrl: {
        apple: string;
        googlePlay: string;
    };
    iubenda: {
        termsAndConditions: string;
        privacyPolicy: string;
        cookiePolicy: string;
    };
}
