<div class="relative h-26">
    <div
        class="top-0 right-0 left-0 p-6 z-10 max-w-screen-md m-0-auto"
        [ngClass]="{
            fixed: !showAvatar(),
            absolute: showAvatar()
        }"
    >
        <div
            class="flex items-center justify-between p-2.5 rounded-full relative"
            [ngClass]="{ 'gradient-blur': !showAvatar() }"
        >
            <div>
                @if (showAvatar()) {
                <div class="flex items-center">
                    <div>
                        <img
                            class="h-10 w-10 object-cover rounded-full"
                            [src]="
                                (user$ | async)?.avatar ??
                                '../../../assets/img/pic/profile.png'
                            "
                            alt=""
                        />
                    </div>
                    <div class="ms-3">
                        <!-- TODO: fare la logica per l'utente loggato e per la chat singola-->
                        <span
                            >Ciao
                            <strong>{{ (user$ | async)?.firstName }}</strong
                            >!</span
                        >
                    </div>
                </div>
                } @else {
                <div class="flex items-center justify-start">
                    <button
                        class="p-2 bg-white rounded-full"
                        (click)="goBack()"
                    >
                        <svg class="size-4 fill-primary">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-left"
                            ></use>
                        </svg>
                    </button>
                </div>
                }
            </div>
            @if (showSettingsLink() && (user$ | async)) {
            <div class="p-2">
                <a class="w-fit" [routerLink]="settingsPath">
                    <svg class="size-5">
                        <use href="../../../assets/svg/svGP.svg#user"></use>
                    </svg>
                </a>
            </div>
            }
        </div>
    </div>
</div>
