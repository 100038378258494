import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Survey } from '../models/survey.models';
import {APP_ROUTES} from "../app.routes.definition";

const checkSurvey = (): Survey | null => {
    const survey = localStorage.getItem('survey');
    const surveyTime = localStorage.getItem('surveyTime');
    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

    function clearSurveyData() {
        localStorage.removeItem('survey');
        localStorage.removeItem('surveyTime');
    }

    if (surveyTime) {
        const surveyDate = new Date(surveyTime);
        const currentDate = new Date();
        const diff = currentDate.getTime() - surveyDate.getTime();

        if (diff > ONE_DAY_IN_MS) {
            clearSurveyData();
            return null;
        }
    }

    return survey ? (JSON.parse(survey) as Survey) : null;
};

export const RegistrationGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const survey: Survey | null = checkSurvey();
    return survey ? true : inject(Router).createUrlTree([APP_ROUTES.SURVEY()]);
};

export const SurveyGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const survey: Survey | null = checkSurvey();
    return survey ? inject(Router).createUrlTree([APP_ROUTES.REGISTER()]) : true;
};
