import { Route } from '@angular/router';
import { APP_ROUTES } from '../../app.routes.definition';
import SettingsPageComponent from "./settings-page.component";
import ProfilePageComponent from "./pages/profile-page/profile-page.component";
import BillingInfoPageComponent from "./pages/billing-info-page/billing-info-page.component";
import {SECURITY_ROUTES} from "./pages/security-page/security.route";
import SubscriptionsPageComponent from "./pages/subscriptions-page/subscriptions-page.component";
import DeleteAccountPageComponent from "./pages/delete-account-page/delete-account-page.component";
import HealthProvidersPageComponent from "./pages/health-providers-page/health-providers-page.component";
import {CONSENT_ROUTES} from "./pages/consent-page/consent.route";
import SupportPageComponent from "./pages/support-page/support-page.component";

export const SETTINGS_ROUTES: Route[] = [
    {
        path: '',
        component: SettingsPageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_PROFILE(true),
        data: { title: 'Profile', showInNavbar: false, showAvatar: false },
        component: ProfilePageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_BILLING_INFO(true),
        data: { title: 'Billing Info', showInNavbar: false, showAvatar: false },
        component: BillingInfoPageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_SECURITY(true),
        data: { title: 'Security', showInNavbar: false, showAvatar: false },
        children: SECURITY_ROUTES,
    },
    {
        path: APP_ROUTES.SETTINGS_SUBSCRIPTIONS(true),
        data: {
            title: 'Subscriptions',
            showInNavbar: false,
            showAvatar: false,
        },
        component: SubscriptionsPageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_DELETE(true),
        data: { title: 'Delete', showInNavbar: false, showAvatar: false },
        component: DeleteAccountPageComponent,
    },
    // TODO: HEALTH_PROVIDERS
    // {
    //     path: APP_ROUTES.HEALTH_PROVIDERS(true),
    //     data: { title: 'Health Providers', showInNavbar: false, showAvatar: false },
    //     component: HealthProvidersPageComponent,
    // },
    {
        path: APP_ROUTES.SETTINGS_CONSENT(true),
        data: { title: 'Consent', showInNavbar: false, showAvatar: false },
        children: CONSENT_ROUTES,
    },
    {
        path: APP_ROUTES.SETTINGS_SUPPORT(true),
        data: { title: 'Support', showInNavbar: false, showAvatar: false },
        component: SupportPageComponent,
    },
];
