<div class="flex flex-col container max-w-3xl mx-auto">
    <div class="mt-5">
        <h1 class="font-bold text-xl">
            Stai per abbonarti con il seguente pacchetto
        </h1>
    </div>
    <div class="mt-4">
        <p class="text-sm">Sei sicuro di voler procedere?</p>
    </div>
    <div class="mt-5 bg-light-linear p-4 rounded-lg">
        <app-tier-card
            [product]="product"
            [voucher]="voucher"
            [showDescription]="false"
        ></app-tier-card>
    </div>
    <div class="mt-2">
        @if (!(isUserBillingInfoValid$ | async)) {
            <p>Prima di poter effettuare qualsiasi acquisto, hai bisogno di aggiornare i tuoi dati di fatturazione.</p>
            <button
                [routerLink]="billingInfoPath" [queryParams]="{isReadOnly: false, shouldRedirectBackOnSave: true}"
                class="mt-3 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
            >
                <div>
                    <span class="text-white text-sm font-bold uppercase">Dati di fatturazione</span>
                </div>
            </button>
        } @else {
            <div class="flex flex-col w-full">
                <form class="mb-4" [formGroup]="discountCodeForm"
                      (submit)="applyDiscountCode()">
                    <div class="flex flex-row">
                        <div class="relative flex-grow">
                            <input
                                id="discountCode"
                                class="py-5 px-4 border border-light-grey rounded-lg mt-6 block w-full bg-transparent appearance-none focus:ring-0 focus:border-primary peer"
                                type="text"
                                formControlName="discountCode"
                                placeholder=" "
                                [readOnly]="voucher != null || (isCreatingOrder$ | async) || isApplyingDiscountCode"
                            />
                            <label
                                for="discountCode"
                                class="absolute text-9ea5b0 duration-300 transform -translate-y-2 scale-75 top-2 z-10 origin-[0] px-4 peer-focus:px-4 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-2/3 peer-placeholder-shown:top-2/3 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-2 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >Codice Sconto {{voucher ? '✔️' : ''}}</label>
                        </div>
                        @if (voucher) {
                            <button
                                class="py-5 px-4 border-0 bg-black rounded-lg flex items-center justify-center ml-1.5 mt-6"
                                [disabled]="(isCreatingOrder$ | async) || isApplyingDiscountCode"
                                (click)="removeDiscountCode()"
                                type="reset">
                                <div>
                                    <span class="text-white text-sm font-bold uppercase">Cancella</span>
                                </div>
                            </button>
                        } @else {
                            <button
                                class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center ml-1.5 mt-6"
                                [disabled]="(isCreatingOrder$ | async) || isApplyingDiscountCode || discountCodeForm.invalid"
                                type="submit">
                                <div>
                                    <span class="text-white text-sm font-bold uppercase">Applica</span>
                                </div>
                            </button>
                        }
                    </div>
                </form>

                <form [formGroup]="termsAndConditionsForm" (ngSubmit)="createOrder()">
                    <!-- Membership Form -->
                    <div>
                        <div class="flex items-center justify-center">
                            <input
                                type="checkbox"
                                class="me-2"
                                formControlName="termsAndConditions"
                                id="termsAndConditions"
                            />
                            <span class="text-sm">
                            <label
                                for="termsAndConditions">* Dichiaro di aver preso visione e di accettare i </label>
                            <a class="text-primary cursor-pointer"
                               [href]="iubenda.termsAndConditions" target="_blank">termini e le condizioni</a>.
                        </span>
                        </div>
                    </div>
                    <!-- END  -->
                    <button
                        [disabled]="(isCreatingOrder$ | async) || termsAndConditionsForm.invalid || isApplyingDiscountCode"
                        type="submit"
                        class="mt-3 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                    >
                        <div>
                            @if (isCreatingOrder$ | async) {
                                <span class="button-loader"></span>
                            } @else {
                                <span class="text-white text-sm font-bold uppercase">Abbonati</span>
                            }
                        </div>
                    </button>
                </form>
            </div>
        }
        <button
            [disabled]="isCreatingOrder$ | async"
            (click)="onClose()"
            class="mt-5 py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center w-full"
        >
            <div>
                <span class="text-primary text-sm font-bold capitalize">Annulla</span>
            </div>
        </button>
    </div>
</div>
