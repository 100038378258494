import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AppointmentRecord } from '../../models/salesforce.models';
import { AsyncPipe, CommonModule, Time } from '@angular/common';
import { CustomDatePipe } from '../../shared/pipes/custom-date.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { CoachingSession } from '../../models/coaching-session.models';
import { CoachingSessionService } from '../../services/coaching-session.service';
import { AppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { selectUserData } from '../../store/user/user.selectors';
import { ModalService } from '../../services/modal.service';
import { RescheduleModalComponent } from '../reschedule-modal/reschedule-modal.component';
import dayjs from 'dayjs';

@Component({
    selector: 'appointment-card',
    standalone: true,
    imports: [CommonModule, CustomDatePipe, TranslocoModule, AsyncPipe],
    templateUrl: './appointment-card.component.html',
    styleUrl: './appointment-card.component.css',
})
export class AppointmentCardComponent {
    @Input() isNextAppointment: boolean = false;
    @Input({ required: true }) appointment: CoachingSession =
        {} as CoachingSession;

    @Output() onAppointmentJoin = new EventEmitter<string>();

    #store = inject(Store<AppState>);
    #coachingSessionService = inject(CoachingSessionService);
    userImg$ = this.#store.select(selectUserData);

    appointmentDate?: Date;
    appointmentTime?: string;

    defaultImg = '/assets/img/pic/profile.png';

    ngOnInit() {
        this.appointment.name = this.transformAppointmentName(
            this.appointment.name
        );
        this.transformAppointmentDate(this.appointment.startingDate);
    }

    joinAppointment() {
        this.onAppointmentJoin.emit(this.appointment.id);
    }

    private transformAppointmentName(name: string): string {
        return name.replace('SC-', 'Sessione ').replace(/(Sessione )0+/g, '$1');
    }

    private transformAppointmentDate(date: string): void {
        this.appointmentDate = new Date(date);
        this.appointmentTime = this.appointmentDate.toLocaleTimeString(
            'it-IT',
            {
                hour: '2-digit',
                minute: '2-digit',
            }
        );
    }

    // Reschedule session modal
    #modalService = inject(ModalService);
    toggleCancelSession() {
        this.#modalService.open(RescheduleModalComponent, {
            data: {
                appointmentId: this.appointment.id,
                appointmentDate: this.appointmentDate,
                appointmentTime: this.appointmentTime,
                coachImg: this.appointment.coach.profileImageUrl,
            },
        });
    }

    isRescheduleTimeValid(): boolean {
        if (!this.appointmentDate) {
            return false;
        }
        return this.#coachingSessionService.isRescheduleTimeValid(
            this.appointmentDate
        );
    }

    isCallTimeInvalid(): boolean {
        if (!this.appointmentDate) {
            return true;
        }
        return this.#coachingSessionService.isCallTimeInvalid(
            this.appointmentDate
        );
    }
}
