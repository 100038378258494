import { Route } from "@angular/router";
import {APP_ROUTES} from "../../../../app.routes.definition";
import SecurityPageComponent from "./security-page.component";
import ChangeEmailPageComponent from "./pages/change-email-page/change-email-page.component";
import ChangePasswordPageComponent from "./pages/change-password-page/change-password-page.component";
import TwoFactorSetupPageComponent
    from "./pages/two-factor-process/two-factor-setup-page/two-factor-setup-page.component";
import DeleteTwoFactorPageComponent
    from "./pages/two-factor-process/delete-two-factor-page/delete-two-factor-page.component";
import BeginTwoFactorProcessPageComponent
    from "./pages/two-factor-process/begin-two-factor-process-page/begin-two-factor-process-page.component";
import EndTwoFactorProcessPageComponent
    from "./pages/two-factor-process/end-two-factor-process-page/end-two-factor-process-page.component";

export const SECURITY_ROUTES: Route[] = [
    {
        path: '',
        component: SecurityPageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_SECURITY_CHANGE_EMAIL(true),
        data: { title: 'Change Email', showInNavbar: false, showAvatar: false },
        component: ChangeEmailPageComponent,
    },
    {
        path: APP_ROUTES.SETTINGS_SECURITY_CHANGE_PASSWORD(true),
        data: { title: 'Change Password', showInNavbar: false, showAvatar: false },
        component: ChangePasswordPageComponent,
    },
    {
        path: 'two-factor-setup',
        data: {
            title: 'Two factor setup',
            showInNavbar: false,
            showAvatar: false,
        },
        component: TwoFactorSetupPageComponent,
    },
    {
        path: 'two-factor-setup/delete-two-factor',
        data: {
            title: 'Delete two factor',
            showInNavbar: false,
            showAvatar: false,
        },
        component: DeleteTwoFactorPageComponent,
    },
    {
        path: 'two-factor-setup/begin-two-factor-process',
        data: {
            title: 'Continue two factor process',
            showInNavbar: false,
            showAvatar: false,
        },
        component: BeginTwoFactorProcessPageComponent,
    },
    {
        path: 'two-factor-setup/begin-two-factor-process/end-two-factor-process',
        data: {
            title: 'End two factor process',
            showInNavbar: false,
            showAvatar: false,
        },
        component: EndTwoFactorProcessPageComponent,
    },
]
