@if (data) {
<div class="fixed top-0 right-0 left-0 m-0-auto max-w-screen-md">
    <div class="flex items-center justify-between p-7 bg-white-40">
        <div class="flex items-center">
            <button class="p-2 bg-transparent rounded-full border border-primary me-3" (click)="goBack()">
                <svg class="size-6 fill-primary-dark">
                    <use href="../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
            </button>
            <span class="font-bold">{{ data.userName }}</span>
        </div>
        <img
            class="size-10 rounded-full overflow-hidden object-cover"
            [src]="data.userAvatar ? data.userAvatar : defaultAvatar"
        />
    </div>
</div>
}
<app-loader *ngIf="loading"></app-loader>
<message-list
    #messageListComponent
    [messages]="chatMessages"
    [myId]="(user$ | async)?.sfAccountId"
    (onScrollToTop)="loadMoreMessages()"
    [loadingOldMessages]="loadingOldMessages"
/>
<message-form [disabled]="sendingMessage" (newMessage)="sendMessage($event)" />
