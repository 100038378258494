import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    AppointmentRecord, SalesforcePicklistValuesResponse,
    SalesforceResponse,
    UserRecord,
} from '../../models/salesforce.models';
import { SalesforceService } from './salesforce.service.interface';

@Injectable({
    providedIn: 'root',
})
export class SalesforceServiceReal implements SalesforceService {
    #http = inject(HttpClient);
    #apiUrl = environment.apiUrl;
    #salesforce = '/salesforce';
    #picklistValues = `${this.#salesforce}/picklist-values`;

    constructor() {}

    getPicklistValues<T>(objApiName: string, picklistFieldApiName: string) {
        return this.#http.get<SalesforcePicklistValuesResponse<T>>(
            `${this.#apiUrl}${this.#picklistValues}/${objApiName}/${picklistFieldApiName}`,
        );
    }
}
