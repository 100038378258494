<div class="p-4 bg-light-linear rounded">
    <div
        class="bg-f2f2f2 rounded py-4 px-2.5 flex items-center justify-between"
    >
        <div>
            <span class="font-bold text-xs text-text-color"
                >Sessione</span
            >
        </div>
        <div class="flex items-center justify-end">
            <div class="flex items-center justify-start">
                <div class="flex items-center me-3">
                    <div class="flex items-center">
                        <svg class="size-3.5 appointment-icon translate-y-px">
                            <use
                                href="../assets/svg/svGP.svg#Appointments"
                            ></use>
                        </svg>
                    </div>
                    <div class="ms-1.5">
                        <span class="appointment-day font-bold text-xs">{{
                            appointmentDate | customDate
                        }}</span>
                    </div>
                </div>
                <div class="flex items-center me-3">
                    <div class="flex items-center">
                        <svg class="size-3.5 appointment-icon translate-y-px">
                            <use href="../assets/svg/svGP.svg#clock"></use>
                        </svg>
                    </div>
                    <div class="ms-1.5">
                        <span class="appointment-hour text-xs font-normal">{{
                            appointmentTime
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="flex items-center photo-user-container">
                <div class="photo-user-item">
                    <img
                        class="w-5 h-5 rounded-full border border-light-grey object-cover"
                        [src]="coachImg === '' ? defaultImg : coachImg"
                        alt=""
                    />
                </div>
                <div class="photo-user-item">
                    <img
                        class="w-5 h-5 rounded-full border border-light-grey object-cover"
                        [src]="(userImg$ | async)?.avatar ?? defaultImg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mt-5">
    <h1 class="font-bold text-xl">
        Sei sicuro di voler chiedere di ripianificare la call?
    </h1>
</div>
<div class="mt-4">
    <p class="text-sm">
        Se lo fai, dovrai aspettare che il coach ti ricontatti per conoscere la
        nuova data.
    </p>
</div>
<div class="mt-5">
    <button
        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
        (click)="rescheduleAppointment()"
    >
        <div>
            <span class="text-white text-sm font-bold uppercase"
                >Ripianifica</span
            >
        </div>
    </button>
</div>
<div class="mt-2">
    <button
        class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center w-full"
        (click)="toggleModal()"
    >
        <div>
            <span class="text-primary text-sm font-bold capitalize"
                >Annulla</span
            >
        </div>
    </button>
</div>
