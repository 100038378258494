<health-data-page
    [chartLabel]="chartLabel"
    [healthDataLabel]="healthDataLabel"
    [healthDataType]="healthDataType"
    [healthDataUnitSymbolLabel]="healthDataUnitSymbolLabel"
    [hasTwoDecimals]="hasTwoDecimals"
    [aggregateOperation]="HealthDataAggregateOperation.SUM"
    [supportedPeriods]="supportedPeriods"
    [payloadTypes]="payloadTypes"
/>
