import {createReducer, on} from '@ngrx/store';
import {InternalHealthDataState} from './internal-health-data.state';
import {
    createInternalHealthDataValue,
    createInternalHealthDataValueFailure,
    createInternalHealthDataValueSuccess,
    getInternalHealthDataValues,
    getInternalHealthDataValuesSuccess,
    getInternalHealthDataValuesFailure,
    getInternalHealthDataValue,
    getInternalHealthDataValueSuccess,
    getInternalHealthDataValueFailure,
    updateInternalHealthDataValue,
    updateInternalHealthDataValueSuccess,
    updateInternalHealthDataValueFailure,
    deleteInternalHealthDataValue,
    deleteInternalHealthDataValueSuccess,
    deleteInternalHealthDataValueFailure,
    loadMoreInternalHealthDataValues,
} from './internal-health-data.actions';

const initialState: InternalHealthDataState = {
    createValue: {
        data: null,
        error: null,
        isLoading: false,
    },
    updateValue: {
        data: null,
        error: null,
        isLoading: false,
    },
    deleteValue: {
        data: null,
        error: null,
        isLoading: false,
    },
    getValues: {
        data: null,
        error: null,
        isLoading: false,
        canLoadMore: false,
        isLoadMoreLoading: false,
    },
};

export const internalHealthDataReducer = createReducer(
    initialState,
    on(createInternalHealthDataValue, (state) => ({
        ...state,
        createValue: {
            ...state.createValue,
            error: null,
            isLoading: true,
        },
    })),
    on(createInternalHealthDataValueSuccess, (state, {data}) => ({
        ...state,
        createValue: {
            ...state.createValue,
            data,
            error: null,
            isLoading: false,
        },
    })),
    on(createInternalHealthDataValueFailure, (state, {error}) => ({
        ...state,
        createValue: {
            ...state.createValue,
            error,
            isLoading: false,
        },
    })),
    on(getInternalHealthDataValues, (state, {options}) => ({
        ...state,
        getValues: {
            ...state.getValues,
            data: (options.page ?? 0) === 0 ? [] : [...(state.getValues.data ?? [])],
            error: null,
            isLoading: (options.page ?? 0) === 0,
            isLoadMoreLoading: (options.page ?? 0) !== 0,
        },
    })),
    on(getInternalHealthDataValuesSuccess, (state, {data, options}) => ({
        ...state,
        getValues: {
            ...state.getValues,
            data: (options.page ?? 0) === 0 ? data : [...(state.getValues.data ?? []), ...data],
            canLoadMore: data.length > 0 && data.length === options.size,
            error: null,
            isLoading: false,
            isLoadMoreLoading: false,
        },
    })),
    on(getInternalHealthDataValuesFailure, (state, {error}) => ({
        ...state,
        getValues: {
            ...state.getValues,
            error,
            isLoading: false,
            isLoadMoreLoading: false,
        },
    })),
    on(loadMoreInternalHealthDataValues, (state) => ({
        ...state,
        getValues: {
            ...state.getValues,
            error: null,
            isLoadMoreLoading: true,
        },
    })),
    on(getInternalHealthDataValue, (state) => ({
        ...state,
        updateValue: {
            ...state.updateValue,
            data: null,
            error: null,
            isLoading: true,
        },
    })),
    on(getInternalHealthDataValueSuccess, (state, {data}) => ({
        ...state,
        updateValue: {
            ...state.updateValue,
            data,
            error: null,
            isLoading: false,
        },
    })),
    on(getInternalHealthDataValueFailure, (state, {error}) => ({
        ...state,
        updateValue: {
            ...state.updateValue,
            data: null,
            error,
            isLoading: false,
        },
    })),
    on(updateInternalHealthDataValue, (state) => ({
        ...state,
        updateValue: {
            ...state.updateValue,
            error: null,
            isLoading: true,
        },
    })),
    on(updateInternalHealthDataValueSuccess, (state) => ({
        ...state,
        updateValue: {
            ...state.updateValue,
            error: null,
            isLoading: false,
        },
    })),
    on(updateInternalHealthDataValueFailure, (state, {error}) => ({
        ...state,
        updateValue: {
            ...state.updateValue,
            error,
            isLoading: false,
        },
    })),
    on(deleteInternalHealthDataValue, (state) => ({
        ...state,
        deleteValue: {
            ...state.deleteValue,
            error: null,
            isLoading: true,
        },
    })),
    on(deleteInternalHealthDataValueSuccess, (state, {data}) => ({
        ...state,
        getValues: {
            ...state.getValues,
            data: state.getValues.data?.filter((value) => value.id !== data.id),
        },
        deleteValue: {
            ...state.deleteValue,
            error: null,
            isLoading: false,
        },
    })),
    on(deleteInternalHealthDataValueFailure, (state, {error}) => ({
        ...state,
        deleteValue: {
            ...state.deleteValue,
            error,
            isLoading: false,
        },
    })),
);
