import {Component, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Chart, registerables} from 'chart.js';
import dayjs from 'dayjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {
    selectUserIsLoading,
    selectUserTryTerraHasProviders, selectUserTryTerraIsGettingUserInfo, selectUserTryTerraIsNativeInitializing
} from '../../store/user/user.selectors';
import {
    HealthChartConfiguration,
    HealthChartPeriod,
    HealthDataAggregateOperation,
    HealthDataPeriod, HealthDataType, HealthDataTypeToUnit, HealthDataUnit, HealthDataUnitToString,
    HealthDataValue,
} from '../../models/health.models';
import {RouterLink} from '@angular/router';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {HealthChartComponent} from '../health-chart/health-chart.component';
import {HealthDataPageStore} from './health-data-page.store';
import {provideComponentStore} from '@ngrx/component-store';
import {APP_ROUTES} from '../../app.routes.definition';
import {dataType} from "terra-api/lib/esm/API/Data";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NoWearableConnectedCardComponent} from "../no-wearable-connected-card/no-wearable-connected-card.component";

@Component({
    selector: 'health-data-page',
    standalone: true,
    imports: [NgClass, AsyncPipe, HealthChartComponent, RouterLink, NgIf, NoWearableConnectedCardComponent],
    templateUrl: './health-data-page.component.html',
    providers: [provideComponentStore(HealthDataPageStore)],
})
export class HealthDataPageComponent implements OnInit, OnChanges {
    @Input() chartLabel: string = '';
    @Input() chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'line',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar'
    }
    @Input() healthDataLabel: string = '';
    @Input({required: true}) healthDataType!: HealthDataType;
    @Input({required: true}) payloadTypes!: dataType[];
    @Input() aggregateOperation: HealthDataAggregateOperation =
        HealthDataAggregateOperation.AVG;
    @Input() prefixAggregateLegendLabel?: string | null;
    @Input() useMetadataEndTime?: boolean;
    @Input() healthDataUnitSymbolLabel?: string | null;
    @Input() hasTwoDecimals: boolean = false;
    @Input() supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.day, HealthChartPeriod.week, HealthChartPeriod.month];
    @Input() canAddHealthData: boolean = false;

    HealthDataAggregateOperation = HealthDataAggregateOperation;
    HealthDataType = HealthDataType;
    HealthDataUnitToString = HealthDataUnitToString;
    HealthDataUnit = HealthDataUnit;
    HealthDataTypeToUnit = HealthDataTypeToUnit;

    #store = inject(Store<AppState>);
    #componentStore = inject(HealthDataPageStore);

    healthDataValues$ = this.#componentStore.healthData$;
    isLoading$ = this.#componentStore.isLoading$;
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    userTryTerraIsLoading$ = this.#store.select(selectUserTryTerraIsGettingUserInfo);
    userTryTerraHasProviders$ = this.#store.select(selectUserTryTerraHasProviders);
    userTryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);

    createInternalHealthDataPath?: string | null;
    viewInternalHealthDataPath?: string | null;

    dateFrom = dayjs(new Date());
    dateLabel = '';
    dateLastMonitored = '';
    lastDataMonitored = '';
    maxValueMonitored = '';
    minValueMonitored = '';
    sumTotalValuesMonitored = '';
    period: HealthChartPeriod = HealthChartPeriod.day;
    HealthChartPeriod = HealthChartPeriod;
    basisClass = this.getBasisClass();

    constructor() {
        Chart.register(...registerables);
        this.updateUserData();
    }

    ngOnInit(): void {
        this.createInternalHealthDataPath = APP_ROUTES.MONITOR_CREATE_INTERNAL_HEALTH_DATA(false, this.healthDataType);
        this.viewInternalHealthDataPath = APP_ROUTES.MONITOR_VIEW_INTERNAL_HEALTH_DATA(false, this.healthDataType);
        this.period = this.supportedPeriods[0];
        this.fetchHealthDataWithStore();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['supportedPeriods']) {
            this.basisClass = this.getBasisClass();
        }
    }

    fetchHealthDataWithStore() {
        this.dateLastMonitored = '';
        this.lastDataMonitored = '';
        this.minValueMonitored = '';
        this.maxValueMonitored = '';
        this.sumTotalValuesMonitored = '';

        this.#componentStore.fetchHealthData({
            healthType: this.healthDataType,
            filters: {
                period: HealthDataPeriod[this.period],
                dateFrom: this.dateFrom.format('YYYY-MM-DD'),
                aggregateOperation: this.aggregateOperation,
                payloadTypes: this.payloadTypes,
                useMetadataEndTime: this.useMetadataEndTime,
            },
        });
        this.updateDateLabel();
    }

    updateUserData() {
        this.healthDataValues$.pipe(takeUntilDestroyed()).subscribe((data) => {
            if (data && data.length > 0) {
                const lastValue = data[data.length - 1];
                const values = data.map((entry) => entry.value);
                const filteredValues = values.filter((value) => value === null);
                const sumValues = values.reduce((acc, value) => acc + value, 0);

                if (filteredValues.length > 0) {
                    this.dateLastMonitored = '-';
                    this.lastDataMonitored = '-';
                    this.minValueMonitored = '- ';
                    this.maxValueMonitored = ' -';
                    this.sumTotalValuesMonitored = '-';
                } else {
                    this.dateLastMonitored =
                        this.period === HealthChartPeriod.day
                            ? dayjs(lastValue.date).format('HH:mm')
                            : dayjs(lastValue.date).format('DD/MM');

                    if (this.hasTwoDecimals) {
                        this.lastDataMonitored = lastValue.value
                            .toFixed(2)
                            .toString();
                        this.minValueMonitored = Math.min(...values)
                            .toFixed(2)
                            .toString();
                        this.maxValueMonitored = Math.max(...values)
                            .toFixed(2)
                            .toString();
                        this.sumTotalValuesMonitored = sumValues
                            .toFixed(2)
                            .toString();
                    } else if ([HealthDataType.SLEEP_IN_BED, HealthDataType.ACTIVITY_SECONDS].includes(lastValue.type.name)) {
                        const totalSeconds = values.reduce((a, b) => a + b, 0);
                        this.lastDataMonitored = this.formatTime(lastValue.value);
                        this.minValueMonitored = this.formatTime(Math.min(...values));
                        this.maxValueMonitored = this.formatTime(Math.max(...values));
                        this.sumTotalValuesMonitored = this.formatTime(totalSeconds);
                    } else {
                        this.lastDataMonitored = lastValue.value
                            .toFixed(0)
                            .toString();
                        this.minValueMonitored = Math.min(...values)
                            .toFixed(0)
                            .toString();
                        this.maxValueMonitored = Math.max(...values)
                            .toFixed(0)
                            .toString();
                        this.sumTotalValuesMonitored = sumValues
                            .toFixed(0)
                            .toString();
                    }
                }
            } else {
                this.dateLastMonitored = '-';
                this.lastDataMonitored = '-';
                this.minValueMonitored = '- ';
                this.maxValueMonitored = ' -';
                this.sumTotalValuesMonitored = '-';
            }
        });
    }

    updateDateLabel() {
        const now = dayjs(new Date());
        switch (this.period) {
            case 'day':
                this.dateLabel = dayjs(this.dateFrom)
                    .format('dddd D MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                if (now.year() !== this.dateFrom.year()) {
                    this.dateLabel += ` ${this.dateFrom.year()}`;
                }
                break;
            case 'week':
                const startWeek = dayjs(this.dateFrom)
                    .startOf('week');
                const endWeek = dayjs(this.dateFrom).endOf('week');
                const month = startWeek
                    .format('MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                const endMonth = endWeek
                    .format('MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                this.dateLabel = `${startWeek.format('D')}`;
                if (startWeek.month() !== endWeek.month()) {
                    this.dateLabel += ` ${month}`;
                }
                if (startWeek.year() !== endWeek.year()) {
                    this.dateLabel += ` ${startWeek.year()}`;
                }
                this.dateLabel += ` - ${endWeek.format('D')} ${endMonth}`;
                if (startWeek.year() !== endWeek.year() || endWeek.year() !== now.year()) {
                    this.dateLabel += ` ${endWeek.year()}`;
                }
                break;
            case 'month':
                this.dateLabel = dayjs(this.dateFrom)
                    .format('MMMM')
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                if (now.year() !== this.dateFrom.year()) {
                    this.dateLabel += ` ${this.dateFrom.year()}`;
                }
                break;
        }
    }

    previousDate() {
        this.dateFrom = this.dateFrom.add(-1, this.period);
        this.fetchHealthDataWithStore();
    }

    nextDate() {
        this.dateFrom = this.dateFrom.add(1, this.period);
        this.fetchHealthDataWithStore();
    }

    filterData(period: HealthChartPeriod) {
        if (period !== this.period) {
            this.period = period;
            this.fetchHealthDataWithStore();
        }
    }

    private getBasisClass(): string {
        if (this.supportedPeriods.length > 1) {
            return this.supportedPeriods.length === 2
                ? 'basis-6/12'
                : 'basis-4/12';
        } else {
            return 'basis-12/12';
        }
    }

    private formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };
}
