import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";

export interface PackageInfo {
    appName: string;
    buildNumber: string;
    packageName: string;
    version: string;
    buildSignature?: string | null;
    installerStore?: string | null;
}

/**
 * This service allows interaction with Package Info native APIs.
 *
 * Official API: https://pub.dev/packages/package_info_plus
 */
@Injectable({
    providedIn: 'root'
})
export class PackageInfoNativeService {
    #nativeAPIService = inject(NativeAPIService);

    constructor() {
    }

    /**
     * Checks if the native Package Info Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }

    /**
     * Retrieves package information from the platform. The result is cached.
     */
    fromPlatform() {
        return this.#nativeAPIService.callHandler<PackageInfo>('PackageInfoService.fromPlatform');
    }
}
