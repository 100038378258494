import { Route } from '@angular/router';
import {APP_ROUTES} from "../../app.routes.definition";
import LoginPageComponent from "./login-page.component";
import VerifyOtpComponent from "./pages/verify-otp/verify-otp.component";

export const LOGIN_PAGE_ROUTES: Route[] = [
    {
        path: '',
        component: LoginPageComponent,
    },
    {
        path: APP_ROUTES.LOGIN_VERIFY_OTP(true),
        component: VerifyOtpComponent,
    },
];
