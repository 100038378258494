<section class="custom-bg-white px-6 relative pb-28">
    <app-loader *ngIf="isLoading"></app-loader>

    @if (feelingSurvey) {
        <survey
            [survey]="feelingSurvey"
            [isLoading]="postSurveyLoading"
            (submitSurvey)="onSurveySubmit($event)"
            completeSurveyButtonLabel="Invia questionario"
        ></survey>
    }
</section>
