import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef, EventEmitter,
    inject,
    Input, OnInit, Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {DOCUMENT, NgClass, NgIf, NgStyle, NgTemplateOutlet} from "@angular/common";

export interface CarouselSlide {
    img?: string | null;
    title?: string | null;
    text?: string | null;
    objectCover?: string | null;
}

@Component({
    selector: 'app-carousel',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgTemplateOutlet,
        NgStyle,
    ],
    templateUrl: './carousel.component.html',
    styleUrl: './carousel.component.css'
})
export default class CarouselComponent implements AfterViewInit {
    @ContentChildren('component') components?: QueryList<TemplateRef<any>>;
    @ViewChild('sliderElement') sliderElement!: ElementRef<HTMLElement>;
    @ViewChildren('slideElements') slideElements!: QueryList<ElementRef<HTMLElement>>;

    @Input() previousButtonLabel = 'Indietro';
    @Input() nextButtonLabel = 'Avanti';
    @Input() previousButtonDisabled = false;
    @Input() nextButtonDisabled = false;
    @Input() paginationDisabled = false;
    @Input() scrollable = true;
    @Input() slides: CarouselSlide[] = [];
    @Input() initialIndex = 0;
    @Input() textAlign: 'center' | 'left' | 'right' = 'center';
    @Input() slideHeight = 350;

    @Output() slideChange = new EventEmitter<number>();

    currentSlide = 0;

    constructor() {
    }

    ngAfterViewInit() {
        if (this.initialIndex > 0 && this.initialIndex < this.slideElements.length) {
            this.slideElements.get(this.initialIndex)?.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });
        }
    }

    nextSlide() {
        const lengthToCheck = this.slideElements.length - 1;
        if (this.currentSlide >= lengthToCheck) {
            return;
        }

        // safari and ios has a bug with scrollBy: it doesn't trigger the scroll snap CSS effect
        if (this.isSafari()) {
            const el = this.slideElements.get(this.currentSlide + 1)?.nativeElement;
            if (el) {
                el.scrollIntoView({
                    block: 'end',
                    behavior: 'instant'
                });
                return;
            }
        }

        this.sliderElement.nativeElement.scrollBy({
            left: 1,
            behavior: 'smooth'
        });
    }

    previousSlide() {
        if (this.currentSlide === 0) {
            return;
        }

        // safari and ios has a bug with scrollBy: it doesn't trigger the scroll snap CSS effect
        if (this.isSafari()) {
            const el = this.slideElements.get(this.currentSlide - 1)?.nativeElement;
            if (el) {
                el.scrollIntoView({
                    block: 'end',
                    behavior: 'instant'
                });
                return;
            }
        }

        this.sliderElement.nativeElement.scrollBy({
            left: -1,
            behavior: 'smooth'
        });
    }

    sliderScroll() {
        for (let i = 0; i < this.slideElements.length; i++) {
            const slideHTMLElement = this.slideElements.get(i)?.nativeElement;
            if (slideHTMLElement && !this.isElementOutSlider(slideHTMLElement)) {
                this.currentSlide = i;
                this.slideChange.emit(this.currentSlide);
                break;
            }
        }
    }

    private isElementOutSlider(el: Element): boolean {
        const rect = el.getBoundingClientRect();
        const sliderRect = this.sliderElement.nativeElement.getBoundingClientRect();
        return rect.x < sliderRect.x || rect.x > (sliderRect.x + sliderRect.width);
    }

    private isSafari() {
        // @ts-ignore
        return window.safari != null ||
            (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1);
    }
}
