<health-data-page
    [chartLabel]="chartLabel"
    [chartValueUnitSymbol]="healthDataUnitSymbolLabel"
    [healthDataLabel]="healthDataLabel"
    [healthDataType]="sleepInBedHealthDataType"
    [healthDataUnitSymbolLabel]="healthDataUnitSymbolLabel"
    [hasTwoDecimals]="hasTwoDecimals"
    [aggregateOperation]="HealthDataAggregateOperation.SUM"
    [supportedPeriods]="supportedPeriods"
    [payloadTypes]="payloadTypes"
    [useMetadataEndTime]="true"
/>
