<section class="modal realtive w-screen h-screen">
    <div
        class="bg-white z-50 absolute bottom-0 inset-x-0 rounded-t-3xl px-6 py-7"
    >
        <div class="p-4 bg-light-linear rounded">
            <div
                class="bg-f2f2f2 rounded py-4 px-2.5 flex items-center justify-between"
            >
                <div>
                    <span class="font-bold text-base text-text-color"
                        >Call terminata</span
                    >
                </div>
                <div class="flex items-center justify-end">
                    <div class="flex items-center photo-user-container me-3">
                        <div class="photo-user-item">
                            <img
                                class="w-6 h-6 rounded-full border border-light-grey object-cover"
                                src="../assets/img/pic/user-male.jpg"
                                alt=""
                            />
                        </div>
                        <div class="photo-user-item">
                            <img
                                class="w-6 h-6 rounded-full border border-light-grey object-cover"
                                src="../assets/img/pic/profile.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="flex items-center justify-start">
                        <div class="flex items-center">
                            <div class="flex items-center">
                                <svg
                                    class="size-3.5 appointment-icon translate-y-px"
                                >
                                    <use
                                        href="../assets/svg/svGP.svg#clock"
                                    ></use>
                                </svg>
                            </div>
                            <div class="ms-1.5">
                                <span
                                    class="appointment-hour text-xs font-normal"
                                    >{{ time }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        @if (surveyData) {
        <survey
            [survey]="surveyData"
            (submitSurvey)="submitFeedback($event)"
        ></survey>
        }
    </div>
</section>
