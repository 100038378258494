import { Component, Output, EventEmitter } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'search-bar',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.css'
})
export class SearchBarComponent {

  @Output() searchTerm = new EventEmitter<string>();

  onSearchTermChange(searchTerm: string) {
    this.searchTerm.emit(searchTerm);
  }

}
