import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslocoModule} from '@ngneat/transloco';
import {APP_ROUTES} from "../../app.routes.definition";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'no-wearable-connected-card',
    standalone: true,
    imports: [CommonModule, TranslocoModule, RouterLink],
    templateUrl: './no-wearable-connected-card.component.html',
    styleUrl: './no-wearable-connected-card.component.css',
})
export class NoWearableConnectedCardComponent {
    healthProvidersPath = APP_ROUTES.HEALTH_PROVIDERS();
}
