import {inject} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {filter, mergeMap, Observable, of, switchMap} from 'rxjs';
import {UserService} from "../services/user.service";
import {map} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.state";
import {selectUserHasBasePlan, selectUserIsLoading} from "../store/user/user.selectors";
import {APP_ROUTES} from "../app.routes.definition";

export const UserHasTodayFeelingSurveyGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const userService = inject(UserService);
    const router = inject(Router);
    const store = inject(Store<AppState>);
    return store.select(selectUserIsLoading).pipe(
        filter((isLoading) => !isLoading),
        switchMap((isLoading) => {
            return userService.hasTodayFeelingSurvey().pipe(
                map((hasTodayFeelingSurvey) => {
                    if (hasTodayFeelingSurvey) {
                        return router.createUrlTree([APP_ROUTES.HOME()]);
                    }
                    return true;
                })
            );
        })
    );
};
