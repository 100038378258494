import {AppState} from '../app.state';
import {createSelector} from "@ngrx/store";

export const selectCreateOrderState = (state: AppState) => state.createOrder;

export const selectCreateOrderData = createSelector(
    selectCreateOrderState,
    (state) => state.data
);

export const selectCreateOrderError = createSelector(
    selectCreateOrderState,
    (state) => state.error
);
export const selectCreateOrderIsLoading = createSelector(
    selectCreateOrderState,
    (state) => state.isLoading
);
