import {Component, inject} from '@angular/core';
import {NgIf} from "@angular/common";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'app-update-app-version-modal',
  standalone: true,
    imports: [
        NgIf
    ],
  templateUrl: './update-app-version-modal.component.html',
  styleUrl: './update-app-version-modal.component.css'
})
export default class UpdateAppVersionModalComponent {
    #modalService = inject(ModalService);

    unrecoverable = false;

    constructor() {
    }

    updateAppVersion() {
        document.location.reload();
    }

    close() {
        this.#modalService.close();
    }
}
