import {inject, Injectable} from '@angular/core';
import {HealthDataUnit} from "../../models/health.models";
import {NativeAPIService} from "./native-api.service";

// TODO: remove when Try Terra fixes health connect for Android
/**
 * Enables reading and writing health data from/to Apple Health, Google Fit and Health Connect.
 *
 * Google Fitness API is deprecated and will be turned down in 2024, thus this package will also transition to only support Health Connect.
 *
 * Note that for Android, the target phone needs to have Google Fit or Health Connect (which is currently in beta)
 * installed and have access to the internet, otherwise this plugin will not work.
 *
 * Official API: https://pub.dev/documentation/health/latest/
 */
@Injectable({
    providedIn: 'root'
})
export class HealthNativeService {
    #nativeAPIService = inject(NativeAPIService);
    constructor() {
    }
    /**
     * Checks if the native Health Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }
    /**
     * Determines if the data types have been granted with the specified access rights.
     *
     * Returns:
     *
     * - true - if all the data types have been granted with the specified access rights.
     * - false - if any of the data types has not been granted with the specified access right(s)
     * - null - if it can not be determined if the data types have been granted with the specified access right(s).
     *
     * Parameters:
     *
     * - [types] - List of {@link HealthDataType} whose permissions are to be checked.
     * - [permissions] - Optional.
     *   + If unspecified, this method checks if each {@link HealthDataType} in [types] has been granted READ access.
     *   + If specified, this method checks if each {@link HealthDataType} in [types] has been granted with the access specified in its
     *   corresponding entry in this list. The length of this list must be equal to that of [types].
     *
     * Caveat:
     *
     * As Apple HealthKit will not disclose if READ access has been granted for a data type due to privacy concern,
     * this method can only return null to represent an undetermined status, if it is called on iOS
     * with a READ or READ_WRITE access.
     *
     * On Android, this function returns true or false, depending on whether the specified access right has been granted.
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/hasPermissions.html
     */
    hasPermissions(options: {
        types: HealthDataType[],
        permissions?: HealthDataAccess[] | null
    }): Promise<boolean | null> {
        return this.#nativeAPIService.callHandler<boolean | null>('HealthService.hasPermissions', options);
    }
    /**
     * Requests permissions to access data types in Apple Health or Google Fit.
     *
     * Returns true if successful, false otherwise.
     *
     * Parameters:
     *
     * - [types] - List of {@link HealthDataType} whose permissions are to be checked.
     * - [permissions] - Optional.
     *   + If unspecified, this method checks if each {@link HealthDataType} in [types] has been granted READ access.
     *   + If specified, this method checks if each {@link HealthDataType} in [types] has been granted with the access specified in its
     *   corresponding entry in this list. The length of this list must be equal to that of [types].
     *
     * Caveat:
     *
     * As Apple HealthKit will not disclose if READ access has been granted for a data type due to privacy concern,
     * this method can only return null to represent an undetermined status,
     * if it is called on iOS with a READ or READ_WRITE access.
     *
     * On Android, this function returns true or false, depending on whether the specified access right has been granted.
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/requestAuthorization.html
     */
    requestAuthorization(options: {
        types: HealthDataType[],
        permissions?: HealthDataAccess[] | null
    }): Promise<boolean> {
        return this.#nativeAPIService.callHandler<boolean>('HealthService.requestAuthorization', options);
    }
    /**
     * Revokes permissions of all types. Uses disableFit() on Google Fit.
     *
     * Not implemented on iOS as there is no way to programmatically remove access.
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/revokePermissions.html
     */
    async revokePermissions(): Promise<void> {
        await this.#nativeAPIService.callHandler<void>('HealthService.revokePermissions');
    }
    /**
     * Fetch a list of health data points based on [types].
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/getHealthDataFromTypes.html
     */
    getHealthDataFromTypes(options: {
        startTime: Date,
        endTime: Date,
        types: HealthDataType[],
        includeManualEntry?: boolean | null
    }): Promise<HealthDataPoint[]> {
        return this.#nativeAPIService.callHandler<HealthDataPoint[]>('HealthService.getHealthDataFromTypes', {
            ...options,
            startTime: options.startTime.getTime(),
            endTime: options.endTime.getTime(),
            includeManualEntry: options.includeManualEntry,
        });
    }
    /**
     * Prompt the user to install the Health Connect app via the installed store (most likely Play Store).
     *
     * Android only.
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/installHealthConnect.html
     */
    async installHealthConnect(): Promise<void> {
        await this.#nativeAPIService.callHandler<void>('HealthService.installHealthConnect');
    }
    /**
     * Prompt the user to install the Health Connect app via the installed store (most likely Play Store).
     *
     * Android only.
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/installHealthConnect.html
     */
    async getHealthConnectSdkStatus(): Promise<HealthConnectSdkStatus | null> {
        return await this.#nativeAPIService.callHandler<HealthConnectSdkStatus | null>('HealthService.getHealthConnectSdkStatus');
    }
    /**
     * Fetch a list of health data points based on [types].
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/getHealthIntervalDataFromTypes.html
     */
    getHealthIntervalDataFromTypes(options: {
        startDate: Date,
        endDate: Date,
        types: HealthDataType[],
        interval?: number | null,
        includeManualEntry?: boolean | null
    }): Promise<HealthDataPoint[]> {
        return this.#nativeAPIService.callHandler<HealthDataPoint[]>('HealthService.getHealthIntervalDataFromTypes', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
            interval: options.interval,
            includeManualEntry: options.includeManualEntry,
        });
    }
    /**
     * Fetch a list of health data points based on [types].
     *
     * Official API: https://pub.dev/documentation/health/latest/health/Health/getHealthAggregateDataFromTypes.html
     */
    getHealthAggregateDataFromTypes(options: {
        startDate: Date,
        endDate: Date,
        types: HealthDataType[],
        activitySegmentDuration?: number | null,
        includeManualEntry?: boolean | null
    }): Promise<HealthDataPoint[]> {
        return this.#nativeAPIService.callHandler<HealthDataPoint[]>('HealthService.getHealthAggregateDataFromTypes', {
            ...options,
            startDate: options.startDate.getTime(),
            endDate: options.endDate.getTime(),
            activitySegmentDuration: options.activitySegmentDuration,
            includeManualEntry: options.includeManualEntry,
        });
    }
}

/**
 * The status of Google Health Connect.
 *
 * Reference:
 * https://developer.android.com/reference/kotlin/androidx/health/connect/client/HealthConnectClient#constants_1
 */
export enum HealthConnectSdkStatus {
    /// https://developer.android.com/reference/kotlin/androidx/health/connect/client/HealthConnectClient#SDK_UNAVAILABLE()
    sdkUnavailable = 1,
    /// https://developer.android.com/reference/kotlin/androidx/health/connect/client/HealthConnectClient#SDK_UNAVAILABLE_PROVIDER_UPDATE_REQUIRED()
    sdkUnavailableProviderUpdateRequired = 2,
    /// https://developer.android.com/reference/kotlin/androidx/health/connect/client/HealthConnectClient#SDK_AVAILABLE()
    sdkAvailable = 3,
}

/**
 * Access types for Health Data.
 */
export enum HealthDataAccess {
    READ,
    WRITE,
    READ_WRITE,
}

/**
 * A {@link HealthDataPoint} object corresponds to a data point capture from
 * GoogleFit or Apple HealthKit with a {@link HealthValue} as value.
 */
export interface HealthDataPoint {
    value: HealthValue;
    type: keyof typeof HealthDataType;
    unit: keyof typeof HealthDataUnit;
    date_from: string;
    date_to: string;
    source_platform: 'appleHealth' | 'googleFit' | 'googleHealthConnect';
    source_device_id: string;
    source_id: string;
    source_name: string;
    is_manual_entry: boolean;
}

/**
 * A {@link HealthDataGroup} object corresponds to a group of data points
 * capture from GoogleFit or Apple HealthKit with a {@link HealthValue} as value.
 */
export interface HealthDataGroup {
    [key: number]: {
        lastValue: string;
        sumValue: string;
        averageValue: string;
        type: HealthDataType;
        unit: HealthDataUnit;
    };
}
/**
 * An abstract class for health values.
 */
export interface HealthValue {}

/**
 * A numerical value from Apple HealthKit or Google Fit
 * such as integer or double.
 * E.g. 1, 2.9, -3
 *
 * Parameters:
 * - [numericValue] - a [num] value for the {@link HealthDataPoint}
 */
export interface NumericHealthValue extends HealthValue {
    numeric_value: number;
}

export enum HealthDataType {
    ACTIVE_ENERGY_BURNED,
    ATRIAL_FIBRILLATION_BURDEN,
    AUDIOGRAM,
    BASAL_ENERGY_BURNED,
    BLOOD_GLUCOSE,
    BLOOD_OXYGEN,
    BLOOD_PRESSURE_DIASTOLIC,
    BLOOD_PRESSURE_SYSTOLIC,
    BODY_FAT_PERCENTAGE,
    BODY_MASS_INDEX,
    BODY_TEMPERATURE,
    BODY_WATER_MASS,
    DIETARY_CARBS_CONSUMED,
    DIETARY_CAFFEINE,
    DIETARY_ENERGY_CONSUMED,
    DIETARY_FATS_CONSUMED,
    DIETARY_PROTEIN_CONSUMED,
    DIETARY_FIBER,
    DIETARY_SUGAR,
    DIETARY_FAT_MONOUNSATURATED,
    DIETARY_FAT_POLYUNSATURATED,
    DIETARY_FAT_SATURATED,
    DIETARY_CHOLESTEROL,
    DIETARY_VITAMIN_A,
    DIETARY_THIAMIN,
    DIETARY_RIBOFLAVIN,
    DIETARY_NIACIN,
    DIETARY_PANTOTHENIC_ACID,
    DIETARY_VITAMIN_B6,
    DIETARY_BIOTIN,
    DIETARY_VITAMIN_B12,
    DIETARY_VITAMIN_C,
    DIETARY_VITAMIN_D,
    DIETARY_VITAMIN_E,
    DIETARY_VITAMIN_K,
    DIETARY_FOLATE,
    DIETARY_CALCIUM,
    DIETARY_CHLORIDE,
    DIETARY_IRON,
    DIETARY_MAGNESIUM,
    DIETARY_PHOSPHORUS,
    DIETARY_POTASSIUM,
    DIETARY_SODIUM,
    DIETARY_ZINC,
    DIETARY_CHROMIUM,
    DIETARY_COPPER,
    DIETARY_IODINE,
    DIETARY_MANGANESE,
    DIETARY_MOLYBDENUM,
    DIETARY_SELENIUM,
    FORCED_EXPIRATORY_VOLUME,
    HEART_RATE,
    HEART_RATE_VARIABILITY_SDNN,
    HEART_RATE_VARIABILITY_RMSSD,
    HEIGHT,
    INSULIN_DELIVERY,
    RESTING_HEART_RATE,
    RESPIRATORY_RATE,
    PERIPHERAL_PERFUSION_INDEX,
    STEPS,
    WAIST_CIRCUMFERENCE,
    WALKING_HEART_RATE,
    WEIGHT,
    DISTANCE_WALKING_RUNNING,
    DISTANCE_SWIMMING,
    DISTANCE_CYCLING,
    FLIGHTS_CLIMBED,
    DISTANCE_DELTA,
    MINDFULNESS,
    WATER,
    SLEEP_ASLEEP,
    SLEEP_AWAKE_IN_BED,
    SLEEP_AWAKE,
    SLEEP_DEEP,
    SLEEP_IN_BED,
    SLEEP_LIGHT,
    SLEEP_OUT_OF_BED,
    SLEEP_REM,
    SLEEP_SESSION,
    SLEEP_UNKNOWN,
    EXERCISE_TIME,
    WORKOUT,
    HEADACHE_NOT_PRESENT,
    HEADACHE_MILD,
    HEADACHE_MODERATE,
    HEADACHE_SEVERE,
    HEADACHE_UNSPECIFIED,
    NUTRITION,
    // HealthKit Characteristics
    GENDER,
    BIRTH_DATE,
    BLOOD_TYPE,
    MENSTRUATION_FLOW,

    // Heart Rate events (specific to Apple Watch)
    HIGH_HEART_RATE_EVENT,
    LOW_HEART_RATE_EVENT,
    IRREGULAR_HEART_RATE_EVENT,
    ELECTRODERMAL_ACTIVITY,
    ELECTROCARDIOGRAM,

    // Health Connect
    TOTAL_CALORIES_BURNED
}
