import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { HealthDataService } from '../../../../../../services/health-data.service';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';
import dayjs from 'dayjs';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataAggregateOperation, HealthDataType,
    HealthDataUnit,
    HealthDataUnitToString,
    HealthDataValue,
} from '../../../../../../models/health.models';
import { HealthChartComponent } from '../../../../../../components/health-chart/health-chart.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NavigationService } from '../../../../../../services/navigation.service';
import { Store } from '@ngrx/store';
import { ProductFeature } from '../../../../../../models/product.model';
import { AppState } from '../../../../../../store/app.state';
import { selectUserHasFeature } from '../../../../../../store/user/user.selectors';
import { AsyncPipe, NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-steps-page',
    standalone: true,
    imports: [HealthDataPageComponent, AsyncPipe, TranslocoModule, NgClass],
    templateUrl: './steps-page.component.html',
    styleUrl: './steps-page.component.css',
})
export default class StepsPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Passi';
    chartLabel = 'Passi';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bar',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar',
    }
    healthDataUnitSymbolLabel = 'passi';
    stepsHealthDataType = HealthDataType.STEPS;
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['daily', 'body', 'activity'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    protected readonly HealthDataAggregateOperation = HealthDataAggregateOperation;
}
