import {Component, inject, OnInit} from '@angular/core';
import {AppState} from '../../store/app.state';
import {Store} from '@ngrx/store';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {
    selectUserAgoraVideoCallIsActive,
    selectUserAgoraVideoCallIsJoining,
    selectUserData,
    selectUserGoals,
    selectUserTryTerraNativeInitializationError,
    selectUserTryTerraIsNativeInitializing,
    selectUserTryTerraState,
    selectUserTryTerraHasNativeAppleConnection,
    selectUserTryTerraHasNativeHealthConnectConnection,
    selectUserTryTerraIsNativeTriggeringWebhook,
    selectUserTryTerraNativeTriggerWebhookError,
    selectUserIsLoading,
    selectUserTryTerraIsGettingUserInfo,
    selectUserTryTerraHasProviders,
} from '../../store/user/user.selectors';
import {HowDoYouFeelCardComponent} from '../../components/how-do-you-feel-card/how-do-you-feel-card.component';
import {TranslocoModule} from '@ngneat/transloco';
import {SfmcNativeService} from '../../services/native/sfmc-native.service';
import {first, firstValueFrom, from} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CoachingSession} from '../../models/coaching-session.models';
import {CoachingSessionService} from '../../services/coaching-session.service';
import {CustomDatePipe} from '../../shared/pipes/custom-date.pipe';
import {NavigationService} from '../../services/navigation.service';
import {ModalService} from '../../services/modal.service';
import {
    HealthStatisticHeartRateCardComponent
} from '../../components/health-statistic-heart-rate-card/health-statistic-heart-rate-card.component';
import {
    HealthStatisticStepsCardComponent
} from '../../components/health-statistic-steps-card/health-statistic-steps-card.component';
import {provideComponentStore} from '@ngrx/component-store';
import {HomePageStore} from './home-page.store';
import LoaderComponent from '../../components/loader/loader.component';
import {APP_ROUTES} from '../../app.routes.definition';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../components/error-modal/error-modal.component';
import {LogService} from '../../services/log.service';
import {initializeNativeTryTerra, joinAgoraVideoCall, updateAgoraVideoCallIsMinimized} from '../../store/user/user.actions';
import {ProductCardComponent} from '../../components/product-card/product-card.component';
import {SurveyService} from '../../services/survey.service';
import {NativeAPIService, TargetPlatform} from "../../services/native/native-api.service";
import {TryTerraNativeService} from "../../services/native/try-terra-native.service";
import {InitialSurveyCardComponent} from "../../components/initial-survey-card/initial-survey-card.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ChatConversation} from "../../models/chat.models";
import {ChatService} from "../../services/chat.service";
import {AgoraChat} from "agora-chat";
import {ChatConversationCardComponent} from "../../components/chat-conversation-card/chat-conversation-card.component";
import {
    NoWearableConnectedCardComponent
} from "../../components/no-wearable-connected-card/no-wearable-connected-card.component";

@Component({
    selector: 'home-page',
    standalone: true,
    imports: [
        AsyncPipe,
        RouterModule,
        HealthStatisticHeartRateCardComponent,
        HealthStatisticStepsCardComponent,
        HowDoYouFeelCardComponent,
        TranslocoModule,
        CustomDatePipe,
        NgIf,
        LoaderComponent,
        NgClass,
        ProductCardComponent,
        InitialSurveyCardComponent,
        ChatConversationCardComponent,
        NoWearableConnectedCardComponent,
    ],
    templateUrl: './home-page.component.html',
    styleUrl: './home-page.component.css',
    providers: [provideComponentStore(HomePageStore)],
})
export default class HomePageComponent implements OnInit {
    #navigationService = inject(NavigationService);
    #store = inject(Store<AppState>);
    #sfmcService = inject(SfmcNativeService);
    #modalService = inject(ModalService);
    #surveyService = inject(SurveyService);
    #logService = inject(LogService);
    #coachingSessionService = inject(CoachingSessionService);
    #componentStore = inject(HomePageStore);
    #router = inject(Router);
    #nativeAPIService = inject(NativeAPIService);
    #tryTerraNativeService = inject(TryTerraNativeService);
    #chatService = inject(ChatService);

    user$ = this.#store.select(selectUserData);
    userTryTerra$ = this.#store.select(selectUserTryTerraState);
    userTryTerraHasNativeAppleConnection$ = this.#store.select(selectUserTryTerraHasNativeAppleConnection);
    userTryTerraHasNativeHealthConnectConnection$ = this.#store.select(selectUserTryTerraHasNativeHealthConnectConnection);
    products$ = this.#componentStore.data$;
    isLoading$ = this.#componentStore.isLoading$;
    userGoals$ = this.#store.select(selectUserGoals);
    userTryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);
    userTryTerraIsNativeTriggeringWebhook$ = this.#store.select(selectUserTryTerraIsNativeTriggeringWebhook);
    userTryTerraNativeInitializationError$ = this.#store.select(selectUserTryTerraNativeInitializationError);
    userTryTerraNativeTriggerWebhookError$ = this.#store.select(selectUserTryTerraNativeTriggerWebhookError);
    userIsLoading$ = this.#store.select(selectUserIsLoading);
    userTryTerraIsLoading$ = this.#store.select(selectUserTryTerraIsGettingUserInfo);
    userTryTerraHasProviders$ = this.#store.select(selectUserTryTerraHasProviders);

    appointmentsPath = APP_ROUTES.APPOINTMENTS();
    monitoringPath = APP_ROUTES.MONITOR();
    marketplacePath = APP_ROUTES.MARKETPLACE();

    nativeAPIServiceAvailable = this.#nativeAPIService.isAvailable();
    appointments: CoachingSession[] = [];
    appointmentDate?: Date;
    appointmentTime?: string;
    defaultCoachImg = '/assets/img/pic/profile.png';
    storeUrl = environment.storeUrl;
    healthConnectAvailable = false;
    currentPlatform?: TargetPlatform;
    TargetPlatform = TargetPlatform;
    hasValidInitialSurvey = true;
    lastConversation?: {
        conversation: ChatConversation;
        lastMessage?: AgoraChat.TextMsgBody | null;
    } | null;

    constructor() {
        this.getLastConversation();
    }

    private transformAppointmentDate(date: string): void {
        this.appointmentDate = new Date(date);
        this.appointmentTime = this.appointmentDate.toLocaleTimeString(
            'it-IT',
            {
                hour: '2-digit',
                minute: '2-digit',
            }
        );
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute(true);

        if (this.#nativeAPIService.isAvailable()) {
            this.#nativeAPIService.defaultTargetPlatform().then(targetPlatform => {
                this.currentPlatform = targetPlatform;
                if (targetPlatform === TargetPlatform.android) {
                    this.#tryTerraNativeService.isHealthConnectAvailable().then(healthConnectAvailable => {
                        this.healthConnectAvailable = healthConnectAvailable;
                    }).catch(reason => {
                        this.#logService.error(reason);
                    });
                }
            }).catch(reason => {
                this.#logService.error(reason);
            });
        }

        this.#surveyService.checkLifeWheelSurveyValidity().subscribe({
            next: (isValid) => {
                if (!isValid) {
                    this.#router.navigate([APP_ROUTES.LIFE_WHEEL_SURVEY()]);
                }
            },
            error: (error) => {
                this.#logService.error(error);
            },
        });

        this.#surveyService.checkInitialSurveyValidity().subscribe({
            next: (isValid) => {
                this.hasValidInitialSurvey = isValid;
            },
            error: (error) => {
                this.#logService.error(error);
            },
        });

        this.#componentStore.fetchProducts();

        this.user$.pipe(first((value) => value != null)).subscribe({
            next: (user) => {
                if (!user) {
                    return;
                }

                this.#logService.log(user);

                this.#coachingSessionService
                    .getAllCoachingSessions()
                    .subscribe({
                        next: (response) => {
                            this.appointments = response ?? [];
                            if (this.appointments.length > 0) {
                                this.transformAppointmentDate(
                                    this.appointments[0].startingDate
                                );
                            }
                        },
                        error: (error) => {
                            this.#logService.error(error);
                        },
                    });

                if (this.#sfmcService.isAvailable()) {
                    from(
                        this.#sfmcService.setup({
                            appId: environment.sfmc.appId,
                            accessToken: environment.sfmc.accessToken,
                            mid: environment.sfmc.mid,
                            sfmcURL: environment.sfmc.sfmcURL,
                            senderId: environment.sfmc.senderId,
                            delayRegistration:
                            environment.sfmc.delayRegistration,
                        })
                    )
                        .pipe(first())
                        .subscribe({
                            next: async () => {
                                await this.#sfmcService.enablePush();
                                await this.#sfmcService.setContactKey({
                                    contactId:
                                    user.sfContactId
                                });
                            },
                            error: (error) => {
                                this.#logService.error(error);
                            },
                        });
                } else {
                    this.#logService.log('SFMC SDK Service not available');
                }
            },
            error: (error) => {
                this.#logService.error(error);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                        },
                    }
                );
            },
        });
    }

    async joinVideoMeeting(meetingId: string) {
        const videoCallIsActive = await firstValueFrom(this.#store.select(selectUserAgoraVideoCallIsActive));
        const videoCallIsJoining = await firstValueFrom(this.#store.select(selectUserAgoraVideoCallIsJoining));
        if (videoCallIsActive || videoCallIsJoining) {
            this.#store.dispatch(updateAgoraVideoCallIsMinimized({isMinimized: false}));
        } else {
            this.#store.dispatch(joinAgoraVideoCall({meetingId: meetingId}));
        }
    }

    requestImportHealthData() {
        this.userTryTerra$.pipe(first((value) => value != null)).subscribe({
            next: (value) => {
                if (!value) {
                    return;
                }
                if (!value.nativeInitialized && !value.isNativeInitializing) {
                    this.#store.dispatch(
                        initializeNativeTryTerra({triggerAllDataWebhook: true})
                    );
                }
            },
        });
    }

    installHealthConnect() {
        if (this.#tryTerraNativeService.isAvailable()) {
            window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.healthdata', '_blank');
        }
    }

    isCallTimeInvalid(): boolean {
        if (!this.appointmentDate) {
            return true;
        }
        return this.#coachingSessionService.isCallTimeInvalid(
            this.appointmentDate
        );
    }

    getLastConversation() {
        this.#chatService.getConversations().pipe(takeUntilDestroyed()).subscribe({
            next: (response) => {
                for (const conversation of response.conversations) {
                    const lastMessage = response.lastMessages[conversation.id];
                    // if the last message is from the salesforce contact (coach), then we take it.
                    if (lastMessage && lastMessage.from && lastMessage.from.toLowerCase() === conversation.contactId.toLowerCase()) {
                        this.lastConversation = {
                            conversation,
                            lastMessage,
                        };
                        this.#logService.log('Last conversation found', this.lastConversation);
                        return;
                    }
                }
                this.lastConversation = null;
            },
            error: (error) => {
                this.#logService.error(error);
                this.lastConversation = null;
            },
        });
    }
}
