<section class="bg-body-bg h-screen flex flex-col justify-between">
    <div class="w-full py-12 relative">
        <div class="flex items-center justify-center absolute inset-0">
            <div class="bg-body-bg p-2.5 w-fit h-fit">
                @if ((importHealthDataError$ | async) && !(isImportingHealthData$ | async)) {
                    <svg class="w-14 h-auto fill-danger">
                        <use href="../assets/svg/svGP.svg#warning-circle"></use>
                    </svg>
                } @else {
                    <svg class="w-14 h-auto fill-primary">
                        <use href="../assets/svg/svGP.svg#clock"></use>
                    </svg>
                }
            </div>
        </div>
    </div>
    @if (isImportingHealthData$ | async) {
        <div class="flex items-center justify-center mt-32">
            <span class="loader"></span>
        </div>
        <div class="flex items-center justify-center mt-10">
            <img class="max-h-96 w-auto" src="../../../../../assets/img/illustrations/illustration-loading.png" alt="">
        </div>
        <div>
            <div class="w-full h-full py-5 px-4 rounded-t-4xl text-center">
                <span class="text-primary font-semibold text-sm">Stiamo importando i tuoi dati</span>
            </div>
        </div>
    } @else if ((importHealthDataError$ | async) && !(isImportingHealthData$ | async)) {
        <div class="flex items-center justify-center mt-10">
            <img class="max-h-96 w-auto" src="../../../../../assets/img/illustrations/illustration-loading.png" alt="">
        </div>
        <div>
            <div class="w-full h-full py-5 px-4 rounded-t-4xl text-center">
                <div class="flex flex-col justify-center ms-3.5">
                    <span class="text-icon-data-red font-semibold text-xl">Importazione iniziale dei dati di salute fallita</span>
                    <button
                        class="mt-2 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                        (click)="requestImportHealthData()">
                        <span class="text-white text-sm font-bold uppercase">Prova di nuovo</span>
                    </button>
                </div>
            </div>
        </div>
    } @else {
        <div class="flex items-center justify-center mt-32">
            <span class="loader"></span>
        </div>
        <div class="flex items-center justify-center mt-10">
            <img class="max-h-96 w-auto" src="../../../../../assets/img/illustrations/illustration-loading.png" alt="">
        </div>
    }
</section>
