import { HttpErrorResponse } from '@angular/common/http';
import {HealthDataFiltersDTO, HealthDataType, HealthDataValue} from '../../models/health.models';
import { RequestState } from '../../store/app.state';
import { Inject, Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { HealthDataService } from '../../services/health-data.service';
import { LogService } from '../../services/log.service';
import { tap, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';

export interface HealthDataPageState
    extends RequestState<HealthDataValue[], HttpErrorResponse> {}

const initialState: HealthDataPageState = {
    data: [],
    error: null,
    isLoading: false,
};

@Injectable()
export class HealthDataPageStore extends ComponentStore<HealthDataPageState> {
    #healthDataService = inject(HealthDataService);
    #logService = inject(LogService);

    constructor() {
        super(initialState);
    }

    // reads
    readonly data$ = this.select((state) => state.data);
    readonly error$ = this.select((state) => state.error);
    readonly isLoading$ = this.select((state) => state.isLoading);

    // writes
    readonly setData = this.updater((state, data: HealthDataValue[]) => ({
        ...state,
        data,
        error: null,
        isLoading: false,
    }));
    readonly setError = this.updater((state, error: HttpErrorResponse) => ({
        ...state,
        error: error,
        isLoading: false,
    }));

    // actions/effects
    readonly fetchHealthData = this.effect<{
        healthType: HealthDataType;
        filters: HealthDataFiltersDTO
    }>((params$) => {
        return params$.pipe(
            tap(() => this.patchState({ isLoading: true })),
            switchMap((params) => {
                return this.#healthDataService
                    .fetchHealthUserData(
                        params.healthType,
                        params.filters
                    )
                    .pipe(
                        tapResponse(
                            (response) => {
                                return this.setData(response);
                            },
                            (error: HttpErrorResponse) => {
                                this.#logService.error(error);
                                return this.setError(error);
                            }
                        )
                    );
            })
        );
    });
}
