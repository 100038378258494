import {Component, inject, OnInit} from '@angular/core';
import {
    HealthDataAggregateOperation, HealthDataType, HealthChartPeriod,
} from '../../../../../../models/health.models';
import { NavigationService } from '../../../../../../services/navigation.service';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-move-minutes-page',
    standalone: true,
    imports: [HealthDataPageComponent],
    templateUrl: './exercise-time-page.component.html',
    styleUrl: './exercise-time-page.component.css',
})
export default class ExerciseTimePageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Tempo in movimento';
    chartLabel = 'Tempo in movimento';
    healthDataUnitSymbolLabel = 'ore';
    healthDataType = HealthDataType.ACTIVITY_SECONDS;
    hasTwoDecimals = false;
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];
    payloadTypes: dataType[] = ['daily', 'body', 'activity'];

    HealthDataAggregateOperation = HealthDataAggregateOperation;

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
