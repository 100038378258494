<div class="flex flex-col -mt-14 h-full">
    <div class="flex flex-col items-center justify-center mt-28">
        <svg class="size-20 fill-icon-data-purple">
            <use href="../../../../../assets/svg/svGP.svg#bed"></use>
        </svg>
        <span class="mt-2 font-bold text-lg text-white">Sonno</span>
    </div>
    <div class="bg-box-gradient rounded-t-4xl relative px-6 mt-7 h-full pb-28">
        <app-navbar-categories></app-navbar-categories>
        <div class="flex items-center justify-between w-full mt-5">
            <h3 class="font-semibold text-base">Tutti i monitoraggi</h3>
        </div>
        <health-statistic-sleep-in-bed-card />
    </div>
</div>
