<section class="bg-image-fullscreen h-screen flex flex-col justify-between">
    <div class="w-full py-12 relative">
        <div class="flex items-center justify-center absolute inset-0">
            <div class="p-2.5 w-fit h-fit">
                @if ((userTryTerraNativeInitializationError$ | async) && !(userTryTerraIsNativeInitializing$ | async)) {
                <svg class="w-14 h-auto fill-danger">
                    <use href="../assets/svg/svGP.svg#warning-circle"></use>
                </svg>
                } @else {
                <svg class="w-20 h-auto fill-white mt-14">
                    <use href="../assets/svg/svGP.svg#wristwatch"></use>
                </svg>
                }
            </div>
        </div>
    </div>
    @if ((userTryTerraIsNativeInitializing$ | async) || (userTryTerraIsNativeTriggeringWebhook$ | async)) {
    <div class="flex items-center justify-center mt-20">
        <span class="loader"></span>
    </div>
    }
    @if ((userTryTerraIsNativeInitializing$ | async) || (userTryTerraIsNativeTriggeringWebhook$ | async)) {
    <div>
        <div class="w-full h-full py-5 px-4 rounded-t-4xl text-center">
            <span class="text-primary font-semibold text-sm">Stiamo configurando ed importando i tuoi dati di salute iniziali</span>
        </div>
    </div>
    } @else if (((userTryTerraNativeInitializationError$ | async) && !(userTryTerraIsNativeInitializing$ | async)) ||
    ((userTryTerraNativeTriggerWebhookError$ | async) && !(userTryTerraIsNativeTriggeringWebhook$ | async))) {
    <div>
        <div class="w-full h-full py-5 px-4 rounded-t-4xl text-center">
            <div class="flex flex-col justify-center">
                <span class="text-icon-data-red font-semibold text-xl">Importazione iniziale dei dati di salute fallita.</span>
                <button class="mt-2 py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full" (click)="requestImportHealthData()">
                    <span class="text-white text-sm font-bold uppercase">Prova di nuovo</span>
                </button>
                <div class="mt-2">
                    <button class="py-5 px-4 border-0 bg-danger rounded-lg flex items-center justify-center w-full" (click)="completeImport()">
                        <span class="text-white text-sm font-bold uppercase">Annulla</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    } @else {
    <div>
        <div class="w-full h-full py-5 px-4 rounded-t-4xl text-center">
            <div class="flex flex-col justify-center">
                <span class="text-white font-semibold text-xl">Inizializzazione e dati di salute importati correttamente!</span>
                <button class="mt-2 py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center w-full" (click)="completeImport()">
                    <span class="text-primary text-sm font-bold uppercase">Completa</span>
                </button>
            </div>
        </div>
    </div>
    }
</section>
