import {Component, inject, OnInit} from '@angular/core';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataAggregateOperation, HealthDataType,
} from '../../../../../../models/health.models';
import { NavigationService } from '../../../../../../services/navigation.service';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-active-energy-burned-page',
    standalone: true,
    imports: [HealthDataPageComponent],
    templateUrl: './active-energy-burned-page.component.html',
    styleUrl: './active-energy-burned-page.component.css',
})
export default class ActiveEnergyBurnedPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Calorie Bruciate';
    chartLabel = 'Calorie Bruciate';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bar',
        [HealthChartPeriod.week]: 'bar',
        [HealthChartPeriod.month]: 'bar',
    }
    healthDataType = HealthDataType.CALORIES;
    hasTwoDecimals = true;
    payloadTypes: dataType[] = ['daily', 'body', 'activity'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    protected readonly HealthDataAggregateOperation = HealthDataAggregateOperation;
}
