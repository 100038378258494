import {createAction, props} from '@ngrx/store';
import {CreateOrderRequest, OrderResponse, Order} from "../../models/order.model";
import {HttpErrorResponse} from "@angular/common/http";

export const createOrder = createAction('[CreateOrder] Create Order',
    props<{ request: CreateOrderRequest }>()
);

export const createOrderSuccess = createAction('[CreateOrder] Create Order Success',
    props<{ data: OrderResponse }>()
);

export const createOrderFailure = createAction('[CreateOrder] Create Order Failure',
    props<{ error: HttpErrorResponse }>()
);

export const completeOrder = createAction('[CreateOrder] Complete Order',
    props<{ id: string }>()
);

export const completeOrderFailure = createAction('[CreateOrder] Complete Order Failure',
    props<{ error: HttpErrorResponse }>()
);

export const cancelOrder = createAction('[CreateOrder] Cancel Order',
    props<{ id: string }>()
);

export const cancelOrderSuccess = createAction('[CreateOrder] Cancel Order Success');

export const cancelOrderFailure = createAction('[CreateOrder] Cancel Order Failure',
    props<{ error: HttpErrorResponse }>()
);

export const clearCreateOrder = createAction('[CreateOrder] Clear Create Order');
