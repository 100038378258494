<div class="custom-bg-white py-7 px-6 relative h-full overflow-hidden pb-32">
    <h2 class="font-semibold text-lg">Gestione permessi</h2>
    <ul class="mt-4">
        <li>
            <a
                [routerLink]="videoAndMicrophonePath"
                class="flex items-center justify-between py-4 border-b border-light-grey"
            >
                <div>
                    <span>{{
                        "dumb-components.security-card.videoAndMicrophonePermissionLabel"
                            | transloco
                    }}</span>
                </div>
                <div>
                    <svg class="size-4 fill-black">
                        <use
                            href="../../../assets/svg/svGP.svg#chevron-right"
                        ></use>
                    </svg>
                </div>
            </a>
        </li>

        <li *ngIf="nativeAPIAvailability">
            <a
                [routerLink]="dataHealthPermissionPath"
                class="flex items-center justify-between py-4 border-b border-light-grey"
            >
                <div>
                    <span>{{
                        "dumb-components.security-card.dataHealthPermissionLabel"
                            | transloco
                    }}</span>
                </div>
                <div>
                    <svg class="size-4 fill-black">
                        <use
                            href="../../../assets/svg/svGP.svg#chevron-right"
                        ></use>
                    </svg>
                </div>
            </a>
        </li>
    </ul>
</div>
