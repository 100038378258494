import {inject, Injectable} from '@angular/core';
import {NativeAPIService} from "./native-api.service";
import {PermissionStatus, PermissionType} from "../../models/permission.models";

export enum ShareResultStatus {
    /// The user has selected an action
    success,

    /// The user dismissed the share-sheet
    dismissed,

    /// The status can not be determined
    unavailable,
}

export interface ShareResult {
    raw: string;

    /// The action the user has taken
    status: ShareResultStatus;
}

/**
 * This service allows interaction with Permission native APIs.
 *
 * Official API: https://pub.dev/documentation/permission_handler/latest/
 */
@Injectable({
    providedIn: 'root'
})
export class ShareNativeService {
    #nativeAPIService = inject(NativeAPIService);

    constructor() {
    }

    /**
     * Checks if the native Permission Service is available.
     */
    isAvailable() {
        return this.#nativeAPIService.isAvailable();
    }

    shareXFiles(options: {files: number[][], subject?: string | null, text?: string | null}) {
        return this.#nativeAPIService.callHandler<ShareResult>('Share.shareXFiles', options);
    }

    share(options: {text: string, subject?: string | null}) {
        return this.#nativeAPIService.callHandler<ShareResult>('Share.share', options);
    }

    shareUri(options: {uri: string}) {
        return this.#nativeAPIService.callHandler<void>('Share.shareUri', options);
    }
}
