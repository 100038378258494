import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { MarketplaceService } from '../../../../services/marketplace.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Product } from '../../../../models/product.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { selectCreateOrderIsLoading } from '../../../../store/create-order/create-order.selectors';
import { ModalService } from '../../../../services/modal.service';
import BuyProductModalContentComponent from './buy-product-modal-content/buy-product-modal-content.component';
import { TierCardComponent } from '../../../../components/tier-card/tier-card.component';
import { selectUserBaseSubscription } from '../../../../store/user/user.selectors';
import { LogService } from '../../../../services/log.service';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../../components/error-modal/error-modal.component';
import {ActivatedRoute, Router} from '@angular/router';
import { APP_ROUTES } from '../../../../app.routes.definition';
import { SurveyService } from '../../../../services/survey.service';

@Component({
    selector: 'app-select-plan-page',
    standalone: true,
    imports: [NgIf, AsyncPipe, TierCardComponent],
    templateUrl: './select-plan-page.component.html',
    styleUrl: './select-plan-page.component.css',
})
export default class SelectPlanPageComponent implements OnInit, OnDestroy {
    #navigationService = inject(NavigationService);
    #modalService = inject(ModalService);
    #logService = inject(LogService);
    #router = inject(Router);
    #activatedRoute = inject(ActivatedRoute);
    #surveyService = inject(SurveyService);
    #marketplaceService = inject(MarketplaceService);
    #store = inject(Store<AppState>);

    userBasePlan$ = this.#store.select(selectUserBaseSubscription);
    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);

    isVerifyingInitialSurvey = false;
    selectedProductId?: string | null;
    baseProducts: Product[] = [];

    ngOnInit(): void {
        this.selectedProductId = this.#activatedRoute.snapshot.queryParams['selectedProductId'];

        this.#navigationService.saveCurrentRoute();

        this.fetchProducts();
    }

    fetchProducts() {
        this.#marketplaceService.getProducts().subscribe({
            next: (products) => {
                this.baseProducts = products
                    .filter((p) => p.type === 'Base')
                    .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
                if (this.selectedProductId) {
                    const selectedProduct = this.baseProducts.find((p) => p.id === this.selectedProductId);
                    if (selectedProduct) {
                        this.selectProduct(selectedProduct);
                    }
                }
            },
            error: (error) => {
                this.#logService.error(error);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                        },
                    }
                );
            },
        });
    }

    selectProduct(product: Product) {
        this.isVerifyingInitialSurvey = true;
        this.#surveyService.checkInitialSurveyValidity().subscribe({
            next: (isValid) => {
                if (isValid) {
                    this.#router.navigate([], {
                        queryParams: {
                            selectedProductId: product.id,
                        },
                    });
                    this.#modalService.open(BuyProductModalContentComponent, {
                        data: {
                            product: product,
                        },
                    });
                } else {
                    this.#router.navigate([APP_ROUTES.EXPIRED_INITIAL_SURVEY()])
                }
                this.isVerifyingInitialSurvey = false;
            },
            error: (error) => {
                this.isVerifyingInitialSurvey = false;
                this.#logService.error(error);
                this.#modalService.open<ErrorModalData, ErrorModalComponent>(
                    ErrorModalComponent,
                    {
                        data: {
                            error: error,
                        },
                    }
                );
            },
        });
    }

    ngOnDestroy() {
        this.#modalService.closeAll();
    }
}
