import {HttpErrorResponse} from "@angular/common/http";
import {RequestState} from "../../../../store/app.state";
import {Product} from "../../../../models/product.model";
import {inject, Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {MarketplaceService} from "../../../../services/marketplace.service";
import {LogService} from "../../../../services/log.service";
import {tapResponse} from "@ngrx/operators";
import {switchMap, tap} from "rxjs";

export interface ProductDetailsPageState extends RequestState<Product, HttpErrorResponse> {

}

const initialState: ProductDetailsPageState = {
    data: null,
    error: null,
    isLoading: false,
};

@Injectable()
export class ProductDetailsPageStore extends ComponentStore<ProductDetailsPageState> {
    #marketplaceService = inject(MarketplaceService);
    #logService = inject(LogService);

    constructor() {
        super(initialState);
    }

    // reads
    readonly data$ = this.select(state => state.data);
    readonly error$ = this.select(state => state.error);
    readonly isLoading$ = this.select(state => state.isLoading);

    // writes
    readonly setData = this.updater((state, data: Product) => ({
        ...state,
        data,
        error: null,
        isLoading: false,
    }));
    readonly setError = this.updater((state, error: HttpErrorResponse) => ({
        ...state,
        error: error,
        isLoading: false,
    }));

    // actions/effects
    readonly fetchProduct = this.effect<string>((productCode$) => {
            return productCode$.pipe(
                tap(() => this.patchState({isLoading: true})),
                switchMap(productCode => {
                    return this.#marketplaceService.getProduct(productCode).pipe(
                        tapResponse(
                            (product) => {
                                return this.setData(product);
                            },
                            (error: HttpErrorResponse) => {
                                this.#logService.error(error);
                                return this.setError(error);
                            }
                        )
                    )
                }));
        }
    );
}
