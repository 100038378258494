import {Component, inject, OnInit} from '@angular/core';
import {RouterLink} from '@angular/router';
import {HealthDataService} from '../../services/health-data.service';
import dayjs from 'dayjs';
import {
    HealthDataPeriod,
    HealthDataUnitToString
} from '../../models/health.models';
import {APP_ROUTES} from '../../app.routes.definition';
import {LogService} from '../../services/log.service';
import {TranslocoPipe} from "@ngneat/transloco";
import LoaderComponent from "../loader/loader.component";

@Component({
    selector: 'health-statistic-activity-aggregation-card',
    standalone: true,
    imports: [
        RouterLink,
        TranslocoPipe,
        LoaderComponent
    ],
    templateUrl: './health-statistic-activity-aggregation-card.component.html',
})
export class HealthStatisticActivityAggregationCardComponent implements OnInit {
    #healthDataService = inject(HealthDataService);
    #logService = inject(LogService);

    title = 'Allenamento';
    healthTypePath = APP_ROUTES.MONITOR_ACTIVITY_AGGREGATION(false);
    avgHeartRate = '-';
    totalDuration = '-';
    dateFrom = dayjs(new Date());
    period: HealthDataPeriod = HealthDataPeriod.week;
    isLoading = true;
    iconPath = "'../../../assets/img/monitoring/activity.png"

    HealthDataPeriod = HealthDataPeriod;
    HealthDataUnitToString = HealthDataUnitToString;

    ngOnInit(): void {
        this.#healthDataService
            .fetchHealthUserActivityAggregationData(
                {
                    period: this.period,
                    dateFrom: this.dateFrom.format('YYYY-MM-DD'),
                    payloadTypes: ['activity']
                }
            )
            .subscribe({
                next: (response) => {
                    if (response.length > 0) {
                        this.avgHeartRate = Math.round(
                            response.reduce((a, b) => a + b.averageHeartRate, 0) / response.length).toString();
                        this.totalDuration = this.formatTime(
                            response.reduce((a, b) => a + b.totalDurationSeconds, 0));
                    } else {
                        this.avgHeartRate = '';
                        this.totalDuration = '';
                    }

                    this.isLoading = false;
                },
                error: (error) => {
                    this.#logService.error(error);
                    this.isLoading = false;
                },
            });
    }

    private formatTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };
}
