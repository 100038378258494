import {Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {LogService} from "../../../../services/log.service";
import {AppState} from "../../../../store/app.state";
import {Store} from "@ngrx/store";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {AsyncPipe, DatePipe, NgForOf, NgIf} from "@angular/common";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import {
    selectInternalHealthDataValuesIsLoading,
    selectInternalHealthDataValuesData,
    selectDeleteInternalHealthDataValueIsLoading,
    selectInternalHealthDataValuesCanLoadMore, selectInternalHealthDataValuesIsLoadMoreLoading,
} from "../../../../store/internal-health-data/internal-health-data.selectors";
import {
    deleteInternalHealthDataValue,
    getInternalHealthDataValues, loadMoreInternalHealthDataValues
} from "../../../../store/internal-health-data/internal-health-data.actions";
import {NavigationService} from "../../../../services/navigation.service";
import {HealthDataType, HealthDataUnitToString, InternalHealthDataValue} from "../../../../models/health.models";
import LoaderComponent from "../../../../components/loader/loader.component";
import {TranslocoPipe} from "@ngneat/transloco";

export interface AddHealthDataForm {
    dateFrom: FormControl<string>;
    dateTo: FormControl<string | null>;
    value: FormControl<number | null>;
}

@Component({
    selector: 'view-internal-health-data-page',
    standalone: true,
    imports: [
        AsyncPipe,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        NgForOf,
        DatePipe,
        LoaderComponent,
        NgIf,
        RouterLink,
        TranslocoPipe,
    ],
    templateUrl: './view-internal-health-data-page.component.html',
    styleUrl: './view-internal-health-data-page.component.css'
})
export class ViewInternalHealthDataPageComponent implements OnInit {
    #activatedRoute = inject(ActivatedRoute);
    #store = inject(Store<AppState>);
    #navigationService = inject(NavigationService);
    #logService = inject(LogService);

    isLoading$ = this.#store.select(selectInternalHealthDataValuesIsLoading);
    data$ = this.#store.select(selectInternalHealthDataValuesData);
    isDeleting$ = this.#store.select(selectDeleteInternalHealthDataValueIsLoading);
    canLoadMore$ = this.#store.select(selectInternalHealthDataValuesCanLoadMore);
    isLoadMoreLoading$ = this.#store.select(selectInternalHealthDataValuesIsLoadMoreLoading);

    APP_ROUTES = APP_ROUTES;
    HealthDataUnitToString = HealthDataUnitToString;

    form!: FormGroup<AddHealthDataForm>;
    typeName!: HealthDataType;
    page = 0;
    size = 20;

    constructor() {
        const typeName = this.#activatedRoute.snapshot.paramMap.get('type') as HealthDataType | null;

        if (!typeName) {
            this.#navigationService.navigateBack();
            return;
        }
        this.typeName = typeName;

        this.#store.dispatch(getInternalHealthDataValues({
            typeName: this.typeName,
            options: {page: this.page, size: this.size}
        }));
    }

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    deleteValue(data: InternalHealthDataValue) {
        this.#store.dispatch(deleteInternalHealthDataValue({data}));
    }

    loadMore() {
        this.page++;
        this.#store.dispatch(loadMoreInternalHealthDataValues({
            typeName: this.typeName,
            options: {page: this.page, size: this.size}
        }));
    }
}
