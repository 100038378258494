<div class="flex flex-col items-center p-10">
    <h2 class="font-semibold text-lg text-center">
        Scansiona il qr code con l'applicazione di autenticazione di tua
        scelta
    </h2>
    <div class="mt-7">
        <qrcode [qrdata]="otpAuthUrl"></qrcode>
    </div>
    <div class="mt-5">
        <button (click)="onClose()" class="py-5 px-4 border-0 rounded-lg flex items-center justify-center w-full">
            <div>
                <span class="text-sm font-bold uppercase">Annulla</span>
            </div>
        </button>
    </div>
</div>
