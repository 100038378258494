<div class="flex flex-col h-full relative">
    <div
        class="flex flex-col items-center justify-center mb-3 custom-bg-illustration-blue"
    >
        <img
            class="h-20 w-auto"
            src="../../../../../../../../../../assets/img/illustrations/illustration-2fa.png"
            alt=""
        />
    </div>
    <div class="custom-bg-white py-7 px-6 relative pb-28 h-full">
        <div class="flex flex-col h-full justify-between">
            <div>
                <h2 class="font-semibold text-lg">Conferma codice</h2>
                <p class="mt-5">
                    Conferma la tua identità inserendo il codice visualizzato nella tua
                    app di autenticazione.
                </p>
                <div class="flex items-center py-1.5 px-3 rounded-lg bg-white my-5">
                    <div class="me-2">
                        <svg class="size-6 fill-bcd9ea">
                            <use href="../../../assets/svg/svGP.svg#info"></use>
                        </svg>
                    </div>
                    <div>
                        <p class="text-595959 text-sm">
                            Codice monouso valido solo per pochi secondi alla volta.
                        </p>
                    </div>
                </div>
                <form [formGroup]="verifyOtpForm">
                    <div>
                        <label for="otp" class="text-595959 font-bold text-sm">{{
                                "pages.security-page.pages.two-factor-page.otpLabel"
                                    | transloco
                            }}</label>
                        <input
                            type="text"
                            class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5 text-sm w-full text-a7a7a7"
                            id="otp"
                            placeholder="{{
                            'pages.security-page.pages.two-factor-page.otpLabel'
                                | transloco
                        }}"
                            formControlName="otp"
                            autocomplete="one-time-code"
                        />
                        <!-- Gestione errore campo form password da stilizzare -->
                        @if (verifyOtpForm.controls['otp'].invalid &&
                        verifyOtpForm.controls['otp'].touched) {
                            <span class="text-danger text-sm"
                            >Per favore inserisci un codice OTP valido, formato da 6
                        caratteri.</span
                            >
                        }
                        <!-- fine  gestione errore campo form password -->
                    </div>
                    <button
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                        [disabled]="loading"
                        (click)="onSubmitVerifyToken()"
                    >
                        <div class="flex items-center justify-center w-full">
                            @if (loading) {
                                <span class="button-loader"></span>
                            } @else {
                                <span class="text-white text-sm font-bold uppercase"
                                >Verifica Codice</span
                                >
                            }
                        </div>
                    </button>
                </form>
            </div>
            <div>
                <button
                    class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center mt-2 w-full"
                    [disabled]="loading"
                    [routerLink]="settingsPath"
                >
                    <div class="flex items-center justify-center w-full">
                    <span class="text-primary text-sm font-bold"
                    >Annulla operazione</span
                    >
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
