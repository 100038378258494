import {Component, inject, signal} from '@angular/core';
import {TopNavigationBarComponent} from '../top-navigation-bar/top-navigation-bar.component';
import {BottomNavigationBarComponent} from '../bottom-navigation-bar/bottom-navigation-bar.component';
import {ActivatedRoute, NavigationEnd, Router, RouterModule} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {selectUserData} from '../../store/user/user.selectors';
import {filter} from "rxjs";
import {NgClass} from "@angular/common";

@Component({
    selector: 'main-container',
    standalone: true,
    imports: [
        TopNavigationBarComponent,
        BottomNavigationBarComponent,
        RouterModule,
        NgClass,
    ],
    template: `
        <section class="flex flex-col min-h-screen h-screen" [ngClass]="customSectionClass()">
            @if (showTopBar()) {
                <header class="basis-1/12">
                    <top-navigation-bar/>
                </header>
            }
            <main [ngClass]="{ 'basis-11/12': showTopBar(), 'basis-full': !showTopBar() }">
                <router-outlet></router-outlet>
                @if (showBottomBar()) {
                    <footer>
                        <bottom-navigation-bar/>
                    </footer>
                }
            </main>
        </section>
    `,
})
export default class MainContainerComponent {
    #store = inject(Store<AppState>);
    #activatedRoute = inject(ActivatedRoute);
    #router = inject(Router);

    user$ = this.#store.select(selectUserData);

    showTopBar = signal(true);
    showBottomBar = signal(true);
    customSectionClass = signal('');

    constructor() {
        // Check if the top and bottom bars should be shown on navigation end
        this.#router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.checkRouteData();
            });
    }

    checkRouteData() {
        let currentRoute: ActivatedRoute | null = this.#activatedRoute.root;
        let showTopBar = false;
        let showBottomBar = false;
        let customSectionClass = '';
        do {
            const childrenRoutes = currentRoute.children;
            currentRoute = null;
            childrenRoutes.forEach((route) => {
                if (route.outlet === 'primary') {
                    currentRoute = route;
                    showTopBar = route.snapshot.data['showTopBar'] ?? showTopBar;
                    showBottomBar = route.snapshot.data['showBottomBar'] ?? showBottomBar;
                    customSectionClass = route.snapshot.data['customSectionClass'] ?? customSectionClass;
                }
            });
        } while (currentRoute);

        this.showTopBar.update((v) => (v = showTopBar));
        this.showBottomBar.update((v) => (v = showBottomBar));
        this.customSectionClass.update((v) => (v = customSectionClass));
    }
}
