import { Injectable, inject } from '@angular/core';
import { Survey } from '../models/survey.models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    #http = inject(HttpClient);

    #apiUrl = environment.apiUrl;
    #postUrl = `${environment.apiUrl}/surveys`;
    #feelingGetUrl = `${environment.apiUrl}/surveys/how-you-feel-today`;
    #account = '/account';
    #checkInitialSurveyValidity = '/check-initial-survey-validity';
    #checkLifeWheelSurveyValidity = '/check-life-wheel-survey-validity';

    constructor() {}

    getSurvey(url: string): Observable<Survey> {
        return this.#http.get<Survey>(url);
    }

    getFeelingSurvey(): Observable<Survey> {
        return this.#http.get<Survey>(this.#feelingGetUrl);
    }

    postSurvey(survey: Survey) {
        return this.#http.post(`${this.#postUrl}`, survey);
    }

    checkInitialSurveyValidity() {
        return this.#http.get<boolean>(`${this.#apiUrl}${this.#account}${this.#checkInitialSurveyValidity}`);
    }

    checkLifeWheelSurveyValidity() {
        return this.#http.get<boolean>(`${this.#apiUrl}${this.#account}${this.#checkLifeWheelSurveyValidity}`);
    }
}
