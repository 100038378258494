<div class="flex flex-col h-full relative">
    <div class="flex flex-col items-center justify-center mb-3 custom-bg-illustration-green">
        <img
            class="h-20 w-auto"
            src="../../../assets/img/illustrations/illustration-2fa-completed.png"
            alt=""
        />
    </div>
    <div class="custom-bg-white py-7 px-6 relative pb-32 h-full">
        <div class="flex flex-col h-full justify-between">
            <div>
                <h2 class="font-semibold text-lg">
                    Autenticazione a due fattori abilitata!
                </h2>
                <p class="mt-5">
                    Congratulazioni! Hai abilitato con successo l'autenticazione a due
                    fattori per il tuo account. Questo aggiunge un livello extra di
                    sicurezza al tuo accesso. Ora, ogni volta che accedi, verrà
                    richiesto di inserire un codice generato dalla tua app di
                    autenticazione.
                </p>
            </div>
            <div>
                <button
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
                    [disabled]="isLoading"
                    (click)="onSubmitResendRecoveryCodes()"
                >
                    <div class="flex items-center justify-center w-full">
                        <svg class="size-4 fill-white me-2">
                            <use href="../../../assets/svg/svGP.svg#download"></use>
                        </svg>
                        <span class="text-white text-sm font-bold uppercase">Genera codici di recupero</span>
                    </div>
                </button>
                <button
                    class="py-5 px-4 border-0 bg-transparent rounded-lg flex items-center justify-center mt-2 w-full"
                    [routerLink]="deleteTwoFactorPath"
                >
                    <div class="flex items-center justify-center w-full">
                        <span class="text-sm text-danger font-semibold">Elimina autenticazione a due fattori</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
