<div class="flex flex-col -mt-14 h-full">
    <div class="flex flex-col items-center justify-center mt-28">
        <svg class="size-20 fill-icon-data-yellow">
            <use href="../../../../../assets/svg/svGP.svg#yoga"></use>
        </svg>
        <span class="mt-2 font-bold text-lg text-white">Corpo</span>
    </div>
    <div class="bg-box-gradient rounded-t-4xl relative px-6 mt-7 h-full pb-32">
        <app-navbar-categories></app-navbar-categories>
        <div class="flex items-center justify-between w-full mt-5">
            <h3 class="font-semibold text-lg">Tutti i monitoraggi</h3>
        </div>
        <health-statistic-weight-card />

        <health-statistic-height-card />

        <health-statistic-respiratory-rate-card />

        <health-statistic-body-temperature-card />

        <health-statistic-blood-glucose-card />

        <health-statistic-blood-pressure-systolic-card />

        <health-statistic-blood-pressure-diastolic-card />

        <health-statistic-bmi-card />

        <health-statistic-body-fat-card />
    </div>
</div>
