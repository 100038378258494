import {Component, inject, OnInit} from '@angular/core';
import {
    HealthChartPeriod,
    HealthDataType,
} from '../../../../../../models/health.models';
import { NavigationService } from '../../../../../../services/navigation.service';
import { HealthDataPageComponent } from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-resting-heart-rate-page',
    standalone: true,
    imports: [HealthDataPageComponent],
    templateUrl: './resting-heart-rate-page.component.html',
    styleUrl: './resting-heart-rate-page.component.css',
})
export default class RestingHeartRatePageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Battito cardiaco a riposo';
    chartLabel = 'Battiti cardiaci a riposo';
    restingHeartRateHealthDataType = HealthDataType.RESTING_HEART_RATE;
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['body', 'activity', 'sleep'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
