import {inject, Injectable} from '@angular/core';
import {defaultIfEmpty, forkJoin, from, Observable, of, switchMap} from 'rxjs';
import {selectUserAgoraChatState, selectUserState} from "../store/user/user.selectors";
import {AgoraChat} from "agora-chat";
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.state";
import {FreeChatService} from "./free-chat.service";
import {AgoraService} from "./agora.service";
import {LogService} from "./log.service";
import {ChatConversation} from "../models/chat.models";
import {ProductFeature} from "../models/product.model";
import {loginAgoraChat} from "../store/user/user.actions";

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    #store = inject(Store<AppState>);
    #freeChatService = inject(FreeChatService);
    #agoraService = inject(AgoraService);
    #logService = inject(LogService);

    getConversations() {
        return this.#store.select(selectUserState)
            .pipe(switchMap(userState => {
                let conversations: ChatConversation[] = [];
                const lastMessages: {
                    [key: string]: AgoraChat.TextMsgBody
                } = {};

                const user = userState.data;
                if (user == null) {
                    return of({
                        conversations,
                        lastMessages
                    });
                }

                return this.#freeChatService.getConversations().pipe(
                    switchMap(response => {
                        conversations = response;

                        if (user.enabledFeatures.includes(ProductFeature.CHAT)) {
                            if (!userState.agora.chat.isLogged && !userState.agora.chat.isLogging) {
                                this.#store.dispatch(loginAgoraChat({userId: user.sfAccountId}));
                            }

                            return this.#store.select(selectUserAgoraChatState)
                                .pipe(switchMap(agoraChatState => {
                                    if (!agoraChatState.isLogged) {
                                        this.#logService.error('Agora Chat not connected', agoraChatState.loginError);
                                        return of({
                                            conversations,
                                            lastMessages
                                        });
                                    }
                                    const agoraObservables: Observable<AgoraChat.HistoryMessages | undefined>[] = [];
                                    for (const conversation of conversations) {
                                        agoraObservables.push(from(this.#agoraService.getHistoryMessages({
                                            chatType: 'singleChat',
                                            searchDirection: 'up',
                                            targetId: conversation.contactId,
                                            pageSize: 1
                                        })));
                                    }
                                    return forkJoin(agoraObservables).pipe(defaultIfEmpty([])).pipe(
                                        switchMap(values => {
                                            for (let i = 0; i < values.length; i++) {
                                                const historyMessage = values[i];
                                                const conversation = conversations[i];
                                                if (conversation && historyMessage && historyMessage.messages.length > 0) {
                                                    const messages = historyMessage.messages as AgoraChat.TextMsgBody[];
                                                    lastMessages[conversation.id] = messages[0]
                                                }
                                            }
                                            return of({
                                                conversations,
                                                lastMessages
                                            });
                                        })
                                    );
                                }))
                        } else {
                            for (const conversation of conversations) {
                                const chatMessage = conversation.chatMessages?.[0];
                                if (chatMessage == null || chatMessage.text.trim() === '' || chatMessage.senderId == null) {
                                    continue;
                                }
                                lastMessages[conversation.id] = {
                                    id: conversation.id,
                                    type: "txt",
                                    chatType: "singleChat",
                                    msg: chatMessage?.text ?? '',
                                    to: chatMessage?.recipientId ?? '',
                                    from: chatMessage?.senderId ?? '',
                                    ext: {},
                                    time: new Date(chatMessage.createdDate).getTime(),
                                    onlineState: 2 // AgoraChat.ONLINESTATETYPE.UNKNOWN
                                }
                            }
                        }

                        return of({
                            conversations,
                            lastMessages
                        });
                    })
                );
            }))
    }
}
