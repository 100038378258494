import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LogService} from "../../../../services/log.service";
import {AppState} from "../../../../store/app.state";
import {Store} from "@ngrx/store";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {ActivatedRoute, Router} from "@angular/router";
import {AsyncPipe} from "@angular/common";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input-gg";
import dayjs from "dayjs";
import {
    selectCreateInternalHealthDataValueIsLoading,
    selectInternalHealthDataValuesData,
    selectUpdateInternalHealthDataValueData,
    selectUpdateInternalHealthDataValueIsLoading
} from "../../../../store/internal-health-data/internal-health-data.selectors";
import {
    getInternalHealthDataValue, updateInternalHealthDataValue
} from "../../../../store/internal-health-data/internal-health-data.actions";
import {AddHealthDataRequest} from "../../../../models/add-health-data.models";
import {NavigationService} from "../../../../services/navigation.service";
import {HealthDataType, HealthDataTypeToUnit, HealthDataUnitToString} from "../../../../models/health.models";
import {HealthDataService} from "../../../../services/health-data.service";
import {first} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {TranslocoPipe} from "@ngneat/transloco";

export interface EditHealthDataForm {
    dateFrom: FormControl<string>;
    dateTo: FormControl<string | null>;
    value: FormControl<number | null>;
}

@Component({
  selector: 'edit-internal-health-data-page',
  standalone: true,
    imports: [
        AsyncPipe,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        TranslocoPipe,
    ],
  templateUrl: './edit-internal-health-data-page.component.html',
  styleUrl: './edit-internal-health-data-page.component.css'
})
export class EditInternalHealthDataPageComponent implements OnInit {
    #router = inject(Router);
    #activatedRoute = inject(ActivatedRoute);
    #store = inject(Store<AppState>);
    #fb = inject(FormBuilder);
    #navigationService = inject(NavigationService);
    #logService = inject(LogService);
    #healthDataService = inject(HealthDataService);

    isLoading$ = this.#store.select(selectUpdateInternalHealthDataValueIsLoading);
    data$ = this.#store.select(selectUpdateInternalHealthDataValueData);

    HealthDataTypeToUnit = HealthDataTypeToUnit;
    HealthDataUnitToString = HealthDataUnitToString;

    form!: FormGroup<EditHealthDataForm>;
    typeName!: HealthDataType;
    id!: string;

    constructor() {
        const typeName = this.#activatedRoute.snapshot.paramMap.get('type') as HealthDataType | null;
        const id = this.#activatedRoute.snapshot.paramMap.get('id') as string | null;

        if (!typeName || !id) {
            this.#navigationService.navigateBack();
            return;
        }
        this.typeName = typeName;
        this.id = id;

        this.#store.dispatch(getInternalHealthDataValue({id, typeName}));

        this.form = this.#fb.group({
            dateFrom: this.#fb.nonNullable.control('', [Validators.required]),
            dateTo: this.#fb.control<string | null>(null),
            value: this.#fb.control<number | null>(null, [Validators.required]),
        });

        this.data$.pipe(takeUntilDestroyed(), first(data => data != null)).subscribe(data => {
            this.form.patchValue({
                dateFrom: dayjs(data!.date).format('YYYY-MM-DDTHH:mm'),
                value: data!.value,
            });
        });
    }

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }

    onFormSubmit() {
        if (this.form.invalid) {
            return;
        }

        this.#logService.log('Form submitted', this.form.value);

        const {dateFrom, dateTo, value} = this.form.value;
        const request: AddHealthDataRequest = {
            dateFrom: dayjs(dateFrom!).toISOString(),
            dateTo: dateTo ? dayjs(dateTo).toISOString() : null,
            value: value!,
            typeName: this.typeName,
        }
        this.#store.dispatch(updateInternalHealthDataValue({id: this.id, request}));
    }
}
