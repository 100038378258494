import {HttpErrorResponse} from "@angular/common/http";
import {RequestState} from "../../store/app.state";
import {Product} from "../../models/product.model";
import {inject, Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {MarketplaceService} from "../../services/marketplace.service";
import {LogService} from "../../services/log.service";
import {tapResponse} from "@ngrx/operators";
import {switchMap, tap} from "rxjs";

export interface MarketplaceData {
    baseProducts: Product[];
    addonProducts: Product[];
}

export interface ProductDetailsPageState extends RequestState<MarketplaceData, HttpErrorResponse> {

}

const initialState: ProductDetailsPageState = {
    data: null,
    error: null,
    isLoading: false,
};

@Injectable()
export class MarketplacePageStore extends ComponentStore<ProductDetailsPageState> {
    #marketplaceService = inject(MarketplaceService);
    #logService = inject(LogService);

    constructor() {
        super(initialState);
    }

    // reads
    readonly data$ = this.select(state => state.data);
    readonly error$ = this.select(state => state.error);
    readonly isLoading$ = this.select(state => state.isLoading);

    // writes
    readonly setData = this.updater((state, data: MarketplaceData) => ({
        ...state,
        data,
        error: null,
        isLoading: false,
    }));
    readonly setError = this.updater((state, error: HttpErrorResponse) => ({
        ...state,
        error: error,
        isLoading: false,
    }));

    // actions/effects
    readonly fetchProducts = this.effect<void>((trigger$) => {
            return trigger$.pipe(
                tap(() => this.patchState({isLoading: true})),
                switchMap(productCode => {
                    return this.#marketplaceService.getProducts().pipe(
                        tapResponse(
                            (products) => {
                                const data: MarketplaceData = {
                                    baseProducts: products.filter(p => p.type === 'Base')
                                        .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)),
                                    addonProducts: products.filter(p => p.type === 'Add-on')
                                        .sort((a, b) => a === b ? 0 : (a.isFeaturedProduct ? -1 : 1)),
                                }
                                return this.setData(data);
                            },
                            (error: HttpErrorResponse) => {
                                this.#logService.error(error);
                                return this.setError(error);
                            }
                        )
                    )
                }));
        }
    );
}
