<section class="flex flex-col justify-between h-full">
    <div class="basis-9/12 flex flex-col justify-center">
        <img
            src="../assets/img/buy-base-plan-carousel/sblocca-funzionalita.png"
            class="object-contain"
            style="max-height: 500px;"
            alt />
    </div>
    <div class="basis-3/12 pt-3">
        <div class="h-full flex flex-col justify-end">
            <div class="text-center px-5">
                <h1 class="text-primary font-semibold text-3xl">
                    Sblocca tutte le funzionalità Be Our Best!
                </h1>
            </div>
            <div class="p-5">
                <button
                    [routerLink]="descriptionPath"
                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full">
                    <div class="flex items-center">
                        <span
                            class="text-white text-sm font-bold uppercase">Continua</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</section>
