import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from '../../../../../../../../../app.routes.definition';
import {
    HealthStatisticHeartRateCardComponent
} from "../../../../../../../../../components/health-statistic-heart-rate-card/health-statistic-heart-rate-card.component";
import {
    HealthStatisticRestingHeartRateCardComponent
} from "../../../../../../../../../components/health-statistic-resting-heart-rate-card/health-statistic-resting-heart-rate-card.component";
import {
    NavbarCategoriesComponent
} from "../../../../../../../../monitor-page/components/navbar-categories/navbar-categories.component";

@Component({
    selector: 'init-two-factor-process',
    standalone: true,
    imports: [RouterModule, HealthStatisticHeartRateCardComponent, HealthStatisticRestingHeartRateCardComponent, NavbarCategoriesComponent],
    templateUrl: './init-two-factor-process.component.html',
    styleUrl: './init-two-factor-process.component.css',
})
export class InitTwoFactorProcessComponent {
    beginTwoFactorProcessPath =
        APP_ROUTES.SETTINGS_BEGIN_TWO_FACTOR_PROCESS(true);
    settingsPath = APP_ROUTES.SETTINGS_SECURITY(false);
}
