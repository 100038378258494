import { Component, inject } from '@angular/core';
import { NavigationService } from '../../../../../../../services/navigation.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { AsyncPipe, NgIf } from '@angular/common';
import { APP_ROUTES } from '../../../../../../../app.routes.definition';
import { RouterModule } from '@angular/router';
import { first } from 'rxjs';
import { AuthResponse } from '../../../../../../../models/responses/auth-response.model';
import { UserService } from '../../../../../../../services/user.service';
import { fetchUser } from '../../../../../../../store/user/user.actions';
import { environment } from '../../../../../../../../environments/environment';
import ErrorModalComponent, {
    ErrorModalData,
} from '../../../../../../../components/error-modal/error-modal.component';
import { LogService } from '../../../../../../../services/log.service';
import { ModalService } from '../../../../../../../services/modal.service';
import { IsMobileGuard } from '../../../../../../../guards/auth.guard';
import {AuthService} from "../../../../../../../services/auth.service";

export interface DeleteTwoFactorForm {
    password: FormControl<string | null>;
}

@Component({
    selector: 'app-delete-two-factor-page',
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, NgIf, AsyncPipe, RouterModule],
    templateUrl: './delete-two-factor-page.component.html',
})
export default class DeleteTwoFactorPageComponent {
    #state = inject(Store);
    #navigationService = inject(NavigationService);
    #userService = inject(UserService);
    #fb = inject(FormBuilder);
    #logService = inject(LogService);
    #modalService = inject(ModalService);
    #authService = inject(AuthService);

    accessTokenLabel = environment.accessTokenKey;
    twoFactorSetupPath = APP_ROUTES.SETTINGS_TWO_FACTOR_SETUP(false);
    deleteTwoFactorForm!: FormGroup<DeleteTwoFactorForm>;

    isLoading: boolean = false;

    constructor() {
        this.#navigationService.saveCurrentRoute();
    }

    ngOnInit() {
        this.#navigationService.saveCurrentRoute();
        this.deleteTwoFactorForm = this.#fb.group({
            password: this.#fb.control<string | null>('', [
                Validators.required,
            ]),
        });
    }

    onSubmitDelete2faAuthentication() {
        if (this.deleteTwoFactorForm.invalid) return;
        const { password } = this.deleteTwoFactorForm.value;

        this.isLoading = true;

        this.#userService
            .disableTwoFactorAuthentication(password!)
            .pipe(first())
            .subscribe({
                next: (response: AuthResponse) => {
                    this.#authService.setAuthenticated(
                        response.access_token
                    );
                    this.#state.dispatch(fetchUser());
                },
                error: (error) => {
                    this.isLoading = false;
                    this.#logService.error(error);
                    this.#modalService.open<
                        ErrorModalData,
                        ErrorModalComponent
                    >(ErrorModalComponent, {
                        data: {
                            error: error,
                        },
                    });
                },
                complete: () => {
                    this.isLoading = false;
                    this.goBack();
                },
            });
    }

    goBack() {
        this.#navigationService.navigateBack();
    }
}
