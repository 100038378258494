@if (showVerifyEmailLabel) {
    <section class="bg-body-bg h-screen flex flex-col justify-between">
        <div class="basis-3/12">
            <app-registration-dash-icon>
                <svg class="w-14 h-auto fill-primary">
                    <use href="../../../assets/svg/svGP.svg#mail"></use>
                </svg>
            </app-registration-dash-icon>
            <div class="mt-10">
                <div class="text-center px-5">
                    <h1 class="text-primary font-semibold text-2xl break-words">
                        Verifica il tuo indirizzo email!
                    </h1>
                </div>
            </div>
        </div>
        <div class="basis-9/12 overflow-hidden mt-8">
            <div class="h-full flex flex-col justify-end">
                <div class="flex justify-center">
                    <img
                        src="../../../assets/img/registration-images/verifica-indirizzo-email.png"
                        class="max-w-sm w-full"
                        alt/>
                </div>
                <div class="p-10">
                    <button [routerLink]="loginPath"
                            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full">
                        <div class="flex items-center justify-center">
                        <span
                            class="text-white text-sm font-bold uppercase">Accedi</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </section>
} @else {
    @if (!showRegistrationWithProvider) {
        <registration-form (isVerifyingStep)="isVerifyingStepEmitted($event)" (userEmail)="isUserEmailEmitted($event)"/>
    } @else {
        <div class="flex items-center justify-start px-3">
            <button
                class="p-2 border border-primary rounded-full mt-3"
                (click)="hideProviderRegistrationForm()">
                <svg class="w-4 h-4 fill-primary">
                    <use href="../../../assets/svg/svGP.svg#chevron-left"></use>
                </svg>
            </button>
        </div>
        <registration-with-provider-form [accessToken]="accessToken"/>
    }
}
