import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from "../../models/product.model";
import {NgIf} from "@angular/common";
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-tier-card',
    standalone: true,
    imports: [
        NgIf,
        TranslocoModule
    ],
    templateUrl: './tier-card.component.html',
    styleUrl: './tier-card.component.css'
})
export class TierCardComponent implements OnInit {
    @Input({required: true}) product!: Product;
    @Input() showDescription = true;
    @Input() showActionButton = false;
    @Input() actionButtonLabel = '';
    @Input() disabledActionButton = false;

    @Output() actionButtonClick = new EventEmitter<void>();

    stars = Array(1);

    constructor() {
    }

    ngOnInit() {
        const starNumber = this.product.tier ? parseInt(this.product.tier) : 1;
        this.stars = Array(isNaN(starNumber) ? 1 : starNumber);
    }

    onActionButtonClick() {
        this.actionButtonClick.emit();
    }
}
