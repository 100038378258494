import { Route } from "@angular/router";
import { UserHasProductFeatureGuard } from "../../../../guards/auth.guard";
import { ProductFeature } from "../../../../models/product.model";
import {APP_ROUTES} from "../../../../app.routes.definition";
import BodyCategoryPageComponent from "./body-category-page.component";
import WeightPageComponent from "./pages/weight-page/weight-page.component";
import HeightPageComponent from "./pages/height-page/height-page.component";
import RespiratoryRatePageComponent from "./pages/respiratory-rate-page/respiratory-rate-page.component";

export const BODY_CATEGORY_ROUTES: Route[] = [
    {
        path: '',
        data: {
            customSectionClass: 'bg-section-data-yellow'
        },
        component: BodyCategoryPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_WEIGHT(true),
        data: { title: 'Weight' },
        component: WeightPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_HEIGHT(true),
        data: { title: 'Height' },
        component: HeightPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_BODY_RESPIRATORY_RATE(true),
        data: { title: 'Respiratory Rate' },
        component: RespiratoryRatePageComponent,
    }
]
