<div class="custom-bg-white py-7 px-6 pb-32 relative h-full overflow-hidden">
    <h2 class="font-semibold text-lg">Account & Sicurezza</h2>
    @if (hasLoginProvider$ | async) {
    <p class="pt-4">
        Hai utilizzato un provider di accesso esterno. Per qualsiasi azione
        relativa all'account e alla sicurezza, ti preghiamo di rivolgerti al
        provider esterno.
    </p>
    } @else {
    <ul class="mt-4">
        <li>
            <a
                [routerLink]="changeEmailPath"
                class="flex items-center justify-between py-4 border-b border-light-grey"
            >
                <div>
                    <span>{{
                        "dumb-components.security-card.changeEmailLabel"
                            | transloco
                    }}</span>
                </div>
                <div>
                    <svg class="size-4 fill-black">
                        <use
                            href="../../../assets/svg/svGP.svg#chevron-right"
                        ></use>
                    </svg>
                </div>
            </a>
        </li>
        <li>
            <div>
                <a
                    [routerLink]="changePasswordPath"
                    class="flex items-center justify-between py-4 border-b border-light-grey"
                >
                    <div>
                        <span>{{
                            "dumb-components.security-card.changePasswordLabel"
                                | transloco
                        }}</span>
                    </div>
                    <div>
                        <svg class="size-4 fill-black">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-right"
                            ></use>
                        </svg>
                    </div>
                </a>
            </div>
        </li>
        <li>
            <div>
                <a
                    [routerLink]="twoFactorPath"
                    class="flex items-center justify-between py-4"
                >
                    <div>
                        <span>{{
                            "dumb-components.security-card.twoFactorLabel"
                                | transloco
                        }}</span>
                    </div>
                    <div>
                        <svg class="size-4 fill-black">
                            <use
                                href="../../../assets/svg/svGP.svg#chevron-right"
                            ></use>
                        </svg>
                    </div>
                </a>
            </div>
        </li>
    </ul>

    }
</div>
