import {Component, OnInit, inject} from '@angular/core';
import {
    HealthDataType,
} from '../../../../../../models/health.models';
import 'dayjs/locale/it';
import {NavigationService} from '../../../../../../services/navigation.service';
import {HealthDataPageComponent} from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-heart-rate-page',
    standalone: true,
    imports: [
        HealthDataPageComponent,
    ],
    templateUrl: './heart-rate-page.component.html',
    styleUrl: './heart-rate-page.component.css',
})
export default class HeartRatePageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Battito cardiaco';
    chartLabel = 'Battiti cardiaci';
    heartRateHealthDataType = HealthDataType.HEART_RATE;
    hasTwoDecimals = false;
    payloadTypes: dataType[] = ['daily', 'body', 'activity', 'sleep'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
