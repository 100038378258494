import { Route } from "@angular/router";
import { UserHasProductFeatureGuard } from "../../../../guards/auth.guard";
import { ProductFeature } from "../../../../models/product.model";
import {APP_ROUTES} from "../../../../app.routes.definition";
import ActivityCategoryPageComponent from "./activity-category-page.component";
import StepsPageComponent from "./pages/steps-page/steps-page.component";
import ActiveEnergyBurnedPageComponent from "./pages/active-energy-burned-page/active-energy-burned-page.component";
import ExerciseTimePageComponent from "./pages/exercise-time-page/exercise-time-page.component";

export const ACTIVITY_CATEGORY_ROUTES: Route[] = [
    {
        path: '',
        data: {
            customSectionClass: 'bg-section-data-green'
        },
        component: ActivityCategoryPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_ACTIVITY_STEPS(true),
        data: { title: 'Steps ' },
        component: StepsPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_ACTIVITY_ACTIVE_ENERGY_BURNED(true),
        data: { title: 'Active Energy Burned ' },
        component: ActiveEnergyBurnedPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_ACTIVITY_EXERCISE_TIME(true),
        data: { title: 'Exercise Time ' },
        component: ExerciseTimePageComponent,
    }
]
