@if (currentUser$ | async; as user) {
    @if (appointments.length === 0) {
        <app-loader *ngIf="loading"></app-loader>
        <div class="custom-bg-white relative p-6 pb-28 h-full">
            <div class="relative flex flex-col justify-between h-full">
                <div class="basis-2/12">
                    <h2 class="text-lg font-semibold">
                        {{ "pages.appointment-page.nextSessionsLabel" | transloco }}
                    </h2>
                    <p class="text-lg mt-3">Non ci sono nuove sessioni programmate! 😔</p>
                </div>
                <div class="basis-10/12 overflow-hidden">
                    <div class="flex flex-col justify-end h-full">
                        <img
                            src="../assets/img/calendar-call-images/prossime-sessioni.png"
                            class="w-full rounded-lg"
                            alt=""
                        />
                        <div
                            class="border-2 border-white bg-linear-gradient rounded-lg card-shadow-bottom py-2.5 px-3"
                        >
                            <div>
                                <p class="text-center font-bold">
                                    {{
                                        !(userHasBasePlan$ | async)
                                            ? "Vuoi pianificare una sessione?"
                                            : "Vuoi parlare con il coach?"
                                    }}
                                </p>
                            </div>
                            <div class="flex items-center justify-center mt-5">
                                <button
                                    class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center w-full"
                                    [routerLink]="
                                        !(userHasBasePlan$ | async)
                                            ? buyBasePlanPath
                                            : messagingPath
                                    "
                                >
                                    <div *ngIf="!(userHasBasePlan$ | async)">
                                        <svg class="size-6 fill-white me-2">
                                            <use
                                                href="../../../assets/svg/svGP.svg#shopping-bag"
                                            ></use>
                                        </svg>
                                    </div>
                                    <div>
                                        <span class="text-white text-sm font-bold uppercase">
                                            {{
                                                !(userHasBasePlan$ | async)
                                                    ? "Fai l'upgrade"
                                                    : "Chatta"
                                            }}
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } @else {
        <div class="custom-bg-white custom-bg-white-translate relative h-full">
            @for (appointment of appointments; track $index) {
                @if ($index != 0) {
                    <div class="mb-5">
                        <appointment-card
                            [appointment]="appointment"
                            (onAppointmentJoin)="joinVideoMeeting($event)"
                        />
                    </div>
                } @else if ($index == 0) {
                    <appointment-card
                        [appointment]="appointment"
                        (onAppointmentJoin)="joinVideoMeeting($event)"
                        [isNextAppointment]="true"
                    />
                    @if (appointments.length > 1) {
                        <h2 class="mx-6 mt-10 text-lg font-semibold mb-5">
                            {{ "pages.appointment-page.nextSessionsLabel" | transloco }}
                        </h2>
                    }
                }
            }
        </div>
    }
}
