import {Component, inject} from '@angular/core';
import {APP_ROUTES} from "../../../../app.routes.definition";
import {RouterLink} from "@angular/router";
import {NativeAPIService, TargetPlatform} from "../../../../services/native/native-api.service";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
    selector: 'app-tutorial-page',
    standalone: true,
    imports: [
        RouterLink
    ],
    templateUrl: './tutorial-page.component.html',
    styleUrl: './tutorial-page.component.css'
})
export default class TutorialPageComponent {
    #nativeAPIService = inject(NativeAPIService);
    #navigationService = inject(NavigationService);

    importPath = APP_ROUTES.ASK_IMPORT_HEALTH_DATA_IMPORT();

    tutorialImg?: string;

    constructor() {
        if (this.#nativeAPIService.isAvailable()) {
            this.#nativeAPIService.defaultTargetPlatform().then(value => {
                if (value === TargetPlatform.android) {
                    this.tutorialImg = '../../../../../assets/img/health_data_permission_android.png'
                } else if (value === TargetPlatform.iOS) {
                    this.tutorialImg = '../../../../../assets/img/health_data_permission_ios.jpeg'
                }
            });
        } else {
            this.#navigationService.navigateBack();
        }
    }

    goBack() {
        this.#navigationService.navigateBack();
    }
}
