import {Component, inject} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {
    selectUserBillingInfoIsValid,
    selectUserFirstPendingOrder,
    selectUserHasBasePlan,
    selectUserSubscriptionByProductCode
} from "../../../../store/user/user.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../store/app.state";
import {AsyncPipe, NgIf} from "@angular/common";
import {createOrder} from "../../../../store/create-order/create-order.actions";
import {selectCreateOrderIsLoading} from "../../../../store/create-order/create-order.selectors";
import {ProductDetailsPageStore} from "./product-details-page.store";
import {provideComponentStore} from "@ngrx/component-store";
import {firstValueFrom, Observable} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {cancelSubscription} from "../../../../store/cancel-subscription/cancel-subscription.actions";
import {Order} from "../../../../models/order.model";
import LoaderComponent from "../../../../components/loader/loader.component";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
    selector: 'product-details',
    standalone: true,
    imports: [TranslocoModule, AsyncPipe, RouterLink, NgIf, LoaderComponent],
    templateUrl: './product-details-page.component.html',
    styleUrl: './product-details-page.component.css',
    providers: [provideComponentStore(ProductDetailsPageStore)]
})
export default class ProductDetailsPageComponent {
    #activatedRoute = inject(ActivatedRoute);
    #store = inject(Store<AppState>);
    #componentStore = inject(ProductDetailsPageStore);
    #navigationService = inject(NavigationService);
    #router = inject(Router);

    userHasBasePlan$ = this.#store.select(selectUserHasBasePlan);
    userSubscription$: Observable<Order | null>;
    product$ = this.#componentStore.data$;
    isLoading$ = this.#componentStore.isLoading$;
    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);
    isUserBillingInfoValid$ = this.#store.select(selectUserBillingInfoIsValid);

    productCode: string;
    buyBasePlanPath = APP_ROUTES.BUY_BASE_PLAN();
    billingInfoPath = APP_ROUTES.SETTINGS_BILLING_INFO();
    marketplacePath = APP_ROUTES.MARKETPLACE();

    constructor() {
        this.#navigationService.saveCurrentRoute();

        this.productCode = this.#activatedRoute.snapshot.params['id'] ?? '';
        this.#componentStore.fetchProduct(this.productCode);

        this.userSubscription$ = this.#store.select(selectUserSubscriptionByProductCode(this.productCode)).pipe(takeUntilDestroyed());
    }

    createOrder() {
        firstValueFrom(this.#store.select(selectUserFirstPendingOrder)).then(pendingOrder => {
            if (pendingOrder) {
                this.#router.navigate([APP_ROUTES.PENDING_ORDER(false, pendingOrder.id)]);
            } else {
                this.#store.dispatch(createOrder({
                    request: {
                        productSKU: this.productCode,
                    }
                }));
            }
        });
    }

    cancelSubscription() {
        firstValueFrom(this.userSubscription$).then(order => {
            if (!order || order.subscriptions.length === 0) return;
            this.#store.dispatch(cancelSubscription({
                id: order.subscriptions[0].id
            }));
        });
    }
}
