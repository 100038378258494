<div class="flex flex-col -mt-14 h-full">
    <div class="flex flex-col items-center justify-center mt-28">
        <svg class="size-20 fill-icon-data-green">
            <use href="../../../../../assets/svg/svGP.svg#activity"></use>
        </svg>
        <span class="mt-2 font-bold text-lg text-white">Attività</span>
    </div>
    <div class="bg-box-gradient rounded-t-4xl relative px-6 mt-7 h-full pb-28">
        <app-navbar-categories></app-navbar-categories>
        <div class="flex items-center justify-between w-full mt-5">
            <h3 class="font-semibold text-base text-lg">Tutti i monitoraggi</h3>
        </div>

        <health-statistic-steps-card />

        <health-statistic-exercise-time-card />

        <health-statistic-active-energy-burned-card />
    </div>
</div>
