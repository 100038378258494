<div class="custom-bg-white py-7 px-6 relative h-full pb-32">
    <h2 class="font-semibold text-lg">Cambia password</h2>
    <form [formGroup]="changeCurrentPasswordForm" class="mt-5">
        <div class="flex flex-col py-3.5">
            <label for="currentPassword"
                   class="text-595959 font-bold text-sm">{{ "pages.security-page.pages.change-password-page.currentPasswordLabel" | transloco }}</label>
            <input
                type="password"
                class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5"
                id="currentPassword"
                placeholder="{{'pages.security-page.pages.change-password-page.currentPasswordPlaceholderLabel' | transloco}}"
                formControlName="currentPassword"
                autocomplete="current-password"
            />
            <!-- Gestione errore campo form currentPassword da stilizzare -->
            @if (changeCurrentPasswordForm.controls['currentPassword'].invalid && changeCurrentPasswordForm.controls['currentPassword'].touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci la tua vecchia password</span>
            }
            <!-- fine  gestione errore campo form currentPassword -->
        </div>
        <div class="flex flex-col py-3.5">
            <label for="newPassword"
                   class="text-595959 font-bold text-sm">{{ "pages.security-page.pages.change-password-page.newPasswordLabel" | transloco }}</label>
            <input
                type="password"
                class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5"
                id="newPassword"
                placeholder="{{'pages.security-page.pages.change-password-page.newPasswordPlaceholderLabel' | transloco}}"
                formControlName="newPassword"
                autocomplete="new-password"
            />
            <!-- Gestione errore campo form password da stilizzare -->
            @if (changeCurrentPasswordForm.controls['newPassword'].invalid && changeCurrentPasswordForm.controls['newPassword'].touched) {
                <span class="text-danger text-sm mt-2">Per favore inserisci una password di almeno 8 caratteri</span>
            } @else {
                <span class="text-sm mt-2">La password deve essere lunga almeno 8 caratteri.</span>
            }
            <!-- fine  gestione errore campo form password -->
        </div>

        <div class="flex flex-col py-3.5">
            <label for="confirmPassword"
                   class="text-595959 font-bold text-sm">{{ "pages.security-page.pages.change-password-page.confirmPasswordLabel" | transloco }}</label>
            <input
                type="password"
                class="form-control py-5 px-4 border border-light-grey rounded-lg mt-3.5"
                id="confirmPassword"
                placeholder="{{'pages.security-page.pages.change-password-page.confirmPasswordPlaceholderLabel' | transloco}}"
                formControlName="confirmPassword"
                autocomplete="new-password"
            />
            @if (changeCurrentPasswordForm.controls['confirmPassword'].errors && changeCurrentPasswordForm.controls['confirmPassword'].touched) {
                <span
                    class="text-danger text-sm mt-2">{{ changeCurrentPasswordForm.controls["confirmPassword"].errors["confirmedValidator"] }}</span>
            }
        </div>
        <button
            class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-7 w-full"
            [disabled]="changeCurrentPasswordForm.invalid || isLoading"
            (click)="onSubmitChangePassword()"
        >
            <div class="flex items-center justify-center w-full">
                @if (isLoading) {
                    <span class="button-loader"></span>
                } @else {
                    <span
                        class="text-white text-sm font-bold uppercase">{{ "pages.security-page.pages.change-password-page.submitLabel" | transloco }}</span>
                }
            </div>
        </button>
        <button
            class="py-5 px-4 border-0 bg-white rounded-lg flex items-center justify-center mt-2 w-full"
            (click)="goBack()"
        >
            <div class="flex items-center justify-center w-full">
                <span class="text-primary text-sm font-bold">Annulla operazione</span>
            </div>
        </button>
    </form>
</div>
