import {bootstrapApplication} from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";

import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import utc from 'dayjs/plugin/utc';
import isBetween from "dayjs/plugin/isBetween";
import {environment} from "./environments/environment";
import packageJson from "../package.json";
import {EnvironmentName} from "./app/models/app-environment.model";

Sentry.init({
    dsn: "https://44cf75addf681e1960078fe92b51617f@o4508126508941312.ingest.de.sentry.io/4508126510776400",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: environment.name,
    enabled: environment.sentryEnabled,
    release: packageJson.version,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(utc);
dayjs.extend(isBetween);

bootstrapApplication(AppComponent, appConfig).catch((err) => {
    console.error(err);
    Sentry.captureException(err);
});
