import {Component, Pipe, inject, OnDestroy} from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../app.routes.definition';
import { Store } from '@ngrx/store';
import { selectUserData } from '../../store/user/user.selectors';
import { HttpErrorResponse } from '@angular/common/http';

export interface ErrorModalData {
    error?: Error;
    message?: string;
    onCloseCallback?: () => void;
}

@Component({
    selector: 'app-error-modal',
    standalone: true,
    imports: [NgIf, AsyncPipe],
    templateUrl: './error-modal.component.html',
    styleUrl: './error-modal.component.css',
})
export default class ErrorModalComponent implements ErrorModalData, OnDestroy {
    #modalService = inject(ModalService);
    #router = inject(Router);
    #store = inject(Store);

    user$ = this.#store.select(selectUserData);

    error?: Error;
    message?: string;
    onCloseCallback?: () => void;

    isOnline = true;

    constructor() {
        this.isOnline = navigator.onLine;
    }

    goToSupportPage() {
        let errorMessage = this.error?.message;
        if (this.error instanceof HttpErrorResponse) {
            errorMessage +=
                '. HTTP error response: ' + JSON.stringify(this.error.error);
        }
        this.#router.navigate([APP_ROUTES.SETTINGS_SUPPORT(false)], {
            state: {
                errorMessage: errorMessage,
            },
        });
        this.#modalService.closeAll();
    }

    onClose() {
        this.#modalService.close();
    }

    ngOnDestroy(): void {
        if (this.onCloseCallback) this.onCloseCallback();
    }
}
