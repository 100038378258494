import {Component, inject, OnInit} from '@angular/core';
import {first} from 'rxjs';
import {
    selectUserData,
    selectUserTryTerraNativeInitializationError,
    selectUserTryTerraIsNativeInitializing,
    selectUserTryTerraIsNativeTriggeringWebhook, selectUserTryTerraNativeTriggerWebhookError,
} from '../../../../store/user/user.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {initializeNativeTryTerra} from "../../../../store/user/user.actions";
import {AsyncPipe, NgIf} from "@angular/common";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Router} from "@angular/router";
import {APP_ROUTES} from "../../../../app.routes.definition";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
    selector: 'app-import-page',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf
    ],
    templateUrl: './import-page.component.html',
    styleUrl: './import-page.component.css',
})
export default class ImportPageComponent implements OnInit {
    #store = inject(Store<AppState>);
    #router = inject(Router);
    #navigationService = inject(NavigationService);

    user$ = this.#store.select(selectUserData);
    userTryTerraIsNativeInitializing$ = this.#store.select(selectUserTryTerraIsNativeInitializing);
    userTryTerraIsNativeTriggeringWebhook$ = this.#store.select(selectUserTryTerraIsNativeTriggeringWebhook);
    userTryTerraNativeInitializationError$ = this.#store.select(selectUserTryTerraNativeInitializationError);
    userTryTerraNativeTriggerWebhookError$ = this.#store.select(selectUserTryTerraNativeTriggerWebhookError);

    constructor() {
    }

    ngOnInit() {
        this.requestImportHealthData();
    }

    requestImportHealthData() {
        this.user$.pipe(first((value) => value != null)).subscribe({
            next: (user) => {
                if (!user) {
                    return;
                }
                this.#store.dispatch(
                    initializeNativeTryTerra({triggerAllDataWebhook: true})
                );
            },
        });
    }

    completeImport() {
        this.#navigationService.navigateBack();
    }
}
