import {Component, inject, OnInit} from '@angular/core';
import {
    HealthChartConfiguration, HealthChartPeriod,
    HealthDataType,
} from '../../../../../../models/health.models';
import {NavigationService} from '../../../../../../services/navigation.service';
import {HealthDataPageComponent} from '../../../../../../components/health-data-page/health-data-page.component';
import {dataType} from "terra-api/lib/esm/API/Data";

@Component({
    selector: 'app-bmi-page',
    standalone: true,
    imports: [HealthDataPageComponent],
    templateUrl: './bmi-page.component.html',
    styleUrl: './bmi-page.component.css',
})
export default class BmiPageComponent implements OnInit {
    #navigationService = inject(NavigationService);

    healthDataLabel = 'Indice Massa Corporea';
    chartLabel = 'Indice Massa Corporea';
    chartConfiguration: HealthChartConfiguration = {
        [HealthChartPeriod.day]: 'bubble',
        [HealthChartPeriod.week]: 'line',
        [HealthChartPeriod.month]: 'line',
    }
    supportedPeriods: HealthChartPeriod[] = [HealthChartPeriod.week, HealthChartPeriod.month];
    heightHealthDataType = HealthDataType.BODY_MASS_INDEX;
    hasTwoDecimals = true;
    payloadTypes: dataType[] = ['body'];

    ngOnInit(): void {
        this.#navigationService.saveCurrentRoute();
    }
}
