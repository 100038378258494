import {Route} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {APP_ROUTES} from "../../app.routes.definition";
import AskImportHealthDataPageComponent from "./ask-import-health-data-page.component";
import TutorialPageComponent from "./pages/tutorial-page/tutorial-page.component";
import ImportPageComponent from "./pages/import-page/import-page.component";

export const ASK_IMPORT_HEALTH_DATA_ROUTES: Route[] = [
    {
        path: '',
        component: AskImportHealthDataPageComponent,
    },
    {
        path: APP_ROUTES.ASK_IMPORT_HEALTH_DATA_TUTORIAL(true),
        data: {title: 'Tutorial'},
        component: TutorialPageComponent,
    },
    {
        path: APP_ROUTES.ASK_IMPORT_HEALTH_DATA_IMPORT(true),
        data: {title: 'Import'},
        component: ImportPageComponent,
    },
];
