import { Component, inject } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import {
    Route,
    RouterModule,
} from '@angular/router';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {Store} from "@ngrx/store";
import {AppState} from "../../store/app.state";
import {selectUserHasUnreadMessages} from "../../store/user/user.selectors";
import {APP_ROUTES} from "../../app.routes.definition";
import {TranslocoPipe} from "@ngneat/transloco";

@Component({
    selector: 'bottom-navigation-bar',
    standalone: true,
    imports: [RouterModule, AsyncPipe, NgClass, TranslocoPipe, NgIf],
    templateUrl: './bottom-navigation-bar.component.html',
    styleUrl: './bottom-navigation-bar.component.css',
})
export class BottomNavigationBarComponent {
    #navigationService = inject(NavigationService);
    #store = inject(Store<AppState>);

    routes: Route[] = [];

    userHasUnreadMessages$ = this.#store.select(selectUserHasUnreadMessages);
    messagingPath = APP_ROUTES.MESSAGING();

    ngOnInit(): void {
        this.routes = this.getRoutes();
    }

    private getRoutes(): Route[] {
        return this.#navigationService.getNavigationRoutes();
    }
}
