import { Route } from '@angular/router';
import {APP_ROUTES} from "../../app.routes.definition";
import MonitorPageComponent from "./monitor-page.component";
import {HEART_CATEGORY_ROUTES} from "./pages/heart-category-page/heart-category.routes";
import {BODY_CATEGORY_ROUTES} from "./pages/body-category-page/body-category.routes";
import {ACTIVITY_CATEGORY_ROUTES} from "./pages/activity-category-page/activity-category.routes";
import {SLEEP_CATEGORY_ROUTES} from "./pages/sleep-category-page/sleep-category.routes";
import {CreateInternalHealthDataPageComponent} from "./pages/create-internal-health-data-page/create-internal-health-data-page.component";
import {
    ViewInternalHealthDataPageComponent
} from "./pages/view-internal-health-data-page/view-internal-health-data-page.component";
import {
    EditInternalHealthDataPageComponent
} from "./pages/edit-internal-health-data-page/edit-internal-health-data-page.component";

export const MONITOR_ROUTES: Route[] = [
    {
        path: '',
        component: MonitorPageComponent,
    },
    {
        path: APP_ROUTES.MONITOR_CREATE_INTERNAL_HEALTH_DATA(true, ':type'),
        data: { title: 'Create Internal Health Data', showInNavbar: false, showAvatar: false },
        component: CreateInternalHealthDataPageComponent
    },
    {
        path: APP_ROUTES.MONITOR_EDIT_INTERNAL_HEALTH_DATA(true, ':type', ':id'),
        data: { title: 'Edit Internal Health Data', showInNavbar: false, showAvatar: false },
        component: EditInternalHealthDataPageComponent
    },
    {
        path: APP_ROUTES.MONITOR_VIEW_INTERNAL_HEALTH_DATA(true, ':type'),
        data: { title: 'View Internal Health Data', showInNavbar: false, showAvatar: false },
        component: ViewInternalHealthDataPageComponent
    },
    {
        path: APP_ROUTES.MONITOR_HEART(true),
        data: { title: 'Heart', showInNavbar: false, showAvatar: false },
        children: HEART_CATEGORY_ROUTES,
    },
    {
        path: APP_ROUTES.MONITOR_BODY(true),
        data: { title: 'Body', showInNavbar: false, showAvatar: false },
        children: BODY_CATEGORY_ROUTES,
    },
    {
        path: APP_ROUTES.MONITOR_ACTIVITY(true),
        data: { title: 'Activity', showInNavbar: false, showAvatar: false },
        children: ACTIVITY_CATEGORY_ROUTES,
    },
    {
        path: APP_ROUTES.MONITOR_SLEEP(true),
        data: { title: 'Sleep', showInNavbar: false, showAvatar: false },
        children: SLEEP_CATEGORY_ROUTES,
    },
];
