<section class="bg-body-bg h-screen flex flex-col justify-between">
    @if (isRegistrationCompleted) {

        <!-- TODO: Mettere la logica per visualizzare l'icona giusta -->
        <app-registration-dash-icon>
            <svg class="w-14 h-auto fill-primary">
                <use href="../../../assets/svg/svGP.svg#paste-clipboard"></use>
            </svg>
        </app-registration-dash-icon>
        <div class="mt-10">
            <div class="text-center px-5">
                <h1 class="text-primary font-semibold text-3xl">
                    Complimenti! Hai completato la registrazione
                </h1>
            </div>
            <div class="mt-24 mx-5">
                <img
                    src="../assets/img/registration-images/completato-registrazione.png"
                    class="w-full rounded-lg"
                    alt=""
                />
            </div>
            <div class="mx-5 mb-5">
                <button [routerLink]="loginPath"
                        class="py-5 px-4 border-0 bg-primary rounded-lg flex items-center justify-center mt-14 w-full"
                >
                    <div class="flex items-center justify-center">
                        <span class="text-white text-sm font-bold uppercase">Accedi</span>
                    </div>
                </button>
            </div>
        </div>
    } @else {
        <div class="basis-10/12">
            <div class="pt-7">
                <div class="bg-white m-5 rounded-3xl">
                    <app-carousel [slides]="slides"></app-carousel>
                </div>
            </div>
        </div>
        <div class="basis-2/12">
            <div class="h-full flex flex-col bg-white pt-7 rounded-t-3xl">
                <button
                    class="bg-primary mx-5 py-5 rounded-lg mb-3"
                    [routerLink]="loginPath"
                    (click)="sendGoogleAnalyticsClickLoginEvent()"
                >
                    <span
                        class="text-white font-bold text-sm uppercase">{{ "pages.auth-page.signInLabel" | transloco }}</span>
                </button>
                <button
                    class="bg-white mx-5 py-5 rounded-lg"
                    [routerLink]="registerPath"
                    (click)="sendGoogleAnalyticsClickRegisterEvent()"
                    [ngClass]="{
                        'mb-3': isNativeAPIAvailable
                    }"
                >
                    <span
                        class="text-primary font-bold text-sm uppercase">{{ "pages.auth-page.signUpLabel" | transloco }}</span>
                </button>
                <div *ngIf="!isNativeAPIAvailable"
                     class="bg-white mx-5 py-5 mb-3 text-center flex flex-row justify-center items-center"
                >
                    <span
                        class="text-sm">{{ "pages.auth-page.linkNativeAppLabel" | transloco }}</span>
                    <a [href]="storeUrl.apple" target="_blank" class="ml-5">
                        <img src="../../../assets/svg/download-on-the-app-store-apple-logo.svg" alt="" class="max-w-24 w-full">
                    </a>
                    <a [href]="storeUrl.googlePlay" target="_blank" class="ml-5">
                        <img src="../../../assets/svg/get-it-on-google-play-logo.svg" alt="" class="max-w-24 w-full">
                    </a>
                </div>
                <div class="text-center mb-6" *ngIf="iubendaIsAvailable()">
                    <a href="#" (click)="openIubendaPref($event)"
                       class="iubenda-cs-preferences-link mt-2 text-sm text-primary underline">
                        {{ "pages.auth-page.cookiePolicyPreferencesLabel" | transloco }}
                    </a>
                </div>
            </div>
        </div>
    }
</section>
