import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';

export function ageValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }

        const maxRange = dayjs().subtract(120, 'year');
        const minRange = dayjs().subtract(18, 'year');
        const birthDateFullYear = new Date(control.value).getFullYear();

        if (birthDateFullYear < maxRange.year()) {
            return {
                invalidAge: {
                    value: control.value,
                },
            };
        }

        const birthDate = dayjs(control.value);
        const res = birthDate.isBetween(maxRange, minRange, 'day', '[]' );
        if (!res) {
            return {
                invalidAge: {
                    value: control.value,
                },
            };
        }
        return null;
    };
}
