import {Component, inject} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {selectCreateOrderIsLoading} from '../../store/create-order/create-order.selectors';
import {
    selectUserBillingInfoIsValid,
    selectUserPendingOrder,
} from '../../store/user/user.selectors';
import {
    cancelOrder,
    completeOrder,
} from '../../store/create-order/create-order.actions';
import {Observable} from 'rxjs';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {Order} from '../../models/order.model';
import {APP_ROUTES} from '../../app.routes.definition';
import {TranslocoModule} from '@ngneat/transloco';
import {NavigationService} from "../../services/navigation.service";

@Component({
    selector: 'app-pending-order-page',
    standalone: true,
    imports: [AsyncPipe, NgIf, RouterLink, TranslocoModule],
    templateUrl: './pending-order-page.component.html',
    styleUrl: './pending-order-page.component.css',
})
export default class PendingOrderPageComponent {
    #store = inject(Store<AppState>);
    #activatedRoute = inject(ActivatedRoute);
    #navigationService = inject(NavigationService);

    isCreatingOrder$ = this.#store.select(selectCreateOrderIsLoading);
    isUserBillingInfoValid$ = this.#store.select(selectUserBillingInfoIsValid);

    order$: Observable<Order | undefined>;

    orderId: string;

    homePath = APP_ROUTES.HOME();
    billingInfoPath = APP_ROUTES.SETTINGS_BILLING_INFO();

    constructor() {
        this.#navigationService.saveCurrentRoute();

        this.orderId = this.#activatedRoute.snapshot.params['id'] ?? '';
        this.order$ = this.#store.select(selectUserPendingOrder(this.orderId));
    }

    completeOrder() {
        this.#store.dispatch(
            completeOrder({
                id: this.orderId,
            })
        );
    }

    cancelOrder() {
        this.#store.dispatch(
            cancelOrder({
                id: this.orderId,
            })
        );
    }
}
